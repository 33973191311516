import { LinearProgress } from "@mui/material";
import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { DimensionTypes } from '../../api/models/Global';
import { loadCompanyDetails, loadResourceDetails, loadServiceDetails, loadVisitAndAllDetails } from '../../store/details/details.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { CompanyDetailsView } from '../details/CompanyDetailsView';
import { ResourceDetailsView } from '../details/ResourceDetailsView';
import { ServiceDetailsView } from '../details/ServiceDetailsView';
import { VisitDetailsView } from '../details/VisitDetailsView';
import { BackArrowHeader } from '../pages/BackArrowHeader';
import ScrollToTop from "../ui/ScrollToTop";
import { L10N } from './L10N';
import { PageHeader } from "./PageHeader";


export const DetailsPage = () => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state: RootState) => state.user);
    const { visit, company, resource, inProgress } = useAppSelector((state: RootState) => state.details);
    const urlParams = useParams<{ tab: DimensionTypes, id: string }>();

    useEffect(() => {
        if (inProgress) return;
        if (!urlParams.id) return;

        if (urlParams.tab === "visit") {
            if (!user) return;
            dispatch(loadVisitAndAllDetails({ message: user, visitID: urlParams.id }));
        } else if (urlParams.tab === "company") {
            dispatch(loadCompanyDetails({ companyID: urlParams.id }));
        } else if (urlParams.tab === "service") {
            dispatch(loadServiceDetails({ serviceID: urlParams.id }));
        } else if (urlParams.tab === "resource") {
            dispatch(loadResourceDetails({ resourceID: urlParams.id }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ScrollToTop />
            <Container>
                <PageHeader showLogo />
                {inProgress && <LinearProgress className="w-100 mt-3" color="secondary" />}
                {urlParams.tab === "visit" && visit && <Container>
                    <BackArrowHeader title={
                        L10N({ key: "_dateAsddd", date: visit.visitStartForClient })
                        + ", "
                        + L10N({ key: "_dateAsDDMMMHHmm", date: visit.visitStartForClient })
                    } />
                    <VisitDetailsView />
                </Container>}
                {urlParams.tab === "service" && <ServiceDetailsView />}
                {urlParams.tab === "company" && <>
                    {company && <CompanyDetailsView defaultExpanded={urlParams.tab === "company"} />}
                </>}
                {urlParams.tab === "resource" && <>
                    {resource && <ResourceDetailsView defaultExpanded={urlParams.tab === "resource"} />}
                </>}
            </Container>
        </>
    );
};
