import { Avatar, Chip } from "@mui/material";
import React from "react";
import { SearchHint } from '../../api/models/Search';
import { L10N } from '../pages/L10N';
import { DimensionIcon } from '../ui/DimensionIcon';
import { theme } from "../ui/Theme";

export interface SearchChipProps {
    data: SearchHint,
    isChecked?: boolean,
    onChange?: (isChecked: boolean) => void,
}
export const SearchChip = (props: SearchChipProps) => {

    function onClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        if (props.onChange) {
            props.onChange(!props.isChecked);
        }
    }
    return (
        <Chip label={L10N({ key: "_hint", hint: props.data.hintText })}
            clickable
            avatar={<Avatar
                style={{
                background: 'none',
            }}>
                <DimensionIcon type={props.data.hintType} variant={props.isChecked ? "light" : "dark"} />
            </Avatar>}
            onClick={props.isChecked !== null ? onClick : undefined}
            variant={props.isChecked ? "filled" : "outlined"}
            color={props.isChecked ? "primary" : "secondary"}
            className={"m-1"}
            sx={props.isChecked ? {
                '&:hover': {
                    backgroundColor: theme.palette.primary.main, // Remove hover background change
                    boxShadow: theme.shadows[3],
                },
                color: theme.palette.common.white,
            } : {

            }}
        />
    );
};
