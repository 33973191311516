export const MarketingLink = (props: {
    href?: string;
    className?: string,
    platform: "fb" | "in" | "yt",
}) => {
    function defaultHref() {
        switch (props.platform) {
            case "fb": return "https://www.facebook.com/classnow.pl";
            case "in": return "https://www.instagram.com/classnow.pl";
            case "yt": return "https://www.youtube.com/@classnow.pl/videos";
        }
    }
    function icon() {
        switch (props.platform) {
            case "fb": return "/img/marketing/facebook.png";
            case "in": return "/img/marketing/instagram.png";
            case "yt": return "/img/marketing/youtube.png";
        }
    }
    return (
        <a href={props.href ?? defaultHref()}
            target="_blank"
            className={props.className}
        >
            <img src={icon()}
                width="32"
                style={{ verticalAlign: "text-bottom" }} />
        </a>);
};

