import { MenuItem, TextField, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { upperCase } from 'lodash';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { changeLanguage } from '../../store/user/user.reducer';
import { L10N } from '../pages/L10N';

const useStyles = makeStyles((theme) =>
    createStyles({
        img: {
            position: 'absolute',
            margin: 'auto',
            top: '0',
            left: '10px',
            bottom: '0',
        },
        select: {
            '& .MuiSelect-root': {
                padding: '17px 32.5px 17px 6px',
            },
        }
    })
);
export const LanguageSelect = (props: {
    lang: string,
    disabled?: boolean,
    onChange: (value: string) => void,
    fullWidth?: boolean,
    hideLabel?: boolean,
}) => {
    const classes = useStyles();
    function langMenuItem(code: string, name: string): JSX.Element {
        return (<MenuItem value={code}>
            <img src={`/img/flags/${code}.png`} height={30} alt={name}
                className={classes.img + " my-auto p-0"}
            />
            <Typography className="ml-4" align="right">
                {upperCase(code)}
            </Typography>
        </MenuItem>);
    }
    return (
        <TextField variant="outlined" color="primary"
            placeholder={L10N({ key: "Language" })}
            label={(props.hideLabel ?? false) ? undefined : L10N({ key: "Language" })}
            disabled={props.disabled}
            select
            fullWidth={props.fullWidth}
            value={props.lang}
            onChange={(event) => props.onChange(event.target.value)}
            className={classes.select}
        >
            {langMenuItem("en", L10N({ key: "English" }))}
        </TextField>
    );
};
export const LanguageSelectFlag = (props: {
    disabled?: boolean,
    fullWidth?: boolean,
    hideLabel?: boolean,
}) => {
    const dispatch = useAppDispatch();
    const { lang } = useAppSelector((state: RootState) => state.user);


    function langMenuItem(code: string, name: string): JSX.Element {
        return (<MenuItem value={code}
            className={" m-0 p-0"}
            style={{
                backgroundColor: 'transparent'
            }}

        >
            {/*<img src={`/img/flags/${code}.png`} height={30} alt={name}*/}
            {/*    className={" m-0 p-0"}*/}
            {/*/>*/}
            <Typography className="mr-4" align="right">
                {upperCase(code)}
            </Typography>
        </MenuItem>);
    }
    return (
        <TextField variant="standard"
            placeholder={L10N({ key: "Language" })}
            label={(props.hideLabel ?? false) ? undefined : L10N({ key: "Language" })}
            disabled={props.disabled}
            className={" m-0 p-0 "}
            style={{
                paddingTop: 0,
                paddingBottom: 0,
                marginTop: 0,
                marginBottom: 0,
            }}
            select
            SelectProps={{
                style: {
                    backgroundColor: 'rgba(120,120,120,0.50)',
                    paddingTop: 0,
                    height: '34px',
                    borderRadius: '8px',
                }
            }}
            fullWidth={props.fullWidth}
            value={lang}
            onChange={(event) => dispatch(changeLanguage({ lang: event.target.value }))}
        >
            {langMenuItem("en", L10N({ key: "English" }))}
            {langMenuItem("de", L10N({ key: "German" }))}
        </TextField>
    );
};

export type Language = {
    region: string,
    name: string,
    nativeName: string,
}
const OTHER = "other";
export const indianLangs: Language[] = [
    { name: "Malayalam", region: "Kerala", nativeName: "മലയാളം" },
    { name: "Telugu", region: "Andhra Pradesh, Telangana", nativeName: "తెలుగు" },
    { name: "Tamil", region: "Tamil Nadu", nativeName: "தமிழ்" },
    { name: "Hindi", region: "Bihar, Chhattisgarh, Haryana, Himachal, Pradesh Jharkhand, Madhya, Pradesh Rajasthan, Uttar, Pradesh Uttarakhand", nativeName: "हिन्दी" },
    { name: "Kannada", region: "Karnataka", nativeName: "ಕನ್ನಡ" },
    { name: OTHER, region: "", nativeName: "" },
];
export const europeLangs: Language[] = [
    { name: "English", region: "Global", nativeName: "English" },
    { name: "French", region: "France", nativeName: "" },
    { name: "German", region: "Germany", nativeName: "" },
    { name: "Polish", region: "Poland", nativeName: "Polski" },
    { name: "Spanish", region: "Spain", nativeName: "" },
    { name: OTHER, region: "", nativeName: "" },
];
export const LanguageFlagsToggleGroup = (props: ToggleButtonGroupProps & {
    showEuropeLangs?: boolean,
    showIndianLangs?: boolean,
    showOther?: boolean,
}) => {
    const {
        showEuropeLangs,
        showIndianLangs,
        showOther,
        ...toggleProps
    } = props;

    return (
        <ToggleButtonGroup
            color='primary'
            fullWidth
            orientation="vertical"
            {...toggleProps}
        >
            {props.showEuropeLangs &&
                europeLangs
                    .filter(m => props.showOther || m.name !== OTHER)
                    .map((m, i) => <ToggleButton key={m.name}
                        value={m.name}
                    >
                        <Typography align="left" className="w-100">
                            {m.name}
                        </Typography>
                    </ToggleButton>)
            }
            {props.showIndianLangs &&
                indianLangs
                    .filter(m => props.showOther || m.name !== OTHER)
                    .map((m, i) => <ToggleButton key={m.name}
                        value={m.name}
                    >
                        <Typography align="left" className="w-100">
                            {m.name} {m.nativeName && `(${m.nativeName})`}
                        </Typography>
                    </ToggleButton>)
            }
        </ToggleButtonGroup>
    );
};
