import { createSlice } from '@reduxjs/toolkit';
import { MapCoords } from '../../api/models/Details';
import { SearchCategories, SearchHints, SearchResults } from '../../api/models/Search';
import { addInProgressCases, addInProgressMatcher } from '../reduxHelpers';
import { changeSearchString, refreshFavorites, refreshHints, startSearch } from './search.actions';

export interface SearchState {
    inProgress: boolean;
    hasSearched: boolean;
    searchString: string;
    searchStringWithoutTags: string;
    searchHintsInProgress: boolean;
    searchHints?: SearchHints;
    searchCategories?: SearchCategories;
    exploreWhenHints?: SearchHints;
    searchResults?: SearchResults;
    searchResultsPrev?: SearchResults;
    featuredResults?: SearchResults;
    favoriteResults?: SearchResults;
    currentLocation?: MapCoords;
}
const initialState: SearchState = {
    inProgress: false,
    hasSearched: false,
    searchString: "",
    searchStringWithoutTags: "",
    searchHintsInProgress: false,
    searchHints: undefined,
    searchCategories: undefined,
    exploreWhenHints: undefined,
    searchResults: undefined,
    searchResultsPrev: undefined,
    featuredResults: undefined,
    favoriteResults: undefined,
    currentLocation: undefined,
}

const searchSlice = createSlice({
    name: 'searchSlice',
    initialState,
    reducers: {
        setHasSearched(state) {
            state.hasSearched = true;
        },
    },
    extraReducers: (builder) => {
        addInProgressCases(builder, changeSearchString);
        addInProgressCases(builder, refreshFavorites);
        addInProgressCases(builder, refreshHints);
        addInProgressCases(builder, startSearch);

        builder.addCase(changeSearchString.fulfilled, (state, action) => {
            state.searchString = action.payload.searchString;
            state.searchStringWithoutTags = action.payload.searchStringWithoutTags;
            state.currentLocation = action.payload.currentLocation;
            state.hasSearched = action.payload?.hasSearched ?? false;
        })
        builder.addCase(refreshFavorites.fulfilled, (state, action) => {
            state.favoriteResults = action.payload?.favoriteResults;
        })
        builder.addCase(refreshHints.fulfilled, (state, action) => {
            state.exploreWhenHints = action.payload?.exploreWhenHints;
            state.searchCategories = action.payload?.searchCategories;
            state.searchHints = action.payload?.searchHints;
        })
        builder.addCase(startSearch.fulfilled, (state, action) => {
            state.searchResults = action.payload?.searchResults;
            state.hasSearched = action.payload?.hasSearched ?? false;
        })
        addInProgressMatcher(builder, changeSearchString);
        addInProgressMatcher(builder, refreshFavorites);
        addInProgressMatcher(builder, refreshHints);
        addInProgressMatcher(builder, startSearch);
    },
});
export const { setHasSearched } = searchSlice.actions
export default searchSlice.reducer




