import { Avatar, Box, Button, CardProps, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { api } from '../../api/api';
import { VisitReviewDetails } from '../../api/models/Details';
import { RootState, useAppSelector } from '../../store/store';
import { L10N } from '../pages/L10N';
import { AppRoutes } from '../pages/Routes';
import { EmiCard } from '../ui/EmiCard';
import { LogotypeTokens } from "../ui/LogotypeSpans";
import { EmiRating } from '../ui/Rating';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            width: theme.spacing(5),
            height: theme.spacing(5),
            margin: '2px',
        },
    })
);

export const VisitReviewItemCard = (props: CardProps & {
    data: VisitReviewDetails,
}) => {
    const navigate = useNavigate();
    const { user } = useAppSelector((state: RootState) => state.user);
    const { data,
        ...cardProps } = props;
    const classes = useStyles();
    function editClick(event: React.MouseEvent<any, MouseEvent>) {
        if (!data) return;
        event.preventDefault();
        event.stopPropagation();
        const route = AppRoutes.visitReviewEditPage(data.visitID, data.visitReviewID);
        navigate(route);
    }
    return (
        <>
            <EmiCard
                {...cardProps}
                elevation={1}
            >
                <Container>
                    <Row className="px-3 pt-3">
                        <Col className="pl-0 align-self-center col-auto" >
                            <Avatar
                                alt={data.reviewAuthor}
                                className={classes.avatar}
                                src={api.Search.getFirstPhotoUrl(data.photos)} />
                        </Col>
                        <Col className="p-0 align-self-center" >
                            <Typography variant="body1" align="left" >
                                <LogotypeTokens text={data.reviewAuthor} />
                            </Typography>
                            <EmiRating value={data.ratingScore} className="float-left mt-n1" />
                            {data.reviewDate &&
                                <Typography variant="body1" align="left" >
                                    {L10N({ key: "_dateAsTimeAgo", date: data.reviewDate })}
                                </Typography>
                            }
                        </Col>
                    </Row>
                    <Row className="px-3 pb-3">
                        <Col className="p-0">
                            <Typography variant="body1" align="left" className="my-1">
                                <LogotypeTokens text={data.reviewTitle} />
                            </Typography>
                            <Typography variant="body2" align="left">
                                <LogotypeTokens text={data.reviewDetails} />
                            </Typography>
                        </Col>
                    </Row>
                    <Box sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                    }}>
                        {user && props.data?.userID === user?.userID &&
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={editClick}>
                                {L10N({ key: "Edit" })}
                            </Button>}
                    </Box>
                </Container>
            </EmiCard>
        </>
    );
};
