import { Accordion, AccordionDetails, TextField, Typography } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { L10N } from '../pages/L10N';
import { CspIcon } from '../ui/CspIcon';
import { FormikFieldProps } from '../ui/FormikAPI';
import { LogotypeSpans } from "../ui/LogotypeSpans";
import { StyledTitleRow } from '../ui/StyledTitle';
import { FieldSetProps } from './FormStudentWizard';

export const FieldSetExperience = (props: FieldSetProps) => {

    return <>
        <Accordion defaultExpanded={false} >
            <AccordionDetails className="d-block pt-3 my-3">
                <Row>
                    <Col xs={12} md={6} >
                        <StyledTitleRow text={"Yourself as a professional"} hideDivider subtitle />
                        <TextField className="mb-3"
                            label={L10N({ todo: "Short description of your experience" })}
                            {...FormikFieldProps(props.formik, m => m.description)}
                        /><br />
                    </Col>
                    <Col xs={12} md={6} >
                        <StyledTitleRow text={"Why is it important?"} hideDivider subtitle />
                        <Typography color="secondary">
                            Short description and a proof of your experience help us in:
                            <br />
                            - Verification and Credibility
                            <br />
                            - Professional Growth and Opportunity
                            <br />
                            - Efficiency in Application Processes
                            <br />
                            <br />
                            At <LogotypeSpans /> we review all candidates personally to maintain highest quality <CspIcon name="WorkspacePremiumIcon" />.
                        </Typography>
                    </Col>
                </Row>
            </AccordionDetails>
        </Accordion>
    </>;
}