import { Typography } from "@mui/material";
import { Col, Row } from 'react-bootstrap';
import { CspDivider } from "./CspDivider";

export type StyledTitleProps = {
    text?: string | JSX.Element,
    alignLeft?: boolean,
    subtitle?: boolean,
    className?: string,
    hideDivider?: boolean,
}
export const StyledTitleRow = (props: StyledTitleProps) => {
    return <Row className={"mb-3 " + props.className}>
        {!props.alignLeft && <Col />}
        <Col className="col-auto">
            <StyledTitle {...props} />
        </Col>
        <Col />
    </Row>
}
export const StyledTitle = (props: StyledTitleProps) => {
    return <div className={" " + props.className}>
        <Typography variant={props.subtitle ? "subtitle1" : "h6"} align="center"
            color="secondary"
            style={{
                position: 'relative',
                marginTop: 20,
                //marginLeft:-28,
                fontWeight: 'bold',
            }}>
            {props.text ?? ""}
        </Typography>
        {!props.hideDivider &&
            <CspDivider />
        }
    </div>
}