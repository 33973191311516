import { Col } from 'react-bootstrap';
import { changeSearchString } from '../../store/search/search.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { SearchChip } from './SearchChip';

export const SearchChips = () => {
    const dispatch = useAppDispatch();
    const { searchString, searchHints } = useAppSelector((state: RootState) => state.search);

    async function handleChange(isChecked: boolean, chip: string) {
        let newSearchString = searchString;
        if (!isChecked) {
            const regEx = new RegExp(chip, "ig");
            newSearchString = newSearchString.replace(regEx, "");
        } else {
            newSearchString = newSearchString + " " + chip;
        }
        await dispatch(changeSearchString({ searchString: newSearchString }));
    }
    return (
        <>
            <Col>
                {searchHints?.results?.map &&
                    <div className="noScroll"
                        style={{
                            display: "flex",
                            overflow: "scroll",
                            scrollbarWidth: "none",
                        }}>
                        {searchHints.results?.map((m, i) =>
                            <div key={i} className="float-left mb-1" >
                                <SearchChip key={i}
                                    onChange={(isChecked) => handleChange(isChecked, m.encodedText)}
                                    isChecked={searchString.includes(m.encodedText)}
                                    data={m} />
                            </div>
                        )}
                    </div>
                }
            </Col>
        </>
    );
}
