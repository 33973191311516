import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api/api';
import { AssignCompanyMessage } from '../../api/models/Edit';
import { AccessTokenBearer } from "../../api/models/Global";
import { UserProfile, UserRefreshAccessTokenMessage, UserRefreshPINMessage } from "../../api/models/User";

export const refreshPIN = createAsyncThunk(
    'thunk/refreshPIN',
    async (args: {
        message: UserRefreshPINMessage
    }, thunkAPI) => {
        const apiResponse = await api.Users.refreshPIN(args.message);

        if (apiResponse.error) throw apiResponse.error;
        return { user: apiResponse.result };
    },
)
export const refreshAccessToken = createAsyncThunk(
    'thunk/refreshAccessToken',
    async (args: {
        message: UserRefreshAccessTokenMessage
    }, thunkAPI) => {
        const apiResponse = await api.Users.refreshAccessToken(args.message);

        if (apiResponse.error) throw apiResponse.error;
        return { user: apiResponse.result };
    },
)

export const getCurrentUser = createAsyncThunk(
    'thunk/getCurrentUser',
    async (args: {
        message: AccessTokenBearer
    }, thunkAPI) => {
        const apiResponse = await api.Users.getCurrentUser(args.message);

        if (apiResponse.error) throw apiResponse.error;
        return { user: apiResponse.result };
    },
)

export const toggleFavorites = createAsyncThunk(
    'thunk/toggleFavorites',
    async (args: {
        user: UserProfile,
        companyID: string,
    }, thunkAPI) => {
        const apiResponse = await api.Users.toggleFavoriteCompany({
            accessToken: args.user.accessToken,
            companyID: args.companyID,
        });

        if (apiResponse.error) throw apiResponse.error;
        return {
            user: apiResponse.result,
            inProgressFavoriteCompanyID: args.companyID,
        };
    },
)

export const updateUserProfile = createAsyncThunk(
    'thunk/updateUserProfile',
    async (args: {
        message: UserProfile
    }, thunkAPI) => {
        const apiResponse = await api.Users.updateUserProfile(args.message);

        if (apiResponse.error) throw apiResponse.error;
        return { user: apiResponse.result };
    },
)

export const assignCompany = createAsyncThunk(
    'thunk/assignCompany',
    async (args: {
        message: AssignCompanyMessage
    }, thunkAPI) => {
        const apiResponse = await api.Edit.assignCompany(args.message);

        if (apiResponse.error) throw apiResponse.error;
        return { user: apiResponse.result };
    },
)

export const logout = createAsyncThunk(
    'thunk/logout',
    async (args: {
        message: AccessTokenBearer
    }, thunkAPI) => {
        const apiResponse = await api.Users.logout(args.message);

        if (apiResponse.error) throw apiResponse.error;
        return { user: null };
    },
)

export const refreshReminders = createAsyncThunk(
    'thunk/refreshReminders',
    async (args: {
    }, thunkAPI) => {
        const apiResponse = await api.Admin.refreshReminders();

        if (apiResponse.error) throw apiResponse.error;
        return { remindersLog: apiResponse.result };
    },
)
