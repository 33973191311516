import { Box, CardActionArea, CardContent, CardHeader, CardMedia, CardProps, Fab, LinearProgress, Typography } from "@mui/material";
import * as React from 'react';
import { RootState, useAppSelector } from 'src/store/store';
import { api } from '../../api/api';
import { SearchResult } from '../../api/models/Search';
import { L10N } from '../pages/L10N';
import { CategoryIconURL } from '../ui/CategoryIcon';
import { CspIcon } from "../ui/CspIcon";
import { EmiCard } from '../ui/EmiCard';
import { FavoriteIcon } from '../ui/FavoriteIcon';
import { LogotypeTokens } from "../ui/LogotypeSpans";
import { theme } from '../ui/Theme';

export const SearchCard = (props: CardProps & {
    data: SearchResult,
    onCardClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, card: SearchResult) => void,
    showText?: boolean,
    showImage?: boolean,
    inProgress?: boolean,
}) => {
    const { user } = useAppSelector((state: RootState) => state.user);

    const {
        data,
        onCardClick,
        showText,
        showImage,
        inProgress,
        ...cardProps } = props;

    const imgHeight = 194;
    const radius = theme.shape.borderRadius;
    const dist = 10;
    const imgSrc = api.Search.getFirstPhotoUrl(data.photos);
    return (
        <>
            <EmiCard {...cardProps}
                elevation={6}
                onClick={(event) => props.onCardClick(event, data)}
                style={{
                    cursor: "pointer",
                }}
            >
                {props.showImage  && <Box>
                    <CardMedia title={data.title}
                        component="img"
                        src={imgSrc
                            ?? '/img/marketing-bg.svg'
                        }
                        height={imgHeight}
                    />
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: imgHeight,
                        width: '100%',
                        background: imgSrc ? '' : `url(${CategoryIconURL(data.subtitle, true)})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',

                    }}>
                        {data?.ratingScore &&
                            <Box sx={{
                                position: 'absolute',
                                top: dist,
                                left: dist,
                            }}>
                                <Fab disabled
                                    variant='extended'
                                    className='py-1 px-2 text-white-shadow'
                                    style={{
                                        height: 'auto',
                                        minHeight: 1,
                                    }}
                                >
                                    <CspIcon name="StarRateOutlinedIcon" />
                                    <Typography variant='body2' style={{
                                        color: "inherit",
                                    }}>
                                        {data.ratingScore?.toFixed(1)}
                                    </Typography>
                                </Fab>
                            </Box>
                        }
                        {data?.badgeLevel &&
                            <Box sx={{
                                position: 'absolute',
                                top: dist,
                                right: dist,
                            }}>
                                <Fab disabled
                                    variant='extended'
                                    className='py-1 px-2 text-white-shadow'
                                    style={{
                                        height: 'auto',
                                        minHeight: 1,
                                    }}
                                >
                                    <Typography variant='body2' style={{
                                        color: "inherit",
                                    }}>
                                        certified
                                    </Typography>
                                    <CspIcon name="WorkspacePremiumIcon" />
                                </Fab>
                            </Box>
                        }
                    </Box>
                </Box>}

                {props.inProgress && <LinearProgress color="primary" />}
                <CardHeader
                    style={{
                        borderRadius: radius,
                        position: 'relative',
                        borderColor: theme.palette.primary.main,
                        marginTop: -radius,
                        background: theme.palette.background.paper,
                        boxShadow: 'darkgray 0px -2px 6px',
                        minHeight: imgHeight * 0.7,
                    }}
                    title={
                        <CardActionArea>
                            <Typography variant="body1" color="secondary">
                                <LogotypeTokens text={data.title} />
                            </Typography>
                            <Typography variant="body2" color="secondary">
                                {L10N({ key: "_category", cat: data.subtitle })}
                            </Typography>
                            {data.addressLine && data.addressLine.length > 0 &&
                                <div>
                                    <Typography variant="body2" color="secondary">
                                        {data.addressLine.map((m, i) => <span key={m}>
                                            {m}<br />
                                        </span>)}
                                    </Typography>
                                </div>
                            }
                        </CardActionArea>
                    }
                />
                {user &&
                    <Box sx={{
                        position: 'absolute',
                        right: dist,
                        bottom: dist,
                    }}>
                        <FavoriteIcon companyID={data.companyID} />
                    </Box>
                }
                {props.showText &&
                    <CardActionArea>
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {data.details}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                }
            </EmiCard >
        </>
    );
};
