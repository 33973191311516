import { Avatar, CardActionArea, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Col, Container, Row } from 'react-bootstrap';
import { api } from '../../api/api';
import { ServiceEditModel } from '../../api/models/Edit';
import { RootState, useAppSelector } from '../../store/store';
import { L10N } from '../pages/L10N';
import { Animations } from '../ui/Animations';
import { CspIcon } from "../ui/CspIcon";
import { EmiCard } from '../ui/EmiCard';
import { EmiPaper } from '../ui/EmiPaper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            margin: '2px',
            cursor: 'pointer',
        },
        card: {
            minHeight: '80px',
            padding: theme.spacing(1),
            margin: theme.spacing(1),
        },
    })
);
export const ServiceListCard = (props: {
    cardIndex: number,
    model: ServiceEditModel,
    onClick: (item: ServiceEditModel) => void,
}) => {
    const classes = useStyles();

    return <EmiCard
        elevation={1}
        className={classes.card
            + " " + Animations.swing_in_top_fwd() + " " + Animations.delay(props.cardIndex)}
        onClick={() => props.onClick(props.model)}
    >
        <CardActionArea>
            <Container className="m-0 p-0">
                <Row>
                    <Col className="col-auto pr-0">
                        <Avatar variant="rounded"
                            alt={props.model.serviceName}
                            className={classes.avatar}
                            src={api.Search.getFirstPhotoUrl(props.model.photos)} />
                    </Col>
                    <Col className="pl-1">
                        <Typography variant="body2" color="textPrimary">
                            {props.model.serviceName}
                        </Typography>
                        <Typography variant="body2" display="inline" color="textSecondary">
                            {L10N({
                                key: "_priceAndCurrency", price: {
                                    price: +(props.model.defaultPrice?.price ?? "0"),
                                    currency: props.model.defaultPrice?.currency ?? "INR",
                                }
                            })}
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            {((props.model.timeBeforeInMinutes ?? "0") !== "0") && `+${props.model.timeBeforeInMinutes}m`}
                            &nbsp;
                            <Typography variant="body2" display="inline" color="textPrimary" className="bold"
                                component="span">
                                {L10N({ key: "_duration", duration: +(props.model.durationInMinutes ?? "0") })}
                            </Typography >
                            &nbsp;
                            {((props.model.timeAfterInMinutes ?? "0") !== "0") && `+${props.model.timeAfterInMinutes}m`}
                        </Typography>
                    </Col>
                </Row>
            </Container>
        </CardActionArea>
    </EmiCard>;
}
export const ServiceListCardAdd = (props: {
    cardIndex: number,
    onClick: () => void,
}) => {
    const classes = useStyles();
    return <EmiCard
        elevation={1}
        className={classes.card
            + " " + Animations.swing_in_top_fwd() + " " + Animations.delay(props.cardIndex)}
        onClick={props.onClick}
    >
        <CardActionArea>
            <Typography variant="body2" align="center">
                <CspIcon name="AddCircleOutlineIcon" fontSize="large" className="my-3" />
            </Typography>
        </CardActionArea>
    </EmiCard >;
}
export const FieldSetServiceList = (props: {
    onSelected: (item: ServiceEditModel) => void,
    onNew: () => void,
}) => {
    const { inProgress: editInProgress, services } = useAppSelector((state: RootState) => state.edit);

    return <>
        <Container>
            <EmiPaper className="p-3 mb-3">
                {!editInProgress && !services?.map && <Row>
                    <Col>
                        <Typography>{L10N({ key: 'No results found' })}</Typography>
                    </Col>
                </Row>}
                <Row>
                    {services?.map && services.map((m, i) =>
                        <Col key={m.serviceID} xs={12} sm={6} md={4} lg={3}>
                            <ServiceListCard cardIndex={i} model={m}
                                onClick={props.onSelected}
                            />
                        </Col>
                    )}
                    <Col key={"new"} xs={12} sm={6} md={4} lg={3}>
                        <ServiceListCardAdd cardIndex={services?.length ?? 0}
                            onClick={props.onNew}
                        />
                    </Col>
                </Row>
            </EmiPaper>
        </Container>
    </>;
}