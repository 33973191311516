import { InputAdornment, TextField } from "@mui/material";
import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';
import { api } from '../../api/api';
import { CompanyEditModel, PhotoEditModel } from '../../api/models/Edit';
import { L10N } from '../pages/L10N';
import { AvatarUpload } from '../ui/AvatarUpload';
import { EmiPaper } from '../ui/EmiPaper';
import { FormikFieldProps, useFormikResult } from '../ui/FormikAPI';

export const FieldSetCompanyForCustomers = (props: {
    formik: useFormikResult<CompanyEditModel>,
}) => {

    return <>
        <Container>
            <EmiPaper className="p-3 mb-3">
                <Row>
                    {props.formik.values.photos?.map && props.formik.values.photos.map((m, i) =>
                        <Col key={m.photoIndex} className="col-auto">
                            <AvatarUpload
                                variant="img"
                                src={props.formik.values.photos?.[i]?.base64 ?? api.Search.getPhotoUrl(m) ?? ""}
                                editMode
                                deleteMode
                                positionMode
                                position={props.formik.values.photos?.[i]?.position ?? "Default"}
                                onDelete={async () => {
                                    await props.formik.setFieldValue(`photos[${i}].isDeleted`, true);
                                    await props.formik.setFieldValue(`photos[${i}].base64`, "");
                                }}
                                onChange={async (base64, position) => {
                                    await props.formik.setFieldValue(`photos[${i}].base64`, base64);
                                    await props.formik.setFieldValue(`photos[${i}].position`, position ?? "Default");
                                }}
                                fileId={"" + i}
                                alt={props.formik.values.description ?? ""} />
                        </Col>
                    )}
                    <Col className="col-auto">
                        <AvatarUpload
                            variant="square"
                            src=""
                            addMode
                            onChange={async (base64, position) => {
                                await props.formik.setFieldValue(`photos[${props.formik.values.photos.length ?? 0}]`,
                                    {
                                        base64: base64,
                                        accessToken: "",
                                        objectID: props.formik.values.companyID,
                                        photoIndex: props.formik.values.photos.length,
                                        modificationDate: moment().toISOString(),
                                        isDeleted: false,
                                        position: position ?? "Default",
                                        url: '',
                                    } as PhotoEditModel)
                            }}
                            fileId={"new"}
                            alt={props.formik.values.description ?? ""} />
                    </Col>
                </Row>
                <TextField className="mb-3"
                    multiline rows={5}
                    label={L10N({ key: "About us" })}
                    {...FormikFieldProps(props.formik, m => m.description)}
                />
                <TextField className="mb-3"
                    InputProps={{
                        startAdornment: <InputAdornment position="start" className="mr-1">https://</InputAdornment>,
                    }}
                    label={L10N({ key: "Company Website" })}
                    {...FormikFieldProps(props.formik, m => m.website)}
                    onChange={(e) => props.formik.setFieldValue(`website`, "https://" + e.target.value)}
                    value={props.formik.values.website?.replace("https://", "") ?? ""}
                />
                <TextField className="mb-3"
                    label={L10N({ key: "Company Email" })}
                    {...FormikFieldProps(props.formik, m => m.email)}
                />
                <TextField className="mb-3"
                    label={L10N({ key: "Contact Details for Clients" })}
                    multiline rows={2}
                    {...FormikFieldProps(props.formik, m => m.contactDetails)}
                /><br />
            </EmiPaper>
        </Container>
    </>;
}