import { Accordion, AccordionDetails, IconButton, InputAdornment, TextField } from "@mui/material";
import { RootState, useAppSelector } from 'src/store/store';
import { CompanyEditModel } from '../../api/models/Edit';
import { L10N } from '../pages/L10N';
import { CspIcon } from "../ui/CspIcon";
import { useFormikResult } from '../ui/FormikAPI';
import { AppBarSubmitCancel } from './AppBarSubmitCancel';

export const FormCompanyReview = (props: {
    formik: useFormikResult<CompanyEditModel>,
}) => {

    return <>
        <form onSubmit={props.formik.handleSubmit}>
            <FieldSetCompanyReview formik={props.formik} />
            {props.formik.dirty && <AppBarSubmitCancel formik={props.formik} />}
        </form>
    </>
}
export const FieldSetCompanyReview = (props: {
    formik: useFormikResult<CompanyEditModel>,
}) => {
    const { visitReviews_AverageScore } = useAppSelector((state: RootState) => state.edit);

    function onAcceptAverageClick(): void {
        props.formik.setFieldValue("reviewSummary.averageScore", visitReviews_AverageScore);
    }

    return <>
        <Accordion defaultExpanded={false} >
            <AccordionDetails className="d-block">
                <TextField variant="outlined" className="mb-3" fullWidth
                    type="number"
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    id="reviewSummary.averageScore" name="reviewSummary.averageScore" label={L10N({ key: "Rating Score" })}
                    value={props.formik.values.reviewSummary?.averageScore ?? 0}
                    onChange={props.formik.handleChange}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                {L10N({ key: "Average:" })}
                                &nbsp;
                                {visitReviews_AverageScore}
                                <IconButton onClick={onAcceptAverageClick}
                                >
                                    <CspIcon name="CheckCircleOutlineIcon" />
                                </IconButton>
                            </InputAdornment>,
                    }}
                /><br />
                <TextField variant="outlined" className="mb-3 text-black" fullWidth
                    type="number"
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    id="reviewSummary.badgeLevel" name="reviewSummary.badgeLevel" label={L10N({ key: "Badge Level" })}
                    value={props.formik.values.reviewSummary?.badgeLevel ?? 0}
                    onChange={props.formik.handleChange}
                /><br />
            </AccordionDetails>
        </Accordion>
    </>;
}