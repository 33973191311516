import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { AssignCompanyMessage, ClientEditModel, CompanyEditModel, RequestCompanyCreateModel, RequestCompanyEditModel, ResourceEditModel, ServiceEditModel, ToggleDemoCompanyMessage, UpdatePhotoMessage, VisitReviewEditModel } from "../models/Edit";
import { RequestVisitCreateModel, RequestVisitEditModel, VisitEditModel } from "../models/EditVisit";
import { BeePhoto } from "../models/Global";
import { UserProfile } from "../models/User";

export class EditEndpoint {
    async getCompany(message: RequestCompanyEditModel): Promise<ApiResponse<CompanyEditModel>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/company-edit`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as CompanyEditModel,
        });
        return apiResponse;
    }
    async getCompanyReviews(message: RequestCompanyEditModel): Promise<ApiResponse<VisitReviewEditModel[]>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/company-reviews-edit`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as VisitReviewEditModel[],
        });
        return apiResponse;
    }
    async getCompanyServices(message: RequestCompanyEditModel): Promise<ApiResponse<ServiceEditModel[]>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/company-services-edit`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as ServiceEditModel[],
        });
        return apiResponse;
    }
    async getCompanyResources(message: RequestCompanyEditModel): Promise<ApiResponse<ResourceEditModel[]>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/company-resources-edit`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as ResourceEditModel[],
        });
        return apiResponse;
    }
    async getVisit(message: RequestVisitEditModel): Promise<ApiResponse<VisitEditModel>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/visit-edit`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as VisitEditModel,
        });
        return apiResponse;
    }
    async refreshVisitResourcesAvailability(message: VisitEditModel): Promise<ApiResponse<VisitEditModel>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/visit-edit-resource-availability`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as VisitEditModel,
        });
        return apiResponse;
    }
    async assignCompany(message: AssignCompanyMessage): Promise<ApiResponse<UserProfile>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/assignCompany`,
            method: "PUT",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as UserProfile,
        });
        return apiResponse;
    }
    async toggleDemoCompany(message: ToggleDemoCompanyMessage): Promise<ApiResponse<null>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/toggleDemoCompany`,
            method: "PUT",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => null,
        });
        return apiResponse;
    }
    async createCompany(message: RequestCompanyCreateModel): Promise<ApiResponse<CompanyEditModel>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/company-create`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as CompanyEditModel,
        });
        return apiResponse;
    }
    async createVisit(message: RequestVisitCreateModel): Promise<ApiResponse<VisitEditModel>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/visit-create`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as VisitEditModel,
        });
        return apiResponse;
    }
    async updateCompany(message: CompanyEditModel): Promise<ApiResponse<CompanyEditModel>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/company-save`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as CompanyEditModel,
        });
        return apiResponse;
    }
    async updateVisitReview(message: VisitReviewEditModel): Promise<ApiResponse<VisitReviewEditModel>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/company-review-save`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as VisitReviewEditModel,
        });
        return apiResponse;
    }
    async updateCompanyService(message: ServiceEditModel): Promise<ApiResponse<ServiceEditModel>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/company-service-save`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as ServiceEditModel,
        });
        return apiResponse;
    }
    async updateCompanyResource(message: ResourceEditModel): Promise<ApiResponse<ResourceEditModel>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/company-resource-save`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as ResourceEditModel,
        });
        return apiResponse;
    }
    async updateVisit(message: VisitEditModel): Promise<ApiResponse<VisitEditModel>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/visit-save`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as VisitEditModel,
        });
        return apiResponse;
    }
    async updatePhoto(message: UpdatePhotoMessage): Promise<ApiResponse<BeePhoto>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/updatePhoto`,
            method: "PUT",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as BeePhoto,
        });
        return apiResponse;
    }
    async getCompanyClients(message: RequestCompanyEditModel): Promise<ApiResponse<ClientEditModel[]>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/company-clients-edit`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as ClientEditModel[],
        });
        return apiResponse;
    }
    async updateCompanyClient(message: ClientEditModel): Promise<ApiResponse<ClientEditModel>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/edit/company-clients-save`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as ClientEditModel,
        });
        return apiResponse;
    }
}