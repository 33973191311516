import { DimensionTypes } from "src/api/models/Global";
import { EditStep } from "./EditPage";
import { ProfileTab } from "./ProfilePage";

export class AppRoutes {
    static defaultUrlParam = "default";
    static home = "/";
    static homeTopID = "hometop";
    static homeTop = "/#" + AppRoutes.homeTopID;
    static homeSearchBarID = "searchbarID";
    static benefitsViewID = "benefitsID";
    static aboutUsViewID = "aboutUsViewID";
    static visionViewID = "visionViewID";

    static homeSearchBar = "/#" + AppRoutes.homeSearchBarID;
    static aboutUsView = "/#" + AppRoutes.aboutUsViewID;
    static benefitsView = "/#" + AppRoutes.benefitsViewID;
    static visionView = "/#" + AppRoutes.visionViewID;

    static mobileHome = "/mobile/:client?/:version?";

    static login = "/login/:returnUrl";
    static loginAndReturn(returnUrl: string) {
        const encoded = encodeURIComponent(returnUrl);
        return `/login/${encoded}`;
    }
    static explore = "/explore";
    static favourites = "/favourites";
    static profile = "/profile/:tab";
    static profilePage(tab?: ProfileTab): string {
        return `/profile/${tab ?? AppRoutes.defaultUrlParam}`;
    }
    static calendar = "/calendar/:tab/:mode";
    static calendarPage(tab: "company" | "resource", mode: "calendar" | "list"): string {
        return `/calendar/${tab}/${mode}`;
    }
    static accountDetails = "/accountDetails";
    static terms = "/terms";
    static contactus = "/contactus";
    static privacy = "/privacy";
    static legal = "/legal";
    static recommend = "/recommend";
    static statistics = "/statistics/:tab";
    static statisticsTab(tab?: "visit" | "referral"): string {
        return `/statistics/${tab ?? "visit"}`;
    }
    static clients = "/clients/:tab";
    static clientsTab(tab?: "banned" | "all"): string {
        return `/clients/${tab ?? "banned"}`;
    }
    static invite = "/invite/:offeredResourceID_";
    static inviteEncodeUrl(offeredResourceID?: string[]): string | undefined {
        if (!offeredResourceID) return;
        const encoded = offeredResourceID?.join("_");
        return encoded;
    }
    static invitePage(offeredResourceID?: string[]): string {
        const encoded = AppRoutes.inviteEncodeUrl(offeredResourceID);
        return `/invite/${encoded ?? AppRoutes.defaultUrlParam}`;
    }
    static visit = "/visit/:tab";
    static visitTab(tab?: "upcoming" | "completed"): string {
        return `/visit/${tab ?? "upcoming"}`;
    }
    static visitReview = "/visitReview";
    static visitReviewEdit = "/visitReviewEdit/:visitID/:visitReviewID";
    static visitReviewEditPage(visitID?: string, visitReviewID?: string): string {
        return `/visitReviewEdit/${visitID ?? AppRoutes.defaultUrlParam}/${visitReviewID ?? AppRoutes.defaultUrlParam}`;
    }
    static visitConfirmed = "/visitConfirmed/:visitID/:visitAccessToken?";
    static visitConfirmedPage(visitID?: string, visitAccessToken?: string): string {
        return `/visitConfirmed/${visitID ?? AppRoutes.defaultUrlParam}/${visitAccessToken ?? ""}`;
    }
    static details = "/details/:tab/:id";
    static detailsTab(type: DimensionTypes, id?: string, companyName?: string, companyAddress?: string): string {
        const encodedCompanyName = (companyName && companyName !== "")
            ? `company=${encodeURIComponent(companyName)}`
            : "";
        const encodedCompanyAddress = (companyAddress && companyAddress !== "")
            ? `address=${encodeURIComponent(companyAddress)}`
            : "";
        const suffix = (encodedCompanyName !== "") && (encodedCompanyAddress !== "")
            ? `?${encodedCompanyName}&${encodedCompanyAddress}`
            : (encodedCompanyName !== "")
                ? `?${encodedCompanyName}`
                : (encodedCompanyAddress !== "")
                    ? `?${encodedCompanyAddress}`
                    : "";
        if (id) return `/details/${type}/${id}${suffix}`;
        else return `/details/${type}`;
    }
    static createCompany = "/createCompanyPage/:referral?";
    static createCompanyPage(referral?: string): string {
        return `/createCompanyPage/${referral ?? ""}`;
    }
    static edit = "/edit/:companyID/:step/:selectedServiceID/:selectedResourceID/:selectedVisitReviewID";
    static editPage(companyID: string, step?: EditStep, selectedServiceID?: string, selectedResourceID?: string, selectedVisitReviewID?: string): string {
        const stepValue: EditStep = step ?? "about";
        return `/edit/${companyID}/${stepValue}/${selectedServiceID ?? "-"}/${selectedResourceID ?? "-"}/${selectedVisitReviewID ?? "-"}`;
    }
    static payment = "/payment/:visitID";
    static paymentPage(visitID?: string): string {
        return `/payment/${visitID ?? AppRoutes.defaultUrlParam}`;
    }
    static superAdmin = "/superAdmin";
    static superAdminPage(): string {
        return this.superAdmin;
    }
    static teacherWizard = "/teacherWizard/:userID";
    static teacherWizardPage(userID?: string): string {
        return `/teacherWizard/${userID ?? AppRoutes.defaultUrlParam}`;
    }
    static studentWizard = "/studentWizard/:userID";
    static studentWizardPage(userID?: string): string {
        return `/studentWizard/${userID ?? AppRoutes.defaultUrlParam}`;
    }
    static externalUrl(internalUrl?: string): string {
        const externalUrl = window.location.origin + (internalUrl ?? "");
        return externalUrl;
    }
}