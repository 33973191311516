import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, TextField } from "@mui/material";
import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';
import { api } from '../../api/api';
import { CreateNextDateTimeRange, PhotoEditModel, ResourceEditModel } from '../../api/models/Edit';
import { L10N } from '../pages/L10N';
import { AvatarUpload } from '../ui/AvatarUpload';
import { EmiPaper } from '../ui/EmiPaper';
import { FormikFieldProps, useFormikResult } from '../ui/FormikAPI';
import { AppBarSubmitCancel } from './AppBarSubmitCancel';
import { OffersCheckboxCard } from './OffersCheckboxCard';
import { WorkingHoursListCard } from './WorkingHoursListCard';

export const FormResourceDetails = (props: {
    formik: useFormikResult<ResourceEditModel>,
}) => {

    return <>
        <form onSubmit={props.formik.handleSubmit} onSubmitCapture={() => console.log(props.formik.errors)}>
            <Container>
                <EmiPaper className="p-3 mb-3">
                    <Row>
                        {props.formik.values.photos?.length > 0
                            ? props.formik.values.photos.map((m, i) =>
                                <Col key={m.photoIndex} className="col-auto">
                                    <AvatarUpload
                                        variant="circular"
                                        src={props.formik.values.photos?.[i]?.base64 ?? api.Search.getPhotoUrl(m) ?? ""}
                                        editMode
                                        onChange={async (base64) => { await props.formik.setFieldValue(`photos[${i}].base64`, base64) }}
                                        fileId={"" + i}
                                        alt={props.formik.values.description ?? ""} />
                                </Col>
                            )
                            :
                            <Col className="col-auto">
                                <AvatarUpload
                                    variant="rounded"
                                    src=""
                                    addMode
                                    onChange={async (base64) => {
                                        await props.formik.setFieldValue(`photos[0]`, {
                                            base64: base64,
                                            accessToken: "",
                                            objectID: props.formik.values.resourceID,
                                            photoIndex: 0,
                                            modificationDate: moment().toISOString(),
                                            isDeleted: false,
                                            position: 'Default',
                                            url: '',
                                        } as PhotoEditModel)
                                    }}
                                    fileId={"new"}
                                    alt={props.formik.values.description ?? ""} />
                            </Col>
                        }
                    </Row>
                    <TextField className="mb-3"
                        disabled
                        label={L10N({ key: "Resource ID" })}
                        {...FormikFieldProps(props.formik, m => m.resourceID)}
                    /><br />
                    <TextField className="mb-3"
                        required
                        label={L10N({ key: "Display Name" })}
                        {...FormikFieldProps(props.formik, m => m.displayName)}
                    /><br />
                    <Row>
                        <Col xs={12} md={6}>
                            <TextField className="mb-3"
                                label={L10N({ key: "First Name" })}
                                {...FormikFieldProps(props.formik, m => m.firstName)}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField className="mb-3"
                                label={L10N({ key: "Last Name" })}
                                {...FormikFieldProps(props.formik, m => m.lastName)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <TextField className="mb-3"
                                label={L10N({ key: "Title" })}
                                {...FormikFieldProps(props.formik, m => m.title)}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField className="mb-3"
                                label={L10N({ key: "Subtitle" })}
                                {...FormikFieldProps(props.formik, m => m.subtitle)}
                            />
                        </Col>
                    </Row>
                    <TextField className="mb-3"
                        multiline rows={5}
                        label={L10N({ key: "Description" })}
                        {...FormikFieldProps(props.formik, m => m.description)}
                    /><br />
                    <TextField className="mb-3"
                        label={L10N({ key: "Resource edit: Phone number" })}
                        {...FormikFieldProps(props.formik, m => m.contactDetails)}
                    /><br />
                    <Row className="mb-3">
                        <Col>
                            <Accordion defaultExpanded={false}>
                                <AccordionSummary className="text-black text-weight-bold2">
                                    {L10N({ key: "Working hours" })}
                                </AccordionSummary>
                                <AccordionDetails className="d-block">
                                    <Container className="p-0 m-0">
                                        <Row>
                                            {props.formik.values.workingHours?.map && props.formik.values.workingHours.map((m, i) =>
                                                <Col key={m.dayOfWeek} sm={12} md={6} xl={6} className="m-0 p-0 p-md-2">
                                                    <WorkingHoursListCard formik={props.formik}
                                                        cardIndex={i} model={m}
                                                        fieldNamePrefix={(index, suffix) => `workingHours[${i}].dayWorkingHours[${index}]${suffix}`}
                                                        onAdd={() => props.formik.setFieldValue(
                                                            `workingHours[${i}].dayWorkingHours[${m.dayWorkingHours?.length ?? 0}]`,
                                                            CreateNextDateTimeRange(m)
                                                        )}
                                                        onDelete={(model, index) => props.formik.setFieldValue(
                                                            `workingHours[${i}].dayWorkingHours`,
                                                            m.dayWorkingHours.filter((mFilter, iFilter) => iFilter !== index)
                                                        )}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    </Container>
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                    </Row>
                    {props.formik.values.offers?.length > 0 &&
                        <Row>
                            <Col>
                                <Accordion defaultExpanded={false}>
                                    <AccordionSummary className="text-black text-weight-bold2">
                                        {L10N({ key: "Services" })}
                                    </AccordionSummary>
                                    <AccordionDetails className="d-block">
                                        <Container className="p-0 m-0">
                                            <Row>
                                                <Col >
                                                    <OffersCheckboxCard model={props.formik.values.offers}
                                                        onToggleChecked={(m, i) => props.formik.setFieldValue(`offers[${i}].isAvailable`, !m.isAvailable)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </AccordionDetails>
                                </Accordion>
                            </Col>
                        </Row>
                    }
                    {props.formik.values.resourceID && props.formik.values.resourceID !== "" &&
                        <Row>
                            <Col>
                                <Accordion defaultExpanded={false}>
                                    <AccordionSummary className="text-black text-weight-bold2">
                                        {L10N({ key: "Delete" })}
                                    </AccordionSummary>
                                    <AccordionDetails className="d-block">
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={props.formik.values.isDeleted ?? false} color="primary"
                                                    id="isDeleted" name="isDeleted"
                                                    onChange={props.formik.handleChange}
                                                />
                                            }
                                            label={L10N({ key: "Mark for deletion" })}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Col>
                        </Row>
                    }
                </EmiPaper>
            </Container>
            {props.formik.dirty && <AppBarSubmitCancel formik={props.formik} />}
        </form>
    </>;
}