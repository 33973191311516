import { CardActionArea, Rating, Typography } from "@mui/material";
import { Col, Container, Row } from 'react-bootstrap';
import { VisitReviewEditModel } from '../../api/models/Edit';
import { RootState, useAppSelector } from '../../store/store';
import { L10N } from '../pages/L10N';
import { Animations } from '../ui/Animations';
import { CspIcon } from "../ui/CspIcon";
import { EmiCard } from '../ui/EmiCard';
import { EmiPaper } from '../ui/EmiPaper';

export const VisitReviewListCard = (props: {
    cardIndex: number,
    model: VisitReviewEditModel,
    onClick: (item: VisitReviewEditModel) => void,
}) => {

    return <EmiCard
        elevation={1}
        className={Animations.swing_in_top_fwd() + " p-1 mb-1 " + Animations.delay(props.cardIndex)}
        onClick={() => props.onClick(props.model)}
    >
        <CardActionArea>
            <Typography variant="body2" color="textPrimary">
                {props.model.reviewTitle}
            </Typography>
            <Rating value={props.model.ratingScore} readOnly />
        </CardActionArea>
    </EmiCard>;
}
export const VisitReviewListCardAdd = (props: {
    cardIndex: number,
    onClick: () => void,
}) => {
    return <EmiCard
        elevation={0}
        className={Animations.swing_in_top_fwd() + " p-1 " + Animations.delay(props.cardIndex)}
        onClick={props.onClick}
    >
        <CardActionArea>
            <Typography variant="body2" align="center">
                <CspIcon name="AddCircleOutlineIcon" fontSize="large" />
            </Typography>
        </CardActionArea>
    </EmiCard >;
}
export const FieldSetVisitReviewList = (props: {
    onSelected: (item: VisitReviewEditModel) => void,
    onNew: () => void,
}) => {
    const { inProgress: editInProgress, visitReviews } = useAppSelector((state: RootState) => state.edit);

    return <>
        <Container>
            <EmiPaper className="p-3 mb-3">
                {!editInProgress && !visitReviews?.map && <Row>
                    <Col>
                        <Typography>{L10N({ key: 'No results found' })}</Typography>
                    </Col>
                </Row>}
                <Row>
                    {visitReviews?.map && visitReviews.map((m, i) =>
                        <Col key={m.visitReviewID} xs={12} sm={6} md={4} lg={3}>
                            <VisitReviewListCard cardIndex={i} model={m}
                                onClick={props.onSelected}
                            />
                        </Col>
                    )}
                    <Col key={"new"} xs={12} sm={6} md={4} lg={3}>
                        <VisitReviewListCardAdd cardIndex={visitReviews?.length ?? 0}
                            onClick={props.onNew}
                        />
                    </Col>
                </Row>
            </EmiPaper>
        </Container>
    </>;
}