import { Button, Chip, LinearProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { InviteCompany, InviteCompanyService, InviteOffer } from '../../api/models/Invite';
import { changeInvite } from '../../store/invite/invite.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { CancelVisitDialogVisible } from '../../store/ui/ui.reducer';
import { createVisit } from '../../store/visit/visit.actions';
import { InviteCard } from '../invite/InviteCard';
import { Animations } from '../ui/Animations';
import { ButtonShadow } from '../ui/ButtonShadow';
import { LogotypeSpans } from "../ui/LogotypeSpans";
import { StyledTitleRow } from "../ui/StyledTitle";
import { L10N } from './L10N';
import { PageHeader } from "./PageHeader";
import { AppRoutes } from './Routes';

export const InvitePage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { user } = useAppSelector((state: RootState) => state.user);
    const { inProgress: inviteInProgress, currentInvite, createInviteMessage } = useAppSelector((state: RootState) => state.invite);
    const { inProgress, createVisitResponse } = useAppSelector((state: RootState) => state.visit);
    const urlParams = useParams<{ offeredResourceID_: string }>();

    useEffect(() => {
        if (inProgress) return;
        if (inviteInProgress) return;
        if (!urlParams?.offeredResourceID_) return;
        if (urlParams.offeredResourceID_ === AppRoutes.defaultUrlParam) return;
        const encoded = AppRoutes.inviteEncodeUrl(createInviteMessage?.offeredResourceID);
        if (encoded === urlParams.offeredResourceID_) return;

        const offeredResourceID = urlParams.offeredResourceID_.split("_");
        if (!offeredResourceID || offeredResourceID.length === 0) return;

        dispatch(changeInvite({
            message: {
                query: "",
                offeredResourceID: offeredResourceID,
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams, inProgress, inviteInProgress, user]);


    const singleOffer = getSelectedOffer();
    const serviceToBeSelected = (currentInvite?.companyServiceDimension.length ?? 0) > 1
        && currentInvite?.companyDimensionSelectedValue;

    function currentService(): InviteCompanyService | undefined {
        if (!currentInvite) return;
        const result = currentInvite
            ?.companyServiceDimension
            ?.find((v, i, a) => v.dimensionValue === currentInvite?.companyServiceDimensionSelectedValue);
        return result;
    }
    function currentCompany(): InviteCompany | undefined {
        if (!currentInvite) return;
        const result = currentInvite
            ?.companyDimension
            ?.find((v, i, a) => v.dimensionValue === currentInvite?.companyDimensionSelectedValue);
        return result;
    }

    function getSelectedOffer(): InviteOffer | undefined {
        const service = currentService();
        let theOfferIsKnown = (currentInvite?.offers?.length ?? 0) === 1
            || service?.classType === "TeacherWizard";
        if (!theOfferIsKnown) {
            if (currentInvite?.companyDimensionSelectedValue
                && currentInvite?.companyServiceDimensionSelectedValue
                && currentInvite?.dateDimensionSelectedValue
                && currentInvite?.timeDimensionSelectedValue) {
                theOfferIsKnown = true;
            }
            else {
                return;
            }
        }
        const theOffer = currentInvite?.offers[0];
        if (!theOffer) return;
        return theOffer;
    }
    async function confirm() {
        if (!(user?.clientAccessGranted ?? false)) {
            await navigate(AppRoutes.loginAndReturn(AppRoutes.invitePage()));
            return;
        }
        const theOffer = getSelectedOffer();
        if (!theOffer) return;
        await dispatch(createVisit({
            message: {
                visitIDToBeReplaced: currentInvite?.visitIDToBeReplaced,
                accessToken: user?.accessToken ?? "",
                offeredResourceID: theOffer?.offerID,
                visitStartForClient: theOffer.start,
                visitNotes: currentInvite?.visitNotes ?? "",
            }
        }));
    }
    async function cancelClick() {
        if (!currentInvite) return;
        if (!currentInvite.visitIDToBeReplaced) return;
        if (!user) return;

        await dispatch(CancelVisitDialogVisible(true));
    }
    async function removeFiltersClick() {
        if (!createInviteMessage) return;
        await dispatch(changeInvite({
            message: {
                query: "",
                offeredResourceID: createInviteMessage.offeredResourceID,
            }
        }));
    }

    function joinAndLoginDisabled() {
        const service = currentService();
        if (inviteInProgress || inProgress) return true;
        if (!user?.clientAccessGranted) return false; //allow for login

        if (service?.classType === "TeacherWizard") return false;

        if (company?.isDemo) return true;

        return !singleOffer;
    }

    const company = currentCompany();
    const service = currentService();

    return (
        <>
            {!inviteInProgress && serviceToBeSelected
                && <Navigate replace to={AppRoutes.detailsTab("company",
                    currentInvite?.companyDimensionSelectedValue,
                    company?.displayName,
                    company?.address
                )} />}
            {!inviteInProgress && createVisitResponse?.shouldRedirectToVisitConfirmed
                && <Navigate replace to={AppRoutes.visitConfirmedPage(createVisitResponse.visitID)} />
            }
            {!inviteInProgress && createVisitResponse?.shouldRedirectToPayment
                && <Navigate replace to={AppRoutes.paymentPage(createVisitResponse.visitID)} />
            }
            <Container>
                <PageHeader showLogo />
                <StyledTitleRow text={<>Well done! You are about to join <LogotypeSpans /></>} className='mb-3' />
                {(inviteInProgress || inProgress) && <>
                    <Row>
                        <Col xs={12}>
                            <LinearProgress className="w-100 mt-n1" color="secondary" />
                        </Col>
                    </Row>
                </>}
                <Row className="mb-3">
                    <Col>
                        <InviteCard />
                    </Col>
                </Row>
                {(inviteInProgress || inProgress) && <>
                    <Row>
                        <Col xs={12}>
                            <LinearProgress className="w-100 mt-n3" color="secondary" />
                        </Col>
                    </Row>
                </>}
                {createVisitResponse?.validationMessage &&
                    <Row className="my-3">
                        <Col xs={12}>
                            <Typography variant="subtitle2" align="center" >
                                {createVisitResponse?.validationMessage}
                            </Typography >
                        </Col>
                    </Row>
                }
                {(!inviteInProgress
                    && ((currentInvite?.dateDimension?.length ?? 0) === 0))
                    ? <>
                        <Row className="my-3">
                            <Col xs={12}>
                                <Typography variant="subtitle2" align="center" >
                                    {L10N({ key: "There are no offers that satisfy your criteria. We need to remove your filtering." })}
                                </Typography >
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col >
                                <Button onClick={removeFiltersClick} variant="contained" color="primary"
                                    fullWidth>
                                    {L10N({ key: "OK" })}
                                    <ButtonShadow animate />
                                </Button>
                            </Col>
                        </Row>
                    </>
                    : <>
                        {service?.paymentUpfront === true &&
                            <Row className="mb-3">
                                <Col >
                                    <Typography >
                                        {L10N({ key: "Upfront payment - you need to pay upfront for this visit" })}
                                    </Typography>
                                </Col>
                            </Row>
                        }
                        {company?.isDemo === true &&
                            <Row className="my-3">
                                <Col >
                                    <Chip label="demo" className={Animations.heartbeat()} />
                                </Col>
                            </Row>
                        }
                        <Row className="mb-5">
                            <Col />
                            <Col className="col-auto">
                                <Button onClick={confirm}
                                    variant="contained" color="primary"
                                    disabled={joinAndLoginDisabled()}>
                                    {user?.clientAccessGranted
                                        ? <>
                                            {service?.paymentUpfront === true
                                                ? L10N({ todo: "Join and Pay" })
                                                : L10N({ key: "Join Now" })}
                                        </>
                                        : <>{L10N({ key: "Login to finalize" })}
                                        </>
                                    }
                                    {singleOffer && <ButtonShadow animate />}
                                </Button>
                            </Col>
                            {currentInvite?.visitIDToBeReplaced &&
                                <Col className="col-auto">
                                    <Button variant="outlined" color="primary"
                                        onClick={cancelClick}
                                        fullWidth>
                                        {L10N({ key: "Cancel Visit" })}
                                    </Button>
                                </Col>
                            }
                            <Col />
                        </Row>
                    </>}
            </Container >
        </>
    );
};
