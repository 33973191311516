import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useState } from "react";
import { L10N } from "../pages/L10N";
import { CspDivider } from "./CspDivider";
import { StyledTitle } from "./StyledTitle";
import { useTimer } from "./useTimer";

export const CspCountdownDialog = (props: {
    seconds: number,
    title: string,
    subtitle: string,
    children: JSX.Element,
}) => {
    const { isFinished, timeLeft } = useTimer(props.seconds);
    const [isSkipped, setIsSkipped] = useState(false);

    return (<>
        {isFinished || isSkipped
            ? props.children
            :
            <Dialog fullWidth
                open={true}>
                <DialogTitle>
                    {props.title}
                </DialogTitle>
                <CspDivider />
                <DialogContent>
                    <StyledTitle text={props.subtitle} hideDivider />
                    <Typography fontSize={124} align="center">
                        {timeLeft}
                    </Typography>
                </DialogContent>
                <CspDivider shadowTop />
                <DialogActions>
                    <Button onClick={() => setIsSkipped(true)}
                        color="primary" variant="contained">
                        {L10N({ todo: "Go there directly..." })}
                    </Button>
                </DialogActions>
            </Dialog>
        }
    </>);
};
