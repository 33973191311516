import { Avatar, Fab, Popover, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import moment from "moment";
import * as React from 'react';
import { useState } from 'react';
import { api } from "../../api/api";
import { Calendar, CalendarEntry, CalendarSlot } from "../../api/models/Company";
import { RequestVisitCreateModel } from '../../api/models/EditVisit';
import { RootState, useAppSelector } from '../../store/store';
import { CalendarSlotWithVisit } from '../company/CalendarSlotWithVisit';
import { L10N } from "../pages/L10N";
import { Animations } from "../ui/Animations";
import { CspIcon } from "../ui/CspIcon";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        CalendarHourViewFullHour: {
            fontSize: '0.5rem',
            width: '100%',
            position: 'absolute',
            borderTop: 'dotted 1px ',
            '&:hover': {
                border: 'solid 1px',
                cursor: 'pointer',
            }
        },
        CalendarHourViewHalfHour: {
            fontSize: '0.5rem',
            width: '100%',
            position: 'absolute',
            '&:hover': {
                border: 'solid 1px',
                cursor: 'pointer',
            }
        },
    }));
export const CalendarHourView = (props: {
    calendar: Calendar,
    scale: number,
    onAdd: (message: RequestVisitCreateModel) => Promise<void>,
    resourceID: string,
    date: string,
}) => {
    const dateDim = props.calendar?.dateDimension.find((v, i, a) => v.dimensionValue === props.date);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<{
        div: HTMLDivElement | null,
        hour?: string,
    }>({
        div: null,
        hour: undefined,
    });
    const handleClick = (event: React.MouseEvent<HTMLDivElement>, hour?: string) => {
        setAnchorEl({
            div: event.currentTarget,
            hour: hour,
        });
    };
    const handleClose = () => {
        setAnchorEl({
            div: null,
            hour: undefined,
        });
    };
    return <>
        <Popover
            open={Boolean(anchorEl?.div)}
            anchorEl={anchorEl?.div}
            onClose={handleClose}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'top',
            }}
            className="bg-transparent"
        >
            <Fab color="primary" size="small"
                onClick={async () => await props.onAdd({
                    accessToken: "",
                    companyID: "",
                    resourceID: props.resourceID,
                    date: dateDim?.date,
                    hour: anchorEl.hour,
                })}
                className="m-1">
                <CspIcon name="AddIcon" />
            </Fab>
        </Popover>
        {props.calendar
            && (new Array<number>(24))
                .fill(1)
                .map((m, i) => (i * 60 >= props.calendar.minimalStartInMinutes
                    && i * 60 <= props.calendar.maximumEndInMinutes)
                    ? i
                    : undefined)
                .filter((v, i, a) => v)
                .map((m, i) => <React.Fragment key={m}>
                    <div
                        className={classes.CalendarHourViewFullHour}
                        onClick={(event) => handleClick(event, moment(`${m}:00`, "HH:mm").utc(true).local().format("HH:mm"))}
                        style={{
                            top: props.scale * ((m ?? 0) * 60 - props.calendar.minimalStartInMinutes),
                            height: props.scale * 30,
                        }}
                    >
                        <Typography className="d-block w-100"
                            variant="caption"
                            align="center">
                            {moment(`${m}:00`, "HH:mm").utc(true).local().format("HH:mm")}
                        </Typography>
                    </div>
                    <div
                        className={classes.CalendarHourViewHalfHour}
                        onClick={(event) => handleClick(event, moment(`${m}:30`, "HH:mm").utc(true).local().format("HH:mm"))}
                        style={{
                            top: props.scale * ((m ?? 0) * 60 - props.calendar.minimalStartInMinutes + 30),
                            height: props.scale * 30,
                        }}
                    >

                    </div>
                </React.Fragment>)}
    </>;
}
export function CalculateWidthOfFilteredEntries(calendar?: Calendar, selectedResourceDimension?: string): {
    filtered?: CalendarEntry[],
    fullWidth: number | 'auto',
    colWidth: number,
    colMargin: number,
} {
    const filtered = calendar?.entries?.map && calendar?.entries
        ?.filter((v, i, a) => !selectedResourceDimension || v.dimensionsValues[1] === selectedResourceDimension);
    const colWidth = 300;
    const colMargin = 30;
    const fullWidth = filtered?.length ? (filtered.length + 1) * (colWidth + colMargin) : 'auto';
    return {
        filtered,
        fullWidth,
        colWidth,
        colMargin
    };
}
export const CalendarView = (props: {
    handleDeleteClick: (slot: CalendarSlot) => Promise<void>,
    selectedResourceDimension?: string,
    onReloadRequired: () => Promise<void>,
    onAdd: (message: RequestVisitCreateModel) => Promise<void>,
    scale: number,
}) => {
    const { calendar, signalMessages } = useAppSelector((state: RootState) => state.company);
    const [selectedSlot, setSelectedSlot] = useState<CalendarSlot | undefined>(undefined);

    function handleCollapseClick(slot: CalendarSlot) {
        if (selectedSlot === slot) {
            setSelectedSlot(undefined);
        } else {
            setSelectedSlot(slot);
        }
    }
    function renderHeader(m: CalendarEntry): JSX.Element {
        if (!calendar) return <></>;
        const dateDim = calendar?.dateDimension.find((v, i, a) => v.dimensionValue === m.dimensionsValues[2]);
        const resDim = calendar?.resourceDimension.find((v, i, a) => v.resourceID === m.dimensionsValues[1]);
        if (!dateDim || !resDim) return <></>;
        return <>
            <Typography align="center"
                variant="body2"
                className={dateDim.isSatOrSun ? "text-primary" : "text-black"} >
                {L10N({ key: "_dateAsDayOrTomorrow", date: dateDim.date })}<br />
                {L10N({ key: "_dateAsOn", date: dateDim.date })}
            </Typography>
            <Avatar className={"mx-auto " + Animations.slide_in_blurred_left()}
                alt={resDim.displayName}
                src={api.Search.getPhotoUrl(resDim.photo)} />
        </>
    }
    function renderAvailabilityBackground(m: CalendarEntry): JSX.Element {
        return <>{calendar
            && m?.calendarSlots?.map && m?.calendarSlots
                ?.filter((v, i, a) => v.isAvailable)
                ?.map((slot, index) => <div key={"bg" + slot.endInMinutes + "-" + slot.startInMinutes} >
                    <div style={{
                        top: props.scale * (slot.startInMinutes - calendar.minimalStartInMinutes),
                        height: props.scale * (slot.endInMinutes - slot.startInMinutes),
                        width: '100%',
                        position: 'absolute',
                        backgroundColor: '#21AA00',
                        opacity: '0.1',
                    }}>
                    </div>
                </div>)}
        </>
    }
    const widthOfFilteredEntries = CalculateWidthOfFilteredEntries(calendar, props.selectedResourceDimension);

    return (
        <>
            {calendar && widthOfFilteredEntries.filtered && <>
                <div className="py-3 bg-white"
                    style={{
                        width: widthOfFilteredEntries.fullWidth,
                        height: 250 + props.scale * ((calendar?.maximumEndInMinutes ?? 0) - (calendar?.minimalStartInMinutes ?? 0)),
                    }}>
                    {widthOfFilteredEntries.filtered
                        ?.map((m, i) =>
                            <div key={m.dimensionsValues.join("|")}
                                style={{
                                    width: widthOfFilteredEntries.colWidth,
                                    position: 'relative',
                                    marginRight: widthOfFilteredEntries.colMargin,
                                }}
                                className="p-0 mx-3 float-left h-100">
                                {renderHeader(m)}
                                <div className="mb-3" />
                                <div className="position-absolute w-100 ">
                                    {renderAvailabilityBackground(m)}
                                </div>
                                <div className="position-absolute w-100 ">
                                    <CalendarHourView calendar={calendar} scale={props.scale} onAdd={props.onAdd}
                                        resourceID={m.dimensionsValues[1]}
                                        date={m.dimensionsValues[2]}
                                    />
                                </div>
                                <div className="position-absolute w-100">
                                    {m?.visitSlots?.map && m?.visitSlots
                                        ?.map((slot, index) => <React.Fragment key={m.dimensionsValues.join("|") + slot.visitID + "?" + index}>
                                            <CalendarSlotWithVisit
                                                onReloadRequired={props.onReloadRequired}
                                                expanded={selectedSlot === slot}
                                                slot={slot}
                                                minimalStartInMinutes={calendar.minimalStartInMinutes}
                                                scale={props.scale}
                                                onCardDeleteClick={async () => await props.handleDeleteClick(slot)}
                                                onCardCollapseClick={handleCollapseClick}
                                                highlightFromSignal={signalMessages
                                                    && signalMessages.find(signal => signal.itemType === "Visit"
                                                        && signal.itemID === slot.visitID)
                                                }
                                            />
                                        </React.Fragment>)}
                                </div>
                            </div>)}
                </div>
            </>}
        </>
    );
};
