import { Accordion, AccordionDetails, Button, TextField } from '@mui/material';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { MapCoords } from 'src/api/models/Details';
import { loadCoordsFromAddress } from 'src/store/edit/edit.actions';
import { RootState } from 'src/store/store';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { CompanyMapView } from '../details/CompanyMapView';
import { L10N } from '../pages/L10N';
import { Animations } from '../ui/Animations';
import { FormikFieldProps } from '../ui/FormikAPI';
import { StyledTitleRow } from '../ui/StyledTitle';
import { FieldSetProps } from './FormStudentWizard';

export const FieldSetContactDetails = (props: FieldSetProps) => {
    const dispatch = useAppDispatch();
    const { mapCoords: coords } = useAppSelector((state: RootState) => state.edit);
    function onMapClick(coords: MapCoords) {
        props.formik.setFieldValue("mapCoords", coords);
    }
    useEffect(() => {
        if (!coords) return;
        props.formik.setFieldValue("mapCoords", coords);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coords]);

    async function refreshCoords() {
        await dispatch(loadCoordsFromAddress({ address: props.formik.values.address ?? "" }));
    }
    return <>
        <Accordion defaultExpanded={false} >
            <AccordionDetails className="d-block pt-3 my-3">
                <Row>
                    <Col xs={12} md={6} >
                        <StyledTitleRow text={"How can we contact you?"} hideDivider subtitle />
                        <TextField className="mb-3"
                            label={L10N({ key: "First Name" })}
                            {...FormikFieldProps(props.formik, m => m.firstName)}
                        /><br />
                        <TextField className="mb-3"
                            label={L10N({ key: "Last Name" })}
                            {...FormikFieldProps(props.formik, m => m.lastName)}
                        /><br />
                        <Row>
                            <Col xs={12} md={6}>
                                <TextField className="mb-3"
                                    label={L10N({ key: "Contact Phone for ClassNow" })}
                                    {...FormikFieldProps(props.formik, m => m.eduinPhone)}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField className="mb-3"
                                    label={L10N({ key: "Contact Email for ClassNow" })}
                                    {...FormikFieldProps(props.formik, m => m.eduinEmail)}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={6} >
                        <StyledTitleRow text={"Where are you based?"} hideDivider subtitle />
                        <TextField variant="outlined" className="mb-3" fullWidth
                            id="address" name="address" label={L10N({ key: "Address" })}
                            value={props.formik.values.address}
                            onChange={props.formik.handleChange}
                            error={props.formik.touched.address && Boolean(props.formik.errors.address)}
                            helperText={props.formik.touched.address && props.formik.errors.address}
                        /><br />
                        {props.formik.dirty && <>
                            <Button className={"mb-3 " + Animations.swing_in_top_fwd()}
                                onClick={refreshCoords}
                                variant="contained"
                                color="primary"
                            >
                                {L10N({ todo: "Find address on the map" })}
                            </Button><br />
                        </>
                        }
                        <CompanyMapView onMapClick={onMapClick}
                            company={{
                                address: '',
                                category: props.formik.values.category ?? "",
                                mapCoords: {
                                    "lat": props.formik.values.mapCoords?.lat ?? 0,
                                    "lng": props.formik.values.mapCoords?.lng ?? 0,
                                },
                                serviceGroups: [],
                                resources: [],
                                portfolio: [],
                                reviews: [],
                                itemID: '',
                                itemType: 'Company',
                                title: '',
                                subtitle: '',
                                description: '',
                                htmlDescription: '',
                                learnMoreURL: '',
                                photos: [],
                                isDemo: true,
                                isFavorite: false,
                            }} />
                    </Col>
                </Row>
            </AccordionDetails>
        </Accordion>
    </>;
}
