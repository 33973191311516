import { Avatar, Button } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { api } from "src/api/api";
import { UserListItem } from "src/api/models/User";

export const UserList = (props: {
    userList: UserListItem[],
    editCompanyClick: (companyID?: string) => Promise<void>,
    viewCompanyClick: (companyID?: string) => Promise<void>,
    assignCompanyClick: (companyID?: string) => Promise<void>,
}) => {
    return (
        <>
            <Row className="m\y-3">
                <Col >
                    <table className="table table-striped table-bordered">
                        <caption>List of users</caption>
                        <thead>
                            <tr>
                                <th scope="col">No.</th>
                                <th scope="col">Photo</th>
                                <th scope="col">First Name</th>
                                <th scope="col">Last Name</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Email</th>
                                <th scope="col">Language</th>
                                <th scope="col">Created On</th>
                                <th scope="col">CompanyID</th>
                                <th scope="col">ResourceID</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.userList.map((m, i) => <tr key={i}>
                                <td>{i + 1}.</td>
                                <td>
                                    <Avatar
                                        src={api.Search.getFirstPhotoUrl(m?.photos)}
                                        className={"m-1 "}
                                        alt={m?.firstName} />
                                </td>
                                <td>{m.firstName}</td>
                                <td>{m.lastName}</td>
                                <td>{m.phone}</td>
                                <td>{m.email}</td>
                                <td>{m.language}</td>
                                <td>{m.createdOn}</td>
                                <td>{m.companyID}</td>
                                <td>{m.resourceID}</td>
                                <td>
                                    {m.companyID && <Button onClick={async () => props.editCompanyClick(m.companyID)}
                                        className="m-1"
                                        variant="outlined" color="primary" size="small">
                                        Edit company
                                    </Button>}
                                    {m.companyID && <Button onClick={async () => props.viewCompanyClick(m.companyID)}
                                        className="m-1"
                                        variant="outlined" color="primary" size="small">
                                        View company
                                    </Button>}
                                    {m.companyID && <Button onClick={async () => props.assignCompanyClick(m.companyID)}
                                        className="m-1"
                                        variant="outlined" color="primary" size="small">
                                        Assign company
                                    </Button>}
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </>
    );
};
