import { Paper } from "@mui/material";
import { useFormik } from "formik";
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router';
import { CreateLoginPageModel, LOGIN_STATE_ENUM, LoginPageModelValidationSchema } from "src/api/models/Login";
import { RootState, useAppSelector } from '../../store/store';
import { FormLogin } from "../login/FormLogin";
import { LogotypeSpans } from "../ui/LogotypeSpans";
import { StyledTitleRow } from "../ui/StyledTitle";
import { PageHeader } from './PageHeader';
import { AppRoutes } from './Routes';

export type LoginPageState = {
    inProgress: boolean,
    stage: LOGIN_STATE_ENUM,
}
export const LoginPage = () => {
    const urlParams = useParams<{ returnUrl: string }>();
    const returnUrl = urlParams?.returnUrl
        ? decodeURIComponent(urlParams?.returnUrl)
        : undefined;
    const { user } = useAppSelector((state: RootState) => state.user);

    const [state, setState] = useState<LoginPageState>({
        inProgress: false,
        stage: "phone",
    });
    const formik = useFormik({
        initialValues: CreateLoginPageModel(),
        enableReinitialize: true,
        validationSchema: LoginPageModelValidationSchema,
        onSubmit: async (values) => {

        },
    });

    return (<>
        {user?.clientAccessGranted && state.stage === "loggedin" && <Navigate replace to={returnUrl ?? AppRoutes.home} />}
        <Container className="py-3">
            <Row >
                <Col xs={0} md={2} lg={3} xl={4} />
                <Col xs={12} md={8} lg={6} xl={4}>
                    <Paper >
                        <Container>
                            <PageHeader showLogo logoNavigatesToHome />
                            <StyledTitleRow text={<>Login to <LogotypeSpans /></>} className='mb-3' />
                            <FormLogin formik={formik} state={state} setState={setState} />
                        </Container>
                    </Paper>
                </Col>
                <Col xs={0} md={2} lg={3} xl={4} />
            </Row>
        </Container >
    </>
    );
};
