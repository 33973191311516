import { Button, CircularProgress, Fab, Typography } from "@mui/material";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../../api/api';
import { RazorpayApiCredentials } from '../../api/models/Edit';
import { PriceAndCurrency } from '../../api/models/Global';
import { PurchaseStatusResponse } from '../../api/models/Purchase';
import { RootState, useAppSelector } from '../../store/store';
import { L10N } from '../pages/L10N';
import { AppRoutes } from '../pages/Routes';
import { CspIcon } from "./CspIcon";


export const TestRazorpayButton = (props: {
    apiCredentials?: RazorpayApiCredentials
}) => {
    const { user } = useAppSelector((state: RootState) => state.user);
    const [apiResult, setApiResult] = useState({
        inProgress: false,
        result: undefined as (boolean | undefined),
    });
    async function onClick() {
        if (apiResult.inProgress) return;
        if (!user) return;
        if (!props.apiCredentials) return;

        setApiResult({
            inProgress: true,
            result: undefined,
        });

        const apiResponse = await api.Purchase.testRazorpay({
            accessToken: user.accessToken,
            apiCredentials: props.apiCredentials,
        });
        setApiResult({
            inProgress: false,
            result: apiResponse.result,
        });
    }
    return (
        <>
            <Fab onClick={onClick} color="primary" size="small"
                disabled={!props.apiCredentials
                    || !props.apiCredentials.apiKey
                    || !props.apiCredentials.secret
                }
            >
                {apiResult.inProgress ? <CircularProgress /> : <CspIcon name="SpeedIcon" />}
            </Fab>
            <Typography className="mx-3 d-inline-block">
                {apiResult.result === undefined && <><CspIcon name="HelpOutlineIcon" />{L10N({ key: "Razorpay Connection Test" })}</>}
                {apiResult.result === true && <><CspIcon name="DoneAllIcon" className="text-success" />{L10N({ key: "Razorpay Connection Successful" })}</>}
                {apiResult.result === false && <><CspIcon name="NotInterested" className="text-danger" />{L10N({ key: "Razorpay Connection Not Working" })}</>}
            </Typography>
        </>
    )
}

export const CheckPaymentStatusAndPayIfPossible = (props: {
    visitID?: string,
    paymentCompleted?: boolean,
    priceAndcurrency?: PriceAndCurrency,
    autoLoad?: boolean,
}) => {
    const navigate = useNavigate();
    const { user } = useAppSelector((state: RootState) => state.user);
    const [checkStatusResult, setCheckStatusResult] = useState({
        inProgress: false,
        result: undefined as (PurchaseStatusResponse | undefined),
    });
    useEffect(() => {
        if (!props.autoLoad) return;
        reloadResult();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.autoLoad]);
    async function onClick(event: React.MouseEvent<any, MouseEvent>) {
        event.preventDefault();
        event.stopPropagation();

        await reloadResult();
    }
    async function reloadResult() {
        if (checkStatusResult.inProgress) return;
        if (!user) return;
        if (!props.visitID) return;

        setCheckStatusResult({
            inProgress: true,
            result: undefined,
        });
        const callback = AppRoutes.externalUrl(AppRoutes.visitConfirmedPage(props.visitID, user.accessToken));
        const apiResponse = await api.Purchase.checkStatus({
            accessToken: user.accessToken,
            visitID: props.visitID,
            frontendCallback: callback,
        });
        setCheckStatusResult({
            inProgress: false,
            result: apiResponse.result,
        });
    }
    async  function paymentClick(event: React.MouseEvent<any, MouseEvent>) {
        if (!props.visitID) return;
        event.preventDefault();
        event.stopPropagation();

        await navigate(AppRoutes.paymentPage(props.visitID));
    }
    function isValidForPayment(): boolean {
        if (props.paymentCompleted) return false;
        if (checkStatusResult.result?.wasPaid) return false;
        return true;
    }
    function renderStatus() {
        const paidStatus = <><CspIcon name="DoneAllIcon" className="text-success" />{L10N({ key: "Paid" })}</>;
        if (props.paymentCompleted) return paidStatus;
        if (checkStatusResult.result?.wasPaid) return paidStatus;
        return <>
            <CspIcon name="HelpOutlineIcon" className="text-success" /> {checkStatusResult.result?.message}
        </>;
    }
    return (
        <>
            {!props.paymentCompleted && !checkStatusResult.result &&
                <Fab onClick={onClick} color="primary" size="small"
                    disabled={!props.visitID}>
                    {checkStatusResult.inProgress ? <CircularProgress /> : L10N({ key: "_currency", price: props.priceAndcurrency })}
                </Fab>
            }
            <Typography className="d-inline-block">
                {renderStatus()}
            </Typography>
            {isValidForPayment() &&
                <Button onClick={paymentClick}
                    color="primary" variant="contained" size="small"
                    disabled={!props.visitID}>
                    {L10N({ key: "Pay" })}
                </Button>
            }
        </>
    )
}
