import { useState, useCallback } from 'react';

const useMouseHover = () => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseOver = useCallback(() => {
        setIsHovered(true);
    }, []);

    const handleMouseOut = useCallback(() => {
        setIsHovered(false);
    }, []);

    return {
        isHovered,
        hoverEventHandlers: {
            onMouseOver: handleMouseOver,
            onMouseOut: handleMouseOut,
        },
    };
};

export default useMouseHover;
