import { Box, CardMedia } from "@mui/material";
import { useRef } from "react";

export const CspVideo = (props: {
    src: string,
    imgSrc: string,
    autoplay?: boolean,
    showControls?: boolean,
}) => {
    const ref = useRef<HTMLVideoElement>(null);

    async function pauseOtherVideos() {
        const videos = document.getElementsByTagName("video");
        for (let i = 0; i < videos.length; i++) {
            const v = videos[i];
            if (v === ref.current) continue;

            v.pause();
        }
    }

    async function handlePlaying() {
        await pauseOtherVideos();
    }

    return (<><Box
        sx={{
            width: '100%',
            height: '100%',
            backgroundImage: `url(${props.imgSrc})`,
            backgroundSize: 'cover',
            objectFit: 'cover',
            objectPosition: 'left top',
        }}
    >
        <CardMedia component="video"
            ref={ref}
            title="ClassNow Video"
            sx={{
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'left top',
            }}
            src={props.src}
            playsInline
            autoPlay={props.autoplay}
            controls={props.showControls}
            onPlaying={handlePlaying}
        />
    </Box>
    </>
    )
}