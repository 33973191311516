import { Accordion, AccordionDetails, CircularProgress, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { GetSearchCategory_Classes, SearchCategory_Anything, SearchCategoryValues } from 'src/api/models/Search';
import { ClassEditModel, StudentWizardEditModel } from 'src/api/models/StudentWizard';
import { formKey } from 'src/helpers';
import { useSearchCategoriesAutoLoaded } from 'src/store/search/search.hooks';
import { L10N } from '../pages/L10N';
import { Animations } from '../ui/Animations';
import { CategoryDescription, CategoryIcon } from '../ui/CategoryIcon';
import { FormikFieldProps, FormikFieldProps_ToggleButtonGroup } from '../ui/FormikAPI';
import { StyledTitle } from '../ui/StyledTitle';
import { FieldSetProps } from './FormStudentWizard';

export const FieldSetAboutYou = (props: FieldSetProps) => {
    const { searchCategories, searchHintsInProgress } = useSearchCategoriesAutoLoaded();

    async function onCategoryChanged(value: string) {
        if (value === SearchCategory_Anything) return;
        const classNames = GetSearchCategory_Classes(value as SearchCategoryValues);
        const classes: ClassEditModel[] = classNames?.map(m => ({
            name: m,
        }));
        await props.formik.setFieldValue(formKey<StudentWizardEditModel>(m => m.classes), classes);
    }
    const animationIfNotFilled = props.formik.values.category
        ? undefined
        : Animations.swing_in_top_fwd() + " " + Animations.delay(15);
    return <>
        <Accordion defaultExpanded={false} >
            <AccordionDetails className="d-block pt-3 my-3">
                <StyledTitle text={L10N({ todo: "What's your name or nickname?" })} className='mb-3' hideDivider subtitle />

                <TextField className="mb-3"
                    label={L10N({ todo: "Name or nickname" })}
                    required
                    placeholder="Name or nickname"
                    {...FormikFieldProps(props.formik, m => m.displayName)}
                /><br />
                {props.formik.values.displayName &&
                    <Row className={animationIfNotFilled}>
                        <Col xs={12} md={6} className="mb-3">
                            <StyledTitle text={"Main specialization"} className='mb-3' hideDivider subtitle />
                            <CategoryDescription categoryName={props.formik.values.category as SearchCategoryValues ?? SearchCategory_Anything} />
                        </Col>
                        <Col xs={12} md={6} className="mb-3">
                            <ToggleButtonGroup
                                color='primary'
                                fullWidth
                                exclusive
                                defaultValue={SearchCategory_Anything}
                                orientation="vertical"
                                {...FormikFieldProps_ToggleButtonGroup(props.formik,
                                    m => m.category,
                                    onCategoryChanged,
                                    'IGNORE_NULL'
                                )}
                            >
                                {searchHintsInProgress && <CircularProgress />}
                                {searchCategories?.results?.map &&
                                    searchCategories.results
                                        .map((m, i) => m.text === SearchCategory_Anything
                                            ?
                                            <ToggleButton key={m.encodedText} value={m.text}>
                                                <Typography align="center" className="w-100">
                                                    Please select
                                                </Typography>
                                            </ToggleButton>
                                            :
                                            <ToggleButton key={m.encodedText}
                                                value={m.text}
                                            >
                                                <Typography align="left" className="w-100">
                                                    <CategoryIcon name={m.text as SearchCategoryValues}
                                                        size="small"
                                                        className="mx-2" />
                                                    {L10N({ key: "_category", cat: m.text })}
                                                </Typography>
                                            </ToggleButton>
                                        )
                                }
                            </ToggleButtonGroup>
                        </Col>
                    </Row>
                }
            </AccordionDetails>
        </Accordion>
    </>;
}