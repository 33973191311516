import { Button, CardActionArea, CardHeader, Divider, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { changeInvite } from '../../store/invite/invite.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { L10N } from '../pages/L10N';
import { AppRoutes } from '../pages/Routes';
import { ButtonShadow } from '../ui/ButtonShadow';
import { DimensionIcon } from '../ui/DimensionIcon';
import { EmiCard } from '../ui/EmiCard';
import { FavoriteIcon } from '../ui/FavoriteIcon';
import { CheckPaymentStatusAndPayIfPossible } from '../ui/RazorpayPayments';
import { CompanyMapView } from './CompanyMapView';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        visitStatus: {
            textTransform: 'uppercase',
            color: '#21be13',
            fontWeight: 'bold',
        },
    })
);
export const VisitDetailsView = (props: {
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { visit, company } = useAppSelector((state: RootState) => state.details);
    const classes = useStyles();

    async function changeClick() {
        if (!visit) return;
        await dispatch(changeInvite({
            message: {
                query: "",
                visitIDToBeReplaced: visit.visitID,
                offeredResourceID: [visit?.offerID],
            }
        }));
        await navigate(AppRoutes.invitePage());
    }
    async function writeReviewClick() {
        if (!visit) return;
        await navigate(AppRoutes.visitReviewEditPage(visit?.visitID, visit?.visitReviewID));
    }
    async function bookAgainClick() {
        if (!visit) return;
        await dispatch(changeInvite({
            message: {
                query: "",
                offeredResourceID: [visit?.offerID],
            }
        }));
        await navigate(AppRoutes.invitePage());
    }
    return (
        <>
            <Row>
                <Col>
                    <Typography variant="subtitle1" align="center" className={"mb-3 " + classes.visitStatus}>
                        {L10N({ key: "_status", status: visit?.visitStatus })}
                    </Typography>
                </Col>
            </Row>
            <Row>
                <Col>
                    <EmiCard elevation={1}>
                        <CardActionArea>
                            {company && <CompanyMapView company={company} height="250px" />}
                        </CardActionArea>
                        <Container>
                            <Row>
                                <Col className="p-0">
                                    <CardHeader
                                        title={<CardActionArea>
                                            <Typography variant="body1">
                                                {company?.title}
                                            </Typography>
                                            <Typography variant="body2">
                                                {L10N({ key: "_category", cat: company?.subtitle })}
                                            </Typography>
                                            {company?.address &&
                                                <div>
                                                    <Typography variant="body2">
                                                        <DimensionIcon type='company' size="small" />
                                                        {company?.address}
                                                    </Typography>
                                                </div>
                                            }
                                        </CardActionArea>}
                                    />
                                </Col>
                                <Col className="col-auto p-0">
                                    {company && <FavoriteIcon companyID={company?.itemID} />}
                                </Col>
                            </Row>
                        </Container>
                    </EmiCard>
                </Col>
            </Row>
            <Row className="mb-3 mt-3">
                <Col>
                    <Typography variant="body2" className="my-3">
                        {L10N({ key: "Your ClassNow:" })}
                    </Typography>
                    <Divider />
                    <Container>
                        <Row className="mt-3 ">
                            <Col className="col-auto px-0">
                                <Typography variant="subtitle2" >
                                    {visit?.serviceName}
                                </Typography>
                            </Col>
                            <Col className="m-0 p-0" />
                            <Col className="col-auto px-0" >
                                <Typography variant="subtitle2" >
                                    {L10N({ key: "_priceAndCurrency", price: visit?.priceAndCurrency })}
                                </Typography>
                            </Col>
                        </Row>
                        <Row >
                            <Col className="col-auto px-0">
                                <Typography >
                                    {visit?.resourceName}
                                </Typography>
                            </Col>
                            <Col />
                            <Col className="col-auto px-0">
                                <Typography >
                                    {L10N({ key: "_dateAsDDMMMHHmm", date: visit?.visitStartForClient })}<br />
                                </Typography>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            {visit?.remarks && <>
                <Row className="mb-3">
                    <Col>
                        <Typography variant="body2" >
                            {L10N({ key: "Additional info:" })}
                        </Typography>
                        <Typography variant="body1">{visit?.remarks}</Typography>
                    </Col>
                </Row>
            </>}
            {visit?.visitNotes && <>
                <Row className="mb-3">
                    <Col>
                        <Typography variant="body2" >
                            {L10N({ key: "Notes:" })}
                        </Typography>
                        <Typography variant="body1">{visit?.visitNotes}</Typography>
                    </Col>
                </Row>
            </>}
            {visit && !visit.visitCompleted && visit.paymentUpfront && !visit?.paymentCompleted &&
                <Typography variant="body1" color="primary" className="mb-3">
                    {L10N({ key: "Upfront payment - you need to pay upfront for this visit" })}
                </Typography>
            }
            {visit && !visit.visitCompleted && <CheckPaymentStatusAndPayIfPossible visitID={visit.visitID}
                paymentCompleted={visit.paymentCompleted}
                priceAndcurrency={visit.priceAndCurrency}
                autoLoad
            />}
            {visit
                && visit.visitStartForClient
                && visit.visitEndForClient
                && !visit?.visitCompleted
                && <>
                    <Row className="mb-3 mt-4">
                        {/* <Col className="pr-1">
                            <AddToCalendarButton withBeeShadow
                                startsAt={visit?.visitStartForClient}
                                endsAt={visit?.visitEndForClient}
                                location={visit?.addressLine}
                                details={visit?.remarks}
                                name={visit?.serviceName}
                                variant="contained" color="primary" fullWidth
                            />
                        </Col> */}
                        <Col className="pl-1">
                            <Button onClick={changeClick}
                                variant="outlined" color="primary" >
                                {L10N({ key: "Change" })}
                            </Button>
                        </Col>
                    </Row>
                </>}
            {visit?.visitCompleted && <>
                <Row className="mb-3 mt-5">
                    <Col >
                        <Button onClick={writeReviewClick}
                            variant="outlined" color="primary" fullWidth>
                            {visit?.visitReviewID
                                ? L10N({ key: "Edit review" })
                                : L10N({ key: "Write review" })}
                        </Button>
                    </Col>
                    <Col className="pl-1">
                        <Button onClick={bookAgainClick}
                            disableElevation
                            variant="contained" color="primary" fullWidth>
                            {L10N({ key: "Join again" })}
                            <ButtonShadow />
                        </Button>
                    </Col>
                </Row>
            </>}
        </ >
    );
};
