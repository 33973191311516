import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { VisitReviewDetails, VisitReviewUpdateMessage } from "../models/Details";
import { AccessTokenBearer } from "../models/Global";
import { ConfirmedVisit, CreateVisitMessage, GetVisitMessage, UpdateVisitMessage } from "../models/Visit";

export class VisitEndpoint {
    async createVisit(message: CreateVisitMessage): Promise<ApiResponse<ConfirmedVisit>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/visit/create-visit`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as ConfirmedVisit,
        });
        return apiResponse;
    }
    async updateVisit(message: UpdateVisitMessage): Promise<ApiResponse<ConfirmedVisit>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/visit/update-visit`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as ConfirmedVisit,
        });
        return apiResponse;
    }
    async getVisit(message: GetVisitMessage): Promise<ApiResponse<ConfirmedVisit>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/visit/confirmed-visit`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as ConfirmedVisit,
        });
        return apiResponse;
    }
    async getAllVisits(message: AccessTokenBearer): Promise<ApiResponse<ConfirmedVisit[]>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/visit/confirmed-visits`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as ConfirmedVisit[],
        });
        return apiResponse;
    }
    async getAllReviews(message: AccessTokenBearer): Promise<ApiResponse<VisitReviewDetails[]>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/visit/getUserReviews`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as VisitReviewDetails[],
        });
        return apiResponse;
    }
    async updateUserReview(message: VisitReviewUpdateMessage): Promise<ApiResponse<VisitReviewDetails>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/visit/updateUserReview`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as VisitReviewDetails,
        });
        return apiResponse;
    }

}