import { Backdrop, Box, Drawer, Fab } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { StudentWizardEditModel } from 'src/api/models/StudentWizard';
import { VisitReviewItemCard } from '../details/VisitReviewItemCard';
import { CspIcon } from '../ui/CspIcon';
import { useFormikResult } from '../ui/FormikAPI';
import { LogotypeSpans } from "../ui/LogotypeSpans";
import { StyledTitleRow } from '../ui/StyledTitle';

export type PreviewDrawerState = {
    isOpen: boolean,
    autoPreviewWasTriggered: boolean,
    wasClosed: boolean,
}

export const PreviewVisitReviewItemCard = (props: {
    formik: useFormikResult<StudentWizardEditModel>,
}) => {
    return (
        <div style={{
            position: 'relative'
        }}>
            <VisitReviewItemCard data={{
                userID: "",
                visitID: "",
                visitReviewID: "",
                reviewAuthor: props.formik.values.displayName,
                reviewDate: moment().add(-1, "M").toISOString(),
                ratingScore: 5,
                reviewTitle: "Review title",
                reviewDetails: "Review details",
                photos: props.formik.values.avatarPhotos,
                isCompanyReview: false,
            }}
            />
        </div>
    );
}

export const PreviewDrawer = (props: {
    formik: useFormikResult<StudentWizardEditModel>,
    state: PreviewDrawerState,
    onAutoPreviewWasTriggered: () => void,
    onClose: () => void,
}) => {
    function shouldTriggerPreviewDrawer() {
        if (props.formik.values.stage && props.formik.values.stage !== "initial") return false;
        if (props.state.autoPreviewWasTriggered) {
            return false;
        }
        if (props.formik.touched
            && props.formik.values.displayName?.length > 0
            && props.formik.values.avatarPhotos?.length > 0
        ) {
            return true;
        }
    }
    useEffect(() => {
        if (shouldTriggerPreviewDrawer()) {
            props.onAutoPreviewWasTriggered();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formik]);

    const drawerWidth = 340;

    return <>
        <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={props.state.isOpen}
            onClick={props.onClose}
        >
            <Drawer open={props.state.isOpen}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                transitionDuration={800}
                variant="persistent"
                anchor="right"
            >
                <Box className="m-3">
                    <Box>
                        <Fab onClick={props.onClose}>
                            <CspIcon name='CloseIcon' />
                        </Fab>
                    </Box>
                    <StyledTitleRow text={<>This is how you will appear in <LogotypeSpans /> </>} alignLeft subtitle />
                    <PreviewVisitReviewItemCard formik={props.formik} />
                </Box>
            </Drawer>
        </Backdrop>
    </>;
}



