import { CspText } from "./CspText";
import { theme } from "./Theme";

export const LogotypeSpans = () => {
    return (<>
        <span style={{ color: theme.palette.secondary.main }}>Class</span>
        <span style={{ color: theme.palette.primary.main }}>Now</span>
    </>);
};
export const LogotypeTokens = (props: {
    text?: string,
}) => {
    return (<>
        <CspText text={props.text}
            replacements={{
                ClassNow: <LogotypeSpans />,
            }} />
    </>);
};
