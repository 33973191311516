import { QrCode } from '@ark-ui/react/qr-code'

export const CspQrCode = (props: {
    value: string,
}) => {
    return (
        <QrCode.Root value={props.value} encoding={{ ecc: 'H' }} >
            <QrCode.Frame>
                <QrCode.Pattern />
            </QrCode.Frame>
            <QrCode.Overlay >
                {/* <img src="https://ark-ui.com/icon-192.png" alt="Ark UI Logo" /> */}
                <img src="/img/logoQR.png" alt="ClassNow" style={{
                    width: '64px',
                    height: '64px',
                }} />
            </QrCode.Overlay>
        </QrCode.Root>
    )
}
