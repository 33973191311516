import { Box, Button, SxProps, Theme } from "@mui/material";
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { changeSearchString } from '../../store/search/search.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { CspIcon } from "../ui/CspIcon";
import { theme } from "../ui/Theme";
import { useIsVisible } from "../ui/useIsVisible";
import useMouseHover from "../ui/useMouseHover";
import { SearchCategoryChip } from './SearchCategoryChip';

const sxBox: SxProps<Theme> = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '44px',
    zIndex: 100,
    borderRadius: theme.shape.borderRadius,
    margin: 0,
    padding: 0,
};
const sxButton: SxProps<Theme> = {
    height: '100%',
    width: '100%',
    minWidth: '10px',
    margin: 0,
    padding: 0,
    background: 'rgba(0,0,0,0.5)',
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    borderStyle: 'solid',
};

export const SearchCategoryChips = (props: {
    showAnything?: boolean,
}) => {
    const dispatch = useAppDispatch();
    const { searchString, searchCategories } = useAppSelector((state: RootState) => state.search);
    const { ref: refFirst, isVisible: isVisibleFirst } = useIsVisible<HTMLDivElement>();
    const { ref: refLast, isVisible: isVisibleLast } = useIsVisible<HTMLDivElement>();
    const { isHovered, hoverEventHandlers } = useMouseHover();

    const ref = React.useRef<HTMLDivElement>(null);

    async function handleChange(isChecked: boolean, chip: string) {
        let newSearchString = searchString;
        for (var i = 0; i < (searchCategories?.results.length ?? 0); i++) {
            const categoryText = searchCategories?.results[i]?.encodedText;
            if (!categoryText) continue;

            const regEx = new RegExp(categoryText, "ig");
            newSearchString = newSearchString.replace(regEx, "");
        }
        if (isChecked) {
            newSearchString = newSearchString + " " + chip;
        }
        await dispatch(changeSearchString({ searchString: newSearchString }));
    }
    function onMouseDownPrev(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        const scroll = ref.current;
        if (!scroll) return;

        scroll.scrollBy({ behavior: 'smooth', left: -scroll.clientWidth / 3 })
        event.stopPropagation();
    }
    function onMouseDownNext(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        const scroll = ref.current;
        if (!scroll) return;
        scroll.scrollBy({ behavior: 'smooth', left: scroll.clientWidth / 3 })
        event.stopPropagation();
    }
    return (
        <Row className="mb-3"
            style={{
                flexWrap: 'nowrap',
                position: 'relative',
            }}
            {...hoverEventHandlers}
        >
            {searchCategories?.results?.map && <>
                {!isMobile && isHovered && !isVisibleFirst
                    && <Box sx={{
                        ...sxBox,
                        left: 0,
                    }}>
                        <Button onClick={onMouseDownPrev}
                            sx={{
                                ...sxButton,
                                left: 0,
                            }}>
                            <CspIcon name="ChevronLeftOutlinedIcon" />
                        </Button>
                    </Box>
                }
                <Col className="p-0 m-0 ">
                    <div ref={ref}
                        style={{
                            display: "flex",
                            overflow: "scroll",
                            scrollbarWidth: "none",
                            paddingBottom: "6px",
                        }}>
                        {searchCategories.results
                            ?.filter((m, i) => props.showAnything || i !== 0)
                            ?.map((m, i, a) =>
                                <div key={i}
                                    ref={i === 0 ? refFirst
                                        : i === (a.length - 1) ? refLast
                                            : undefined
                                    } >
                                    <SearchCategoryChip key={i} showText
                                        onChange={(isChecked) => handleChange(isChecked, m.encodedText)}
                                        isChecked={searchString.includes(m.encodedText)}
                                        data={m} />
                                </div>
                            )}
                    </div>
                </Col>
                {!isMobile && isHovered && !isVisibleLast
                    && <Box sx={{
                        ...sxBox,
                        right: 0,
                    }}>
                        <Button onClick={onMouseDownNext}
                            sx={{
                                ...sxButton,
                                left: 0,
                            }}>
                            <CspIcon name="ChevronRightOutlinedIcon" />
                        </Button>
                    </Box>
                }
            </>}
        </Row >
    );
}
