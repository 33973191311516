import { ActionReducerMapBuilder, AsyncThunk } from "@reduxjs/toolkit";

export function addInProgressCases<TPayload extends { inProgress: boolean }>(
    builder: ActionReducerMapBuilder<TPayload>,
    action: AsyncThunk<any, any, any>,
) {
    builder.addCase(action.pending, (state, action) => {
        state.inProgress = true;
    })
    builder.addCase(action.rejected, (state, action) => {
        console.log(action.error);
    })
}

export function addInProgressMatcher<TPayload extends { inProgress: boolean }>(
    builder: ActionReducerMapBuilder<TPayload>,
    action: AsyncThunk<any, any, any>,
) {
    builder.addMatcher(action.settled, (state, action) => {
        state.inProgress = false;
    })
}