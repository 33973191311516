import { Button, IconButton, TextField, Typography } from "@mui/material";
import copy from 'copy-to-clipboard';
import { useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { RootState, useAppSelector } from '../../store/store';
import { ButtonShadow } from '../ui/ButtonShadow';
import { CspIcon } from "../ui/CspIcon";
import { LogotypeSpans } from "../ui/LogotypeSpans";
import { BackArrowHeader } from './BackArrowHeader';
import { L10N } from './L10N';
import { AppRoutes } from './Routes';

export const RecommendPage = (props: {}) => {
    const { user, inProgress } = useAppSelector((state: RootState) => state.user);
    const sendBtnRef = useRef<HTMLAnchorElement>(null);
    const createAccountUrl = AppRoutes.externalUrl(AppRoutes.createCompanyPage(user?.userID));
    function buildHref(): string {
        const emailSubject = L10N({ key: "emailsubject:Invite to join ClassNow" });
        const emailBody = L10N({ key: `emailbody1:Check out this new ClassNow: ` })
            + createAccountUrl
            + L10N({ key: `emailbody2:Remember to use my referral code` });
        const result = `mailto:?subject=${emailSubject}&body=${emailBody}`;
        return result;
    }
    async function shareClick() {
        if (navigator.share) {
            try {
                await navigator.share({ title: L10N({ key: "Invite to join ClassNow" }), url: createAccountUrl });
            } catch { }
        } else {
            sendBtnRef?.current?.click();
        }
    }
    function onLinkClick() {
        copy(createAccountUrl);
    }
    return (<>
        {!inProgress && !(user?.clientAccessGranted ?? false) && <Navigate replace to={AppRoutes.loginAndReturn(AppRoutes.recommend)} />}
        <Container>
            <Row className="my-3" />
            <BackArrowHeader title={<>
                {L10N({ key: "Recommend" })}
                &nbsp;<LogotypeSpans />
            </>
            } />
            <Row className="mb-3">
                <Col />
                <Col className="col-auto">
                    <img src={`/img/icons/notebook.png`} alt="heart" width="150" />
                </Col>
                <Col />
            </Row>
            <Row className="mb-3">
                <Col md={3} />
                <Col >
                    <Typography variant="body1" className="my-3" align="center">
                        {L10N({ key: "Are you satisfied with ClassNow and want to find even more offers?" })}
                    </Typography>
                    <Typography variant="body1" className="my-3" align="center">
                        {L10N({ key: "Then recommend us to your friends, colleagues or other service provider and receive a bonus for every subscription you take out." })}
                    </Typography>
                    <Typography variant="body1" className="my-3" align="center">
                        {L10N({ key: "Get started now, support us and secure your reward!" })}
                    </Typography>
                </Col>
                <Col md={3} />
            </Row>
            <Row className="mb-3">
                <Col md={3} />
                <Col className="px-5">
                    <Typography variant="subtitle2" align="center" className="mb-3">
                        {L10N({ key: "Here is your special code." })}
                    </Typography>
                    <TextField
                        value={createAccountUrl}
                        variant="outlined" color="primary"
                        fullWidth
                        inputProps={
                            { readOnly: true, }
                        }
                        InputProps={{
                            endAdornment: (<>
                                <IconButton onClick={onLinkClick} style={{ order: 1 }} >
                                    <CspIcon name="FileCopyIcon" color="primary" />
                                </IconButton>
                            </>)
                        }}
                    />
                </Col>
                <Col md={3} />
            </Row>
            <Row className="my-1">
                <Col md={3} />
                <Col className="px-5">
                    <Button onClick={shareClick}
                        variant="outlined" color="primary" fullWidth>
                        {L10N({ key: "Share" })}
                    </Button>
                </Col>
                <Col md={3} />
            </Row>
            <Row >
                <Col md={3} />
                <Col className="px-5">
                    <Typography variant="subtitle2" align="center" color="secondary">
                        {L10N({ key: "OR" })}
                    </Typography>
                </Col>
                <Col md={3} />
            </Row>
            <Row className="my-1 mb-5">
                <Col md={3} />
                <Col className="px-5">
                    <Button ref={sendBtnRef}
                        href={buildHref()}
                        variant="contained" color="primary"
                        disableElevation
                        fullWidth>
                        {L10N({ key: "Send to contacts" })}
                        <ButtonShadow />
                    </Button>
                </Col>
                <Col md={3} />
            </Row>
        </Container>
    </>
    );
};
