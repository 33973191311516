import { Typography } from '@mui/material';
import { Col, Container, Row } from 'react-bootstrap';
import { CspIcon } from '../ui/CspIcon';
import { CspImageList } from '../ui/CspImageList';
import { CspList } from '../ui/CspList';
import { LogotypeSpans } from '../ui/LogotypeSpans';
import { StyledTitleRow } from '../ui/StyledTitle';
import { MarketingLink } from './MarketingLink';

export const AboutUsView = () => {
    let i = 0;
    return (<>
        <Container >
            <Row className="my-5">
                <Col xs={true}
                    className="mb-3">
                    <StyledTitleRow text="Highest quality education Beyond Borders" subtitle />
                    <Typography color="secondary">
                        For our family it started in year 2023 when we moved from Poland <CspIcon name='FlagPL' /> to India <CspIcon name='FlagIN' />.
                        <br />
                        We travelled 7000 km <CspIcon name='FlightTakeoffIcon' /> from snowy and cold home country to our new warm home of Kerala, Trivandrum.
                        <br />
                        By having our unique experience of cross-cultural bonding
                        <CspIcon name='HandshakeOutlinedIcon' />
                        we joined together the benefits of the two worlds.
                    </Typography>
                    <CspImageList moreColumns
                        other={[
                            <img src="/img/people/india.png" />
                            ,
                            <img src="/img/people/withAvion.png" />
                        ]} />
                    <Typography color="secondary">
                        Together with Abilash and Merlin from
                        <a href="http://www.avionaviationacademy.com/">
                            &nbsp;Avion Group&nbsp;
                        </a>
                        <MarketingLink platform='fb' className="mx-1" href="https://www.facebook.com/avionavationacademy" />
                        <MarketingLink platform='in' className="mx-1" href="https://www.instagram.com/avion_aviation_academy" />
                        we have founded the new online educational platform in India - <LogotypeSpans />.
                    </Typography>
                </Col>

                <Col xs={12} md={6} lg={true}
                    className="mb-3">
                    <StyledTitleRow text="People Behind Platform" subtitle />
                    <CspList animationFromRight
                        delayMultiplier='fast'
                        items={[
                            {
                                index: i++,
                                iconSrc: "/img/people/agata.png",
                                primaryTitle: "Agata Sawicka",
                                secondaryTitle: <>CEO, <LogotypeSpans /> Poland</>,
                            },
                            {
                                index: i++,
                                iconSrc: "/img/people/abilash.png",
                                primaryTitle: "Dr. Abilash Symon",
                                secondaryTitle: <>CEO, <LogotypeSpans /> India</>,
                            },
                            {
                                index: i++,
                                iconSrc: "/img/people/mat.png",
                                primaryTitle: "Mateusz Sawicki, MBA",
                                secondaryTitle: "Global Technology Partner",
                            },
                            {
                                index: i++,
                                iconSrc: "/img/people/merlin.png",
                                primaryTitle: "Merlin Mariya",
                                secondaryTitle: <>Chief Operating Officer, <LogotypeSpans /> India</>,
                            },
                            {
                                index: i++,
                                icon: <img src="/img/flags/PL.png" width={70} height={70} />,
                                primaryTitle: <>Technical Team, <LogotypeSpans /> Poland</>,
                            },
                            {
                                index: i++,
                                icon: <img src="/img/flags/IN.png" width={70} height={70} />,
                                primaryTitle: <>Customer Success Team, <LogotypeSpans /> India</>,
                            },
                        ]}
                    />
                </Col>
            </Row >
            <Row />
        </Container >
    </>);
}
