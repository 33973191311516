import { Avatar, Button, Chip, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Col } from 'react-bootstrap';
import { api } from '../../api/api';
import { InviteCompanyResource } from '../../api/models/Invite';
import { changeSelectedDimension } from '../../store/invite/invite.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            margin: '2px',
        },
        avatarText: {
            maxWidth: theme.spacing(12),
        },
        halfTransparent: {
            opacity: '0.3',
        },
        horizontalList: {
            display: "flex",
            overflow: "scroll",
            scrollbarWidth: "none",
            paddingBottom: "6px",
        },
        divCenterParent: {
            float: 'left',
            position: 'relative',
            left: '50%',
        },
        divCenterChild: {
            float: 'left',
            position: 'relative',
            left: '-50%',
        }
    })
);
export const ResourceSelector = (props: {
    hideIfOne?: boolean,
}) => {
    const dispatch = useAppDispatch();
    const { currentInvite } = useAppSelector((state: RootState) => state.invite);
    const classes = useStyles();

    function isSelected(m: InviteCompanyResource): boolean {
        return m.dimensionValue === currentInvite?.companyResourceDimensionSelectedValue;
    }
    async function onCompanyResourceClicked(m: InviteCompanyResource) {
        await dispatch(changeSelectedDimension({
            dimensionValue: m,
            dimensionType: "resource",
        }));
    }
    const fabSize = 87;
    return (<>
        {currentInvite?.companyResourceDimension?.map
            && (!props.hideIfOne || currentInvite?.companyResourceDimension?.length > 1)
            && currentInvite?.companyResourceDimension
            && <div className={classes.horizontalList + " noScroll"}>
                {currentInvite?.companyResourceDimension?.map && currentInvite?.companyResourceDimension
                    ?.map((m, i) =>
                        <Col key={i} className={"col-auto p-2"}>
                            <Button
                                onClick={() => onCompanyResourceClicked(m)}>
                                <div className='text-center mx-1'
                                    style={{
                                        maxWidth: fabSize * 1.8,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                    }}
                                >
                                    <Avatar key={i}
                                        className={classes.avatar}
                                        src={api.Search.getPhotoUrl(m.photos[0])} alt={m.displayName} />
                                    <Chip label={m.displayName}
                                        className="mt-1"
                                        color={isSelected(m) ? "primary" : "secondary"}
                                        variant={isSelected(m) ? "filled" : "outlined"}
                                    />
                                </div>
                            </Button>
                        </Col>
                    )}
            </div>
        }
    </>);
};
