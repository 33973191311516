import { Button, ButtonGroup, LinearProgress, Typography } from "@mui/material";
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import { RequestStudentWizard } from "src/api/endpoints/StudentWizardEndpoint";
import { RequestTeacherWizard } from "src/api/endpoints/TeacherWizardEndpoint";
import { StudentWizardEditModel } from "src/api/models/StudentWizard";
import { TeacherWizardEditModel } from "src/api/models/TeacherWizard";
import { api } from '../../api/api';
import { CompanyDetails } from '../../api/models/Details';
import { AssignCompanyMessage, ToggleDemoCompanyMessage } from '../../api/models/Edit';
import { UserListItem } from '../../api/models/User';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { assignCompany } from '../../store/user/user.actions';
import { BackArrowHeader } from '../pages/BackArrowHeader';
import { CompanyList } from "../superAdmin/CompanyList";
import { StudentWizardList } from "../superAdmin/StudentWizardList";
import { TeacherWizardList } from "../superAdmin/TeacherWizardList";
import { UserList } from "../superAdmin/UserList";
import { AppRoutes } from './Routes';

type SuperAdminPageState = {
    inProgress: boolean,
    userList?: UserListItem[],
    companyList?: CompanyDetails[],
    teacherWizardList?: TeacherWizardEditModel[],
    studentWizardList?: StudentWizardEditModel[],
};
export const SuperAdminPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { user, inProgress } = useAppSelector((state: RootState) => state.user);
    const [state, setState] = useState<SuperAdminPageState>({
        inProgress: false,
        userList: undefined,
        companyList: undefined,
        teacherWizardList: undefined,
    });
    async function reloadStore(): Promise<void> {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;

        setState({ inProgress: true });
        await api.Admin.reloadStore(user);
        setState({ inProgress: false });
    }
    async function reloadUserList(): Promise<void> {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;

        setState({
            inProgress: true,
        });
        const result = await api.Users.getUserList(user);
        setState({
            inProgress: false,
            userList: result.result,
        });
    }
    async function reloadCompanyList(): Promise<void> {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;

        setState({
            inProgress: true,
        });
        const result = await api.Details.getCompanyList(user);
        setState({
            inProgress: false,
            companyList: result.result,
        });
    }
    async function reloadTeacherWizardList(): Promise<void> {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;

        setState({
            inProgress: true,
        });
        const result = await api.TeacherWizard.getList(user);
        setState({
            inProgress: false,
            teacherWizardList: result.result,
        });
    }
    async function reloadStudentWizardList(): Promise<void> {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;

        setState({
            inProgress: true,
        });
        const result = await api.StudentWizard.getList(user);
        setState({
            inProgress: false,
            studentWizardList: result.result,
        });
    }
    async function editCompanyClick(companyID?: string) {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;
        if (!companyID) return;

        await navigate(AppRoutes.editPage(companyID));
    }
    async function editTeacherWizard(userID?: string) {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;
        if (!userID) return;

        await navigate(AppRoutes.teacherWizardPage(userID));
    }
    async function editStudentWizard(userID?: string) {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;
        if (!userID) return;

        await navigate(AppRoutes.studentWizardPage(userID));
    }
    async function deleteTeacherWizard(userID?: string) {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;
        if (!userID) return;

        const message: RequestTeacherWizard = {
            accessToken: user.accessToken,
            userID: userID,
        };
        await api.TeacherWizard.deleteForm(message);
        await reloadTeacherWizardList();
    }
    async function deleteStudentWizard(userID?: string) {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;
        if (!userID) return;

        const message: RequestStudentWizard = {
            accessToken: user.accessToken,
            userID: userID,
        };
        await api.StudentWizard.deleteForm(message);
        await reloadStudentWizardList();
    }
    async function viewCompanyClick(companyID?: string) {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;
        if (!companyID) return;

        await navigate(AppRoutes.detailsTab("company", companyID));
    }
    async function CreateCompanyClick() {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;

        const assignCompanyMessage: AssignCompanyMessage = {
            accessToken: user.accessToken,
            companyID: undefined,
        };
        await dispatch(assignCompany({ message: assignCompanyMessage }));
        await navigate(AppRoutes.createCompanyPage());
    }
    async function onAssignCompanyClick(companyID?: string) {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;
        if (!companyID) return;

        const assignCompanyMessage: AssignCompanyMessage = {
            accessToken: user.accessToken,
            companyID: companyID,
        };
        await dispatch(assignCompany({ message: assignCompanyMessage }));
    }
    async function onToggleDemoClick(companyID?: string) {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;
        if (!companyID) return;

        const toggleDemoMessage: ToggleDemoCompanyMessage = {
            accessToken: user.accessToken,
            companyID: companyID,
        };
        await api.Edit.toggleDemoCompany(toggleDemoMessage);
        await reloadCompanyList();
    }

    return (
        <>
            {!inProgress && user?.isSuperAdmin !== true && <Navigate replace to={AppRoutes.loginAndReturn(AppRoutes.superAdminPage())} />}

            <Container className="w-100" style={{ maxWidth: '100%', }}>
                <BackArrowHeader title="Super Admin" />
                {(inProgress || state.inProgress) && <>
                    <Row>
                        <Col xs={12}>
                            <LinearProgress className="w-100 mt-n1" color="secondary" />
                        </Col>
                    </Row>
                </>}
                <Row className="mb-3">
                    <Col md={3} />
                    <Col >
                        <Typography variant="body1" className="my-3" align="center">
                            The tools below are only meant to be used by Super Admins. Please use with care.
                        </Typography>
                    </Col>
                    <Col md={3} />
                </Row>
                <Row className="my-3">
                    <Col xs={12} className="my-3">
                        <ButtonGroup size="small" color="secondary"
                            disabled={state.inProgress}>
                            <Button onClick={reloadStore}>
                                Reload Store
                            </Button>
                            <Button onClick={CreateCompanyClick}>
                                Create company
                            </Button>
                        </ButtonGroup>
                    </Col>
                    <Col xs={12} className="my-3">
                        <ButtonGroup size="small"
                            variant="contained"
                            disabled={state.inProgress}>
                            <Button onClick={reloadUserList}>
                                Manage Users
                            </Button>
                            <Button onClick={reloadCompanyList}>
                                Manage Companies
                            </Button>
                            <Button onClick={reloadTeacherWizardList}>
                                Manage Teachers
                            </Button>
                            <Button onClick={reloadStudentWizardList}>
                                Manage Students
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                {state.userList && state.userList.length > 0 &&
                    <UserList userList={state.userList}
                        assignCompanyClick={onAssignCompanyClick}
                        viewCompanyClick={viewCompanyClick}
                        editCompanyClick={editCompanyClick}
                    />
                }
                {state.companyList && state.companyList.length > 0 &&
                    <CompanyList companyList={state.companyList}
                        assignCompanyClick={onAssignCompanyClick}
                        viewCompanyClick={viewCompanyClick}
                        editCompanyClick={editCompanyClick}
                        toggleDemoClick={onToggleDemoClick}
                    />
                }
                {state.teacherWizardList && state.teacherWizardList.length > 0 &&
                    <TeacherWizardList teacherWizardList={state.teacherWizardList}
                        editTeacherWizard={editTeacherWizard}
                        deleteTeacherWizard={deleteTeacherWizard}
                    />
                }
                {state.studentWizardList && state.studentWizardList.length > 0 &&
                    <StudentWizardList studentWizardList={state.studentWizardList}
                        editStudentWizard={editStudentWizard}
                        deleteStudentWizard={deleteStudentWizard}
                    />
                }
            </Container >
        </>
    );
};
