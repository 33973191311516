import { Backdrop, Box, Drawer, Fab } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { SearchCategory_Anything } from 'src/api/models/Search';
import { TeacherWizardEditModel } from 'src/api/models/TeacherWizard';
import { CompanyDetailsCard } from '../details/CompanyDetailsCard';
import { ResourceAvatar } from '../details/CompanyDetailsViewTabDetailsResources';
import { VisitReviewItemCard } from '../details/VisitReviewItemCard';
import { SearchCard } from '../search/SearchCard';
import { CspIcon } from '../ui/CspIcon';
import { useFormikResult } from '../ui/FormikAPI';
import { StyledTitleRow } from '../ui/StyledTitle';
import { LogotypeSpans } from "../ui/LogotypeSpans";

export type PreviewDrawerState = {
    isOpen: boolean,
    autoPreviewWasTriggered: boolean,
    wasClosed: boolean,
}
export const PreviewResourceAvatar = (props: {
    formik: useFormikResult<TeacherWizardEditModel>,
}) => {
    return (
        <ResourceAvatar isCurrent
            resource={{
                itemID: "",
                itemType: "Resource",
                title: props.formik.values.displayName,
                subtitle: props.formik.values.category ?? SearchCategory_Anything,
                description: props.formik.values.description ?? "",
                htmlDescription: "",
                learnMoreURL: "",
                photos: props.formik.values.avatarPhotos,
                availability: [],
                contactDetails: "",
            }}
        />
    );
}

export const PreviewVisitReviewItemCard = (props: {
    formik: useFormikResult<TeacherWizardEditModel>,
}) => {
    return (
        <div style={{
            position: 'relative'
        }}>
            <VisitReviewItemCard data={{
                userID: "",
                visitID: "",
                visitReviewID: "",
                reviewAuthor: props.formik.values.displayName,
                reviewDate: moment().add(-1, "M").toISOString(),
                ratingScore: 5,
                reviewTitle: "Review title",
                reviewDetails: "Review details",
                photos: props.formik.values.avatarPhotos,
                isCompanyReview: false,
            }}
            />
        </div>
    );
}

export const PreviewSearchCard = (props: {
    formik: useFormikResult<TeacherWizardEditModel>,
}) => {
    return (
        <div style={{
            position: 'relative'
        }}>
            <SearchCard
                data={{
                    title: props.formik.values.displayName,
                    subtitle: props.formik.values.category ?? SearchCategory_Anything,
                    addressLine: [props.formik.values.address ?? ""],
                    details: props.formik.values.description ?? "",
                    photos: props.formik.values.cardPhotos,
                    companyID: "",
                    isDemo: true,
                    ratingScore: 5,
                    badgeLevel: 5,
                    mapCoords: {
                        lat: 1, lng: 1,
                    },
                    offeredResourceID: [],
                }}
                showImage
                onCardClick={() => { }}
            />
        </div>
    );
}

export const PreviewCompanyDetailsCard = (props: {
    formik: useFormikResult<TeacherWizardEditModel>,
    renderVertical?: boolean,
}) => {
    return (
        <CompanyDetailsCard renderVertical={props.renderVertical}
            company={{
                itemID: "",
                itemType: "Company",
                title: props.formik.values.displayName,
                subtitle: props.formik.values.category ?? SearchCategory_Anything,
                description: props.formik.values.description ?? "",
                htmlDescription: "",
                learnMoreURL: "",
                photos: props.formik.values.cardPhotos,
                reviewSummary: {
                    averageScore: 1,
                    badgeLevel: 1,
                },
                address: props.formik.values.address ?? "",
                category: props.formik.values.category ?? SearchCategory_Anything,
                mapCoords: props.formik.values.mapCoords,
                serviceGroups: [],
                resources: [{
                    itemID: "",
                    itemType: "Resource",
                    title: props.formik.values.displayName,
                    subtitle: props.formik.values.category ?? SearchCategory_Anything,
                    description: props.formik.values.description ?? "",
                    htmlDescription: "",
                    learnMoreURL: "",
                    photos: props.formik.values.avatarPhotos,
                    availability: [],
                    contactDetails: undefined
                }],
                portfolio: [],
                reviews: [],
                isDemo: true,
                isFavorite: false,
            }} />
    );
}
export const PreviewDrawer = (props: {
    formik: useFormikResult<TeacherWizardEditModel>,
    state: PreviewDrawerState,
    onAutoPreviewWasTriggered: () => void,
    onClose: () => void,
}) => {
    function shouldTriggerPreviewDrawer() {
        if (props.formik.values.stage && props.formik.values.stage !== "initial") return false;
        if (props.state.autoPreviewWasTriggered) {
            return false;
        }
        if (props.formik.touched
            && props.formik.values.displayName?.length > 0
            && props.formik.values.avatarPhotos?.length > 0
            && props.formik.values.cardPhotos?.length > 0
        ) {
            return true;
        }
    }
    useEffect(() => {
        if (shouldTriggerPreviewDrawer()) {
            props.onAutoPreviewWasTriggered();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formik]);

    const drawerWidth = 340;

    return <>
        <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={props.state.isOpen}
            onClick={props.onClose}
        >
            <Drawer open={props.state.isOpen}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                transitionDuration={800}
                variant="persistent"
                anchor="right"
            >
                <Box className="m-3">
                    <Box>
                        <Fab onClick={props.onClose}>
                            <CspIcon name='CloseIcon' />
                        </Fab>
                    </Box>
                    <StyledTitleRow text={<>This is how you will appear in <LogotypeSpans /> </>} alignLeft subtitle />
                    <PreviewCompanyDetailsCard formik={props.formik} renderVertical />
                </Box>
            </Drawer>
        </Backdrop>
    </>;
}



