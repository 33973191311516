import { createTheme } from '@mui/material';
import createPalette from '@mui/material/styles/createPalette';
import { CspIcon } from './CspIcon';

export const palette = createPalette({
    primary: {
        main: '#f49f0a',
        light: '#ffdcad',
        dark: '#a24936',
    },
    secondary: {
        main: '#2a2a72',
        light: '#EEEEEE',
    },
    info: {
        main: '#ffffff',
        light: '#ffffff',
        dark: '#000000',
    },
    background: {
        paper: '#ffffff',
    },

});
export const theme = createTheme({
    palette: {
        primary: palette.primary,
        secondary: palette.secondary,
        background: palette.background,
        info: palette.info,
    },
    shape: {
        borderRadius: 32,
    },
    typography: {
        fontFamily: ['"Commissioner"'].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        fontSize: 14,
        body1: {
            fontSize: "0.9rem",
        },
        body2: {
            fontSize: "0.8rem",
        },
        subtitle1: {
        },
        subtitle2: {
        },
        h6: {
        },

    },
    components: {
        MuiAccordionSummary: {
            defaultProps: {
                expandIcon: <CspIcon name="ExpandMoreIcon" />
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                containedPrimary: {
                    color: '#ffffff',
                    textShadow: '0 2px 4px #a24936',
                    '&.Mui-disabled': {
                        textShadow: 'none',
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    '&.Mui-selected': {
                        borderColor: palette.primary.main,
                        borderWidth: 2,
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                filledPrimary: {
                    color: '#ffffff',
                    textShadow: '0 2px 4px #a24936',
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        backgroundColor: 'rgba(0, 0, 0, 0.32)',
                    },
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                color: 'secondary',
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                fullWidth: true,
            },
        },
        MuiDialogTitle: {
            defaultProps: {
                color: "secondary",
                align: 'center',
            },
        },
        MuiDialogContent: {
            defaultProps: {
                color: "secondary",
            },
        },
        MuiDialogActions: {
            defaultProps: {
                sx: {
                    justifyContent: "center",
                },
            },
        },
    }
});