import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Calendar, MyCompanies } from '../../api/models/Company';
import { SignalMessage } from '../../api/models/Global';
import { addInProgressCases, addInProgressMatcher } from '../reduxHelpers';
import { loadCompanyCalendar, loadResourceCalendar } from './company.actions';

export interface CompanyState {
    inProgress: boolean;
    calendar?: Calendar;
    myCompanies?: MyCompanies;
    signalMessages?: SignalMessage[],
}
const initialState: CompanyState = {
    inProgress: false,
    calendar: undefined,
    myCompanies: undefined,
    signalMessages: undefined,
}

const companySlice = createSlice({
    name: 'companySlice',
    initialState,
    reducers: {
        setSignalMessages(state, action: PayloadAction<{
            signalMessages?: SignalMessage[],
        }>) {
            state.signalMessages = action.payload.signalMessages;
        },
    },
    extraReducers: (builder) => {
        addInProgressCases(builder, loadCompanyCalendar);
        addInProgressCases(builder, loadResourceCalendar);

        builder.addCase(loadCompanyCalendar.fulfilled, (state, action) => {
            state.calendar = action.payload;
        })
        builder.addCase(loadResourceCalendar.fulfilled, (state, action) => {
            state.calendar = action.payload;
        })
        addInProgressMatcher(builder, loadCompanyCalendar);
        addInProgressMatcher(builder, loadResourceCalendar);
    },
});
export const {
    setSignalMessages,
} = companySlice.actions
export default companySlice.reducer