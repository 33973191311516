import { Avatar, Badge, Button, CardActionArea, CircularProgress, Typography } from "@mui/material";
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { api } from '../../api/api';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { getCurrentUser, logout } from '../../store/user/user.actions';
import { Animations } from '../ui/Animations';
import { CspIcon } from "../ui/CspIcon";
import { CspList } from "../ui/CspList";
import { LogotypeSpans } from "../ui/LogotypeSpans";
import { StyledTitleRow } from "../ui/StyledTitle";
import { L10N } from './L10N';
import { PageHeader } from "./PageHeader";
import { AppRoutes } from './Routes';

export const MenuButton = (props: {
    disabled?: boolean,
    inProgress?: boolean,
    text: string,
    onClick?: (tab?: ProfileTab) => void,
    destination?: ProfileTab,
    i: number,
    notificationBadge?: number,
}) => {
    const navigate = useNavigate();

    async function handleClick() {
        if (props.onClick) {
            props.onClick(props.destination);
            return;
        } else {
            await navigate(AppRoutes.profilePage(props.destination));
        }
    }

    return (<Button disabled={props.disabled} variant="contained"
        onClick={(event) => handleClick()}
        className={"m-1"
            + " " + Animations.delay(props.i)
            + " " + Animations.slide_in_bottom()
        }
        endIcon={
            <Badge badgeContent={props.notificationBadge} color="primary"
                max={9}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
                {!props.inProgress && <img alt="next" src="/img/icons/icon_next.svg" />}
            </Badge>
        }
        fullWidth>
        {props.inProgress && <CircularProgress />}
        {!props.inProgress &&
            <Typography variant="subtitle2" align="left" className="m-2 w-100" >
                {props.text}
            </Typography>}
    </Button>);
}
export type ProfileTab = ""
    | "default"
    | "createCompany"
    | "editCompany"
    | "clients"
    | "companyCalendar"
    | "resourceCalendar"
    | "recommend"
    | "statistics"
    | "account"
    | "reviews"
    | "terms"
    | "privacy"
    | "contactus"
    | "about"
    | "superAdmin"
    ;

export const ProfilePage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { user, inProgress } = useAppSelector((state: RootState) => state.user);
    const urlParams = useParams<{ tab: ProfileTab }>();
    useEffect(() => {
        if (inProgress) return;
        if (!user) return;
        dispatch(getCurrentUser({ message: user }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loginClick() {
        await navigate(AppRoutes.loginAndReturn(AppRoutes.profilePage()));
    }
    async function logoutClick() {
        if (!user) return;
        await dispatch(logout({ message: user }));
    }
    async function onClick(tab?: ProfileTab) {
        await navigate(AppRoutes.profilePage(tab));
    }
    function tabRequiresUser(): boolean {
        switch (urlParams.tab) {
            case "createCompany":
            case "editCompany":
            case "clients":
            case "companyCalendar":
            case "resourceCalendar":
            case "account":
            case "reviews":
            case "recommend":
            case "statistics":
                return true;
            default:
                return false;
        }
    }
    const authorized = (user?.clientAccessGranted ?? false);
    let i = 0;
    return (<>
        {tabRequiresUser() && !authorized && <Navigate replace to={AppRoutes.loginAndReturn(AppRoutes.profilePage())} />}
        {urlParams?.tab === "createCompany" && <Navigate replace to={AppRoutes.createCompanyPage()} />}
        {urlParams?.tab === "editCompany" && <Navigate replace to={AppRoutes.editPage(user?.companyID ?? "")} />}
        {urlParams?.tab === "clients" && <Navigate replace to={AppRoutes.clientsTab()} />}
        {urlParams?.tab === "account" && <Navigate replace to={AppRoutes.accountDetails} />}
        {urlParams?.tab === "terms" && <Navigate replace to={AppRoutes.terms} />}
        {urlParams?.tab === "contactus" && <Navigate replace to={AppRoutes.contactus} />}
        {urlParams?.tab === "privacy" && <Navigate replace to={AppRoutes.privacy} />}
        {urlParams?.tab === "statistics" && <Navigate replace to={AppRoutes.statisticsTab("visit")} />}
        {urlParams?.tab === "recommend" && <Navigate replace to={AppRoutes.recommend} />}
        {urlParams?.tab === "reviews" && <Navigate replace to={AppRoutes.visitReview} />}
        {urlParams?.tab === "companyCalendar" && user?.companyID && <Navigate replace to={AppRoutes.calendarPage("company", "calendar")} />}
        {urlParams?.tab === "resourceCalendar" && user?.resourceID && <Navigate replace to={AppRoutes.calendarPage("resource", "list")} />}
        {urlParams?.tab === "superAdmin" && <Navigate replace to={AppRoutes.superAdminPage()} />}

        <Container className="px-5"
            style={{
                overflowY: 'hidden',
                minHeight: '100vh',
            }}
        >
            <PageHeader title="" showLogo />
            <StyledTitleRow text={<>Here you will find more options of <LogotypeSpans /> </>} className='mb-3' />
            <CardActionArea onClick={() => onClick("account")}>
                <Row className="my-5" >
                    <Col className="col-auto" >
                        <Avatar style={{
                            width: '70px',
                            height: '70px',
                        }}
                            src={api.Search.getFirstPhotoUrl(user?.photos)}
                            className={"m-1 "}
                            alt={user?.firstName} />
                    </Col >
                    <Col className="col-auto align-items-center d-flex" >
                        <div className=" " >
                            <Typography variant="subtitle2">
                                {user?.firstName}&nbsp;{user?.lastName}
                            </Typography>
                            <Typography >
                                {user?.phone}
                            </Typography>
                        </div>
                    </Col >
                    <Col />
                </Row>
            </CardActionArea>
            <CspList delayMultiplier="fastest"
                items={[
                    {
                        index: i++,
                        icon: <CspIcon name="AdminPanelSettingsOutlinedIcon" />,
                        primaryTitle: "Super Admin",
                        onClick: async () => onClick("superAdmin"),
                        hidden: !user?.isSuperAdmin,
                    },
                    {
                        index: i++,
                        icon: <CspIcon name="TouchAppOutlinedIcon" />,
                        primaryTitle: L10N({ key: "Create Company" }),
                        onClick: async () => onClick("createCompany"),
                        hidden: !user?.isSuperAdmin,
                    },
                    {
                        index: i++,
                        icon: <CspIcon name="TouchAppOutlinedIcon" />,
                        primaryTitle: L10N({ todo: "Teacher Wizard" }),
                        onClick: async () => await navigate(AppRoutes.teacherWizardPage()),
                        hidden: !user?.clientAccessGranted,
                    }, {
                        index: i++,
                        icon: <CspIcon name="TouchAppOutlinedIcon" />,
                        primaryTitle: L10N({ todo: "Student Wizard" }),
                        onClick: async () => await navigate(AppRoutes.studentWizardPage()),
                        hidden: !user?.clientAccessGranted,
                    },
                    {
                        index: i++,
                        icon: <CspIcon name="EditIcon" />,
                        primaryTitle: L10N({ key: "Edit Company" }),
                        onClick: async () => onClick("editCompany"),
                        hidden: !user?.companyID,
                    },
                    {
                        index: i++,
                        icon: <CspIcon name="EventAvailableOutlinedIcon" />,
                        primaryTitle: L10N({ key: "Company Calendar" }),
                        onClick: async () => onClick("companyCalendar"),
                        hidden: !user?.companyID,
                        notificationBadge: user?.notificationBadges?.numberOfVisitsToConfirmForCompany,
                    },
                    {
                        index: i++,
                        icon: <CspIcon name="EventAvailableOutlinedIcon" />,
                        primaryTitle: L10N({ key: "My Calendar" }),
                        onClick: async () => onClick("resourceCalendar"),
                        hidden: !user?.companyID,
                        notificationBadge: user?.notificationBadges?.numberOfVisitsToConfirmForResource,
                    },
                    {
                        index: i++,
                        icon: <CspIcon name="PeopleOutlinedIcon" />,
                        primaryTitle: L10N({ todo: "Students" }),
                        onClick: async () => onClick("clients"),
                        hidden: true,
                        notificationBadge: user?.notificationBadges?.numberOfVisitsToConfirmForResource,
                    },
                    {
                        index: i++,
                        icon: <CspIcon name="RecommendOutlinedIcon" />,
                        primaryTitle: L10N({ key: "Recommend" }),
                        onClick: async () => onClick("recommend"),
                    },
                    {
                        index: i++,
                        icon: <CspIcon name="QueryStatsIcon" />,
                        primaryTitle: L10N({ key: "Statistics &amp; Reports" }),
                        onClick: async () => onClick("statistics"),
                        hidden: true,
                    },
                    {
                        index: i++,
                        icon: <CspIcon name="ReviewsIcon" />,
                        primaryTitle: L10N({ key: "Reviews" }),
                        onClick: async () => onClick("reviews"),
                        hidden: !authorized,
                    },
                    {
                        index: i++,
                        icon: <CspIcon name="SecurityIcon" />,
                        primaryTitle: L10N({ key: "Terms and Conditions" }),
                        onClick: async () => onClick("terms"),
                    },
                    {
                        index: i++,
                        icon: <CspIcon name="SecurityIcon" />,
                        primaryTitle: L10N({ key: "Privacy Policy" }),
                        onClick: async () => onClick("privacy"),
                    },
                    {
                        index: i++,
                        icon: <CspIcon name="ContactSupportOutlinedIcon" />,
                        primaryTitle: L10N({ key: "Contact Us" }),
                        onClick: async () => onClick("contactus"),
                    },
                    {
                        index: i++,
                        icon: <CspIcon name="LogoutIcon" />,
                        primaryTitle: L10N({ key: "Logout" }),
                        onClick: logoutClick,
                        hidden: !authorized,
                    },
                    {
                        index: i++,
                        icon: <CspIcon name="LoginOutlinedIcon" />,
                        primaryTitle: L10N({ key: "Login" }),
                        onClick: loginClick,
                        hidden: authorized,
                    },
                ]}
            />
        </Container>
    </>);
}
