import { Button, ButtonGroup, LinearProgress, Step, StepButton, Stepper, Typography } from "@mui/material";
import { useFormik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { CompanyEditModelValidationSchema, CreateNewCompany, CreateNewResource, CreateNewService, CreateNewVisitReview, ResourceEditModel, ResourceEditModelValidationSchema, ServiceEditModel, ServiceEditModelValidationSchema, VisitReviewEditModel, VisitReviewEditModelValidationSchema } from '../../api/models/Edit';
import { loadAllCompanyForEdit, UpdateCompanyAndReload, UpdateCompanyResourceAndReload, UpdateCompanyServiceAndReload, UpdateVisitReviewAndReload } from '../../store/edit/edit.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { FormCompanyReview } from '../edit/FieldSetCompanyReview';
import { FieldSetResourceList } from '../edit/FieldSetResourceList';
import { FieldSetServiceList } from '../edit/FieldSetServiceList';
import { FieldSetVisitReviewList } from '../edit/FieldSetVisitReviewList';
import { CompanyEditStep, FormCompanyDetails } from '../edit/FormCompanyDetails';
import { FormResourceDetails } from '../edit/FormResourceDetails';
import { FormServiceDetails } from '../edit/FormServiceDetails';
import { FormVisitReviewDetails } from '../edit/FormVisitReviewDetails';
import { BackArrowHeader } from '../pages/BackArrowHeader';
import { ScreenSizeListener } from '../ui/ScreenSizeListener';
import { L10N } from './L10N';
import { AppRoutes } from './Routes';

export type EditStep = CompanyEditStep
    | 'resources'
    | 'services'
    | 'reviews'
    | 'publish'
    ;
function getAllSteps(): EditStep[] {
    return [
        'about',
        'for clients',
        'address',
        'resources',
        'services',
        'reviews',
        'publish',
    ];
}

export const EditPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { user, inProgress } = useAppSelector((state: RootState) => state.user);
    const { inProgress: editInProgress, company, resources, services, visitReviews } = useAppSelector((state: RootState) => state.edit);
    const urlParams = useParams<{
        step: EditStep,
        companyID: string,
        selectedServiceID?: string,
        selectedResourceID?: string,
        selectedVisitReviewID?: string,
    }>();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getAllSteps();
    const selectedResource = resources?.find((m, i) => m.resourceID === urlParams.selectedResourceID);
    const selectedService = services?.find((m, i) => m.serviceID === urlParams.selectedServiceID);
    const selectedVisitReview = visitReviews?.find((m, i) => m.visitReviewID === urlParams.selectedVisitReviewID);

    useEffect(() => {
        if (inProgress) return;
        if (!urlParams.companyID) return;

        if (urlParams.companyID !== company?.companyID) {
            if (!user) return;
            dispatch(loadAllCompanyForEdit({
                message: user,
                companyID: urlParams.companyID
            }));
        }
    }, [company, urlParams, inProgress, user, dispatch]);

    const formik = useFormik({
        initialValues: company ?? CreateNewCompany(),
        enableReinitialize: true,
        validationSchema: CompanyEditModelValidationSchema,
        onSubmit: async (values) => {
            values.accessToken = user?.accessToken ?? "";
            await dispatch(UpdateCompanyAndReload({ message: values }));
        },
    });
    const formikResource = useFormik({
        initialValues: selectedResource ?? CreateNewResource(company?.companyID ?? ""),
        enableReinitialize: true,
        validationSchema: ResourceEditModelValidationSchema,
        onSubmit: async (values) => {
            values.accessToken = user?.accessToken ?? "";
            await dispatch(UpdateCompanyResourceAndReload({ message: values }));
            const url = AppRoutes.editPage(urlParams.companyID ?? "unknown", "resources", undefined, undefined);
            navigate(url);
        },
    });
    const formikService = useFormik({
        initialValues: selectedService ?? CreateNewService(company?.companyID ?? ""),
        enableReinitialize: true,
        validationSchema: ServiceEditModelValidationSchema,
        onSubmit: async (values) => {
            values.accessToken = user?.accessToken ?? "";
            await dispatch(UpdateCompanyServiceAndReload({ message: values }));
            const url = AppRoutes.editPage(urlParams.companyID ?? "unknown", "services", undefined, undefined);
            navigate(url);
        },
    });
    const formikVisitReview = useFormik({
        initialValues: selectedVisitReview ?? CreateNewVisitReview(company?.companyID ?? ""),
        enableReinitialize: true,
        validationSchema: VisitReviewEditModelValidationSchema,
        onSubmit: async (values) => {
            values.accessToken = user?.accessToken ?? "";
            await dispatch(UpdateVisitReviewAndReload({ message: values }));
            const url = AppRoutes.editPage(urlParams.companyID ?? "unknown", "reviews", undefined, undefined);
            navigate(url);
        },
    });
    useEffect(() => {
        if (activeStep < 0 || activeStep >= steps.length) return;
        const stepValue = steps[activeStep];
        if (stepValue === urlParams.step) return;

        const url = getStepUrl(activeStep);
        if (!url) return;

        navigate(url);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep]);

    function getStepUrl(stepIndex: number): string {
        const stepValue = getStepByIndex(stepIndex);
        const url = AppRoutes.editPage(urlParams.companyID ?? "unknown", stepValue, undefined, undefined);
        return url;
    }
    function getStepByIndex(step: number): EditStep | undefined {
        if (activeStep < 0 || activeStep >= steps.length) return;

        const stepValue = steps[step];
        return stepValue;
    }
    const handleNext = () => {
        const stepValue = getStepByIndex(activeStep);
        const shouldReturnFromResources = stepValue === "resources"
            && urlParams.selectedResourceID
            && urlParams.selectedResourceID !== "-";
        const shouldReturnFromServices = stepValue === "services"
            && urlParams.selectedServiceID
            && urlParams.selectedServiceID !== "-";
        const shouldReturnFromVisitReviews = stepValue === "reviews"
            && urlParams.selectedVisitReviewID
            && urlParams.selectedVisitReviewID !== "-";
        if (shouldReturnFromResources
            || shouldReturnFromServices
            || shouldReturnFromVisitReviews
        ) {
            const url = getStepUrl(activeStep);
            if (!url) return;
            navigate(url);
            return;
        } else {
            setActiveStep((prevActiveStep) => (prevActiveStep + 1) % steps.length);
        }
    };
    const handleBack = () => {
        const stepValue = getStepByIndex(activeStep);
        const shouldReturnFromResources = stepValue === "resources"
            && urlParams.selectedResourceID
            && urlParams.selectedResourceID !== "-";
        const shouldReturnFromServices = stepValue === "services"
            && urlParams.selectedServiceID
            && urlParams.selectedServiceID !== "-";
        const shouldReturnFromVisitReviews = stepValue === "reviews"
            && urlParams.selectedVisitReviewID
            && urlParams.selectedVisitReviewID !== "-";
        if (shouldReturnFromResources
            || shouldReturnFromServices
            || shouldReturnFromVisitReviews
        ) {
            const url = getStepUrl(activeStep);
            if (!url) return;
            navigate(url);
            return;
        } else {
            setActiveStep((prevActiveStep) => (prevActiveStep - 1) % steps.length);
        }
    };
    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };
    function onNewVisitReviewClick() {
        formikService.resetForm();
        const url = AppRoutes.editPage(urlParams.companyID ?? "unknown",
            "reviews",
            urlParams.selectedServiceID,
            urlParams.selectedResourceID,
            "new");
        navigate(url);
    }
    function onVisitReviewClick(item: VisitReviewEditModel) {
        const url = AppRoutes.editPage(urlParams.companyID ?? "unknown",
            "reviews",
            urlParams.selectedServiceID,
            urlParams.selectedResourceID,
            item.visitReviewID);
        navigate(url);
    }
    function onNewServiceClick() {
        formikService.resetForm();
        const url = AppRoutes.editPage(urlParams.companyID ?? "unknown",
            "services",
            "new",
            urlParams.selectedResourceID,
            urlParams.selectedVisitReviewID);
        navigate(url);
    }
    function onServiceClick(item: ServiceEditModel) {
        const url = AppRoutes.editPage(urlParams.companyID ?? "unknown",
            "services",
            item.serviceID,
            urlParams.selectedResourceID,
            urlParams.selectedVisitReviewID);
        navigate(url);
    }
    function onNewResourceClick() {
        formikResource.resetForm();
        const url = AppRoutes.editPage(urlParams.companyID ?? "unknown",
            "resources",
            urlParams.selectedServiceID,
            "new",
            urlParams.selectedVisitReviewID);
        navigate(url);
    }
    function onResourceClick(item: ResourceEditModel) {
        const url = AppRoutes.editPage(urlParams.companyID ?? "unknown",
            "resources",
            urlParams.selectedServiceID,
            item.resourceID,
            urlParams.selectedVisitReviewID);
        navigate(url);
    }
    function onSeeAsClientClick() {
        const url = AppRoutes.detailsTab("company", urlParams.companyID);
        if (isMobile) {
            const fullUrl = AppRoutes.externalUrl(url);
            window.location.href = fullUrl;
        } else {
            window.open(url, '_blank')?.focus();
        }
    }
    const showTitleThreshold = "xs";
    const activeStepValue = getStepByIndex(activeStep);

    return (
        <>
            {!inProgress && !(user?.clientAccessGranted ?? false) && <Navigate replace to={AppRoutes.loginAndReturn(getStepUrl(activeStep))} />}
            <Container>
                <BackArrowHeader title={L10N({ key: 'Edit Company' })} />
                {editInProgress && <>
                    <Row>
                        <Col xs={12}>
                            <LinearProgress className="w-100 mt-n1" color="secondary" />
                        </Col>
                    </Row>
                </>}
                <Row>
                    <Col>
                        <Stepper alternativeLabel nonLinear
                            activeStep={activeStep} >
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepButton onClick={handleStep(index)}
                                    >
                                        <ScreenSizeListener threshold={showTitleThreshold}
                                            whenAboveThreshold={L10N({ key: '_editStep', editStep: getStepByIndex(index) })}
                                            whenThresholdOrBelow={""}
                                        />
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ScreenSizeListener threshold={showTitleThreshold}
                            whenAboveThreshold={""}
                            whenThresholdOrBelow={<Typography align="center" >{L10N({ key: '_editStep', editStep: getStepByIndex(activeStep) })}
                            </Typography>}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="my-5">
                            {(activeStepValue === 'about'
                                || activeStepValue === 'for clients'
                                || activeStepValue === 'address'
                                || activeStepValue === 'publish'
                            )
                                &&
                                <FormCompanyDetails formik={formik} activeStepValue={activeStepValue} />
                            }
                            {activeStepValue === 'resources' && <>
                                {urlParams.selectedResourceID && urlParams.selectedResourceID !== "-"
                                    ? <FormResourceDetails formik={formikResource} />
                                    : <FieldSetResourceList onNew={onNewResourceClick} onSelected={onResourceClick} />
                                }
                            </>}
                            {activeStepValue === 'services' && <>
                                {urlParams.selectedServiceID && urlParams.selectedServiceID !== "-"
                                    ? <FormServiceDetails formik={formikService} />
                                    : <FieldSetServiceList onNew={onNewServiceClick} onSelected={onServiceClick} />
                                }
                            </>}
                            {activeStepValue === 'reviews' && <>
                                {urlParams.selectedVisitReviewID && urlParams.selectedVisitReviewID !== "-"
                                    ? <FormVisitReviewDetails formik={formikVisitReview} />
                                    : <>
                                        <FormCompanyReview formik={formik} />
                                        <FieldSetVisitReviewList onNew={onNewVisitReviewClick} onSelected={onVisitReviewClick} />
                                    </>
                                }
                            </>}
                        </div>
                    </Col>
                </Row>
                {(!formikService.dirty && !formikResource.dirty && !formik.dirty)
                    ?
                    <Row className="mb-5">
                        <Col />
                        <Col className="col-auto">
                            <ButtonGroup size="small" >
                                <Button disabled={activeStep === 0} onClick={handleBack} >
                                    {L10N({ key: 'Back' })}
                                </Button>
                                <Button onClick={onSeeAsClientClick}>
                                    {L10N({ key: 'View as client' })}
                                </Button>
                                <Button variant="contained"
                                    disabled={activeStep === steps.length - 1}
                                    color="primary"
                                    onClick={handleNext}
                                >
                                    {L10N({ key: 'Next' })}
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col />
                    </Row>
                    :
                    <Row className="mb-5">
                        <Col />
                        <Col className="col-auto">
                            <ButtonGroup size="small" >
                                <Button onClick={onSeeAsClientClick}>
                                    {L10N({ key: 'View as client' })}
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col />
                    </Row>
                }
            </Container>
        </>
    );
};
