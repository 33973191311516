import { RootState, useAppSelector } from '../../store/store';
import { ServiceDetailsDialog } from '../details/ServiceDetailsDialog';
import { CancelVisitDialog } from '../invite/CancelVisitDialog';
import { ConsentsDialog } from '../login/ConsentsDialog';

export const ModalDialogs = () => {
    const { cancelVisitDialogVisible,
        serviceDetailsDialogVisible,
        consentsDialogVisible } = useAppSelector((state: RootState) => state.ui);

    return (
        <>
            {cancelVisitDialogVisible && <CancelVisitDialog />}
            {serviceDetailsDialogVisible && <ServiceDetailsDialog />}
            {consentsDialogVisible && <ConsentsDialog />}
        </>
    );
};
