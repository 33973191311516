import { Col, Row } from 'react-bootstrap';
import { TeacherWizardEditModel } from 'src/api/models/TeacherWizard';
import { useFormikResult } from '../ui/FormikAPI';
import { TeacherWizardStep } from './Common';
import { FieldSetAboutYou } from './FieldSetAboutYou';
import { FieldSetAppearance } from './FieldSetAppearance';
import { FieldSetAvailability } from './FieldSetAvailability';
import { FieldSetClasses } from './FieldSetClasses';
import { FieldSetContactDetails } from './FieldSetContactDetails';
import { FieldSetExperience } from './FieldSetExperience';
import { FieldSetSummary } from './FieldSetSummary';

export type FormState = {
    activeStep: number,
    activeStepValue: TeacherWizardStep,
}
export type FormProps = {
    formik: useFormikResult<TeacherWizardEditModel>,
    state: FormState,
}
export type FieldSetProps = {
    formik: useFormikResult<TeacherWizardEditModel>,
}
export const FormTeacherWizard = (props: FormProps) => {
    const fieldSetProps: FieldSetProps = {
        formik: props.formik,
    };
    return <>
        <Row>
            <Col >
                <form onSubmit={props.formik.handleSubmit}>
                    {props.state.activeStepValue === 'About you' && <FieldSetAboutYou {...fieldSetProps} />}
                    {props.state.activeStepValue === 'Appearance' && <FieldSetAppearance  {...fieldSetProps} />}
                    {props.state.activeStepValue === 'Experience' && <FieldSetExperience  {...fieldSetProps} />}
                    {props.state.activeStepValue === 'Classes' && <FieldSetClasses  {...fieldSetProps} />}
                    {props.state.activeStepValue === 'Availability' && <FieldSetAvailability  {...fieldSetProps} />}
                    {props.state.activeStepValue === 'Contact Details' && <FieldSetContactDetails  {...fieldSetProps} />}
                    {props.state.activeStepValue === 'Summary' && <FieldSetSummary  {...fieldSetProps} />}
                </form>
            </Col>
        </Row>
    </>;
}