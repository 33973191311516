import { Avatar, AvatarGroup, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';
import { api } from "../../api/api";
import { RootState, useAppSelector } from '../../store/store';
import { theme } from "../ui/Theme";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            cursor: 'pointer',
        },
        largeSelected: {
            width: theme.spacing(8),
            height: theme.spacing(8),
            borderColor: theme.palette.primary.main,
            borderWidth: '3px',
            cursor: 'pointer',
        },
    }),
);
export const CalendarResourcePicker = (props: {
    toggleSelectResource: (resourceID: string | undefined) => void,
    selectedResourceDimension?: string,
}) => {
    const { calendar } = useAppSelector((state: RootState) => state.company);
    const classes = useStyles();

    return (<>
        {calendar?.resourceDimension?.map &&
            <AvatarGroup>
                {calendar?.resourceDimension?.map((m, i) =>
                    <div className={"float-left mx-2"} key={m.resourceID}
                        onClick={() => props.toggleSelectResource(m.resourceID)}
                    >
                        <div className="divCenterParent">
                            <div className="divCenterChild">
                                <Avatar
                                    alt={m.displayName}
                                    className={m.resourceID === props.selectedResourceDimension
                                        ? `${classes.largeSelected} `
                                        : `${classes.large} `}
                                    src={api.Search.getPhotoUrl(m.photo)} />
                            </div>
                        </div>
                        <div className="w-100"></div>
                        <div className={"float-left w-100 mt-1"}
                            style={{ maxWidth: theme.spacing(12) }}>
                            <Typography variant="body2" align="center"
                                color={m.resourceID === props.selectedResourceDimension
                                    ? "textPrimary"
                                    : "textSecondary"}                                >
                                {m.displayName}
                            </Typography>
                        </div>
                    </div>
                )}
            </AvatarGroup>
        }
    </>);
};
