import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { AccessTokenBearer } from "../models/Global";
import { ToggleFavoriteCompanyMessage, UserListItem, UserProfile, UserRefreshAccessTokenMessage, UserRefreshPINMessage } from "../models/User";

export class UsersEndpoint {
    async refreshPIN(message: UserRefreshPINMessage): Promise<ApiResponse<UserProfile>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/user/refreshPIN`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as UserProfile,
        });
        return apiResponse;
    }
    async refreshAccessToken(message: UserRefreshAccessTokenMessage): Promise<ApiResponse<UserProfile>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/user/refreshAccessToken`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as UserProfile,
        });
        return apiResponse;
    }
    async getCurrentUser(message: AccessTokenBearer): Promise<ApiResponse<UserProfile>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/user/getUser`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as UserProfile,
        });
        return apiResponse;
    }
    async updateUserProfile(message: UserProfile): Promise<ApiResponse<UserProfile>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/user/updateUser`,
            method: "PUT",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as UserProfile,
        });
        return apiResponse;
    }
    async toggleFavoriteCompany(message: ToggleFavoriteCompanyMessage): Promise<ApiResponse<UserProfile>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/user/toggleFavoriteCompany`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as UserProfile,
        });
        return apiResponse;
    }
    async logout(message: AccessTokenBearer): Promise<ApiResponse<void>> {
        const apiResponse = await api.createRequest<void>({
            endpoint: `api/user/logout`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: undefined,
        });
        return apiResponse;
    }
    async getUserList(message: AccessTokenBearer): Promise<ApiResponse<UserListItem[]>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/user/getUserList`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as UserListItem[],
        });
        return apiResponse;
    }
}