import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../../api/ApiError';
import { UserProfile } from '../../api/models/User';
import { addInProgressCases, addInProgressMatcher } from '../reduxHelpers';
import { assignCompany, getCurrentUser, logout, refreshAccessToken, refreshPIN, refreshReminders, toggleFavorites, updateUserProfile } from './user.actions';

export interface UserState {
    inProgress: boolean,
    inProgressFavoriteCompanyID?: string,
    user: UserProfile | null,
    remindersLog?: string[],
    apiError?: ApiError,
    lang?: string,
    mobileClient?: string,
    mobileVersion?: string,
}
const initialState: UserState = {
    inProgress: false,
    inProgressFavoriteCompanyID: undefined,
    user: null,
    remindersLog: undefined,
    apiError: undefined,
    lang: undefined,
    mobileClient: undefined,
    mobileVersion: undefined,
}
const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        clearUser(state) {
            state.user = null;
            state.apiError = undefined;
        },
        clearReminders(state) {
            state.remindersLog = undefined;
        },
        setUser(state, action: PayloadAction<{
            user?: UserProfile | null,
            apiError: ApiError | undefined,
        }>) {
            state.user = action.payload.user ?? null;
            state.apiError = action.payload.apiError;
        },
        setMobileClient(state, action: PayloadAction<{
            mobileClient?: string,
            mobileVersion?: string,
        }>) {
            state.mobileClient = action.payload.mobileClient;
            state.mobileVersion = action.payload.mobileVersion;
        },
        changeLanguage(state, action: PayloadAction<{
            lang?: string,
        }>) {
            state.lang = action.payload.lang;
        },
    },
    extraReducers: (builder) => {
        addInProgressCases(builder, assignCompany);
        addInProgressCases(builder, getCurrentUser);
        addInProgressCases(builder, logout);
        addInProgressCases(builder, refreshAccessToken);
        addInProgressCases(builder, refreshPIN);
        addInProgressCases(builder, refreshReminders);
        addInProgressCases(builder, updateUserProfile);
        builder.addCase(toggleFavorites.pending, (state, action) => {
            state.inProgress = true;
            state.inProgressFavoriteCompanyID = action.meta.arg.companyID;
        })
        builder.addCase(toggleFavorites.rejected, (state, action) => {
            console.log(action.error);
        })
        builder.addCase(assignCompany.fulfilled, (state, action) => {
            state.user = action.payload.user ?? null;
        })
        builder.addCase(getCurrentUser.fulfilled, (state, action) => {
            state.user = action.payload.user ?? null;
        })
        builder.addCase(logout.fulfilled, (state, action) => {
            state.user = action.payload.user ?? null;
        })
        builder.addCase(refreshAccessToken.fulfilled, (state, action) => {
            state.user = action.payload.user ?? null;
        })
        builder.addCase(refreshPIN.fulfilled, (state, action) => {
            state.user = action.payload.user ?? null;
        })
        builder.addCase(refreshReminders.fulfilled, (state, action) => {
            state.remindersLog = action.payload.remindersLog;
        })
        builder.addCase(updateUserProfile.fulfilled, (state, action) => {
            state.user = action.payload.user ?? null;
        })
        
        builder.addMatcher(toggleFavorites.settled, (state, action) => {
            state.inProgress = false;
            state.inProgressFavoriteCompanyID = undefined;
        })
        addInProgressMatcher(builder, assignCompany);
        addInProgressMatcher(builder, getCurrentUser);
        addInProgressMatcher(builder, logout);
        addInProgressMatcher(builder, refreshAccessToken);
        addInProgressMatcher(builder, refreshPIN);
        addInProgressMatcher(builder, refreshReminders);
        addInProgressMatcher(builder, updateUserProfile);
    },
});
export const {
    changeLanguage,
    clearUser,
    clearReminders,
    setMobileClient,
    setUser,
} = userSlice.actions
export default userSlice.reducer


