import { Accordion, AccordionDetails, Button, CircularProgress } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { L10N } from '../pages/L10N';
import { StyledTitleRow } from '../ui/StyledTitle';
import { FieldSetProps } from './FormTeacherWizard';
import { PreviewCompanyDetailsCard } from './PreviewDrawer';

export const FieldSetSummary = (props: FieldSetProps) => {
    function onSubmitCapture() {
        if (!props.formik.isValid) {
            console.log(props.formik.errors);
            alert(JSON.stringify(props.formik.errors));
        }
    }
    function buttonLabel() {
        switch (props.formik.values.stage) {
            case "initial": return L10N({ todo: "Submit for review" });
            case "initial-submitted": return L10N({ todo: "Update" });
            case "reviewed-accepted": return L10N({ todo: "Submit" });
            default: return L10N({ key: "Save" }) + props.formik.values.stage;
        }
    }
    return <>
        <Accordion defaultExpanded={false} >
            <AccordionDetails className="d-block pt-3 my-3">
                <StyledTitleRow text={"Well Done! You're almost there"} hideDivider subtitle />

                <PreviewCompanyDetailsCard formik={props.formik} />
                <StyledTitleRow text={"Have a look and submit!"} hideDivider subtitle />
                <Row className="my-3 ">
                    <Col xs={false} md={true} />
                    <Col className="col-auto" xs={12} md={'auto'} >
                        <Button
                            variant="contained"
                            type="submit"
                            onClick={onSubmitCapture}
                            onSubmitCapture={onSubmitCapture}
                        >
                            {props.formik.isSubmitting
                                ? <CircularProgress />
                                : buttonLabel()
                            }
                        </Button>
                    </Col>
                    <Col xs={false} md={true} />
                </Row>
            </AccordionDetails>
        </Accordion>
    </>;
}