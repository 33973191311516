import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { AccessTokenBearer } from "../models/Global";

export class AdminEndpoint {

    async refreshReminders(): Promise<ApiResponse<string[]>> {
        const apiResponse = await api.createRequest<string[]>({
            endpoint: `api/admin/refresh-reminders`,
            method: "GET",
            resultJsonTransformer: (json) => json as string[],
        });
        return apiResponse;
    }
    async reloadStore(message: AccessTokenBearer): Promise<ApiResponse<void>> {
        const apiResponse = await api.createRequest<void>({
            endpoint: `api/admin/reloadStore`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: undefined,
        });
        return apiResponse;
    }
}