import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/store/store';
import { setMobileClient } from '../../store/user/user.reducer';
import { AppRoutes } from './Routes';

export const MobilePage = () => {
    const dispatch = useAppDispatch();
    const urlParams = useParams<{ client?: string, version?: string }>();
    useEffect(() => {
        dispatch(setMobileClient({
            mobileClient: urlParams.client,
            mobileVersion: urlParams.version,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams]);

    return (
        <Navigate replace to={AppRoutes.home} />
    );
}
