import { Button, ButtonGroup } from "@mui/material";

export const TabsSwitch = (props: {
    selectedTab: number,
    onTabChanged: (selectedTab: number) => Promise<void>,
    tabs: string[],
    groupClassName?: string,
}) => {

    return (
        <ButtonGroup >
            {props.tabs.map((m, i) =>
                <Button key={m}
                    disableFocusRipple
                    variant={i === props.selectedTab ? "contained" : "outlined"}
                    color={i === props.selectedTab ? "primary" : "secondary"}
                    onClick={async () => props.onTabChanged(i)}>
                    {m}
                </Button>)}
        </ButtonGroup>
    );
};
