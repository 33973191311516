import { Animations } from "./Animations";
import { useIsVisible } from "./useIsVisible";

export const CspImage = (props: {
    src: string,
    animationIndex?: number,
    title?: string,
}) => {
    const { ref, isVisible } = useIsVisible<HTMLImageElement>();
    const animationClassName =
        props.animationIndex === 0 ? Animations.kenburns_bottom()
            : props.animationIndex === 1 ? Animations.kenburns_bottom_left()
                : props.animationIndex === 2 ? Animations.kenburns_right()
                    : Animations.kenburns_top_right();
    return (
        <img src={props.src}
            ref={ref}
            className={isVisible ? animationClassName : ""}
            alt={props.title} />
    )
}