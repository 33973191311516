import { CircularProgress, IconButton, InputAdornment, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Col } from 'react-bootstrap';
import { changeSearchString } from '../../store/search/search.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { L10N } from '../pages/L10N';
import { CspIcon } from "../ui/CspIcon";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            color: theme.palette.primary.main,
            fontSize: theme.typography.h6.fontSize,
            fontWeight: theme.typography.h6.fontWeight,
            '&::placeholder': {
                textAlign: 'center',
            },
        },
    }));
export const SearchBar = (props: {
    handleClickMore?: () => void,
}) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { searchString, searchStringWithoutTags, inProgress: searchResultsInProgress } = useAppSelector((state: RootState) => state.search);
    async function onChange(value: string) {
        await dispatch(changeSearchString({ searchString: value }));
    }
    function handleClickMore() {
        if (!props.handleClickMore) return;
        props.handleClickMore();
    }
    return (
        <>
            <Col>
                <TextField
                    spellCheck={false}
                    fullWidth variant="outlined"
                    placeholder={L10N({ key: "Search bar placeholder" })}
                    value={searchStringWithoutTags}
                    onChange={(event) => onChange(event.target.value)}
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position='start'>
                                    {searchResultsInProgress
                                        ?
                                        <CircularProgress color="primary" />
                                        :
                                        <IconButton onClick={() => onChange(searchString)} disabled={!searchString}
                                            color="primary"
                                            style={{
                                                order: 1,
                                            }}>
                                            <CspIcon name="SearchIcon" color={searchString ? "primary" : "disabled"} />
                                        </IconButton>
                                    }
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => onChange("")} disabled={!searchString}
                                        color="primary"
                                        style={{
                                            order: 1,
                                        }}>
                                        <CspIcon name="ClearIcon" />
                                    </IconButton>
                                    {props.handleClickMore &&
                                        <IconButton onClick={() => handleClickMore()} style={{
                                            order: 1,
                                        }}>
                                            <CspIcon name="MoreVertIcon" color="disabled" />
                                        </IconButton>
                                    }
                                </InputAdornment>
                            ),
                            classes: { input: classes.input }
                        }
                    }}
                />
            </Col>
        </>
    );
}
