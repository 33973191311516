import { Button, Divider, Link, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { changeInvite } from '../../store/invite/invite.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { BackArrowHeader } from '../pages/BackArrowHeader';
import { L10N } from '../pages/L10N';
import { AppRoutes } from '../pages/Routes';
import { CspImageList } from "../ui/CspImageList";
import { ServiceQRCode } from "./ServiceQRCode";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
            width: '100%',
        },
        backArrowHeader: {
            position: 'absolute',
            top: '30px',
            opacity: '0.7',
            zIndex: 10,
        },
    }),
);

export const ServiceDetailsView = (props: {
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { service } = useAppSelector((state: RootState) => state.details);
    const { searchResults } = useAppSelector((state: RootState) => state.search);
    const classes = useStyles();
    async function bookClick() {
        if (!service) return;
        if (!service.offeredResourceID) return;

        await dispatch(changeInvite({
            message: {
                query: searchResults?.query ?? "",
                offeredResourceID: service.offeredResourceID,
            }
        }));
        await navigate(AppRoutes.invitePage());
    }
    return (<>
        <CspImageList havePhotos={service} moreColumns/>

        <Container className="my-3">
            <BackArrowHeader className={classes.backArrowHeader} dark />
            <Row>
                <Col >
                    <Typography variant="subtitle1">{service?.title}</Typography>
                    <Typography variant="body1" color="secondary">{service?.subtitle}</Typography>
                </Col>
            </Row>
            <Row >
                <Col xs={12}>
                    <Divider className="my-3" />
                    <Typography variant="subtitle2" color="secondary" >
                        {L10N({ key: "Description:" })}
                    </Typography>
                    {service?.htmlDescription && <>
                        <div className="mb-3" />
                        <div dangerouslySetInnerHTML={{ __html: service?.htmlDescription }} />
                    </>}
                    {service?.description && <>
                        <div className="mb-3" />
                        <Typography variant="body1" color="secondary">{service?.description}</Typography>
                    </>}
                    {service?.learnMoreURL && <>
                        <div className="mb-3" />
                        <Link target="_blank" href={service?.learnMoreURL} >
                            {L10N({ key: "Learn more" })}
                        </Link >
                    </>}
                    <div className="mb-3" />
                    <Row className="mb-3">
                        <Col >
                            <Button onClick={bookClick} variant="contained" color="primary" fullWidth
                                disabled={!service?.offeredResourceID}>
                                {L10N({ key: "Join Now" })}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>);
};

export const ServiceDetailsDialogView = (props: {
}) => {
    const { service } = useAppSelector((state: RootState) => state.details);

    return (<>
        <CspImageList havePhotos={service} moreColumns
            another={service &&
                <ServiceQRCode service={service} />
            }
        />

        <Container className="my-3">
            <Row>
                <Col >
                    <Typography variant="body1" color="secondary">{service?.subtitle}</Typography>
                </Col>
            </Row>
            <Row >
                <Col xs={12}>
                    <Divider className="my-3" />
                    <Typography variant="subtitle2" color="secondary"
                        style={{ minWidth: '30vw' }}>
                        {L10N({ key: "Description:" })}
                    </Typography>
                    {service?.htmlDescription && <>
                        <div className="mb-3" />
                        <div dangerouslySetInnerHTML={{ __html: service?.htmlDescription }} />
                    </>}
                    {service?.description && <>
                        <div className="mb-3" />
                        <Typography variant="body1" color="secondary">{service?.description}</Typography>
                    </>}
                    {service?.learnMoreURL && <>
                        <div className="mb-3" />
                        <Link target="_blank" href={service?.learnMoreURL} >
                            {L10N({ key: "Learn more" })}
                        </Link >
                    </>}
                </Col>
            </Row>
        </Container>
    </>);
};
