import { animals, colors, Config, uniqueNamesGenerator } from 'unique-names-generator';

const config: Config = {
    dictionaries: [colors, animals],// big donkey
    style: 'capital',
    separator: ' ',
}
export function RandomName() {
    const result = uniqueNamesGenerator(config);
    return result;
}