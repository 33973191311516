import React from "react";

export const CspText = (props: {
    text?: string,
    replacements: { [key: string]: React.ReactNode }
}) => {
    const renderText = () => {
        const regex = /\[([^\]]+)]/g; // Matches tokens in [token] format
        const parts = props.text?.split(regex); // Split string by the token regex

        return parts?.map((part, index) => {
            if (props.replacements[part]) {
                return <React.Fragment key={index}>{props.replacements[part]}</React.Fragment>;
            }
            return <React.Fragment key={index}>{part}</React.Fragment>;
        });
    };

    return <>{renderText()}</>;
}
