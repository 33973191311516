import * as yup from 'yup';
import { MapCoords } from "./Details";
import { AccessTokenValidationSchema, EditModel, MapCoordsValidationSchema, PhotoEditModel, PhotoEditModelArrayValidationSchema } from './Edit';
import { AccessTokenBearer } from "./Global";
import { SearchCategory_Anything, SearchCategory_Array } from './Search';
import { EmailValidationSchema } from './Validators';

export interface RequestStudentWizardCreateModel extends AccessTokenBearer {
    referralCode: string,
}
export interface ClassEditModel {
    name: string,
}
export function CreateNewClass(): ClassEditModel {
    return {
        name: "",
    };
}
export type StudentWizardStage = "initial"
    | "initial-submitted"
    | "reviewed-accepted"
    ;
export const StudentWizardStageAll: StudentWizardStage[] = [
    "initial",
    "initial-submitted",
    "reviewed-accepted",
];
export interface StudentWizardEditModel extends EditModel {
    userID: string,
    firstName: string | null,
    lastName: string | null,
    displayName: string,
    category: string,
    eduinPhone: string | null,
    eduinEmail: string | null,
    address: string | null,
    mapCoords: MapCoords,
    description: string | null,
    keyWords: string | null,
    classes: ClassEditModel[],
    avatarPhotosOwnerID: string,
    avatarPhotos: PhotoEditModel[],
    stage?: StudentWizardStage | null,
}
export function CreateNewCompanyWizard(): StudentWizardEditModel {
    return {
        accessToken: "",
        userID: "123-564",
        firstName: "",
        lastName: "",
        displayName: "",
        category: SearchCategory_Anything,
        eduinPhone: "",
        eduinEmail: "",
        address: "",
        mapCoords: {
            lat: 1,
            lng: 1,
        },
        description: "",
        keyWords: "",
        classes: [],
        avatarPhotosOwnerID: "",
        avatarPhotos: [],
        stage: "initial",
    };
}
export const ClassEditModelValidationSchema = yup
    .object<ClassEditModel>()
    .shape({
        name: yup.string().defined(),
    });
export const StudentWizardEditModelValidationSchema = yup
    .object<StudentWizardEditModel>()
    .shape({
        accessToken: AccessTokenValidationSchema,
        userID: yup.string().defined(),
        firstName: yup.string().defined().nullable(),
        lastName: yup.string().defined().nullable(),
        displayName: yup.string().defined(),
        category: yup.string().oneOf(SearchCategory_Array).defined().nullable(),
        eduinPhone: yup.string().defined().nullable(),
        eduinEmail: EmailValidationSchema.defined().nullable(),
        address: yup.string().defined().default("").nullable(),
        mapCoords: MapCoordsValidationSchema.nullable(),
        description: yup.string().default("").nullable(),
        keyWords: yup.string().default("").nullable(),
        classes: yup.array().of(ClassEditModelValidationSchema).optional().nullable(),
        avatarPhotosOwnerID: yup.string().defined().nullable(),
        avatarPhotos: PhotoEditModelArrayValidationSchema.optional().nullable(),
        stage: yup.mixed().oneOf(StudentWizardStageAll).default("").nullable(),
    });