import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { L10N } from '../pages/L10N';

export const ConfirmDialog = (props: {
    isVisible: boolean,
    inProgress: boolean,
    onOK: () => Promise<void>,
    onCancel: () => void,
    title: string,
    contents: string,
}) => {

    function handleCancel() {
        props.onCancel();
    }
    async function handleOK(): Promise<void>{
        await props.onOK();
    }
    return (
        <>
            <Dialog
                open={props.isVisible}
                onClose={handleCancel}>
                <DialogTitle>
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.contents}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOK}
                        color="primary" variant="outlined">
                        {props.inProgress
                            ? <CircularProgress />
                            : L10N({ key: "OK" })
                        }
                    </Button>
                    <Button onClick={handleCancel}
                        color="primary" autoFocus variant="contained">
                        {L10N({ key: "Cancel" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
