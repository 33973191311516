import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VisitReviewDetails } from '../../api/models/Details';
import { PurchaseResponse } from '../../api/models/Purchase';
import { ConfirmedVisit, CreateVisitMessage } from '../../api/models/Visit';
import { addInProgressCases, addInProgressMatcher } from '../reduxHelpers';
import { createVisit, CreateVisitResponse, loadAllVisits, loadAllVisitsAndReviews, payForVisit, selectReviewOrCreate, updateReview, updateVisit } from './visit.actions';

export interface VisitState {
    createVisitMessage?: CreateVisitMessage;
    createVisitResponse?: CreateVisitResponse;
    purchaseResponse?: PurchaseResponse;
    inProgress: boolean;
    currentVisit?: ConfirmedVisit;
    allVisits?: ConfirmedVisit[];
    completedVisits?: ConfirmedVisit[];
    notCompletedVisits?: ConfirmedVisit[];
    notCompletedVisitReviews?: ConfirmedVisit[];
    completedReviews?: VisitReviewDetails[];
    currentReview?: VisitReviewDetails;
}
const initialState: VisitState = {
    createVisitMessage: undefined,
    createVisitResponse: undefined,
    purchaseResponse: undefined,
    inProgress: false,
    currentVisit: undefined,
    allVisits: undefined,
    completedVisits: undefined,
    notCompletedVisits: undefined,
    notCompletedVisitReviews: undefined,
    completedReviews: undefined,
    currentReview: undefined,
}
const visitSlice = createSlice({
    name: 'visitSlice',
    initialState,
    reducers: {
        clearVisitResponse(state) {
            state.createVisitResponse = undefined;
            state.purchaseResponse = undefined;
        },
        beforeCreateVisit(state, action: PayloadAction<CreateVisitMessage>) {
            state.createVisitResponse = undefined;
            state.purchaseResponse = undefined;
            state.createVisitMessage = action.payload;
        },
    },
    extraReducers: (builder) => {
        addInProgressCases(builder, createVisit);
        addInProgressCases(builder, loadAllVisits);
        addInProgressCases(builder, loadAllVisitsAndReviews);
        addInProgressCases(builder, payForVisit);
        addInProgressCases(builder, selectReviewOrCreate);
        addInProgressCases(builder, updateReview);
        addInProgressCases(builder, updateVisit);

        builder.addCase(createVisit.fulfilled, (state, action) => {
            state.createVisitResponse = action.payload.createVisitResponse;
        })
        builder.addCase(loadAllVisits.fulfilled, (state, action) => {
            state.allVisits = action.payload.allVisits;
            state.completedVisits = action.payload.completedVisits;
            state.notCompletedVisits = action.payload.notCompletedVisits;
        })
        builder.addCase(loadAllVisitsAndReviews.fulfilled, (state, action) => {
            state.allVisits = action.payload.allVisits;
            state.completedVisits = action.payload.completedVisits;
            state.notCompletedVisits = action.payload.notCompletedVisits;
        })
        builder.addCase(payForVisit.fulfilled, (state, action) => {
            state.purchaseResponse = action.payload.purchaseResponse;
        })
        builder.addCase(selectReviewOrCreate.fulfilled, (state, action) => {
            state.currentReview = action.payload.currentReview;
        })
        builder.addCase(updateReview.fulfilled, (state, action) => {
            state.currentReview = action.payload.currentReview;
        })
        builder.addCase(updateVisit.fulfilled, (state, action) => {
            state.currentVisit = action.payload.currentVisit;
        })
        addInProgressMatcher(builder, createVisit);
        addInProgressMatcher(builder, loadAllVisits);
        addInProgressMatcher(builder, loadAllVisitsAndReviews);
        addInProgressMatcher(builder, payForVisit);
        addInProgressMatcher(builder, selectReviewOrCreate);
        addInProgressMatcher(builder, updateReview);
        addInProgressMatcher(builder, updateVisit);
    },
});
export const { clearVisitResponse, beforeCreateVisit } = visitSlice.actions
export default visitSlice.reducer


