import { MenuItem, TextField, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { ReactNode } from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { PhoneAndCountry } from "src/api/models/Login";
import { formKey } from "src/helpers";
import { L10N } from '../pages/L10N';
import { FormikFieldProps_PhoneCountrySelector, FormikFieldProps_StringValue, useFormikResult } from "../ui/FormikAPI";

export const extractCountry = (phone: string): string | undefined => {
    if (!phone) return undefined;
    if (phone.startsWith("+43")
        || phone.startsWith("0043")) {
        return "AT";
    } else if (phone.startsWith("+32")
        || phone.startsWith("0032")) {
        return "BE";
    } else if (phone.startsWith("+41")
        || phone.startsWith("0041")) {
        return "CH";
    } else if (phone.startsWith("+420")
        || phone.startsWith("00420")) {
        return "CZ";
    } else if (phone.startsWith("+49")
        || phone.startsWith("0049")) {
        return "DE";
    } else if (phone.startsWith("+48")
        || phone.startsWith("0048")) {
        return "PL";
    } else if (phone.startsWith("+971")
        || phone.startsWith("00971")) {
        return "AE";
    } else if (phone.startsWith("+91")
        || phone.startsWith("0091")) {
        return "IN";
    } else {
        return undefined;
    }
}
export const extractPrefix = (country?: string): string => {
    switch (country) {
        case "AT": return "+43";
        case "BE": return "+32";
        case "CH": return "+41";
        case "CZ": return "+420";
        case "DE": return "+49";
        case "PL": return "+48";
        case "AE": return "+971";
        case "IN": return "+91";
        default: return country ?? "";
    }
}
export const extractInCountryPhone = (phone?: string): string | undefined => {
    if (!phone) return undefined;

    const country = extractCountry(phone);
    if (!country) return phone;
    const prefix = extractPrefix(country);
    if (!prefix) return phone;

    let inCountryPhone = phone;
    if (inCountryPhone.startsWith("00")) {
        inCountryPhone = "+" + inCountryPhone.substr(2);
    }
    inCountryPhone = inCountryPhone
        .replace(prefix, "")
        .replace(/\s/g, "");
    return inCountryPhone;
}
const useStyles = makeStyles((theme) =>
    createStyles({
        img: {
            position: 'absolute',
            margin: 'auto',
            top: '0',
            left: '10px',
            bottom: '0',
        },
        select: {
            '& .MuiSelect-root': {
                padding: '17px 32.5px 17px 6px',
            },
        }
    })
);
export const PhoneCountrySelect = (props: {
    value: string,
    onChange: (value: string) => void,
    disabled?: boolean,
}) => {
    const classes = useStyles();
    function flagMenuItem(value: string, name: string): JSX.Element {
        return (<MenuItem value={value}  >
            <img src={`/img/flags/${value}.png`} height={30} alt={name}
                className={classes.img + " my-auto p-0"}
            />
            <Typography className="ml-5"
                color={props.disabled ? "textSecondary" : "inherit"}
                align="right">
                {extractPrefix(value)}
            </Typography>
        </MenuItem>);
    }
    return (
        <TextField color="primary"
            select
            value={extractCountry(props.value) ?? ""}
            disabled={props.disabled}
            onChange={(event) => props.onChange(extractPrefix(event.target.value))}
            className={classes.select}
        >
            {/* {flagMenuItem("AT", L10N({ key: "Austria" }))}
            {flagMenuItem("BE", L10N({ key: "Belgium" }))}
            {flagMenuItem("CH", L10N({ key: "Switzerland" }))}
            {flagMenuItem("CZ", L10N({ key: "Czech Republic" }))}
            {flagMenuItem("DE", L10N({ key: "Deutschland" }))} */}
            {flagMenuItem("PL", L10N({ key: "Poland" }))}
            {/* {flagMenuItem("AE", L10N({ key: "United Arab Emirates" }))} */}
            {flagMenuItem("IN", L10N({ key: "India" }))}
        </TextField>
    );
};

export const PhoneTextField = (props: {
    value: string,
    onChange: (value: string) => Promise<void>,
    phoneCountry?: string,
    onPhoneCountryChange?: (value: string) => Promise<void>,
    disabled?: boolean,
    adornment?: ReactNode,
    error?: boolean;
    helperText?: string,
}) => {
    async function onChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        const phone = event.target.value;
        const country = extractCountry(phone);
        const inCountryPhone = extractInCountryPhone(phone) ?? "";

        if (props.onPhoneCountryChange &&
            country
            && country !== props.phoneCountry) {
            const prefix = extractPrefix(country);
            await props.onPhoneCountryChange(prefix);
        }
        await props.onChange(inCountryPhone);
    }

    return (
        <TextField
            label={L10N({ key: "Phone number" })}
            helperText={props.helperText}
            required
            type="tel" autoComplete="tel" spellCheck="false" name="tel"
            value={props.value}
            onChange={onChange}
            error={props.error}
            autoFocus={!isMobile}
            slotProps={{
                input: {
                    endAdornment: props.adornment,
                }
            }}
        />
    );
};

export const PhoneAndCountryField = <T extends PhoneAndCountry>(props: {
    formik: useFormikResult<T>,
    onChange: (value: string) => Promise<void>,
    disabled?: boolean,
    adornment?: ReactNode,
}) => {
    async function onChange(value: string) {
        const phone = value;
        const country = extractCountry(phone);
        const inCountryPhone = extractInCountryPhone(phone) ?? "";
        const prefix = extractPrefix(country);

        if (prefix && prefix !== props.formik.values.phoneCountry) {
            await props.formik.setFieldValue(formKey<PhoneAndCountry>(m => m.phoneCountry), prefix);
        }
        if (inCountryPhone !== props.formik.values.tel) {
            await props.onChange(inCountryPhone);
        }
    }
    return (
        <Row className="my-3">
            <Col className="pr-1 col-auto" >
                <PhoneCountrySelect
                    {...FormikFieldProps_PhoneCountrySelector(props.formik, m => m.phoneCountry)}
                />
            </Col>
            <Col className="pl-1">
                <PhoneTextField
                    {...FormikFieldProps_StringValue(props.formik,
                        m => m.tel,
                        onChange
                    )}
                    phoneCountry={props.formik.values.phoneCountry}
                    onPhoneCountryChange={async (value) => {
                        await props.formik.setFieldValue(formKey<PhoneAndCountry>(m => m.phoneCountry), value);
                    }}
                    adornment={props.adornment}
                />
            </Col>
        </Row>
    );
};
export const TextFieldPin = (props: {
    value?: string,
    onChange: (value: string) => Promise<void>,
    adornment?: ReactNode,
    error?: boolean;
    helperText?: string,
}) => {
    function formatPIN(value?: string): string {
        if (!value) return "";

        const noDash = value.replace("-", "");
        const first3 = noDash.substr(0, 3);
        if (noDash.length <= 3) return first3;

        const theRest = noDash.substr(3, 3);
        return `${first3}-${theRest}`;
    }
    async function onChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        const value = event.target.value;
        const noDash = value.replace("-", "");

        await props.onChange(noDash);
    }
    return (
        <TextField fullWidth color="primary"
            placeholder="___-___"
            label={L10N({ key: "PIN" })}
            helperText={props.helperText}
            value={formatPIN(props.value)}
            onChange={onChange}
            autoComplete="one-time-code"
            error={props.error}
            type="password"
            autoFocus
            slotProps={{
                input: {
                    endAdornment: props.adornment,
                    
                }
            }}
        />
    )
}