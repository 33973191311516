import { CardProps, Typography } from "@mui/material";
import { Navigate } from "react-router-dom";
import { AppRoutes } from "../pages/Routes";
import { CspCountdownDialog } from "../ui/CspCountdownDialog";
import { CspIcon } from "../ui/CspIcon";
import { LogotypeSpans } from "../ui/LogotypeSpans";
import { StyledTitleRow } from "../ui/StyledTitle";


export const TeacherWizardInviteCard = (props: CardProps & {}) => {
    return (<>
        <StyledTitleRow text={"Congratulations!!!"} hideDivider subtitle />
        <Typography color="secondary" align="center">

            <br />
            You're about to take the first step into an exciting new chapter with our system!
            <br />
            <CspIcon name="TouchAppOutlinedIcon" />
            <br />
            Get ready to explore incredible features, unlock amazing opportunities,
            and achieve more than ever before.
            <br />
            <br />
        </Typography>
        <StyledTitleRow text={"Let's make this journey unforgettable!"} hideDivider subtitle />
        <Typography color="secondary" align="center">
            <br />
            Configure your account with <LogotypeSpans />
        </Typography>
    </>);
};

export const TeacherWizardVisitConfirmed = (props: CardProps & {}) => {
    return (<>
        <CspCountdownDialog seconds={5}
            title="Congratulations"
            subtitle="you can start in a moment..."
        >
            <>
                <Navigate replace to={AppRoutes.teacherWizardPage()} />
            </>
        </CspCountdownDialog>
    </>);
};