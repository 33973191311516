import { Box, CardHeader, CardMedia, CardProps, useMediaQuery } from "@mui/material";
import { CompanyDetails } from 'src/api/models/Details';
import { api } from '../../api/api';
import { RootState, useAppSelector } from '../../store/store';
import { L10N } from '../pages/L10N';
import { CategoryIconURL } from '../ui/CategoryIcon';
import { CspIcon } from "../ui/CspIcon";
import { CspList } from '../ui/CspList';
import { CspVideo } from "../ui/CspVideo";
import { EmiCard } from '../ui/EmiCard';
import { FavoriteIcon } from '../ui/FavoriteIcon';
import { LogotypeSpans, LogotypeTokens } from "../ui/LogotypeSpans";
import { theme } from '../ui/Theme';

export const CompanyDetailsCard = (props: CardProps & {
    company: CompanyDetails,
    renderVertical?: boolean,
}) => {
    const { user } = useAppSelector((state: RootState) => state.user);
    const { company, renderVertical,
        ...cardProps } = props;
    const imgSrc = api.Search.getFirstPhotoUrl(company.photos);
    const radius = theme.shape.borderRadius;
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isVertical = props.renderVertical || isSmallScreen;
    let i = 0;
    const dist = theme.spacing(2);

    const isClassNowCard = company?.title?.toLowerCase().indexOf("classnow") >= 0;
    return (
        <EmiCard {...cardProps}
            elevation={6}
            style={{
            }}
            sx={{ display: 'flex', flexDirection: isVertical ? 'column' : 'row' }}
        >
            <Box >
                {isClassNowCard
                    ? <><CspVideo
                        src={'https://eduinstorage.blob.core.windows.net/blob/classnow-video.mp4'}
                        imgSrc='/img/classnow-video-alt.png'
                        autoplay
                        showControls
                    /></>
                    : <><CardMedia title={company.title}
                        sx={{
                            width: '100%',
                            height: '100%',
                        }}
                        component="img"
                        src={imgSrc ?? '/img/marketing-bg.svg'}
                    /></>
                }

            </Box>
            <CardHeader
                style={{
                    borderRadius: radius,
                    position: 'relative',
                    borderColor: theme.palette.primary.main,
                    marginTop: isVertical ? -radius : 0,
                    marginLeft: isVertical ? 0 : -radius,
                    background: theme.palette.background.paper,
                    boxShadow: 'darkgray 0px -2px 6px',
                    width: '100%',
                }}
                title={
                    <>
                        {!user &&
                            <Box sx={{
                                position: 'absolute',
                                right: dist,
                                bottom: dist,
                            }}>
                                <FavoriteIcon companyID={company.itemID} />
                            </Box>
                        }
                        <CspList animationFromRight
                            delayMultiplier="fast"
                            items={[
                                {
                                    index: i++,
                                    iconSrc: api.Search.getFirstPhotoUrl(company.resources?.[0]),
                                    primaryTitle: <LogotypeTokens text={company.title} />,
                                },
                                {
                                    index: i++,
                                    icon: <CspIcon name="InfoOutlinedIcon" />,
                                    primaryTitle: <LogotypeTokens text={company.description} />,
                                    secondaryTitle: "About me"
                                },
                                {
                                    index: i++,
                                    iconSrc: CategoryIconURL(company.subtitle, true),
                                    primaryTitle: L10N({ key: "_category", cat: company.subtitle }),
                                    secondaryTitle: "Specialization"
                                },
                                {
                                    index: i++,
                                    icon: <CspIcon name="LocationOnOutlinedIcon" />,
                                    primaryTitle: company.address,
                                    secondaryTitle: "Location"
                                },
                                {
                                    index: i++,
                                    icon: <CspIcon name="StarRateOutlinedIcon" />,
                                    primaryTitle: (company.reviewSummary?.averageScore ?? 0) <= 1
                                        ? <>You will see the rating score here</>
                                        : <>{company.reviewSummary?.averageScore?.toFixed(2) ?? ""}</>
                                    ,
                                    secondaryTitle: (company.reviewSummary?.averageScore ?? 0) <= 1
                                        ? <>There are no ratings yet...</>
                                        : <>Average Rating</>
                                    ,

                                    hidden: !(company.reviewSummary?.averageScore),
                                },
                                {
                                    index: i++,
                                    icon: <CspIcon name="WorkspacePremiumIcon" />,
                                    primaryTitle: <><LogotypeSpans /> Certification</>,
                                    secondaryTitle: (company.reviewSummary?.badgeLevel ?? 0) <= 1
                                        ? <>This teacher is yet to be certified by <LogotypeSpans />...</>
                                        : <>This teacher was certified by <LogotypeSpans /></>
                                    ,
                                    hidden: !(company.reviewSummary?.badgeLevel),
                                },

                            ]}
                        />
                    </>
                }
            />
        </EmiCard >
    );
}
