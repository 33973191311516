import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateCompanyMessage } from 'src/api/models/Details';
import { api } from '../../api/api';
import { ApiError } from '../../api/ApiError';
import { CompanyDetails, ResourceDetails, ServiceDetails } from '../../api/models/Details';
import { AccessTokenBearer, DimensionTypes } from '../../api/models/Global';
import { ConfirmedVisit } from '../../api/models/Visit';
import { clearAllDetails, clearCompanyDetails, clearResourceDetails, clearServiceDetails, clearVisitDetails } from './details.reducer';

export interface AllDetailsResposePayload {
    visit?: ConfirmedVisit;
    company?: CompanyDetails;
    service?: ServiceDetails;
    resource?: ResourceDetails;
    visitError?: ApiError;
    companyError?: ApiError;
    serviceError?: ApiError;
    resourceError?: ApiError;
}
export const loadAllDetails = createAsyncThunk(
    'thunk/loadAllDetails',
    async (args: {
        companyID?: string,
        serviceID?: string,
        resourceID?: string,
    }, thunkAPI) => {
        thunkAPI.dispatch(clearAllDetails())
        const promises: Promise<void>[] = [];
        const allDetails: AllDetailsResposePayload = {};
        if (args.companyID) {
            promises.push(api.Details.getCompany(args.companyID).then(result => {
                allDetails.company = result.result;
                allDetails.companyError = result.error;
            }));
        }
        if (args.serviceID) {
            promises.push(api.Details.getService(args.serviceID).then(result => {
                allDetails.service = result.result;
                allDetails.companyError = result.error;
            }));
        }
        if (args.resourceID) {
            promises.push(api.Details.getResource(args.resourceID).then(result => {
                allDetails.resource = result.result;
                allDetails.companyError = result.error;
            }));
        }
        await Promise.all(promises);
        const error = allDetails.visitError ?? allDetails.companyError ?? allDetails.serviceError ?? allDetails.resourceError;
        if (error) {
            throw error;
        }
        return allDetails;
    },
)
export const loadDetails = createAsyncThunk(
    'thunk/loadDetails',
    async (args: {
        itemID: string,
        itemType: DimensionTypes,
    }, thunkAPI) => {
        switch (args.itemType) {
            case 'company': return await thunkAPI.dispatch(loadCompanyDetails({ companyID: args.itemType }));
            case 'service': return await thunkAPI.dispatch(loadServiceDetails({ serviceID: args.itemType }));
            case 'resource': return await thunkAPI.dispatch(loadResourceDetails({ resourceID: args.itemType }));
            default: throw Error();
        }
    },
)
export const loadVisitAndAllDetails = createAsyncThunk(
    'thunk/loadVisitAndAllDetails',
    async (args: {
        message: AccessTokenBearer,
        visitID: string,
    }, thunkAPI) => {
        thunkAPI.dispatch(clearVisitDetails())
        const apiResponse = await api.Visit.getVisit({
            accessToken: args.message.accessToken,
            visitID: args.visitID,
        });
        if (apiResponse.error) throw apiResponse.error;

        const visit = apiResponse.result;
        await thunkAPI.dispatch(loadAllDetails({
            companyID: visit?.companyID,
            serviceID: visit?.serviceID,
            resourceID: visit?.resourceID
        }));
        return { visit: visit };
    },
)

export const loadCompanyDetails = createAsyncThunk(
    'thunk/loadCompanyDetails',
    async (args: {
        companyID: string
    }, thunkAPI) => {
        thunkAPI.dispatch(clearCompanyDetails())
        const apiResponse = await api.Details.getCompany(args.companyID);
        if (apiResponse.error) throw apiResponse.error;
        return { company: apiResponse.result };
    },
)
export const loadServiceDetails = createAsyncThunk(
    'thunk/loadServiceDetails',
    async (args: {
        serviceID: string
    }, thunkAPI) => {
        thunkAPI.dispatch(clearServiceDetails())
        const apiResponse = await api.Details.getService(args.serviceID);
        if (apiResponse.error) throw apiResponse.error;
        return { service: apiResponse.result };
    },
)
export const loadResourceDetails = createAsyncThunk(
    'thunk/loadResourceDetails',
    async (args: {
        resourceID: string
    }, thunkAPI) => {
        thunkAPI.dispatch(clearResourceDetails())
        const apiResponse = await api.Details.getResource(args.resourceID);
        if (apiResponse.error) throw apiResponse.error;
        return { resource: apiResponse.result };
    },
)
export const updateCompanyDetails = createAsyncThunk(
    'thunk/updateCompanyDetails',
    async (args: {
        message: UpdateCompanyMessage
    }, thunkAPI) => {
        thunkAPI.dispatch(clearCompanyDetails())
        const apiResponse = await api.Details.updateCompanyDetails(args.message);
        if (apiResponse.error) throw apiResponse.error;
        return { company: apiResponse.result };
    },
)