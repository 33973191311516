import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { api } from '../../api/api';
import { ClientEditModel, CompanyEditModel, RequestCompanyCreateModel, RequestCompanyEditModel, ResourceEditModel, ServiceEditModel, UpdatePhotoMessage, VisitReviewEditModel } from '../../api/models/Edit';
import { RequestVisitCreateModel, VisitEditModel } from '../../api/models/EditVisit';
import { AccessTokenBearer } from '../../api/models/Global';
import { getCurrentUser } from '../user/user.actions';

export const loadCoordsFromAddress = createAsyncThunk(
    'thunk/loadCoordsFromAddress',
    async (args: {
        address: string,
    }, thunkAPI) => {
        const apiResponse = await api.Geocoding.getCoords(args.address);
        if (apiResponse.error) throw apiResponse.error;
        return { mapCoords: apiResponse.result };
    },
)
export const updatePhoto = createAsyncThunk(
    'thunk/updatePhoto',
    async (args: {
        message: UpdatePhotoMessage
    }, thunkAPI) => {
        const apiResponse = await api.Edit.updatePhoto(args.message);
        if (apiResponse.error) throw apiResponse.error;
        return { photo: apiResponse.result };
    },
)
export const loadCompanyForEdit = createAsyncThunk(
    'thunk/loadCompanyForEdit',
    async (args: {
        message: AccessTokenBearer,
        companyID: string,
    }, thunkAPI) => {
        const apiResponse = await api.Edit.getCompany({
            accessToken: args.message.accessToken,
            companyID: args.companyID,
        });
        if (apiResponse.error) throw apiResponse.error;
        return { company: apiResponse.result };
    },
)

export const loadCompanyResourcesForEdit = createAsyncThunk(
    'thunk/loadCompanyResourcesForEdit',
    async (args: {
        message: AccessTokenBearer,
        companyID: string,
    }, thunkAPI) => {
        const apiResponse = await api.Edit.getCompanyResources({
            accessToken: args.message.accessToken,
            companyID: args.companyID,
        });
        if (apiResponse.error) throw apiResponse.error;
        return { resources: apiResponse.result };
    },
)

export const loadCompanyServicesForEdit = createAsyncThunk(
    'thunk/loadCompanyServicesForEdit',
    async (args: {
        message: AccessTokenBearer,
        companyID: string,
    }, thunkAPI) => {
        const apiResponse = await api.Edit.getCompanyServices({
            accessToken: args.message.accessToken,
            companyID: args.companyID,
        });
        if (apiResponse.error) throw apiResponse.error;
        return { services: apiResponse.result };
    },
)

export const loadAllCompanyForEdit = createAsyncThunk(
    'thunk/loadAllCompanyForEdit',
    async (args: {
        message: AccessTokenBearer,
        companyID: string,
    }, thunkAPI) => {
        const requestArgs: RequestCompanyEditModel = {
            accessToken: args.message.accessToken,
            companyID: args.companyID,
        }
        const apiResponseCompany = await api.Edit.getCompany(requestArgs);
        const apiResponseResources = await api.Edit.getCompanyResources(requestArgs);
        const apiResponseServices = await api.Edit.getCompanyServices(requestArgs);
        const apiResponseReviews = await api.Edit.getCompanyReviews(requestArgs);
        const visitReviews_AverageScore = _.mean(
            apiResponseReviews.result
                ?.map(m => m.ratingScore)
        );
        const error = apiResponseCompany.error
            ?? apiResponseResources.error
            ?? apiResponseServices.error
            ?? apiResponseReviews.error
            ;
        if (error) throw error;
        return {
            company: apiResponseCompany.result,
            resources: apiResponseResources.result,
            services: apiResponseServices.result,
            visitReviews: apiResponseReviews.result,
            visitReviews_AverageScore: visitReviews_AverageScore,
        };
    },
)

export const CreateCompanyAndReload = createAsyncThunk(
    'thunk/CreateCompanyAndReload',
    async (args: {
        message: RequestCompanyCreateModel,
    }, thunkAPI) => {
        const apiResponse = await api.Edit.createCompany(args.message);
        if (apiResponse.result?.companyID) {
            await thunkAPI.dispatch(loadAllCompanyForEdit({
                message: args.message,
                companyID: apiResponse.result?.companyID
            }))
            await thunkAPI.dispatch(getCurrentUser({ message: args.message }));
            if (apiResponse.error) throw apiResponse.error;
            return { company: apiResponse.result };
        }
    }
)

export const CreateVisit = createAsyncThunk(
    'thunk/CreateVisit',
    async (args: {
        message: RequestVisitCreateModel,
    }, thunkAPI) => {
        const apiResponse = await api.Edit.createVisit(args.message);

        if (apiResponse.error) throw apiResponse.error;
        return { visit: apiResponse.result };
    },
)
export const UpdateCompanyAndReload = createAsyncThunk(
    'thunk/UpdateCompanyAndReload',
    async (args: {
        message: CompanyEditModel,
    }, thunkAPI) => {
        const apiResponse = await api.Edit.updateCompany(args.message);

        if (apiResponse.error) throw apiResponse.error;
        if (apiResponse.result?.companyID) {
            await thunkAPI.dispatch(loadAllCompanyForEdit({
                message: args.message,
                companyID: apiResponse.result?.companyID,
            }));
        }
        return { company: apiResponse.result };
    },
)

export const UpdateCompanyResourceAndReload = createAsyncThunk(
    'thunk/UpdateCompanyResourceAndReload',
    async (args: {
        message: ResourceEditModel,
    }, thunkAPI) => {
        const apiResponse = await api.Edit.updateCompanyResource(args.message);

        if (apiResponse.error) throw apiResponse.error;
        if (apiResponse.result?.companyID) {
            await thunkAPI.dispatch(loadAllCompanyForEdit({
                message: args.message,
                companyID: apiResponse.result?.companyID,
            }));
        }
        return { resource: apiResponse.result };
    },
)

export const UpdateCompanyServiceAndReload = createAsyncThunk(
    'thunk/UpdateCompanyServiceAndReload',
    async (args: {
        message: ServiceEditModel,
    }, thunkAPI) => {
        const apiResponse = await api.Edit.updateCompanyService(args.message);

        if (apiResponse.error) throw apiResponse.error;
        if (apiResponse.result?.companyID) {
            await thunkAPI.dispatch(loadAllCompanyForEdit({
                message: args.message,
                companyID: apiResponse.result?.companyID,
            }));
        }
        return { service: apiResponse.result };
    },
)

export const UpdateVisitReviewAndReload = createAsyncThunk(
    'thunk/UpdateVisitReviewAndReload',
    async (args: {
        message: VisitReviewEditModel,
    }, thunkAPI) => {
        const apiResponse = await api.Edit.updateVisitReview(args.message);

        if (apiResponse.error) throw apiResponse.error;
        if (apiResponse.result?.companyID) {
            await thunkAPI.dispatch(loadAllCompanyForEdit({
                message: args.message,
                companyID: apiResponse.result?.companyID,
            }));
        }
        return { visitReview: apiResponse.result };
    },
)

export const loadClientsForEdit = createAsyncThunk(
    'thunk/loadClientsForEdit',
    async (args: {
        message: AccessTokenBearer,
        companyID: string,
    }, thunkAPI) => {
        const apiResponse = await api.Edit.getCompanyClients({
            accessToken: args.message.accessToken,
            companyID: args.companyID,
        });

        if (apiResponse.error) throw apiResponse.error;
        return { clients: apiResponse.result };
    },
)

export const UpdateCompanyClient = createAsyncThunk(
    'thunk/UpdateCompanyClient',
    async (args: {
        message: ClientEditModel,
    }, thunkAPI) => {
        const apiResponse = await api.Edit.updateCompanyClient(args.message);

        if (apiResponse.error) throw apiResponse.error;
        return {
            editedClient: apiResponse.result,
        };
    },
)
export const loadVisitForEdit = createAsyncThunk(
    'thunk/loadVisitForEdit',
    async (args: {
        message: AccessTokenBearer,
        visitID: string,
    }, thunkAPI) => {
        const apiResponse = await api.Edit.getVisit({
            accessToken: args.message.accessToken,
            visitID: args.visitID,
        });

        if (apiResponse.error) throw apiResponse.error;
        return {
            visit: apiResponse.result,
        };
    },
)

export const RefreshResourceAvailabilityIfPossible = createAsyncThunk(
    'thunk/RefreshResourceAvailabilityIfPossible',
    async (args: {
        message: VisitEditModel,
    }, thunkAPI) => {
        const apiResponse = await api.Edit.refreshVisitResourcesAvailability(args.message);

        if (apiResponse.error) throw apiResponse.error;
        return {
            visit: apiResponse.result,
        };
    },
)

export const UpdateVisitAndReload = createAsyncThunk(
    'thunk/UpdateVisitAndReload',
    async (args: {
        message: VisitEditModel,
    }, thunkAPI) => {
        const apiResponse = await api.Edit.updateVisit(args.message);

        if (apiResponse.error) throw apiResponse.error;
        return {
            visit: apiResponse.result,
        };
    },
)
