import { Box, CardHeader, CardMedia, useMediaQuery } from '@mui/material';
import moment from 'moment';
import { api } from 'src/api/api';
import { BeePhoto } from 'src/api/models/Global';
import { InviteCompany, InviteCompanyResource } from 'src/api/models/Invite';
import { RootState, useAppSelector } from '../../store/store';
import { L10N } from '../pages/L10N';
import { CspIcon } from '../ui/CspIcon';
import { CspList } from "../ui/CspList";
import { EmiCard } from '../ui/EmiCard';
import { theme } from '../ui/Theme';

export type TextWithPhoto = {
    text?: string,
    photo?: BeePhoto,
}
export type SummaryCardProps = {
    class?: string,
    resource?: TextWithPhoto,
    date?: string,
    time?: string,
    price?: string,
    renderVertical?: boolean,
    largePhoto?: BeePhoto,
}

export const InviteSummaryCard = (props: SummaryCardProps) => {

    let i = 0;
    const imgSrc = api.Search.getPhotoUrl(props.largePhoto);
    const radius = theme.shape.borderRadius;
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isVertical = props.renderVertical || isSmallScreen;
    return (<>
        <EmiCard className="my-3"
            elevation={6}
            style={{
            }}
            sx={{ display: 'flex', flexDirection: isVertical ? 'column' : 'row' }}
        >
            <Box >
                <CardMedia title={props.class}
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                    component="img"
                    src={imgSrc
                        ?? '/img/marketing-bg.svg'
                    }
                />
            </Box>
            <CardHeader
                style={{
                    borderRadius: radius,
                    position: 'relative',
                    borderColor: theme.palette.primary.main,
                    marginTop: isVertical ? -radius : 0,
                    marginLeft: isVertical ? 0 : -radius,
                    background: theme.palette.background.paper,
                    boxShadow: 'darkgray 0px -2px 6px',
                    width: '100%',
                }}
                title={
                    <>
                        <CspList delayMultiplier="fast"
                            items={[
                                {
                                    index: i++,
                                    icon: <CspIcon name='SchoolOutlinedIcon' />,
                                    primaryTitle: props.class,
                                    secondaryTitle: "Class name",
                                },
                                {
                                    index: i++,
                                    iconSrc: api.Search.getPhotoUrl(props.resource?.photo),
                                    icon: props.resource?.photo ? undefined
                                        : <CspIcon name='InsertEmoticonOutlinedIcon' />,
                                    primaryTitle: props.resource?.text,
                                    secondaryTitle: "Teacher",
                                },
                                {
                                    index: i++,
                                    icon: <CspIcon name='EventAvailableOutlinedIcon' />,
                                    primaryTitle: props.date,
                                    secondaryTitle: "Date",
                                },
                                {
                                    index: i++,
                                    icon: <CspIcon name='AccessTimeOutlinedIcon' />,
                                    primaryTitle: props.time,
                                    secondaryTitle: "Time",
                                },
                                {
                                    index: i++,
                                    icon: <CspIcon name='CurrencyRupeeOutlinedIcon' />,
                                    primaryTitle: props.price,
                                    secondaryTitle: "Price",
                                },
                            ]}
                        />
                    </>
                }
            />
        </EmiCard>
    </>);
};

export const InviteSummary = (props: {
}) => {
    const { currentInvite } = useAppSelector((state: RootState) => state.invite);

    function currentCompany(): InviteCompany | undefined {
        if (!currentInvite) return undefined;
        const result = currentInvite
            ?.companyDimension
            ?.find((v, i, a) => v.dimensionValue === currentInvite?.companyDimensionSelectedValue);
        return result ?? undefined;
    }
    function currentService(): string {
        if (!currentInvite) return "";
        const result = currentInvite
            ?.companyServiceDimension
            ?.find((v, i, a) => v.dimensionValue === currentInvite?.companyServiceDimensionSelectedValue);
        return result?.displayName ?? "";
    }
    function currentResource(): InviteCompanyResource | undefined {
        if (!currentInvite) return undefined;
        const result = currentInvite
            ?.companyResourceDimension
            ?.find((v, i, a) => v.dimensionValue === currentInvite?.companyResourceDimensionSelectedValue);
        return result;
    }
    function currentPrice(): string {
        if (!currentInvite) return "";
        const result = currentInvite
            ?.priceDimension
            ?.find((v, i, a) => v.dimensionValue === currentInvite?.priceDimensionSelectedValue);

        return L10N({
            key: '_priceAndCurrency',
            price: result,
        });
    }
    function currentDate(): string {
        if (!currentInvite) return "";
        const result = currentInvite
            ?.dateDimension
            ?.find((v, i, a) => v.dimensionValue === currentInvite?.dateDimensionSelectedValue);
        if (!result) return "";
        return L10N({
            key: '_dateAsDDMMMM',
            date: result?.start,
        });
    }
    function currentTime(): string {
        if (!currentInvite) return "";
        const value = currentInvite?.timeDimensionSelectedValue;
        if (!value) return "";

        const result = moment(currentInvite?.timeDimensionSelectedValue, "HH:mm")
            .utc(true)
            .local()
            .format("HH:mm");
        return result;
    }
    const company = currentCompany();
    const resource = currentResource();
    return (<>
        <InviteSummaryCard largePhoto={company?.photos?.[0]}
            class={currentService()}
            resource={{
                text: resource?.displayName,
                photo: resource?.photos?.[0],
            }}
            date={currentDate()}
            time={currentTime()}
            price={currentPrice()}
        />
    </>);
};
