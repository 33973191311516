import { nameof } from "ts-simple-nameof";
import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { berlinMapCoords, IHavePhotos, MapCoords } from "../models/Details";
import { PhotoEditModel } from "../models/Edit";
import { AccessTokenBearer, BeePhoto } from "../models/Global";
import { SearchCategories, SearchHints, SearchResults } from "../models/Search";

export class SearchEndpoint {
    async getHints(query?: string): Promise<ApiResponse<SearchHints>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/search/hints?q=${query}`,
            method: "GET",
            resultJsonTransformer: (json) => json as SearchHints,
        });
        return apiResponse;
    }
    async getExploreWhenHints(query?: string): Promise<ApiResponse<SearchHints>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/search/exploreWhenHints?q=${query}`,
            method: "GET",
            resultJsonTransformer: (json) => json as SearchHints,
        });
        return apiResponse;
    }
    async getCategories(query?: string): Promise<ApiResponse<SearchCategories>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/search/categories?q=${query}`,
            method: "GET",
            resultJsonTransformer: (json) => json as SearchCategories,
        });
        return apiResponse;
    }
    async getResults(query: string, currentLocation?: MapCoords): Promise<ApiResponse<SearchResults>> {
        const coords = currentLocation ?? berlinMapCoords;
        const apiResponse = await api.createRequest({
            endpoint: `api/search/results?q=${query}&lat=${coords?.lat ?? 0}&lng=${coords?.lng ?? 0}`,
            method: "GET",
            resultJsonTransformer: (json) => json as SearchResults,
        });
        return apiResponse;
    }
    async getFavorites(message: AccessTokenBearer): Promise<ApiResponse<SearchResults>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/search/favorites`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as SearchResults,
        });
        return apiResponse;
    }
    async getFeaturedResults(query: string, currentLocation?: MapCoords): Promise<ApiResponse<SearchResults>> {
        const coords = currentLocation ?? berlinMapCoords;
        const apiResponse = await api.createRequest({
            endpoint: `api/search/featured?q=${query}&lat=${coords?.lat ?? 0}&lng=${coords?.lng ?? 0}`,
            method: "GET",
            resultJsonTransformer: (json) => json as SearchResults,
        });
        return apiResponse;
    }
    getPhotoUrl(photo: BeePhoto | PhotoEditModel | undefined): string | undefined {
        if (!photo) return undefined;
        if (photo.url) return photo.url;

        if ("base64" in photo && photo.base64) {
            return photo.base64;
        }
        const apiRequest = {
            ignoreAuthenticate: true,
            endpoint: `api/search/images?ObjectID=${photo.objectID}&PhotoIndex=${photo.photoIndex}&ModificationDate=${photo.modificationDate}`,
            method: "GET",
        };
        return api.url + apiRequest.endpoint;
    }
    getFirstPhotoUrl(photos: BeePhoto[] | IHavePhotos | undefined): string | undefined {
        // const placeholder = api.url + `search/images?ObjectID=${"881F7E9C-2EE2-4497-ADB3-763DAE4A7C9B"}&PhotoIndex=${0}&VersionStamp=${"2021-04-18"}`;
        if (photos === undefined) return undefined;
        if (photos === null) return undefined;

        const p = Array.isArray(photos) ? photos
            : (nameof<IHavePhotos>(m => m.photos) in photos) ? photos.photos
                : undefined;

        if (!p) return undefined;
        if (p.length === 0) return undefined;
        const photo = p[0];
        return this.getPhotoUrl(photo);
    }
}