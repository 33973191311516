import { MapCoords } from "./Details";
import { BeePhoto, DimensionTypes } from "./Global";

export interface SearchResults {
    query: string,
    timeStamp: string,
    results: SearchResult[],
}
export interface SearchResult {
    title: string,
    subtitle: string,
    addressLine: string[],
    details: string,
    photos: BeePhoto[],
    companyID: string,
    isDemo: boolean,
    ratingScore?: number,
    badgeLevel?: number,
    mapCoords: MapCoords,
    offeredResourceID: string[],
}
export interface SearchAvatar {
    photo: BeePhoto,
    displayName: string,
}
export interface SearchHints {
    query: string,
    timeStamp: string,
    results: SearchHint[],
}
export interface SearchHint {
    hintText: string,
    encodedText: string,
    hintType: DimensionTypes,
}
export interface SearchCategories {
    query: string,
    timeStamp: string,
    results: SearchCategory[],
}
export const SearchCategory_Anything: SearchCategoryValues = "anything";
export type SearchCategoryValues = "anything"
    | "stem"
    | "lang"
    | "biz"
    | "human"
    | "arts"
    | "aviation"
    | "personal"
    ;
export const SearchCategory_Array: SearchCategoryValues[] = [
    "anything",
    "stem",
    "lang",
    "biz",
    "human",
    "arts",
    "aviation",
    "personal",
];
export const SearchCategory_Classes = {
    "anything": [],
    "stem": ["Mathematics", "Physics", "Chemistry"],
    "lang": ["English", "Hindi", "Malayalam", "German", "French"],
    "biz": ["Accounting", "Marketing", "Human Resources", "Economics"],
    "human": ["Psychology", "Sociology", "Political Science", "History", "Communication", "Philosophy"],
    "arts": ["Painting or Drawing", "Design", "Architecture", "Singing", "Piano", "Violin", "Guitar"],
    "aviation": ["Cabin Crew", "Air Traffic Control", "Travel Agents", "Aircraft Maintenance", "Pilots"],
    "personal": ["Communication ", "Leadership", "Time Management", "Mindfulness & Stress Management", "Health & Wellness"],
}
export function GetSearchCategory_Classes(category: SearchCategoryValues): string[] {
    const result = SearchCategory_Classes[category];
    return result;
}
export interface SearchCategory {
    text: string,
    encodedText: string,
    hasResults: boolean,
}