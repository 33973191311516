export type DimensionTypes =
    "duration"
    | "price"
    | "service"
    | "when"
    | "what_time"
    | "day_part"
    | "company"
    | "resource"
    | "visit"
    ;
export type PhotoPositionEnum =
    "Default"
    | "Top"
    | "Center"
    | "Bottom"
    ;
export function ToBackgroundPosition(position?: PhotoPositionEnum): "bottom" | "center" | "top" {
    switch (position) {
        case "Top": return "top";
        case "Center": return "center";
        case "Bottom": return "bottom";
        default: return "top";
    }
}
export function ToObjectPosition(position?: PhotoPositionEnum): "50% 0" | "50% 100%" | "50% 50%" {
    switch (position) {
        case "Top": return "50% 0";
        case "Bottom": return "50% 100%";
        default: return "50% 50%";
    }
}
export class DimensionTypeValues {
    static visit: DimensionTypes = "visit";
    static company: DimensionTypes = "company";
    static service: DimensionTypes = "service";
    static resource: DimensionTypes = "resource";
}
export interface BeePhoto {
    objectID: string,
    photoIndex: number,
    position?: PhotoPositionEnum,
    modificationDate: string,
    url?: string | null,
}
export const CURRENCY_INR = "INR";
export interface PriceAndCurrency {
    price: number,
    currency: string,
}
export interface AccessTokenBearer {
    accessToken: string,
}
export interface ValidationResult {
    isValid: boolean,
    message: string,
}
export interface SignalMessage {
    timeStamp: string,
    itemID: string,
    itemType: SignalMessageItemType,
    messageType: SignalMessageType,
    message: string,
};
export type SignalMessageItemType =
    "Company"
    | "Visit"
    ;
export type SignalMessageType =
    "Custom"
    | "CompanyViewed"
    | "InviteCreated"
    | "VisitConfirmed"
    | "VisitCancelled"
    | "VisitRescheduled"
    ;