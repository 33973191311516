import { Typography } from '@mui/material';
import { Col, Container, Row } from 'react-bootstrap';
import { CspImageList } from '../ui/CspImageList';
import { CspList } from '../ui/CspList';
import { CspVideo } from '../ui/CspVideo';
import { LogotypeSpans } from '../ui/LogotypeSpans';
import { StyledTitleRow } from '../ui/StyledTitle';

export const VisionView = () => {
    let i = 0;
    let videoI = 0;
    return (<>
        <Container >
            <Row className="my-5">
                <Col xs={true}
                    className="mb-3">
                    <StyledTitleRow text="Testimonials" subtitle />
                    <CspImageList moreColumns
                        other={[
                            <CspVideo showControls
                                src={'https://eduinstorage.blob.core.windows.net/blob/vision1.mp4'}
                                imgSrc='/img/people/agata.png'
                            />
                            ,
                            <CspVideo showControls
                                src={'https://eduinstorage.blob.core.windows.net/blob/vision1.mp4'}
                                imgSrc='/img/people/agata.png'
                            />
                            ,
                            <CspVideo showControls
                                src={'https://eduinstorage.blob.core.windows.net/blob/vision1.mp4'}
                                imgSrc='/img/people/agata.png'
                            />
                            ,
                            <CspVideo showControls
                                src={'https://eduinstorage.blob.core.windows.net/blob/vision1.mp4'}
                                imgSrc='/img/people/agata.png'
                            />
                        ]} />
                </Col>

                <Col xs={12} md={6} lg={true}
                    className="mb-3">
                    <StyledTitleRow text="How it works?" subtitle />
                    <CspList animationFromRight
                        delayMultiplier='fast'
                        items={[
                            {
                                index: i++,
                                icon: <Typography>{i}</Typography>,
                                primaryTitle: <><LogotypeSpans /> is a fully online educational platform</>,
                                secondaryTitle: <>Join Us for the highest quality education without borders</>,
                            },
                            {
                                index: i++,
                                icon: <Typography>{i}</Typography>,
                                primaryTitle: <>Choose your subjects or interests</>,
                                secondaryTitle: <>We will find you the best teachers</>,
                            },
                            {
                                index: i++,
                                icon: <Typography>{i}</Typography>,
                                primaryTitle: <>Use Private <LogotypeSpans /></>,
                                secondaryTitle: "and meet your teacher 1-1",
                            },
                            {
                                index: i++,
                                icon: <Typography>{i}</Typography>,
                                primaryTitle: <>or Small  <LogotypeSpans /></>,
                                secondaryTitle: "with 3 other students",
                            },
                            {
                                index: i++,
                                icon: <Typography>{i}</Typography>,
                                primaryTitle: <>or Full <LogotypeSpans /></>,
                                secondaryTitle: "where 7 to 10 students learn together",
                            },
                        ]}
                    />
                </Col>
            </Row >
            <Row />
        </Container >
    </>);
}
