import { Avatar, CircularProgress, IconButton, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { default as React } from "react";
import Resizer from "react-image-file-resizer";
import { PhotoPositionEnum, ToObjectPosition } from "../../api/models/Global";
import { CspIcon } from "./CspIcon";
import { Rotator } from "./ImageResizer";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        userAvatar: {
            alignSelf: 'center',
            width: '130px',
            height: '130px',
            backgroundColor: theme.palette.primary.light,
        },
        btnEditAvatarLabel: {
            display: 'flex',
            alignSelf: 'center',
            marginTop: '-20px',
        },
        btnEditAvatar: {
            width: '40px',
            height: '40px',
            margin: '0',
            padding: '0',
            border: 'solid 1px',
            borderColor: theme.palette.primary.light,
            zIndex: 10,
            backgroundColor: theme.palette.background.paper,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
        },
        btnRotateAvatar: {
            width: '40px',
            height: '40px',
            padding: '0',
            backgroundColor: theme.palette.background.paper,
            border: 'solid 1px',
            borderColor: theme.palette.primary.light,
            zIndex: 10,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
        },
    })
);
export const AvatarUpload = (props: {
    src: string,
    editMode?: boolean,
    addMode?: boolean,
    deleteMode?: boolean,
    positionMode?: boolean,
    position?: PhotoPositionEnum,
    inProgress?: boolean,
    alt: string,
    onChange?: (base64?: string, position?: PhotoPositionEnum) => Promise<void>,
    onDelete?: () => Promise<void>,
    fileId?: string,
    variant?: 'circular' | 'rounded' | 'square' | 'img',
}) => {
    const classes = useStyles();

    const resizeFile = (file: File) =>
        new Promise<string>((resolve) => {
            Rotator.createRotatedImage(file, "blob",
                (rotatedImage) => {
                    if (!(rotatedImage instanceof Blob)) return;

                    Resizer.imageFileResizer(
                        rotatedImage,
                        640,
                        640,
                        "png",
                        1,
                        0,
                        (uri) => {
                            resolve(uri.toString());
                        },
                        "base64"
                    );
                },
                error => { console.error(error); }
            );
        });
    async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!event.target.files) return;
        if (event.target.files.length <= 0) return;
        if (!props.onChange) return;

        const file = event.target.files[0];
        const base64 = await resizeFile(file);
       await props.onChange(base64);
    }
    async function rotateImage() {
        if (!props.onChange) return;
        if (!props.src) return;

        const blob = await (await fetch(props.src)).blob();
        Resizer.imageFileResizer(blob, 640, 640, "png", 1, 90, (uri) => {
            if (props.onChange) {
                props.onChange(uri.toString());
            }
        }, "base64");
    }
    async function deleteImage() {
        if (!props.onDelete) return;

        await props.onDelete();
    }
    async function togglePosition() {
        if (!props.onChange) return;

        const nextPosition = getNextPosition();
        const blob = await (await fetch(props.src)).blob();
        Resizer.imageFileResizer(blob, 640, 640, "png", 1, 0, (uri) => {
            if (props.onChange) {
                props.onChange(uri.toString(), nextPosition);
            }
        }, "base64");
    }
    function getNextPosition(): PhotoPositionEnum {
        switch (props.position) {
            case "Top": return "Center";
            case "Center": return "Bottom";
            case "Bottom": return "Default";
            case undefined:
            default: return "Top";
        }
    }
    const allowRotate = false;//disabled, as there are problems with CORS when fetching image. Several attempts failed to resolve :)
    return (<><div className={classes.root}>
        {props.variant === "img" &&
            <img src={props.addMode ? "/img/placeholder-300x202.jpg" : props.src} alt=""
                className={classes.userAvatar}
                style={{
                    objectFit: "contain",
                    objectPosition: ToObjectPosition(props.position),
                }}
            />
        }
        {props.variant !== "img" &&
            <Avatar src={props.addMode ? "/img/placeholder-300x202.jpg" : props.src}
                className={classes.userAvatar}
                variant={props.variant ?? "circular"}
            />
        }
        {(props.editMode || props.addMode)
            && <><div className={classes.btnEditAvatarLabel}>
                <input accept="image/jpeg"
                    id={"icon-button-photo" + props.fileId}
                    className="d-none"
                    onChange={handleChange}
                    type="file"
                />
                <label
                    htmlFor={"icon-button-photo" + props.fileId}>
                    <IconButton className={classes.btnEditAvatar} component="span">
                        {props.inProgress && <CircularProgress />}
                        {!props.inProgress &&
                            <Typography variant="body1" color="primary">
                                <CspIcon name="CameraAltOutlinedIcon" fontSize="small" />
                            </Typography>
                        }
                    </IconButton>
                </label>
                {allowRotate &&
                    <IconButton className={classes.btnRotateAvatar}
                        color="primary"
                        onClick={rotateImage}>
                        <Typography variant="body1" color="primary">
                            <CspIcon name="Rotate90DegreesCwOutlinedIcon" fontSize="small" />
                        </Typography>
                    </IconButton>
                }
                {props.deleteMode &&
                    <IconButton className={classes.btnRotateAvatar}
                        color="primary"
                        onClick={deleteImage}>
                        <Typography variant="body1" color="primary">
                            <CspIcon name="ClearIcon" fontSize="small" />
                        </Typography>
                    </IconButton>
                }
                {props.positionMode &&
                    <IconButton className={classes.btnRotateAvatar}
                        color="primary"
                        onClick={togglePosition}>
                        <Typography variant="body1" color="primary">
                            <CspIcon name="VerticalAlignCenterIcon" fontSize="small" />
                        </Typography>
                    </IconButton>
                }
            </div>
            </>}
    </div>
    </>);
}