import { Fab, IconButton, Typography } from "@mui/material";
import React from "react";
import { SearchCategory, SearchCategoryValues } from '../../api/models/Search';
import { L10N } from '../pages/L10N';
import { Animations } from "../ui/Animations";
import { CategoryIcon, CategoryIconURL } from '../ui/CategoryIcon';
import { theme } from "../ui/Theme";

export interface SearchCategoryChipProps {
    data: SearchCategory,
    isChecked?: boolean,
    onChange?: (isChecked: boolean) => void,
    showText?: boolean,
}
export const SearchCategoryChip = (props: SearchCategoryChipProps) => {

    function onClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        if (props.onChange) {
            props.onChange(!props.isChecked);
        }
        event.stopPropagation();
    }
    const fabSize = 87;

    const fabOrButton = false;
    return (
        <div className='text-center mx-1'
            style={{
                maxWidth: fabSize * 1.8,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            }}
        >
            {fabOrButton ?
                <Fab size="large"
                    className={(props.isChecked ? "bg-gradient-classnow " : "") + " my-2 "}
                    color={(props.isChecked ? "secondary" : "default")}
                    style={{
                        width: fabSize,
                        height: fabSize,
                    }}
                    onClick={onClick}
                    title={L10N({ key: "_category", cat: props.data.text })}
                >
                    <CategoryIcon name={props.data.text as SearchCategoryValues} white={props.isChecked} />
                </Fab>
                :
                <IconButton size="large"
                    style={{
                        width: fabSize,
                        height: fabSize,
                        boxShadow: props.isChecked ? " 3px 6px 6px rgba(244, 159, 10, 0.5)" : undefined,
                    }}
                    className={(props.isChecked ? "" + Animations.fade_in() : "")
                        + " my-2 "}
                    sx={{
                        bgcolor: props.isChecked ? theme.palette.primary.main : undefined,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.main, // Remove hover background change
                            boxShadow: theme.shadows[3],
                        },
                    }}
                    color={props.isChecked ? "primary" : "secondary"}
                    onClick={onClick}
                    title={L10N({ key: "_category", cat: props.data.text })}
                >
                    <img src={CategoryIconURL(props.data.text, props.isChecked)}
                        width={fabSize}
                        height={fabSize}
                        alt={L10N({ key: "_category", cat: props.data.text })} />
                    {/* <CategoryIcon name={props.data.text} white={props.isChecked} /> */}
                </IconButton>

            }
            {props.showText &&
                <Typography variant="body1"
                    onClick={onClick}
                    align="center"
                    style={{
                        cursor: "pointer"
                    }}
                    color={props.data.hasResults ? "secondary" : "secondary"}>
                    {L10N({ key: "_category", cat: props.data.text })}
                </Typography>
            }
        </div>
    );
};
