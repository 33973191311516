import { Button, Typography } from "@mui/material";
import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';
import { InviteTime } from '../../api/models/Invite';
import { changeSelectedDimension } from '../../store/invite/invite.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { L10N } from '../pages/L10N';
import { EmiPaper } from '../ui/EmiPaper';

export const WhatTimeSelector = (props: {
}) => {
    const dispatch = useAppDispatch();
    const { currentInvite } = useAppSelector((state: RootState) => state.invite);

    function isSelected(m: InviteTime | undefined): boolean {
        if (!m) return false;
        return m.dimensionValue === currentInvite?.timeDimensionSelectedValue;
    }
    async function onTimeClicked(m: InviteTime) {
        await dispatch(changeSelectedDimension({ dimensionValue: m, dimensionType: "what_time" }));
    }
    return (<>
        <EmiPaper className="pb-3">
            <Container>
                <Row className="mt-4">
                    <Col xs={12}>
                        <Typography variant="subtitle1" align="left" className={"my-3 "}>
                            {L10N({ key: "What time?" })}
                        </Typography>
                    </Col>
                    {currentInvite?.timeDimension?.map && currentInvite?.timeDimension &&
                        currentInvite?.timeDimension?.map && currentInvite?.timeDimension
                            ?.filter((v, i, a) => v.countOfOffers > 0)
                            ?.map((m, i) =>
                                <Col key={i} className={"col-auto p-2"}>
                                    <Button
                                        variant={isSelected(m) ? "contained" : "outlined"}
                                        color={isSelected(m) ? "primary" : "secondary"}
                                        size="small"
                                        onClick={() => onTimeClicked(m)}>
                                        {moment(m.dimensionValue, "HH:mm").utc(true).local().format("HH:mm")}
                                    </Button>
                                </Col>
                            )}
                </Row></Container >
        </EmiPaper>

    </>);
};
