import { FieldHelperProps, FieldInputProps, FieldMetaProps, FormikErrors, FormikState, FormikTouched } from 'formik';
import * as React from 'react';
import { formKey } from 'src/helpers';

export function FormikFieldProps<T extends Object>(
    formik: useFormikResult<T>,
    fieldSelector: (obj: T
        | FormikTouched<T>
        | FormikErrors<T>
    ) => any
) {
    return {
        id: formKey<T>(fieldSelector),
        name: formKey<T>(fieldSelector),
        onChange: formik.handleChange,
        value: fieldSelector(formik.values) ?? "",
        error: fieldSelector(formik.touched) && Boolean(fieldSelector(formik.errors)),
        helperText: fieldSelector(formik.touched) && fieldSelector(formik.errors),
        onKeyPress: (e: React.KeyboardEvent) => { e.key === 'Enter' && e.preventDefault() },
    };
}
export function FormikFieldProps_PhoneCountrySelector<T extends Object>(
    formik: useFormikResult<T>,
    fieldSelector: (obj: T
        | FormikTouched<T>
        | FormikErrors<T>
    ) => any
) {
    return {
        id: formKey<T>(fieldSelector),
        name: formKey<T>(fieldSelector),
        onChange: async (value: string) => {
            await formik.setFieldValue(formKey<T>(fieldSelector), value);
            await formik.setFieldTouched(formKey<T>(fieldSelector));
        },
        value: fieldSelector(formik.values) ?? "",
    };
}
export function FormikFieldProps_StringValue<T extends Object>(
    formik: useFormikResult<T>,
    fieldSelector: (obj: T
        | FormikTouched<T>
        | FormikErrors<T>
    ) => any,
    onChange?: (value: string) => Promise<void>,
) {
    return {
        id: formKey<T>(fieldSelector),
        name: formKey<T>(fieldSelector),
        onChange: async (value: string) => {
            await formik.setFieldValue(formKey<T>(fieldSelector), value);
            await formik.setFieldTouched(formKey<T>(fieldSelector));
            if (onChange) await onChange(value);
        },
        value: fieldSelector(formik.values) ?? "",
        error: fieldSelector(formik.touched) && Boolean(fieldSelector(formik.errors)),
        helperText: fieldSelector(formik.touched) && fieldSelector(formik.errors),
    };
}
export function FormikFieldProps_Switch<T extends Object>(
    formik: useFormikResult<T>,
    fieldSelector: (obj: T
        | FormikTouched<T>
        | FormikErrors<T>
    ) => any
) {
    return {
        id: formKey<T>(fieldSelector),
        name: formKey<T>(fieldSelector),
        onChange: async (event: any, checked: boolean) => {
            await formik.setFieldValue(formKey<T>(fieldSelector), checked)
            await formik.setFieldTouched(formKey<T>(fieldSelector));
        },
        checked: Boolean(fieldSelector(formik.values) ?? ""),
        onKeyPress: (e: React.KeyboardEvent) => { e.key === 'Enter' && e.preventDefault() },
    };
}
export function FormikFieldProps_ToggleButtonGroup<T extends Object>(
    formik: useFormikResult<T>,
    fieldSelector: (obj: T
        | FormikTouched<T>
        | FormikErrors<T>
    ) => any,
    onChange?: (value: string) => Promise<void>,
    ignoreNull?: "IGNORE_NULL",
) {
    return {
        id: formKey<T>(fieldSelector),
        name: formKey<T>(fieldSelector),
        onChange: async (event: any, value: string) => {
            if (ignoreNull && !value) return;
            await formik.setFieldValue(formKey<T>(fieldSelector), value);
            await formik.setFieldTouched(formKey<T>(fieldSelector));
            if (onChange) await onChange(value);
        },
        value: fieldSelector(formik.values) ?? "",
        onKeyPress: (e: React.KeyboardEvent) => { e.key === 'Enter' && e.preventDefault() },
    };
}
export type useFormikResult<Values> = {
    initialValues: Values;
    initialErrors: FormikErrors<unknown>;
    initialTouched: FormikTouched<unknown>;
    initialStatus: any;
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    handleReset: (e: any) => void;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    resetForm: (nextState?: Partial<FormikState<Values>> | undefined) => void;
    setErrors: (errors: FormikErrors<Values>) => void;
    setFormikState: (stateOrCb: FormikState<Values> | ((state: FormikState<Values>) => FormikState<Values>)) => void;
    setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => Promise<FormikErrors<Values>> | Promise<void>;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<Values>> | Promise<void>;
    setFieldError: (field: string, value: string | undefined) => void;
    setStatus: (status: any) => void;
    setSubmitting: (isSubmitting: boolean) => void;
    setTouched: (touched: FormikTouched<Values>, shouldValidate?: boolean | undefined) => Promise<FormikErrors<Values>> | Promise<void>;
    setValues: (values: React.SetStateAction<Values>, shouldValidate?: boolean | undefined) => Promise<FormikErrors<Values>> | Promise<void>;
    submitForm: () => Promise<any>;
    validateForm: (values?: Values) => Promise<FormikErrors<Values>>;
    validateField: (name: string) => Promise<void> | Promise<string | undefined>;
    isValid: boolean;
    dirty: boolean;
    unregisterField: (name: string) => void;
    registerField: (name: string, { validate }: any) => void;
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
    getFieldMeta: (name: string) => FieldMetaProps<any>;
    getFieldHelpers: (name: string) => FieldHelperProps<any>;
    validateOnBlur: boolean;
    validateOnChange: boolean;
    validateOnMount: boolean;
    values: Values;
    errors: FormikErrors<Values>;
    touched: FormikTouched<Values>;
    isSubmitting: boolean;
    isValidating: boolean;
    status?: any;
    submitCount: number;
}