const localhost44382 = {
    PUBLIC_URL: "https://localhost:44382",
    auth_redirectUri: "https://localhost:44382",
    auth_postLogoutRedirectUri: "https://localhost:44382",
}
const localhost44378_3000 = {
    PUBLIC_URL: "https://localhost:44378",
    auth_redirectUri: "http://localhost:3000",
    auth_postLogoutRedirectUri: "http://localhost:3000",
}
const azure = {
    PUBLIC_URL: "https://classnow.azurewebsites.net",
    auth_redirectUri: "https://classnow.azurewebsites.net",
    auth_postLogoutRedirectUri: "https://classnow.azurewebsites.net",
}
export class env {
    static auth_clientId = "bf5fd012-601b-4d4f-8e5b-9f5c4fc87e15";
    static auth_authority = "https://cspeduin.b2clogin.com/cspeduin.onmicrosoft.com/B2C_1_eduin";
    static classNowCompanyID = "f8d3f83e-e441-4ffe-97e5-c3692110ea1b";
    static all = [
        localhost44382,
        localhost44378_3000,
        azure,
    ];
    static current = azure;
}
