import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { PurchaseRequest, PurchaseResponse, PurchaseStatusResponse, TestKlarnaRequest, TestRazorpayRequest } from "../models/Purchase";

export class PurchaseEndpoint {
    async postPurchase(message: PurchaseRequest): Promise<ApiResponse<PurchaseResponse>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/purchase/purchase`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as PurchaseResponse,
        });
        return apiResponse;
    }
    async testKlarna(message: TestKlarnaRequest): Promise<ApiResponse<boolean>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/purchase/testKlarna`,
            method: "POST",
            json: JSON.stringify(message),
            resultTransformer: async rawResponse => "true" === await rawResponse.text(),
        });
        return apiResponse;
    }
    async testRazorpay(message: TestRazorpayRequest): Promise<ApiResponse<boolean>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/purchase/testRazorpay`,
            method: "POST",
            json: JSON.stringify(message),
            resultTransformer: async rawResponse => "true" === await rawResponse.text(),
        });
        return apiResponse;
    }
    async checkStatus(message: PurchaseRequest): Promise<ApiResponse<PurchaseStatusResponse>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/purchase/checkStatus`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as PurchaseStatusResponse,
        });
        return apiResponse;
    }
}