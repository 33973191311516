import { createStyles, makeStyles } from "@mui/styles";
import { Animations } from './Animations';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'absolute',
            top: '80%',
            left: '5%',
            right: '5%',
            bottom: '-30%',
            filter: 'blur(7px)',
            opacity: '0.5',
            background: 'radial-gradient(circle, #f49f0a 0%, #d9781e 40%, #a24936 100%)',
            backgroundClip: 'content-box',
            zIndex:-1,
        },
    })
);

export const ButtonShadow = (props: {
    animate?: boolean,
}) => {
    const classes = useStyles();
    return (<div className={classes.root
        + (props.animate ? " " + Animations.slide_in_bottom() : "")} />);
};
