import { Button, CircularProgress, FormControlLabel, FormHelperText, IconButton, InputAdornment, Switch, TextField, Typography } from "@mui/material";
import { Col, Row } from 'react-bootstrap';
import { api } from "src/api/api";
import { ApiResponse } from "src/api/ApiResponse";
import { LOGIN_STATE_ENUM, LoginPageModel } from "src/api/models/Login";
import { RandomName } from "src/api/models/NameGenerator";
import { UserProfile } from "src/api/models/User";
import { formKey } from "src/helpers";
import { RootState, useAppDispatch, useAppSelector } from "src/store/store";
import { clearUser, setUser } from "src/store/user/user.reducer";
import { L10N } from '../pages/L10N';
import { LoginPageState } from "../pages/LoginPage";
import { Animations } from "../ui/Animations";
import { CspIcon } from "../ui/CspIcon";
import { FormikFieldProps, FormikFieldProps_StringValue, FormikFieldProps_Switch, useFormikResult } from "../ui/FormikAPI";
import { ConsentsDialogButton } from "./ConsentsDialog";
import { PhoneAndCountryField, TextFieldPin } from "./PhoneCountrySelect";

export const FormLogin = (props: {
    formik: useFormikResult<LoginPageModel>,
    state: LoginPageState,
    setState: React.Dispatch<React.SetStateAction<LoginPageState>>,
}) => {
    const { user } = useAppSelector((state: RootState) => state.user);
    const dispatch = useAppDispatch();

    async function beforeApiRequest() {
        props.setState(prev => ({
            ...prev,
            inProgress: true,
        }));
        await dispatch(clearUser());
    }
    async function afterApiResponse(apiResponse: ApiResponse<UserProfile>, nextStage: LOGIN_STATE_ENUM) {
        await dispatch(setUser({
            user: apiResponse.result,
            apiError: apiResponse.error,
        }));
        const hasError = Boolean(apiResponse.error);
        props.setState(prevState => ({
            ...prevState,
            inProgress: false,
            stage: hasError
                ? prevState.stage
                : nextStage
        }));
    }
    async function submitStage_phone() {
        if (!canExecuteClick_phone()) return;

        beforeApiRequest();
        const phone = props.formik.values.phoneCountry
            + props.formik.values.tel;
        const apiResponse = await api.Users.refreshPIN({
            phone: phone,
        });
        await props.formik.setFieldError(
            formKey<LoginPageModel>(m => m.tel),
            apiResponse.error?.validation?.message
        )
        const nextStage: LOGIN_STATE_ENUM = "pin";
        afterApiResponse(apiResponse, nextStage);
    }
    async function submitStage_pin() {
        if (!canExecuteClick_pin()) return;

        beforeApiRequest();
        const phone = props.formik.values.phoneCountry
            + props.formik.values.tel;
        const apiResponse = await api.Users.refreshAccessToken({
            phone: phone,
            pin: props.formik.values.pin,
        });
        await props.formik.setFieldError(
            formKey<LoginPageModel>(m => m.pin),
            apiResponse.error?.validation?.message
        )
        const nextStage: LOGIN_STATE_ENUM = apiResponse.result?.accountDetailsRequired
            ? "details"
            : "loggedin";
        afterApiResponse(apiResponse, nextStage);
    }

    async function submitStage_details() {
        if (!user || !canExecuteClick_details()) return;

        beforeApiRequest();
        const apiResponse = await api.Users.updateUserProfile({
            ...user,
            displayName: props.formik.values.displayName,
            consent: props.formik.values.consent,
        });
        await props.formik.setFieldError(
            formKey<LoginPageModel>(m => m.consent),
            apiResponse.error?.validation?.message
        )
        const nextStage: LOGIN_STATE_ENUM = "loggedin";
        afterApiResponse(apiResponse, nextStage);
    }

    async function onClick() {
        switch (props.state.stage) {
            case "phone": return await submitStage_phone();
            case "pin": return await submitStage_pin();
            case "details": return await submitStage_details();
            default: return;
        }
    }
    function canExecuteClick_phone(): boolean {
        if (!props.formik.dirty
            || props.formik.errors.tel
            || props.formik.errors.phoneCountry) {
            return false;
        }
        return true;
    }
    function canExecuteClick_pin(): boolean {
        if (!props.formik.dirty
            || props.formik.errors.tel
            || props.formik.errors.phoneCountry
            || props.formik.errors.pin
            || !props.formik.values.pin
        ) {
            return false;
        }
        return true;
    }
    function canExecuteClick_details(): boolean {
        if (!props.formik.dirty
            || props.formik.errors.displayName
            || props.formik.errors.consent
            || props.formik.errors.consentViewed
            || !props.formik.values.displayName
            || !props.formik.values.consent
            || !props.formik.values.consentViewed
            || !user
        ) {
            return false;
        }
        return true;
    }
    function canExecuteClick(): boolean {
        switch (props.state.stage) {
            case "phone": return canExecuteClick_phone();
            case "pin": return canExecuteClick_pin();
            case "details": return canExecuteClick_details();
            default: return true;
        }
    }
    function loginClickLabel(): string {
        switch (props.state.stage) {
            case "phone": return L10N({ todo: "Get OTP" });
            case "pin": return L10N({ todo: "Verify OTP" });
            case "details": return L10N({ key: "Save" });
            default: return L10N({ key: "Log in" });
        }
    }
    async function onPhoneChanged() {
        await props.formik.setFieldValue(formKey<LoginPageModel>(m => m.pin), "");
        await props.formik.setFieldError(formKey<LoginPageModel>(m => m.pin), undefined);
        await props.formik.setFieldTouched(formKey<LoginPageModel>(m => m.pin), false);
        await props.formik.setFieldValue(formKey<LoginPageModel>(m => m.consentViewed), false);
        props.setState(prev => ({
            ...prev,
            inProgress: false,
            stage: 'phone',
        }));
    }
    async function nextRandomName() {
        await props.formik.setFieldValue(formKey<LoginPageModel>(m => m.displayName), RandomName());
    }
    const showDebug = false;
    return (<>
        <form onSubmit={props.formik.handleSubmit}>
            {showDebug &&
                <Row className="my-3">
                    <Col className="pr-1 " xs={12}>
                        <Typography >Vals:
                            <br />
                            {JSON.stringify(props.formik.values, null, 2)}
                            <br />Errors:
                            <br />
                            {JSON.stringify(props.formik.errors, null, 2)}
                            <br />Touched:
                            <br />
                            {JSON.stringify(props.formik.touched, null, 2)}
                            <br />
                            {JSON.stringify(props.state, null, 2)}
                        </Typography>
                    </Col>
                </Row>
            }
            <PhoneAndCountryField formik={props.formik}
                onChange={onPhoneChanged}
                adornment={canExecuteClick_phone()
                    && <>
                        <IconButton className={"bg-gradient-classnow text-white"
                            + Animations.puff_in_center()}
                            onClick={onClick}
                        >
                            <CspIcon name="LoginOutlinedIcon" />
                        </IconButton>
                    </>}
            />
            {props.state.stage === "pin" && <>
                <TextFieldPin
                    {...FormikFieldProps_StringValue(props.formik, m => m.pin)}
                    adornment={canExecuteClick_pin()
                        && <>
                            <IconButton className={"bg-gradient-classnow text-white"
                                + Animations.puff_in_center()}
                                onClick={onClick}
                            >
                                <CspIcon name="FingerprintOutlinedIcon" />
                            </IconButton>
                        </>}
                /><br />
            </>}
            {props.state.stage === "details" && <>
                <TextField className="mb-3"
                    label={L10N({ todo: "Name or nickname" })}
                    required
                    placeholder="Name or nickname"
                    {...FormikFieldProps(props.formik, m => m.displayName)}
                    slotProps={{
                        input: {
                            endAdornment: <InputAdornment position="end">
                                <IconButton color="primary"
                                    onClick={nextRandomName}
                                >
                                    <CspIcon name="CasinoOutlinedIcon" />
                                </IconButton>
                            </InputAdornment>
                        }
                    }}
                /><br />
                <ConsentsDialogButton animate={!!(props.formik.errors.consentViewed)}
                    onOpen={async () => {
                        await props.formik.setFieldValue(formKey<LoginPageModel>(m => m.consentViewed), true);
                    }}
                />
                <FormHelperText variant="filled" color="error" >
                    {props.formik.errors.consentViewed}
                </FormHelperText>
                <FormControlLabel control={
                    <Switch {...FormikFieldProps_Switch(props.formik, m => m.consent)} />
                }
                    label={L10N({ todo: "I consent" })}
                /><br />
                <FormHelperText variant="filled" color="error" >
                    {props.formik.errors.consent}
                </FormHelperText>
            </>}

            <Row>
                <Col />
                <Col className="col-auto">
                    <Button color="secondary"
                        className="my-3 mx-2 bg-gradient-classnow"
                        variant="contained"
                        type="submit"
                        onClick={onClick}
                        disabled={!canExecuteClick()}
                    >
                        {props.state.inProgress && <CircularProgress />}
                        {!props.state.inProgress && loginClickLabel()}
                    </Button>
                </Col>
                <Col />
            </Row>

        </form>
    </>
    )
}
