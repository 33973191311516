import 'bootstrap/dist/css/bootstrap.css';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { IntlProvider } from "react-intl";
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { store } from './store/store';

ReactGA.initialize('G-2RNRK878T9', {
    // debug: true,
    gaOptions: {
        siteSpeedSampleRate: 100,
        sampleRate: 100,
    },
});

//persistor.purge();
const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(<Provider store={store}>
    <BrowserRouter>
        <IntlProvider locale='en-IN'>
            <App />
        </IntlProvider>
    </BrowserRouter>
</Provider>);

// registerServiceWorker();