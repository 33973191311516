import { Avatar, Button } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { api } from "src/api/api";
import { CompanyDetails } from "src/api/models/Details";

export const CompanyList = (props: {
    companyList: CompanyDetails[],
    editCompanyClick: (companyID?: string) => Promise<void>,
    viewCompanyClick: (companyID?: string) => Promise<void>,
    assignCompanyClick: (companyID?: string) => Promise<void>,
    toggleDemoClick: (companyID?: string) => Promise<void>,
}) => {
    return (
        <>
            <Row className="my-3">
                <Col >
                    <table className="table table-striped table-bordered">
                        <caption>List of companies</caption>
                        <thead>
                            <tr>
                                <th scope="col">No.</th>
                                <th scope="col">Photo</th>
                                <th scope="col">Title</th>
                                <th scope="col">Category</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.companyList
                                .reverse()
                                .map((m, i) => <tr key={i}>
                                    <td>{i + 1}.</td>
                                    <td>
                                        <Avatar
                                            src={api.Search.getFirstPhotoUrl(m?.photos)}
                                            className={"m-1 "}
                                            alt={m?.title} />
                                    </td>
                                    <td>{m.title}</td>
                                    <td>{m.category}</td>
                                    <td>
                                        {m.itemID && <Button onClick={async () => props.editCompanyClick(m.itemID)}
                                            className="m-1"
                                            variant="outlined" color="primary" size="small">
                                            Edit company
                                        </Button>}
                                        {m.itemID && <Button onClick={async () => props.viewCompanyClick(m.itemID)}
                                            className="m-1"
                                            variant="outlined" color="primary" size="small">
                                            View company
                                        </Button>}
                                        {m.itemID && <Button onClick={async () => props.assignCompanyClick(m.itemID)}
                                            className="m-1"
                                            variant="outlined" color="primary" size="small">
                                            Assign company
                                        </Button>}
                                        {m.itemID && <Button onClick={async () => props.toggleDemoClick(m.itemID)}
                                            className="m-1"
                                            variant="outlined" color="primary" size="small">
                                            {m.isDemo ? "Unset demo" : "Set demo"}
                                        </Button>}
                                    </td>
                                </tr>)}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </>
    );
};