import { Button, LinearProgress, Typography } from "@mui/material";
import * as React from 'react';
import { useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { SearchResult } from '../../api/models/Search';
import { changeInvite } from '../../store/invite/invite.actions';
import { refreshFavorites } from '../../store/search/search.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { SearchCard } from '../search/SearchCard';
import { Animations } from '../ui/Animations';
import { LogotypeSpans } from "../ui/LogotypeSpans";
import { L10N } from './L10N';
import { PageHeader } from './PageHeader';
import { AppRoutes } from './Routes';

export const FavouritesPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { inProgress: favoriteResultsInProgress, favoriteResults } = useAppSelector((state: RootState) => state.search);
    const { user, inProgress } = useAppSelector((state: RootState) => state.user);

    useMemo(() => {
        if (!user) return;
        dispatch(refreshFavorites({ message: user }));
    }, [dispatch, user]);

    async function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>, item: SearchResult) {
        await dispatch(changeInvite({
            message: {
                query: "",
                offeredResourceID: item.offeredResourceID,
            }
        }));
        await navigate(AppRoutes.invitePage());
        event.stopPropagation();
    }
    function renderItem(m: SearchResult, i: number, isCurrent: boolean): JSX.Element {
        return <Col key={i}
            className="mb-3"
            xs={12} sm={6} md={4} lg={3} xl={3}>
            <SearchCard className={
                isCurrent
                    ? Animations.swing_in_top_fwd()
                    : Animations.slide_out_bck_center()
                    + " " + Animations.delay(i)}
                data={m}
                showImage
                onCardClick={handleClick} />
        </Col>
    }
    return (<>
        {!inProgress && !(user?.clientAccessGranted ?? false)
            &&
            <Navigate replace to={AppRoutes.loginAndReturn(AppRoutes.favourites)} />
        }

        <Container
            style={{
                overflowY: 'hidden',
                minHeight: '100vh',
            }}>
            <Row className="my-5">
                <Col />
                <Col className="col-auto">
                    <Typography variant="subtitle1" color="primary">
                        {L10N({ key: "Favorites" })}
                    </Typography >
                </Col>
                <Col />
            </Row>
            {favoriteResultsInProgress && <>
                <Row>
                    <Col xs={12}>
                        <LinearProgress className="w-100 mt-n1" color="secondary" />
                    </Col>
                </Row>
            </>}
            {!favoriteResultsInProgress && (favoriteResults?.results?.length ?? 0) === 0 && <>
                <Row className="mb-3">
                    <Col />
                    <Col className="col-auto">
                        <Typography align="center">{L10N({ key: "You have no favorites yet" })}</Typography>
                    </Col>
                    <Col />
                </Row>
                <Row className="my-3">
                    <Col />
                    <Col className="col-auto">
                        <Button variant="outlined" fullWidth
                            component={Link}
                            to={AppRoutes.homeSearchBar}
                        >
                            Explore &nbsp; <LogotypeSpans />
                        </Button>
                    </Col>
                    <Col />
                </Row>
                <PageHeader showLogo logoNavigatesToHome />
            </>}
            <Row className="mb-3">
                {favoriteResults?.results?.map && favoriteResults.results?.map((m, i) => (renderItem(m, i, true)))}
            </Row>
        </Container>
    </>);
}
