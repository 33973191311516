import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { AccessTokenBearer } from "../models/Global";
import { StudentWizardEditModel } from "../models/StudentWizard";

export interface RequestStudentWizard extends AccessTokenBearer {
    userID?: string,
}
export class StudentWizardEndpoint {

    async getForm(message: RequestStudentWizard): Promise<ApiResponse<StudentWizardEditModel>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/StudentWizard/get-form`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as StudentWizardEditModel,
        });
        return apiResponse;
    }
    async saveForm(message: StudentWizardEditModel): Promise<ApiResponse<StudentWizardEditModel>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/StudentWizard/save-form`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as StudentWizardEditModel,
        });
        return apiResponse;
    }
    async getList(message: AccessTokenBearer): Promise<ApiResponse<StudentWizardEditModel[]>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/StudentWizard/get-list`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as StudentWizardEditModel[],
        });
        return apiResponse;
    }
    async deleteForm(message: RequestStudentWizard): Promise<ApiResponse<boolean>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/StudentWizard/delete-form`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as boolean,
        });
        return apiResponse;
    }
}
