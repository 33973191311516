import { ButtonProps } from "@mui/material";
import { env } from "src/env";
import { AppRoutes } from "../pages/Routes";
import { CspButtonLink } from "../ui/CspButtonLink";

export const CompanyViewDetailsButton = (props: ButtonProps & {
    companyID?: string;
}) => {
    const { companyID, ...buttonProps } = props;
    return (
        <CspButtonLink {...buttonProps}
            navigateHref={AppRoutes.detailsTab("company", props.companyID ?? env.classNowCompanyID)}
        />
    )
}
