import { useEffect, useRef, useState } from 'react';

export function useIsVisible<T extends HTMLElement>()
  : { ref: React.RefObject<T>, isVisible: boolean, wasVisible: boolean } {
  const [isVisible, setIsVisible] = useState(false);
  const [wasVisible, setWasVisible] = useState(false);
  const ref = useRef<T>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        setWasVisible(prev => entry.isIntersecting || prev);
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return { ref, isVisible, wasVisible };
}
