import { AppBar, Button, CircularProgress, Toolbar } from "@mui/material";
import { RootState, useAppSelector } from '../../store/store';
import { L10N } from '../pages/L10N';
import { Animations } from '../ui/Animations';
import { useFormikResult } from '../ui/FormikAPI';

export const AppBarSubmitCancel = (props: {
    formik: useFormikResult<any>,
}) => {
    const { inProgress: editInProgress } = useAppSelector((state: RootState) => state.edit);
    function onSubmitCapture() {
        if (!props.formik.isValid) {
            console.log(props.formik.errors);
            alert(JSON.stringify(props.formik.errors));
        }
    }
    return <>
        <AppBar position="fixed" color="secondary"
            className={Animations.slit_in_vertical()}
            style={{
                top: 'auto',
                bottom: 0,
                zIndex: 2010,
            }} >
            <Toolbar className="mx-auto">
                <Button color="primary" variant="contained" type="submit"
                    onClick={onSubmitCapture}
                    onSubmitCapture={onSubmitCapture}
                    disabled={editInProgress}
                    className="mx-1">
                    {editInProgress && <CircularProgress />}
                    {!editInProgress && L10N({ key: "Save" })}
                </Button>
                <Button variant="contained"
                    disabled={editInProgress}
                    onClick={() => props.formik.resetForm()}
                    className="mx-1">
                    {L10N({ key: "Cancel" })}
                </Button>
            </Toolbar>
        </AppBar>
    </>;
}