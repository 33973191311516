import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { ConsentsDialogVisible } from '../../store/ui/ui.reducer';
import { L10N } from '../pages/L10N';
import { PrivacyPolicyPage } from "../pages/PrivacyPolicyPage";
import { TermsOfUsePage } from "../pages/TermsOfUsePage";
import { Animations } from "../ui/Animations";
import { CspDivider } from "../ui/CspDivider";

export const ConsentsDialog = () => {
    const dispatch = useAppDispatch();

    const { consentsDialogVisible } = useAppSelector((state: RootState) => state.ui);

    async function handleClose() {
        await dispatch(ConsentsDialogVisible(false));
    }

    return (
        <>
            <Dialog fullWidth
                open={consentsDialogVisible ?? false}
                onClose={handleClose}>
                <DialogTitle>
                    {L10N({ todo: "User Consents" })}
                </DialogTitle>
                <CspDivider />
                <DialogContent>
                    <TermsOfUsePage />
                    <PrivacyPolicyPage />
                </DialogContent>
                <CspDivider shadowTop />
                <DialogActions>
                    <Button onClick={handleClose}
                        color="primary" variant="contained">
                        {L10N({ key: "OK" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export const ConsentsDialogButton = (props: {
    onOpen?: () => Promise<void>,
    animate?: boolean,
    className?: string,
}) => {
    const dispatch = useAppDispatch();
    async function handleTermsClick(event?: React.MouseEvent<any, MouseEvent>) {
        await dispatch(ConsentsDialogVisible(true));
        if (props.onOpen) await props.onOpen();
    }
    return (
        <>
            <Button onClick={handleTermsClick}
                variant="contained"
                className={props.className + (props.animate ? Animations.heartbeat() : "")}
            >
                {L10N({ todo: "Terms and conditions" })}
            </Button><br />
        </>
    );
};

