import { ButtonGroup, Typography } from "@mui/material";
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { refreshHints, startSearch } from '../../store/search/search.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { ClassNowExternalURLQRCode } from "../details/ClassNowExternalURLQRCode";
import { AboutUsView } from "../marketing/AboutUsView";
import { BenefitsView } from '../marketing/BenefitsView';
import { MarketingLink } from "../marketing/MarketingLink";
import { VisionView } from "../marketing/VisionView";
import { SearchBar } from '../search/SearchBar';
import { SearchCategoryChips } from '../search/SearchCategoryChips';
import { SearchChips } from '../search/SearchChips';
import { SearchTable } from '../search/SearchTable';
import { CspButtonLink } from "../ui/CspButtonLink";
import { CspDivider } from "../ui/CspDivider";
import { EmiPaper } from "../ui/EmiPaper";
import { LogotypeSpans } from "../ui/LogotypeSpans";
import { ScreenSizeListener } from "../ui/ScreenSizeListener";
import { StyledTitleRow } from '../ui/StyledTitle';
import { L10N } from './L10N';
import { AppRoutes } from "./Routes";

export const SearchPage = () => {
    const dispatch = useAppDispatch();
    const { searchResults, hasSearched, inProgress: searchResultsInProgress } = useAppSelector((state: RootState) => state.search);

    useEffect(() => {
        dispatch(refreshHints({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (hasSearched || searchResultsInProgress) return;

        dispatch(startSearch({}));
    }, [dispatch, hasSearched, searchResultsInProgress]);

    return (
        <Container
            style={{
                overflowY: 'hidden',
                minHeight: '100vh',
            }}>
            <div id={AppRoutes.homeTopID} />

            <ScreenSizeListener threshold="xs"
                whenAboveThreshold={<>
                    <Row className="mt-5" style={{
                        height: '150px'
                    }}>  <Col xs={6} className="h-100" >
                            <div style={{
                                backgroundImage: `url(img/logo.png)`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPositionX: 'right',
                                height: '100%',
                            }} />
                        </Col>
                        <Col xs={6} className="h-100" >
                            <div style={{
                                backgroundImage: `url(img/logo-text.png)`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPositionX: 'left',
                                height: '100%',
                            }} />
                        </Col>
                    </Row>
                </>}
                whenThresholdOrBelow={<>
                    <Row className="mt-5" style={{
                        height: '20vh'
                    }}>    <Col xs={12} className="h-100" >
                            <div style={{
                                backgroundImage: `url(img/logo.png)`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPositionX: 'center',
                                height: '100%',
                            }} />
                        </Col>
                    </Row>
                    <StyledTitleRow hideDivider text={<>
                        Highest quality education with <LogotypeSpans />
                    </>} />
                </>}
            />
            <CspDivider className="mt-5" shadowTop />
            <ButtonGroup fullWidth className="my-2" variant="text">
                <CspButtonLink href={AppRoutes.aboutUsView}>About Us</CspButtonLink>
                <CspButtonLink href={AppRoutes.homeSearchBar}>Find Classes</CspButtonLink>
                <CspButtonLink href={AppRoutes.visionView}>Vision</CspButtonLink>
                <CspButtonLink href={AppRoutes.benefitsView}>Benefits</CspButtonLink>
                <CspButtonLink navigateHref={AppRoutes.contactus}>Contact</CspButtonLink>
            </ButtonGroup>
            <CspDivider className="mb-4" />
            <div id={AppRoutes.benefitsViewID} />
            <StyledTitleRow text="Benefits for Teachers and Students" />
            <BenefitsView />

            <EmiPaper className="mb-5">
                <div id={AppRoutes.aboutUsViewID} />
                <StyledTitleRow text={<>
                    About Us
                    <MarketingLink platform="fb" className="mx-1" />
                    <MarketingLink platform="yt" className="mx-1" />
                    <MarketingLink platform="in" className="mx-1" />
                </>} />
                <AboutUsView />
            </EmiPaper>
            <EmiPaper className="mb-5">
                <div id={AppRoutes.visionViewID} />
                <StyledTitleRow text="Vision" />
                <VisionView />
            </EmiPaper>

            <div id={AppRoutes.homeSearchBarID} />
            <StyledTitleRow text="Find your Class..." />
            <SearchCategoryChips />
            <Row className="mb-3">
                <SearchBar />
            </Row>
            <Row className="mb-3 ">
                <SearchChips />
            </Row>
            <StyledTitleRow text={<>
                ...and join <LogotypeSpans />
            </>} />
            {hasSearched && !searchResultsInProgress && <>
                {(searchResults?.results?.length ?? 0) === 0 && <>
                    <Typography variant='body1' color="secondary"
                        style={{ minHeight: 100 }}>
                        {L10N({ key: "No results found" })}
                    </Typography>
                </>}
            </>
            }
            <Row className="my-5">
                <SearchTable />
            </Row>
            <StyledTitleRow text={<>Share <LogotypeSpans /> with friends!</>} />
            <Row className="mb-5">
                <Col />
                <Col xs={12} md={6} lg={4}>
                    <ClassNowExternalURLQRCode />
                </Col>
                <Col />
            </Row>
        </Container>
    );
}
