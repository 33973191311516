import { configureStore } from '@reduxjs/toolkit';
import companyReducer, { CompanyState } from './company/company.reducer';
import detailsReducer, { DetailsState } from './details/details.reducer';
import editReducer, { EditState } from './edit/edit.reducer';
import inviteReducer, { InviteState } from './invite/invite.reducer';
import searchReducer, { SearchState } from './search/search.reducer';
import uiReducer, { UIState } from './ui/ui.reducer';
import userReducer, { UserState } from './user/user.reducer';
import visitReducer, { VisitState } from './visit/visit.reducer';
import { useDispatch, useSelector } from 'react-redux';
export const RESET_REDUX = 'RESET_REDUX';

export interface RootState {
    company: CompanyState,
    invite: InviteState,
    search: SearchState,
    user: UserState,
    ui: UIState,
    details: DetailsState,
    visit: VisitState,
    edit: EditState,
}
export const store = configureStore<RootState>({
    reducer: {
        company: companyReducer,
        invite: inviteReducer,
        search: searchReducer,
        user: userReducer,
        ui: uiReducer,
        details: detailsReducer,
        visit: visitReducer,
        edit: editReducer,
    },
});
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()