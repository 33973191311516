import { Button, IconButton, Paper, Typography } from "@mui/material";
import moment from 'moment';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { InviteCompanyService, InviteDuration, InvitePrice } from '../../api/models/Invite';
import { loadAllDetails } from '../../store/details/details.actions';
import { changeSelectedDimension } from '../../store/invite/invite.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { AppRoutes } from '../pages/Routes';
import { EmiPaper } from '../ui/EmiPaper';

export const ServiceSelector = (props: {
    hideIfOne?: boolean,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { currentInvite, serverInvite } = useAppSelector((state: RootState) => state.invite);

    function isSelected(m: InviteCompanyService | undefined): boolean {
        if (!m) return false;
        return m.dimensionValue === currentInvite?.companyServiceDimensionSelectedValue;
    }
    function onServiceClicked(m: InviteCompanyService) {
        dispatch(changeSelectedDimension({ dimensionValue: m, dimensionType: "service" }));
    }
    function findServiceDetails(serviceID: string): undefined | {
        service?: InviteCompanyService,
        price?: InvitePrice,
        duration?: InviteDuration
    } {
        if (!serverInvite) return undefined;
        const offer = serverInvite
            ?.offers
            ?.find((v, i, a) => v.s === serviceID);

        const price = serverInvite
            ?.priceDimension
            ?.find((v, i, a) => v.dimensionValue === offer?.p);
        const duration = serverInvite
            ?.durationDimension
            ?.find((v, i, a) => v.dimensionValue === offer?.du);
        const service = serverInvite
            ?.companyServiceDimension
            ?.find((v, i, a) => v.dimensionValue === offer?.s);

        return { price, duration, service };
    }
    async function infoClick(event: React.MouseEvent<any, MouseEvent>, serviceID: string) {
        await dispatch(loadAllDetails({
            companyID: undefined,
            serviceID,
            resourceID: undefined
        }));
        const route = AppRoutes.detailsTab('service', serviceID);
        navigate(route);
        event.stopPropagation();
        event.preventDefault();
    }
    function renderService(m: InviteCompanyService): JSX.Element {
        const details = findServiceDetails(m.serviceID);
        return (<>
            <Col className={"col-auto p-2"}>
                <Typography display="inline">
                    {details?.service?.displayName ?? m.displayName}
                </Typography>
                <IconButton onClick={async (event) => await infoClick(event, m.serviceID)} >
                    <img src="/img/icons/icon_info.svg" alt="info" />
                </IconButton>
                <br />
                <Typography variant="body2" display="inline" >
                    {details?.price?.dimensionValue}
                    &nbsp;
                    &nbsp;
                    &nbsp;
                </Typography>
                {details?.duration?.duration &&
                    <Typography variant="body2" display="inline">
                        {moment.duration(details?.duration?.duration ?? 0, "minutes").humanize()}
                    </Typography>
                }
            </Col>
            <Col className="p-0" lg={false} xl={false}>
            </Col>
            <Col className="col-auto p-2">
                <Button className={"px-4 align-middle"}
                    variant={isSelected(m) ? "contained" : "outlined"}
                    color={isSelected(m) ? "primary" : "inherit"}
                    size="small"
                    onClick={() => onServiceClicked(m)}>
                    Book
                </Button>
            </Col>
        </>);
    }
    return (<>
        <Col className="mt-3" >
            <Paper>
                {currentInvite?.companyServiceDimension?.map
                    && currentInvite?.companyServiceDimension
                    && (!props.hideIfOne || currentInvite?.companyServiceDimension?.length > 1)
                    && currentInvite?.companyServiceDimension?.map
                    && currentInvite?.companyServiceDimension?.map((m, i) =>
                        <EmiPaper key={i}
                            elevationOnNoMouse={0} elevationOnMouseOver={0}
                            classOnMouseOver="border border-primary"
                            classOnNoMouse="border border-white"
                        >
                            <Container>
                                <Row >
                                    {renderService(m)}
                                </Row>
                            </Container>
                        </EmiPaper>
                    )}
            </Paper>
        </Col>
    </>);
};
