import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateInviteMessage, Invite } from '../../api/models/Invite';
import { addInProgressCases, addInProgressMatcher } from '../reduxHelpers';
import { changeInvite, changeSelectedDimension, clearSelectedDimensions, clearVisitIDFromCurrentInvite } from './invite.actions';

export interface InviteState {
    createInviteMessage?: CreateInviteMessage;
    inProgress: boolean;
    serverInvite?: Invite;
    currentInvite?: Invite;
}
const initialState: InviteState = {
    createInviteMessage: undefined,
    inProgress: false,
    serverInvite: undefined,
    currentInvite: undefined,
}

const inviteSlice = createSlice({
    name: 'inviteSlice',
    initialState,
    reducers: {
        setCurrentInviteMessage(state, action: PayloadAction<CreateInviteMessage>) {
            state.createInviteMessage = action.payload;
            state.serverInvite = undefined;
            state.currentInvite = undefined;
        },
    },
    extraReducers: (builder) => {
        addInProgressCases(builder, changeInvite);
        addInProgressCases(builder, changeSelectedDimension);
        addInProgressCases(builder, clearSelectedDimensions);
        addInProgressCases(builder, clearVisitIDFromCurrentInvite);

        builder.addCase(changeInvite.fulfilled, (state, action) => {
            state.createInviteMessage = action.payload.createInviteMessage;
            state.currentInvite = action.payload.currentInvite;
            state.serverInvite = action.payload.serverInvite;
        })
        builder.addCase(changeSelectedDimension.fulfilled, (state, action) => {
            state.currentInvite = action.payload?.currentInvite;
        })
        builder.addCase(clearSelectedDimensions.fulfilled, (state, action) => {
            state.currentInvite = action.payload?.currentInvite;
        })
        builder.addCase(clearVisitIDFromCurrentInvite.fulfilled, (state, action) => {
            state.currentInvite = action.payload?.currentInvite;
        })
        addInProgressMatcher(builder, changeInvite);
        addInProgressMatcher(builder, changeSelectedDimension);
        addInProgressMatcher(builder, clearSelectedDimensions);
        addInProgressMatcher(builder, clearVisitIDFromCurrentInvite);
    },
});
export const { setCurrentInviteMessage } = inviteSlice.actions
export default inviteSlice.reducer