import { CardProps, LinearProgress } from "@mui/material";
import { InviteCompanyService } from "src/api/models/Invite";
import { RootState, useAppSelector } from '../../store/store';
import { ResourceSelector } from './ResourceSelector';
import { ServiceSelector } from './ServiceSelector';
import { InviteSummary } from './Summary';
import { TeacherWizardInviteCard } from "./TeacherWizardInviteCard";
import { WhatTimeSelector } from './WhatTimeSelector';
import { WhenSelector } from './WhenSelector';

export const InviteCard = (props: CardProps & {
    showText?: boolean,
    showMenu?: boolean,
    buttonTextNotStarted?: JSX.Element,
    buttonTextInProgress?: JSX.Element,
    buttonTextCompleted?: JSX.Element,
}) => {
    const { inProgress: inviteInProgress, currentInvite } = useAppSelector((state: RootState) => state.invite);
    function currentService(): InviteCompanyService | undefined {
        if (!currentInvite) return;
        const result = currentInvite
            ?.companyServiceDimension
            ?.find((v, i, a) => v.dimensionValue === currentInvite?.companyServiceDimensionSelectedValue);
        return result;
    }
    const service = currentService();
    return (
        <>
            {service?.classType === "TeacherWizard"
                ? <TeacherWizardInviteCard />
                : <>
                    <ServiceSelector hideIfOne />
                    <ResourceSelector hideIfOne />
                    {inviteInProgress &&
                        <LinearProgress className="w-100 " color="secondary" />
                    }
                    <WhenSelector />
                    <WhatTimeSelector />
                    {/* <VisitNoteEditor /> */}
                    <InviteSummary />
                </>
            }
        </>
    );
};
