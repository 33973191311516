import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { Calendar, CreateCalendarMessage } from "../models/Company";

export class CalendarEndpoint {
    async getCompanyCalendar(message: CreateCalendarMessage): Promise<ApiResponse<Calendar>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/calendar/company-calendar`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as Calendar,
        });
        return apiResponse;
    }
    async getResourceCalendar(message: CreateCalendarMessage): Promise<ApiResponse<Calendar>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/calendar/resource-calendar`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as Calendar,
        });
        return apiResponse;
    }
}