import { Animations } from './Animations';

export function CspDivider(props: {
    shadowTop?: boolean,
    noAnimation?: boolean,
    className?: string,
}) {

    return <div className={props.className + " bg-gradient-classnow "
        + (props.noAnimation ? ""
            : (
                Animations.slide_in_left()
                + Animations.delay(16)
            ))}
        style={{
            zIndex: 4,
            position: 'relative',
            height: '2px',
            width: '100%',
            boxShadow: props.shadowTop
                ? 'darkgray 0px -2px 6px'
                : '3px 3px 3px silver',
        }} />
}
