import { Typography } from "@mui/material";
import { Col, Row } from 'react-bootstrap';
import { RootState, useAppSelector } from '../../store/store';
import { L10N } from '../pages/L10N';
import { CspImageList } from "../ui/CspImageList";

export const CompanyDetailsViewTabPortfolio = (props: {
}) => {
    const { company } = useAppSelector((state: RootState) => state.details);

    return (<>
        {(company?.portfolio?.length ?? 0) === 0 && <>
            <Row className="mt-5">
                <Col />
                <Col className="col-auto">
                    <img src={`/img/icons/notebook.png`} alt="heart" width="150" />
                </Col>
                <Col />
            </Row>
            <Row className="mb-3">
                <Col>
                    <Typography align="center">
                        {L10N({ key: "This person has no portoflio" })}
                    </Typography>
                </Col>
            </Row>
        </>}
        <CspImageList photos={company?.portfolio} moreColumns />
    </>);
};
