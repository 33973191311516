import { IconButton } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useState } from 'react';

const useStyles = makeStyles((theme) =>
    createStyles({
        clickable: {
            cursor: 'pointer',
            outline: 'none !important',
        },
    })
);
export const EmiRating = (props: {
    value?: number,
    className?: string,
    big?: boolean,
    onValueChange?: (value: number) => void,
}) => {
    const [hovered, setHovered] = useState<number | undefined>(undefined);
    const classes = useStyles();

    function icon(i: number): string {
        let val = props.value ?? 0;
        if (hovered
            && hovered !== val
            && hovered !== val + 0.5
        ) {
            val = hovered;
        }

        const src = i - 0.5 === val
            ? `/img/icons/icon_star_half.svg`
            : i <= val
                ? `/img/icons/icon_star_full.svg`
                : `/img/icons/icon_star_empty.svg`;
        return src;
    }
    function onClick(value: number) {
        if (props.onValueChange) {
            if (props.value === value) {
                props.onValueChange(value - 0.5);
            } else {
                props.onValueChange(value);
            }
        }
    }
    const size = props.big ? 48 : 16;
    const className = "mr-1 p-0 " + (props.onValueChange ? classes.clickable : "");
    function renderImg(i: number) {
        return (<IconButton className={className}
            disabled={!props.onValueChange}
            onClick={() => onClick(i)}
            onMouseOver={() => setHovered(i)}>
            <img alt="star"
                src={icon(i)}
                height={size}
                width={size}
            />
        </IconButton >
        );
    }
    return (<div
        onMouseLeave={() => setHovered(undefined)}
        className={props.className}>
        {renderImg(1)}
        {renderImg(2)}
        {renderImg(3)}
        {renderImg(4)}
        {renderImg(5)}
    </div>);
};
