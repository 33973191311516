import { CircularProgress, Step, StepButton, Stepper, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { api } from 'src/api/api';
import { CreateNewCompanyWizard, TeacherWizardEditModel, TeacherWizardEditModelValidationSchema } from 'src/api/models/TeacherWizard';
import { RootState, useAppSelector } from 'src/store/store';
import { getAllTeacherWizardSteps } from '../teacherWizard/Common';
import { FormState, FormTeacherWizard } from '../teacherWizard/FormTeacherWizard';
import { PreviewDrawer, PreviewDrawerState } from '../teacherWizard/PreviewDrawer';
import { TeacherWizardAppBar, TeacherWizardAppBarState } from '../teacherWizard/TeacherWizardAppBar';
import { CspCountdownDialog } from '../ui/CspCountdownDialog';
import { LogotypeSpans } from "../ui/LogotypeSpans";
import { ScreenSizeListener } from '../ui/ScreenSizeListener';
import { StyledTitleRow } from '../ui/StyledTitle';
import { PageHeader } from './PageHeader';
import { AppRoutes } from './Routes';

export const TeacherWizardPage = () => {
    const navigate = useNavigate();
    const { user, inProgress } = useAppSelector((state: RootState) => state.user);
    const urlParams = useParams<{ userID: string }>();

    const [teacherWizard, setTeacherWizard] = useState<{
        model?: TeacherWizardEditModel,
        inProgress?: boolean,
        shouldRedirectToHome?: boolean,
    }>({
        model: undefined,
        inProgress: false,
        shouldRedirectToHome: false,
    });

    useEffect(() => {
        if (inProgress) return;
        if (!user) return;

        initializeFromApi();

    }, [inProgress, user]);

    async function initializeFromApi() {
        if (!user) return;
        setTeacherWizard({
            model: undefined,
            inProgress: true,
        });
        const userID = urlParams.userID && urlParams.userID !== AppRoutes.defaultUrlParam
            ? urlParams.userID
            : user.userID;
        const apiResponse = await api.TeacherWizard.getForm({
            accessToken: user.accessToken,
            userID: userID
        });
        if (apiResponse.error) {
            console.log(apiResponse.error);
        }
        if (apiResponse.result) {
            apiResponse.result.stage ??= "initial";
        }

        setTeacherWizard({
            model: apiResponse.result,
            inProgress: false,
        });
    }
    async function saveThroughApi(values: TeacherWizardEditModel) {
        setTeacherWizard((prev) => ({
            ...prev,
            inProgress: true,
            shouldRedirectToHome: false,
        }));

        values.accessToken = user?.accessToken ?? "";
        const beforeSaveCondition = values.stage === 'initial';
        const apiResponse = await api.TeacherWizard.saveForm(values);
        const afterSaveCondition = apiResponse.result?.stage === 'initial-submitted';

        if (apiResponse.error) {
            console.log(apiResponse.error);
        }
        setTeacherWizard({
            model: apiResponse.result,
            inProgress: false,
            shouldRedirectToHome: beforeSaveCondition && afterSaveCondition,
        });
    }
    const steps = getAllTeacherWizardSteps("initial");
    const [state, setState] = React.useState<
        {
            form: FormState,
            previewDrawer: PreviewDrawerState,
            appBar: TeacherWizardAppBarState,
        }
    >({
        form: {
            activeStep: 0,
            activeStepValue: steps[0],
        },
        previewDrawer: {
            isOpen: false,
            autoPreviewWasTriggered: false,
            wasClosed: false,
        },
        appBar: {
            backDisabled: true,
            nextDisabled: false,
            previewDisabled: true,
        }
    })

    const formik = useFormik({
        initialValues: teacherWizard.model ?? CreateNewCompanyWizard(),
        enableReinitialize: true,
        validationSchema: TeacherWizardEditModelValidationSchema,
        onSubmit: async (values, helpers) => {
            console.log("formikSubmit");
            await saveThroughApi(values);
            if (teacherWizard.model) {
                helpers.setValues(teacherWizard.model);
            }
        },
    });
    function handleStep(step: number, unhide?: boolean) {
        const stepValue = steps[step];

        setState(prev => ({
            ...prev,
            form: {
                ...prev.form,
                activeStep: step,
                activeStepValue: stepValue,
            },
            appBar: {
                ...prev.appBar,
                backDisabled: step === 0,
                nextDisabled: step === steps.length - 1,
            }
        }));
    };
    async function handleHome() {
        await navigate(AppRoutes.home);
    }
    async function handleNext() {
        const step = (state.form.activeStep + 1) % steps.length;
        handleStep(step);
    };
    async function handleBack() {
        const step = (state.form.activeStep - 1) % steps.length;
        handleStep(step);
    };
    async function handleSave() {
        formik.submitForm();
    };
    async function handlePreview() {
        setState(prev => ({
            ...prev,
            previewDrawer: {
                ...prev.previewDrawer,
                isOpen: !prev.previewDrawer.isOpen,
                wasClosed: prev.previewDrawer.wasClosed || prev.previewDrawer.isOpen,
            }
        }));
    };
    function handleClosePreview() {
        setState(prev => ({
            ...prev,
            appBar: {
                ...prev.appBar,
                previewDisabled: false,
            },
            previewDrawer: {
                ...prev.previewDrawer,
                isOpen: false,
                wasClosed: true,
            }
        }));
    };
    function handleAutoTriggerPreview() {
        setState(prev => ({
            ...prev,
            previewDrawer: {
                ...prev.previewDrawer,
                isOpen: true,
                autoPreviewWasTriggered: true,
            }
        }));
    };
    const showTitleThreshold = "xs";
    return (<>
        {!inProgress && !(user?.clientAccessGranted ?? false) && <Navigate replace to={AppRoutes.loginAndReturn(AppRoutes.teacherWizardPage())} />}
        {!teacherWizard.inProgress && teacherWizard.shouldRedirectToHome &&
            <CspCountdownDialog seconds={3}
                title="Congratulations"
                subtitle="we will contact you soon..."
            >
                <>
                    <Navigate replace to={AppRoutes.home} />
                </>
            </CspCountdownDialog>
        }
        <Container
            style={{
                overflowY: 'hidden',
                minHeight: '100vh',
            }}>
            <PageHeader title="" showLogo />
            <StyledTitleRow text={<>Let's create your <LogotypeSpans /> profile</>} className='mb-3' />
            <Row>
                <Col>
                    <Stepper alternativeLabel nonLinear
                        activeStep={state.form.activeStep} >
                        {steps.map((m, index) => (
                            <Step key={m}>
                                <StepButton onClick={() => handleStep(index)} >
                                    <ScreenSizeListener threshold={showTitleThreshold}
                                        whenAboveThreshold={<>
                                            <Typography variant="body2">
                                                {m}
                                            </Typography>
                                        </>}
                                        whenThresholdOrBelow={""}
                                    />
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Col>
            </Row>
            {teacherWizard
                ? <>
                    <FormTeacherWizard formik={formik}
                        state={state.form}
                    />
                </>
                : <>
                    <CircularProgress />
                </>
            }
            <PreviewDrawer formik={formik}
                state={state.previewDrawer}
                onClose={handleClosePreview}
                onAutoPreviewWasTriggered={handleAutoTriggerPreview}
            />
            <TeacherWizardAppBar formik={formik}
                state={state.appBar}
                onHome={handleHome}
                onBack={handleBack}
                onNext={handleNext}
                onPreview={handlePreview}
                onSave={handleSave}
            />
        </Container >
    </>);
}
