import { Accordion, AccordionDetails, AccordionSummary, Button, TextField, Typography } from "@mui/material";
import { isMobile } from 'react-device-detect';
import { CompanyEditModel } from '../../api/models/Edit';
import { L10N } from '../pages/L10N';
import { CspIcon } from "../ui/CspIcon";
import { useFormikResult } from '../ui/FormikAPI';
import { TestRazorpayButton } from '../ui/RazorpayPayments';

export const FieldSetRazorpayApiCredentials = (props: {
    formik: useFormikResult<CompanyEditModel>,
}) => {
    function onGoToRazorpay() {
        const url = "https://razorpay.me/@aviontourskochi";
        if (isMobile) {
            window.location.href = url;
        } else {
            window.open(url, '_blank')?.focus();
        }
    }
    return <>
        <Accordion defaultExpanded={false} >
            <AccordionSummary className="text-black text-weight-bold2">
                {L10N({ key: "Razorpay Payments" })}
                <Button onClick={onGoToRazorpay} variant="contained" color="primary"
                    className="ml-auto">
                    <CspIcon name="ExitToApp" />
                </Button>
            </AccordionSummary>
            <AccordionDetails className="d-block">
                <Typography variant="body1">
                    {L10N({ key: "Razorpay Intro Text" })}
                </Typography>
                <br />
                <TextField variant="outlined" className="mb-3 text-black" fullWidth
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    id="razorpayApiCredentials.apiKey" name="razorpayApiCredentials.apiKey"
                    label={L10N({ key: "Razorpay Api Key" })}
                    value={props.formik.values.razorpayApiCredentials?.apiKey ?? ""}
                    onChange={props.formik.handleChange}
                /><br />
                <TextField variant="outlined" className="mb-3 text-black" fullWidth
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    id="razorpayApiCredentials.secret" name="razorpayApiCredentials.secret"
                    label={L10N({ key: "Razorpay secret" })}
                    value={props.formik.values.razorpayApiCredentials?.secret ?? ""}
                    onChange={props.formik.handleChange}
                /><br />
                <TestRazorpayButton apiCredentials={props.formik.values.razorpayApiCredentials} />
            </AccordionDetails>
        </Accordion>

    </>;
}