import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AddIcon from '@mui/icons-material/Add';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import CasinoOutlinedIcon from '@mui/icons-material/CasinoOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import DepartureBoardOutlinedIcon from '@mui/icons-material/DepartureBoardOutlined';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import ExitToApp from '@mui/icons-material/ExitToApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import MobileFriendlyOutlinedIcon from '@mui/icons-material/MobileFriendlyOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotInterested from '@mui/icons-material/NotInterested';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PreviewIcon from '@mui/icons-material/Preview';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RecommendOutlinedIcon from '@mui/icons-material/RecommendOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutlined';
import ReviewsIcon from '@mui/icons-material/Reviews';
import Rotate90DegreesCwOutlinedIcon from '@mui/icons-material/Rotate90DegreesCwOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from '@mui/icons-material/Speed';
import StarRateOutlinedIcon from '@mui/icons-material/StarRateOutlined';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import WhereToVoteOutlinedIcon from '@mui/icons-material/WhereToVoteOutlined';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

export const NewIconPlaceholder = (props: any) => {
    return <></>;
}
export const CspIcon = (props: {
    name: undefined
    | "AccessTimeOutlinedIcon"
    | "AddAlarmIcon"
    | "AddCircleIcon"
    | "AddCircleOutlineIcon"
    | "AddIcon"
    | "AdminPanelSettingsOutlinedIcon"
    | "AppRegistrationOutlinedIcon"
    | "AssignmentIndIcon"
    | "CameraAltOutlinedIcon"
    | "CasinoOutlinedIcon"
    | "CheckCircleOutlineIcon"
    | "ChevronLeftOutlinedIcon"
    | "ChevronRightOutlinedIcon"
    | "ClearIcon"
    | "CloseIcon"
    | "CloudDoneOutlinedIcon"
    | "ContactSupportOutlinedIcon"
    | "CreditScoreOutlinedIcon"
    | "CurrencyRupeeOutlinedIcon"
    | "DepartureBoardOutlinedIcon"
    | "DoneAllIcon"
    | "DoneIcon"
    | "EditIcon"
    | "EventAvailableOutlinedIcon"
    | "EventIcon"
    | "ExitToApp"
    | "ExpandMoreIcon"
    | "FavoriteBorderOutlinedIcon"
    | "FileCopyIcon"
    | "FingerprintOutlinedIcon"
    | "FlagPL"
    | "FlagIN"
    | "FlightTakeoffIcon"
    | "HandshakeOutlinedIcon"
    | "HelpOutlineIcon"
    | "HighlightOffIcon"
    | "HourglassEmptyIcon"
    | "HowToRegOutlinedIcon"
    | "InfoOutlinedIcon"
    | "InsertEmoticonOutlinedIcon"
    | "LocationOnOutlinedIcon"
    | "LockIcon"
    | "LockOpenIcon"
    | "LoginOutlinedIcon"
    | "LogoutIcon"
    | "MobileFriendlyOutlinedIcon"
    | "MoreVertIcon"
    | "NotInterested"
    | "PeopleOutlinedIcon"
    | "Earth"
    | "PreviewIcon"
    | "PublicOutlinedIcon"
    | "QueryStatsIcon"
    | "RecommendOutlinedIcon"
    | "RefreshIcon"
    | "RefreshOutlinedIcon"
    | "RemoveCircleOutlineIcon"
    | "ReviewsIcon"
    | "Rotate90DegreesCwOutlinedIcon"
    | "SchoolOutlinedIcon"
    | "SearchIcon"
    | "SecurityIcon"
    | "SpeedIcon"
    | "StarRateOutlinedIcon"
    | "TimelapseIcon"
    | "TouchAppOutlinedIcon"
    | "VerifiedUserOutlinedIcon"
    | "VerticalAlignCenterIcon"
    | "WhereToVoteOutlinedIcon"
    | "WorkspacePremiumIcon"
    | "ZoomInIcon"
    | "ZoomOutIcon"
    | "NewIconPlaceholder"
    //Custom names
    | "Home",
    color?: 'primary' | 'secondary' | 'disabled',
    fontSize?: 'inherit' | 'medium' | 'small' | 'large',
    className?: string,
}) => {
    const { name, ...iconProps } = props;
    switch (props.name) {
        case "AccessTimeOutlinedIcon": return <AccessTimeOutlinedIcon {...iconProps} />
        case "AddAlarmIcon": return <AddAlarmIcon {...iconProps} />
        case "AddCircleIcon": return <AddCircleIcon {...iconProps} />
        case "AddCircleOutlineIcon": return <AddCircleOutlineIcon {...iconProps} />
        case "AddIcon": return <AddIcon {...iconProps} />
        case "AdminPanelSettingsOutlinedIcon": return <AdminPanelSettingsOutlinedIcon {...iconProps} />
        case "AppRegistrationOutlinedIcon": return <AppRegistrationOutlinedIcon {...iconProps} />
        case "AssignmentIndIcon": return <AssignmentIndIcon {...iconProps} />
        case "CameraAltOutlinedIcon": return <CameraAltOutlinedIcon {...iconProps} />
        case "CasinoOutlinedIcon": return <CasinoOutlinedIcon {...iconProps} />
        case "CheckCircleOutlineIcon": return <CheckCircleOutlineIcon {...iconProps} />
        case "ChevronLeftOutlinedIcon": return <ChevronLeftOutlinedIcon {...iconProps} />
        case "ChevronRightOutlinedIcon": return <ChevronRightOutlinedIcon {...iconProps} />
        case "ClearIcon": return <ClearIcon {...iconProps} />
        case "CloseIcon": return <CloseIcon {...iconProps} />
        case "CloudDoneOutlinedIcon": return <CloudDoneOutlinedIcon {...iconProps} />
        case "ContactSupportOutlinedIcon": return <ContactSupportOutlinedIcon {...iconProps} />
        case "CreditScoreOutlinedIcon": return <CreditScoreOutlinedIcon {...iconProps} />
        case "CurrencyRupeeOutlinedIcon": return <CurrencyRupeeOutlinedIcon {...iconProps} />
        case "DepartureBoardOutlinedIcon": return <DepartureBoardOutlinedIcon {...iconProps} />
        case "DoneAllIcon": return <DoneAllIcon {...iconProps} />
        case "DoneIcon": return <DoneIcon {...iconProps} />
        case "EditIcon": return <EditIcon {...iconProps} />
        case "EventAvailableOutlinedIcon": return <EventAvailableOutlinedIcon {...iconProps} />
        case "EventIcon": return <EventIcon {...iconProps} />
        case "ExitToApp": return <ExitToApp {...iconProps} />
        case "ExpandMoreIcon": return <ExpandMoreIcon {...iconProps} />
        case "FavoriteBorderOutlinedIcon": return <FavoriteBorderOutlinedIcon {...iconProps} />
        case "FingerprintOutlinedIcon": return <FingerprintOutlinedIcon {...iconProps} />
        case "FlagPL": return <img src="/img/flags/PL.png" {...iconProps} width="32" />
        case "FlagIN": return <img src="/img/flags/IN.png" {...iconProps} width="32" />
        case "FlightTakeoffIcon": return <FlightTakeoffIcon {...iconProps} />
        case "HandshakeOutlinedIcon": return <HandshakeOutlinedIcon {...iconProps} />
        case "FileCopyIcon": return <FileCopyIcon {...iconProps} />
        case "HelpOutlineIcon": return <HelpOutlineIcon {...iconProps} />
        case "HighlightOffIcon": return <HighlightOffIcon {...iconProps} />
        case "HourglassEmptyIcon": return <HourglassEmptyIcon {...iconProps} />
        case "HowToRegOutlinedIcon": return <HowToRegOutlinedIcon {...iconProps} />
        case "InfoOutlinedIcon": return <InfoOutlinedIcon {...iconProps} />
        case "InsertEmoticonOutlinedIcon": return <InsertEmoticonOutlinedIcon {...iconProps} />
        case "LocationOnOutlinedIcon": return <LocationOnOutlinedIcon {...iconProps} />
        case "LockIcon": return <LockIcon {...iconProps} />
        case "LockOpenIcon": return <LockOpenIcon {...iconProps} />
        case "LoginOutlinedIcon": return <LoginOutlinedIcon {...iconProps} />
        case "LogoutIcon": return <LogoutIcon {...iconProps} />
        case "MobileFriendlyOutlinedIcon": return <MobileFriendlyOutlinedIcon {...iconProps} />
        case "MoreVertIcon": return <MoreVertIcon {...iconProps} />
        case "NotInterested": return <NotInterested {...iconProps} />
        case "PeopleOutlinedIcon": return <PeopleOutlinedIcon {...iconProps} />
        case "PreviewIcon": return <PreviewIcon {...iconProps} />
        case "Earth": return <PublicOutlinedIcon {...iconProps} />
        case "PublicOutlinedIcon": return <PublicOutlinedIcon {...iconProps} />
        case "QueryStatsIcon": return <QueryStatsIcon {...iconProps} />
        case "RecommendOutlinedIcon": return <RecommendOutlinedIcon {...iconProps} />
        case "RefreshIcon": return <RefreshIcon {...iconProps} />
        case "RefreshOutlinedIcon": return <RefreshOutlinedIcon {...iconProps} />
        case "RemoveCircleOutlineIcon": return <RemoveCircleOutlineIcon {...iconProps} />
        case "ReviewsIcon": return <ReviewsIcon {...iconProps} />
        case "Rotate90DegreesCwOutlinedIcon": return <Rotate90DegreesCwOutlinedIcon {...iconProps} />
        case "Home":
        case "SchoolOutlinedIcon": return <SchoolOutlinedIcon {...iconProps} />
        case "SearchIcon": return <SearchIcon {...iconProps} />
        case "SecurityIcon": return <SecurityIcon {...iconProps} />
        case "SpeedIcon": return <SpeedIcon {...iconProps} />
        case "StarRateOutlinedIcon": return <StarRateOutlinedIcon {...iconProps} />
        case "TimelapseIcon": return <TimelapseIcon {...iconProps} />
        case "TouchAppOutlinedIcon": return <TouchAppOutlinedIcon {...iconProps} />
        case "VerifiedUserOutlinedIcon": return <VerifiedUserOutlinedIcon {...iconProps} />
        case "VerticalAlignCenterIcon": return <VerticalAlignCenterIcon {...iconProps} />
        case "WhereToVoteOutlinedIcon": return <WhereToVoteOutlinedIcon {...iconProps} />
        case "WorkspacePremiumIcon": return <WorkspacePremiumIcon {...iconProps} />
        case "ZoomInIcon": return <ZoomInIcon {...iconProps} />
        case "ZoomOutIcon": return <ZoomOutIcon {...iconProps} />
        case "NewIconPlaceholder": return <NewIconPlaceholder {...iconProps} />
        default: return <ContactSupportOutlinedIcon {...iconProps} />
    }
}
