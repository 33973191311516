import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api/api';
import { VisitReviewDetails, VisitReviewUpdateMessage } from '../../api/models/Details';
import { AccessTokenBearer } from '../../api/models/Global';
import { PurchaseRequest } from '../../api/models/Purchase';
import { CreateVisitMessage, UpdateVisitMessage } from '../../api/models/Visit';
import { loadVisitAndAllDetails } from '../details/details.actions';
import { RootState } from '../store';
import { beforeCreateVisit } from './visit.reducer';

export type CreateVisitResponse = {
    shouldRedirectToVisitConfirmed?: boolean,
    shouldRedirectToPayment?: boolean,
    visitID?: string,
    paymentLink?: string,
    paymentQRCodeUrl?: string,
    validationMessage?: string,
};

export const createVisit = createAsyncThunk(
    'thunk/createVisit',
    async (args: {
        message: CreateVisitMessage
    }, thunkAPI) => {
        await thunkAPI.dispatch(beforeCreateVisit(args.message))
        const apiResponse = await api.Visit.createVisit(args.message);
        const createVisitResponse: CreateVisitResponse = {
            visitID: apiResponse.result?.visitID,
            validationMessage: apiResponse.error?.validation?.message,
        };
        if (apiResponse.error?.type === "validation") {
            createVisitResponse.shouldRedirectToVisitConfirmed = false;
            createVisitResponse.shouldRedirectToPayment = false;

        } else if (apiResponse.result?.paymentUpfront) {
            createVisitResponse.shouldRedirectToVisitConfirmed = false;
            createVisitResponse.shouldRedirectToPayment = true;
        } else {
            createVisitResponse.shouldRedirectToVisitConfirmed = true;
            createVisitResponse.shouldRedirectToPayment = false;
        }

        if (apiResponse.result) {
            await thunkAPI.dispatch(loadVisitAndAllDetails({
                message: args.message,
                visitID: apiResponse.result.visitID,
            }));
        }
        return { createVisitResponse: createVisitResponse };
    },
)
export const updateVisit = createAsyncThunk(
    'thunk/updateVisit',
    async (args: {
        message: UpdateVisitMessage
    }, thunkAPI) => {
        const apiResponse = await api.Visit.updateVisit(args.message);

        if (apiResponse.error) throw apiResponse.error;

        if (apiResponse.result) {
            await thunkAPI.dispatch(loadVisitAndAllDetails({
                message: args.message,
                visitID: apiResponse.result.visitID,
            }));
        }
        return { currentVisit: apiResponse.result };
    },
)

export const payForVisit = createAsyncThunk(
    'thunk/payForVisit',
    async (args: {
        message: PurchaseRequest
    }, thunkAPI) => {
        const apiResponse = await api.Purchase.postPurchase(args.message);

        if (apiResponse.error) throw apiResponse.error;
        return { purchaseResponse: apiResponse.result };
    },
)
export const loadAllVisits = createAsyncThunk(
    'thunk/loadAllVisits',
    async (args: {
        message: AccessTokenBearer
    }, thunkAPI) => {
        const apiResponse = await api.Visit.getAllVisits(args.message);

        if (apiResponse.error) throw apiResponse.error;
        return {
            allVisits: apiResponse.result,
            completedVisits: apiResponse.result?.filter((v, i, a) => v.visitCompleted),
            notCompletedVisits: apiResponse.result?.filter((v, i, a) => !v.visitCompleted),
        }
    },
)

export const loadAllVisitsAndReviews = createAsyncThunk(
    'thunk/loadAllVisitsAndReviews',
    async (args: {
        message: AccessTokenBearer
    }, thunkAPI) => {
        const apiResponseVisits = await api.Visit.getAllVisits(args.message);
        const apiResponseReviews = await api.Visit.getAllReviews(args.message);

        const error = apiResponseVisits.error ?? apiResponseReviews.error;
        if (error) throw error;
        return {
            allVisits: apiResponseVisits.result,
            completedVisits: apiResponseVisits.result?.filter((v, i, a) => v.visitCompleted),
            notCompletedVisits: apiResponseVisits.result?.filter((v, i, a) => !v.visitCompleted),
            completedReviews: apiResponseReviews.result,
            notCompletedVisitReviews: apiResponseVisits.result?.filter((v, i, a) => v.visitCompleted && !v.visitReviewID),
        }
    },
)

export const selectReviewOrCreate = createAsyncThunk(
    'thunk/selectReviewOrCreate',
    async (args: {
        message: AccessTokenBearer,
        visitID?: string,
        visitReviewID?: string,
    }, thunkAPI) => {
        await thunkAPI.dispatch(loadAllVisitsAndReviews({ message: args.message }));
        const visitID = args.visitID;
        const visitReviewID = args.visitReviewID;

        const state = (thunkAPI.getState() as RootState).visit;
        const currentVisit = visitID ? state.completedVisits?.find(v => v.visitID === visitID) : undefined;
        const currentReviewByID = visitReviewID ? state.completedReviews?.find(v => v.visitReviewID === visitReviewID) : undefined;
        const currentReviewByVisitID = visitID ? state.completedReviews?.find(v => v.visitID === visitID) : undefined;
        const currentReview: VisitReviewDetails = currentReviewByID
            ?? currentReviewByVisitID
            ??
        {
            visitID: visitID,
            visitReviewID: visitReviewID,
            photos: currentVisit?.photos,
            ratingScore: 0,
            reviewTitle: "",
            reviewDetails: "",
            isCompanyReview: false,
            reviewAuthor: "",
            reviewDate: "",
            userID: "",
        };

        return {
            currentReview: currentReview,
        }
    },
)
export const updateReview = createAsyncThunk(
    'thunk/updateReview',
    async (args: {
        message: VisitReviewUpdateMessage
    }, thunkAPI) => {
        const apiResponse = await api.Visit.updateUserReview(args.message);
        if (apiResponse.error) throw apiResponse.error;

        return {
            currentReview: apiResponse.result,
        }
    },
)