import { Button, ButtonProps } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const CspButtonLink = (props: ButtonProps & {
    navigateHref?: string;
}) => {
    const navigate = useNavigate();
    const { navigateHref, ...buttonProps } = props;

    async function handleClick() {
        if (!navigateHref) return;

        await navigate(navigateHref);
    }
    return (
        <Button {...buttonProps} onClick={handleClick}>
            {props.children}
        </Button>
    );
};
