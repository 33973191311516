import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { CompanyDetails, ResourceDetails, ServiceDetails, UpdateCompanyMessage } from "../models/Details";
import { AccessTokenBearer } from "../models/Global";

export class DetailsEndpoint {
    async getCompany(id: string): Promise<ApiResponse<CompanyDetails>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/details/company-details?id=${id}`,
            method: "GET",
            resultJsonTransformer: (json) => json as CompanyDetails,
        });
        return apiResponse;
    }
    async getCompanyList(message: AccessTokenBearer): Promise<ApiResponse<CompanyDetails[]>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/details/company-list`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as CompanyDetails[],
        });
        return apiResponse;
    }
    async getService(id: string): Promise<ApiResponse<ServiceDetails>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/details/service-details?id=${id}`,
            method: "GET",
            resultJsonTransformer: (json) => json as ServiceDetails,
        });
        return apiResponse;
    }
    async getResource(id: string): Promise<ApiResponse<ResourceDetails>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/details/resource-details?id=${id}`,
            method: "GET",
            resultJsonTransformer: (json) => json as ResourceDetails,
        });
        return apiResponse;
    }

    async updateCompanyDetails(message: UpdateCompanyMessage): Promise<ApiResponse<CompanyDetails>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/details/update-company`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as CompanyDetails,
        });
        return apiResponse;
    }
}