import { L10N } from 'src/components/pages/L10N';
import * as yup from 'yup';
import { PhoneValidationSchema, PinValidationSchema } from "./Validators";

export type LOGIN_STATE_ENUM =
    "phone"
    | "pin"
    | "details"
    | "loggedin";

export interface PhoneAndCountry {
    phoneCountry: string;
    tel: string;//must be "tel" for autocomplete to work without hacks
}
export interface LoginPageModel extends PhoneAndCountry {
    pin: string;
    displayName: string;
    consentViewed: boolean;
    consent: boolean;
}
export const LoginPageModelValidationSchema = yup
    .object<LoginPageModel>()
    .shape({
        phoneCountry: yup.string().defined(),
        tel: PhoneValidationSchema.defined(),
        pin: PinValidationSchema.defined(""),
        displayName: yup.string().min(3).defined("Please provide"),
        consentViewed: yup.boolean()
            .oneOf([true], L10N({ todo: 'Please view the consent page' }))
            .required(L10N({ todo: 'Please view the consent page' })),
        consent: yup.boolean()
            .oneOf([true], L10N({ key: 'Your consent is required' }))
            .required(L10N({ key: 'Your consent is required' })),
    });
export function CreateLoginPageModel(): LoginPageModel {
    return {
        phoneCountry: "+91",
        tel: "",
        pin: "",
        displayName: "",
        consentViewed: false,
        consent: false,
    }
}