import { Accordion, AccordionDetails, AccordionSummary, Checkbox, CircularProgress, FormControlLabel, InputAdornment, MenuItem, Switch, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { getIn } from 'formik';
import moment from 'moment';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { api } from '../../api/api';
import { AllClassTypes, PhotoEditModel, ServiceEditModel } from '../../api/models/Edit';
import { SearchCategory_Anything, SearchCategoryValues } from '../../api/models/Search';
import { refreshHints } from '../../store/search/search.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { L10N } from '../pages/L10N';
import { AvatarUpload } from '../ui/AvatarUpload';
import { CategoryIcon } from '../ui/CategoryIcon';
import { EmiPaper } from '../ui/EmiPaper';
import { FormikFieldProps, FormikFieldProps_Switch, useFormikResult } from '../ui/FormikAPI';
import { AppBarSubmitCancel } from './AppBarSubmitCancel';
import { OffersCheckboxCard } from './OffersCheckboxCard';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectItemImg: {
            position: 'absolute',
            margin: 'auto',
            top: '0',
            left: '10px',
            bottom: '0',
            width: '20px',
            height: '20px',
        },
        menuText: {
            marginLeft: '20px',
        },
    })
);
export const FormServiceDetails = (props: {
    formik: useFormikResult<ServiceEditModel>,
}) => {
    const dispatch = useAppDispatch();
    const { searchCategories, searchHintsInProgress } = useAppSelector((state: RootState) => state.search);
    const classes = useStyles();
    useEffect(() => {
        if ((searchCategories?.results?.length ?? 0) === 0) {
            dispatch(refreshHints({}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <>
        <form onSubmit={props.formik.handleSubmit}>
            <Container>
                <EmiPaper className="p-3 mb-3">
                    <Row>
                        {props.formik.values.photos?.length > 0
                            ? props.formik.values.photos.map((m, i) =>
                                <Col key={m.photoIndex} className="col-auto">
                                    <AvatarUpload
                                        variant="rounded"
                                        src={props.formik.values.photos?.[i]?.base64 ?? api.Search.getPhotoUrl(m) ?? ""}
                                        editMode={true}
                                        onChange={async (base64) => { await props.formik.setFieldValue(`photos[${i}].base64`, base64) }}
                                        fileId={"" + i}
                                        alt={props.formik.values.description} />
                                </Col>
                            )
                            :
                            <Col className="col-auto">
                                <AvatarUpload
                                    variant="rounded"
                                    src=""
                                    addMode
                                    onChange={async (base64) => {
                                        await props.formik.setFieldValue(`photos[0]`, {
                                            base64: base64,
                                            accessToken: "",
                                            objectID: props.formik.values.serviceID,
                                            photoIndex: 0,
                                            modificationDate: moment().toISOString(),
                                            isDeleted: false,
                                            position: 'Default',
                                            url: '',
                                        } as PhotoEditModel)
                                    }}
                                    fileId={"new"}
                                    alt={props.formik.values.description} />
                            </Col>
                        }
                    </Row>

                    <TextField className="mb-3"
                        disabled
                        label={L10N({ key: "Service ID" })}
                        {...FormikFieldProps(props.formik, m => m.serviceID)}
                    /><br />
                    <TextField className="mb-3"
                        required
                        label={L10N({ key: "Service Name" })}
                        {...FormikFieldProps(props.formik, m => m.serviceName)}
                    /><br />
                    <TextField className="mb-3"
                        label={L10N({ key: "Service Category" })}
                        {...FormikFieldProps(props.formik, m => m.serviceGroupName)}
                    /><br />
                    <TextField className="mb-3"
                        select
                        label={L10N({ key: "ClassNow Category" })}
                        {...FormikFieldProps(props.formik, m => m.category)}
                    >
                        {searchHintsInProgress && <CircularProgress />}
                        {searchCategories?.results?.map &&
                            searchCategories.results
                                .filter((m, i, a) => m.text !== SearchCategory_Anything)
                                .map((m, i) => (
                                    <MenuItem key={m.encodedText} value={m.text}>
                                        <CategoryIcon name={m.text as SearchCategoryValues} className={classes.selectItemImg} />
                                        <Typography variant="body1" color={m.hasResults ? "textPrimary" : "textSecondary"} className={classes.menuText}>
                                            {L10N({ key: "_category", cat: m.text })}
                                        </Typography>
                                    </MenuItem>
                                ))}
                    </TextField>
                    <TextField className="mb-3"
                        multiline rows={5}
                        label={L10N({ key: "Description" })}
                        {...FormikFieldProps(props.formik, m => m.description)}
                    /><br />
                    <TextField className="mb-3"
                        select
                        label={L10N({ todo: "Class Type" })}
                        {...FormikFieldProps(props.formik, m => m.classType)}
                    >
                        {AllClassTypes
                            .map((m, i) => (
                                <MenuItem key={m} value={m}>
                                    {m}
                                </MenuItem>
                            ))}
                    </TextField>
                    <br />
                    <TextField className="mb-3"
                        multiline rows={5}
                        label={L10N({ todo: "Class Content" })}
                        {...FormikFieldProps(props.formik, m => m.classContent)}
                    /><br />
                    <TextField className="mb-3"
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        id="defaultPrice.price" name="defaultPrice.price" label={L10N({ key: "Default Price" })}
                        value={props.formik.values.defaultPrice?.price ?? "0"}
                        onChange={props.formik.handleChange}
                        error={props.formik.touched.defaultPrice && Boolean(props.formik.errors.defaultPrice)}
                        helperText={props.formik.touched.defaultPrice && getIn(props.formik.errors, "defaultPrice.price")}
                        InputProps={{ endAdornment: <InputAdornment position="end">{L10N({ key: '_defaultCurrency' })}</InputAdornment> }}
                    />
                    <Row>
                        <Col>
                            <TextField className="mb-3"
                                label={L10N({ key: "Time before [minutes]" })}
                                {...FormikFieldProps(props.formik, m => m.timeBeforeInMinutes)}
                            />
                        </Col>
                        <Col>
                            <TextField className="mb-3"
                                label={L10N({ key: "Duration [minutes]" })}
                                {...FormikFieldProps(props.formik, m => m.durationInMinutes)}
                            />
                        </Col>
                        <Col>
                            <TextField className="mb-3"
                                label={L10N({ key: "Time after [minutes]" })}
                                {...FormikFieldProps(props.formik, m => m.timeAfterInMinutes)}
                            />
                        </Col>
                    </Row>
                    <FormControlLabel control={
                        <Switch {...FormikFieldProps_Switch(props.formik, m => m.notBlockingAvailability)} />
                    }
                        label={L10N({ todo: "Groups allowed" })}
                    /><br />
                    <FormControlLabel control={
                        <Switch {...FormikFieldProps_Switch(props.formik, m => m.paymentUpfront)} />
                    }
                        label={L10N({ key: "Upfront payment - the client has to pay upfront online while booking a visit" })}
                    /><br />
                    <TextField className="mb-3"
                        label={L10N({ key: "Upfront payment - price" })}
                        slotProps={{
                            input: {
                                endAdornment: <InputAdornment position="end">{L10N({ key: '_defaultCurrency' })}</InputAdornment>
                            }
                        }}
                        {...FormikFieldProps(props.formik, m => m.paymentUpfrontPrice)}
                    /><br />
                    <FormControlLabel control={
                        <Switch {...FormikFieldProps_Switch(props.formik, m => m.manualConfirmationRequired)} />
                    }
                        label={L10N({ key: "Require manual confirmation of the booking" })}
                    /><br />
                    {props.formik.values.offers?.length > 0 &&
                        <Row>
                            <Col>
                                <Accordion defaultExpanded={false}>
                                    <AccordionSummary className="text-black text-weight-bold2">
                                        {L10N({ key: "Resources" })}
                                    </AccordionSummary>
                                    <AccordionDetails className="d-block">
                                        <Container>
                                            <Row>
                                                <Col >
                                                    <OffersCheckboxCard model={props.formik.values.offers}
                                                        showFromServicePerspective
                                                        onToggleChecked={(m, i) => props.formik.setFieldValue(`offers[${i}].isAvailable`, !m.isAvailable)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </AccordionDetails>
                                </Accordion>
                            </Col>
                        </Row>
                    }
                    {props.formik.values.serviceID && props.formik.values.serviceID !== "" &&
                        <Row>
                            <Col>
                                <Accordion defaultExpanded={false}>
                                    <AccordionSummary className="text-black text-weight-bold2">
                                        {L10N({ key: "Delete" })}
                                    </AccordionSummary>
                                    <AccordionDetails className="d-block">
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={props.formik.values.isDeleted ?? false} color="primary"
                                                    id="isDeleted" name="isDeleted"
                                                    onChange={props.formik.handleChange}
                                                />
                                            }
                                            label={L10N({ key: "Mark for deletion" })}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Col>
                        </Row>
                    }
                </EmiPaper>
            </Container>
            {props.formik.dirty && <AppBarSubmitCancel formik={props.formik} />}
        </form>
    </>;
}