import { Typography } from "@mui/material";
import { Col, Row } from 'react-bootstrap';
import { RootState, useAppSelector } from '../../store/store';
import { L10N } from '../pages/L10N';
import { VisitReviewItemCard } from './VisitReviewItemCard';

export const CompanyDetailsViewTabReviews = (props: {
}) => {
    const { company } = useAppSelector((state: RootState) => state.details);

    return (<>
        {(company?.reviews?.length ?? 0) === 0 && <>
            <Row className="mt-5">
                <Col />
                <Col className="col-auto">
                    <img src={`/img/icons/review-secondary.svg`} alt="heart" width="150" />
                </Col>
                <Col />
            </Row>
            <Row className="mb-3">
                <Col>
                    <Typography align="center" color="secondary">
                        {L10N({ key: "This person has no reviews yet" })}
                    </Typography>
                </Col>
            </Row>
        </>}
        <Row className="mb-3">
            {company?.reviews?.map && company?.reviews?.map((row, i) =>
                <Col key={i} className="mb-3"
                    xs={12} md={6} >
                    <VisitReviewItemCard data={row} />
                </Col>
            )}
        </Row>
    </>);
};
