import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api/api';
import { MapCoords } from '../../api/models/Details';
import { AccessTokenBearer } from '../../api/models/Global';
import { RootState } from '../store';
import { setHasSearched } from './search.reducer';

export const changeSearchString = createAsyncThunk(
    'thunk/changeSearchString',
    async (args: {
        searchString: string,
        currentLocation?: MapCoords,
    }, thunkAPI) => {
        const state = (thunkAPI.getState() as RootState).search;
        const cleanSearchString = args.searchString
            .replace(/\s+/ig, "|")
            .replace(/\|/ig, " ");
        var cleanSearchStringWithoutTags = cleanSearchString;
        for (let i = 0; i < (state.searchHints?.results?.length ?? 0); i++) {
            const tag = state.searchHints?.results[i]?.encodedText ?? "";
            cleanSearchStringWithoutTags = cleanSearchStringWithoutTags.replace(tag, "");
        }
        for (let i = 0; i < (state.searchCategories?.results?.length ?? 0); i++) {
            const tag = state.searchCategories?.results[i]?.encodedText ?? "";
            cleanSearchStringWithoutTags = cleanSearchStringWithoutTags.replace(tag, "");
        }
        for (let i = 0; i < (state.exploreWhenHints?.results?.length ?? 0); i++) {
            const tag = state.exploreWhenHints?.results[i]?.encodedText ?? "";
            cleanSearchStringWithoutTags = cleanSearchStringWithoutTags.replace(tag, "");
        }
        return {
            searchString: cleanSearchString === " " ? "" : cleanSearchString,
            searchStringWithoutTags: cleanSearchStringWithoutTags === " " ? "" : cleanSearchStringWithoutTags,
            currentLocation: args.currentLocation,
            hasSearched: false,
        }
    },
)

export async function sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}
async function shouldCancelSearch(getState: () => RootState, ms: number): Promise<boolean> {
    const searchString = getState().search.searchString.trim();
    await sleep(ms);
    const currentSearchString = getState().search.searchString.trim();
    return (searchString !== currentSearchString);
}
export const startSearch = createAsyncThunk(
    'thunk/startSearch',
    async (args: {
    }, thunkAPI) => {
        const shouldCancel = await shouldCancelSearch(() => thunkAPI.getState() as RootState, 500);
        if (shouldCancel) { return; }

        const state = thunkAPI.getState() as RootState;
        const searchString = state.search.searchString.trim();
        const apiResponse = await api.Search.getResults(searchString, state.search.currentLocation);

        thunkAPI.dispatch(setHasSearched())
        if (apiResponse.error) throw apiResponse.error;
        return {
            searchResults: apiResponse.result,
            hasSearched: true,
        };
    },
)

export const refreshHints = createAsyncThunk(
    'thunk/refreshHints',
    async (args: {
    }, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const searchString = state.search.searchString.trim();

        const apiResponseHints = await api.Search.getHints(searchString);
        const apiResponseCategories = await api.Search.getCategories(searchString);
        const apiResponseExploreWhenHints = await api.Search.getExploreWhenHints(searchString);

        const error = apiResponseHints.error ?? apiResponseCategories.error ?? apiResponseExploreWhenHints.error;
        if (error) throw error;

        return {
            searchHints: apiResponseHints.result,
            searchCategories: apiResponseCategories.result,
            exploreWhenHints: apiResponseExploreWhenHints.result,
        };
    },
)
export const refreshFavorites = createAsyncThunk(
    'thunk/refreshFavorites',
    async (args: {
        message: AccessTokenBearer
    }, thunkAPI) => {
        const apiResponse = await api.Search.getFavorites(args.message);

        if (apiResponse.error) throw apiResponse.error;
        return {
            favoriteResults: apiResponse.result,
        };
    },
)