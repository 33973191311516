import { IconButton, Typography } from "@mui/material";
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const BackArrowHeader = (props: {
    title?: JSX.Element | string,
    className?: string,
    dark?: boolean,
}) => {
    const navigate = useNavigate();
    function handleClick() {
        navigate(-1);
    }
    return (
        <>
            <Row className={"mt-2 align-items-center " + props.className} >
                <Col className="col-auto">
                    <IconButton onClick={handleClick} size="small" className="p-0" >
                        {props.dark && <img src="/img/icons/icon_back_dark.svg" alt="back" />}
                        {!props.dark && <img src="/img/icons/icon_back.svg" alt="back" />}
                    </IconButton>
                </Col>
                <Col>
                    <Typography variant="subtitle2" align="center" className="w-100 ml-n3">
                        {props.title}
                    </Typography >
                </Col>
            </Row>
        </>
    )
}
