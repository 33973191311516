import { ThemeProvider } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import { AccountDetailsPage } from './components/pages/AccountDetailsPage';
import { CalendarPage } from './components/pages/CalendarPage';
import { ClientsPage } from './components/pages/ClientsPage';
import { ContactUsPage } from './components/pages/ContactUsPage';
import { CreateCompanyPage } from './components/pages/CreateCompanyPage';
import { DetailsPage } from './components/pages/DetailsPage';
import { EditPage } from './components/pages/EditPage';
import { ExplorePage } from './components/pages/ExplorePage';
import { FavouritesPage } from './components/pages/FavouritesPage';
import { InvitePage } from './components/pages/InvitePage';
import { Layout } from './components/pages/Layout';
import { LegalNoticePage } from './components/pages/LegalNoticePage';
import { LoginPage } from './components/pages/LoginPage';
import { MobilePage } from './components/pages/MobilePage';
import { PaymentPage } from './components/pages/PaymentPage';
import { PrivacyPolicyPage } from './components/pages/PrivacyPolicyPage';
import { ProfilePage } from './components/pages/ProfilePage';
import { RecommendPage } from './components/pages/RecommendPage';
import { AppRoutes } from './components/pages/Routes';
import { SearchPage } from './components/pages/SearchPage';
import { StatisticsPage } from './components/pages/StatisticsPage';
import { StudentWizardPage } from './components/pages/StudentWizardPage';
import { SuperAdminPage } from './components/pages/SuperAdminPage';
import { TeacherWizardPage } from './components/pages/TeacherWizardPage';
import { TermsOfUsePage } from './components/pages/TermsOfUsePage';
import { VisitConfirmedPage } from './components/pages/VisitConfirmedPage';
import { VisitPage } from './components/pages/VisitPage';
import { VisitReviewEditPage } from './components/pages/VisitReviewEditPage';
import { VisitReviewPage } from './components/pages/VisitReviewPage';
import { theme } from './components/ui/Theme';
import './scss/custom.scss';
import './scss/gradients.scss';
import { RootState, useAppDispatch, useAppSelector } from './store/store';
import { getCurrentUser } from './store/user/user.actions';

export const App = (props: {
}) => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state: RootState) => state.user);
    useEffect(() => {
        if (user) {
            dispatch(getCurrentUser({ message: user }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-IN"} >
            <ThemeProvider theme={theme}>
                <Layout>
                    <Routes>
                        <Route path={AppRoutes.home} element={<SearchPage />} />
                        <Route path={AppRoutes.mobileHome} element={<MobilePage />} />
                        <Route path={AppRoutes.login} element={<LoginPage />} />
                        <Route path={AppRoutes.explore} element={<ExplorePage />} />
                        <Route path={AppRoutes.favourites} element={<FavouritesPage />} />
                        <Route path={AppRoutes.profile} element={<ProfilePage />} />
                        <Route path={AppRoutes.calendar} element={<CalendarPage />} />
                        <Route path={AppRoutes.invite} element={<InvitePage />} />
                        <Route path={AppRoutes.visit} element={<VisitPage />} />
                        <Route path={AppRoutes.visitConfirmed} element={<VisitConfirmedPage />} />
                        <Route path={AppRoutes.visitReview} element={<VisitReviewPage />} />
                        <Route path={AppRoutes.visitReviewEdit} element={<VisitReviewEditPage />} />
                        <Route path={AppRoutes.details} element={<DetailsPage />} />
                        <Route path={AppRoutes.accountDetails} element={<AccountDetailsPage />} />
                        <Route path={AppRoutes.terms} element={<TermsOfUsePage />} />
                        <Route path={AppRoutes.contactus} element={<ContactUsPage />} />
                        <Route path={AppRoutes.privacy} element={<PrivacyPolicyPage />} />
                        <Route path={AppRoutes.legal} element={<LegalNoticePage />} />
                        <Route path={AppRoutes.recommend} element={<RecommendPage />} />
                        <Route path={AppRoutes.statistics} element={<StatisticsPage />} />
                        <Route path={AppRoutes.edit} element={<EditPage />} />
                        <Route path={AppRoutes.clients} element={<ClientsPage />} />
                        <Route path={AppRoutes.createCompany} element={<CreateCompanyPage />} />
                        <Route path={AppRoutes.payment} element={<PaymentPage />} />
                        <Route path={AppRoutes.superAdmin} element={<SuperAdminPage />} />
                        <Route path={AppRoutes.teacherWizard} element={<TeacherWizardPage />} />
                        <Route path={AppRoutes.studentWizard} element={<StudentWizardPage />} />
                    </Routes>
                </Layout>
            </ThemeProvider>
        </LocalizationProvider>
    );
}
