import { CompanyDetails } from "src/api/models/Details";
import { AppRoutes } from "../pages/Routes";
import { CspQrCode } from "../ui/CspQrCode";

export const CompanyQRCode = (props: {
    company: CompanyDetails;
}) => {
    const url = AppRoutes.externalUrl(AppRoutes.detailsTab("company", props.company.itemID));
    return <>
        <CspQrCode value={url} />
    </>;
};
