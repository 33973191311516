import { Accordion, AccordionDetails, AccordionSummary, Button, CardActionArea, Typography } from "@mui/material";
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CompanyOfferService } from '../../api/models/Details';
import { loadServiceDetails } from '../../store/details/details.actions';
import { changeInvite } from '../../store/invite/invite.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { ServiceDetailsDialogVisible } from '../../store/ui/ui.reducer';
import { L10N } from '../pages/L10N';
import { AppRoutes } from '../pages/Routes';
import { CspIcon } from "../ui/CspIcon";
import { EmiPaper } from '../ui/EmiPaper';
import { LogotypeTokens } from "../ui/LogotypeSpans";
import { theme } from '../ui/Theme';

const ServiceViewItem = (props: {
    details: CompanyOfferService,
    onBookClick: () => void,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { searchResults } = useAppSelector((state: RootState) => state.search);

    const { details } = props;

    async function infoClick(event: React.MouseEvent<any, MouseEvent>, serviceID: string) {
        event.stopPropagation();
        event.preventDefault();

        await dispatch(loadServiceDetails({ serviceID }));
        await dispatch(ServiceDetailsDialogVisible(true));
    }
    async function bookClick(event: React.MouseEvent<HTMLElement, MouseEvent>, item: CompanyOfferService) {
        event.stopPropagation();
        props.onBookClick();

        await dispatch(changeInvite({
            message: {
                query: searchResults?.query ?? "",
                offeredResourceID: item.offeredResourceID,
            }
        }));
        await navigate(AppRoutes.invitePage(item.offeredResourceID));
    }

    return (<>
        <Col className={"pl-4 "}>
            <CardActionArea onClick={async (event) => await infoClick(event, details.serviceID)}>
                <Typography display="inline" color="secondary">
                    <LogotypeTokens text={details?.serviceName} />
                </Typography>
                <br />
                <CspIcon name="CurrencyRupeeOutlinedIcon" fontSize="small" color="secondary" />
                <Typography variant="body1" display="inline" color="secondary">
                    {L10N({ key: "_priceAndCurrency", price: details?.price })}
                    &nbsp;
                    &nbsp;
                </Typography>
                <CspIcon name="AccessTimeOutlinedIcon" fontSize="small" color="secondary" />
                &nbsp;
                <Typography variant="body1" display="inline" color="secondary">
                    {L10N({
                        key: "_duration",
                        duration: (details?.durationInMinutes ?? 0)
                            + (details?.timeBeforeInMinutes ?? 0)
                            + (details?.timeAfterInMinutes ?? 0),
                    })}
                </Typography>
            </CardActionArea>
        </Col>
        <Col className="p-0 col-auto " >
        </Col>
        <Col className="col-auto ml-auto py-2 pr-2">
            <Button className={"m-0 h-100"}
                variant="contained"
                color="primary"
                onClick={async (event) => await bookClick(event, details)}>
                {L10N({ key: "Join Now" })}
            </Button>
        </Col>
    </>);
}
export const CompanyDetailsViewTabServices = (props: {
    onBookClick: () => void,
}) => {
    const { company } = useAppSelector((state: RootState) => state.details);

    return (<>
        <Container className="my-3 p-0">
            <Row className="p-0">
                {company?.serviceGroups?.map && company?.serviceGroups?.map((group, iGroup) =>
                    <React.Fragment key={"Ac" + iGroup + group?.serviceGroupName}>
                        <Col xl={6} md={12} className={"mb-3 " + ((iGroup % 2) ? "" : "")}>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary>
                                    <Typography color="secondary" variant="subtitle1">
                                        {group?.serviceGroupName ?? L10N({ key: "All services" })}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails className="d-block">
                                    {group.services.map && group.services.map((service, iService) =>
                                        <EmiPaper key={"sp" + iService + service?.offeredResourceID.join()}
                                            className="mb-1"
                                            style={{
                                                border: '1px solid',
                                                borderColor: theme.palette.primary.main,
                                            }}
                                        >
                                            <Container>
                                                <Row >
                                                    <ServiceViewItem details={service} onBookClick={props.onBookClick} />
                                                </Row>
                                            </Container>
                                        </EmiPaper>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                    </React.Fragment>)}
            </Row>
        </Container>
    </>);
};
