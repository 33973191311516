import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UIState {
    cancelVisitDialogVisible?: boolean,
    serviceDetailsDialogVisible?: boolean,
    consentsDialogVisible?: boolean,
}

const initialState: UIState = {
    cancelVisitDialogVisible: false,
    serviceDetailsDialogVisible: false,
    consentsDialogVisible: false,
}

const uiSlice = createSlice({
    name: 'uiSlice',
    initialState,
    reducers: {
        CancelVisitDialogVisible(state, action: PayloadAction<boolean>) {
            state.cancelVisitDialogVisible = action.payload;
        },
        ServiceDetailsDialogVisible(state, action: PayloadAction<boolean>) {
            state.serviceDetailsDialogVisible = action.payload;
        },
        ConsentsDialogVisible(state, action: PayloadAction<boolean>) {
            state.consentsDialogVisible = action.payload;
        },
    },
});
export const { CancelVisitDialogVisible, ServiceDetailsDialogVisible, ConsentsDialogVisible } = uiSlice.actions
export default uiSlice.reducer


