import { CompanyEditModel } from '../../api/models/Edit';
import { AppBarSubmitCancel } from '../edit/AppBarSubmitCancel';
import { FieldSetCompanyAddress } from '../edit/FieldSetCompanyAddress';
import { FieldSetCompanyBasics } from '../edit/FieldSetCompanyBasics';
import { FieldSetCompanyForCustomers } from '../edit/FieldSetCompanyForCustomers';
import { FieldSetCompanyPublish } from '../edit/FieldSetCompanyPublish';
import { useFormikResult } from '../ui/FormikAPI';

export type CompanyEditStep =
    'about'
    | 'for clients'
    | 'address'
    | 'publish'
    ;
export const FormCompanyDetails = (props: {
    formik: useFormikResult<CompanyEditModel>,
    activeStepValue: CompanyEditStep,
}) => {

    return <>
        <form onSubmit={props.formik.handleSubmit}>
            {props.activeStepValue === 'about' && <FieldSetCompanyBasics formik={props.formik} />}
            {props.activeStepValue === 'for clients' && <FieldSetCompanyForCustomers formik={props.formik} />}
            {props.activeStepValue === 'address' && <FieldSetCompanyAddress formik={props.formik} />}
            {props.activeStepValue === 'publish' && <FieldSetCompanyPublish formik={props.formik} />}

            {props.formik.dirty && <AppBarSubmitCancel formik={props.formik} />}
        </form>
    </>;
}