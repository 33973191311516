import { Accordion, AccordionDetails, CircularProgress, MenuItem, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Col, Row } from 'react-bootstrap';
import { SearchCategory_Anything, SearchCategoryValues } from "src/api/models/Search";
import { useSearchCategoriesAutoLoaded } from "src/store/search/search.hooks";
import { CompanyEditModel } from '../../api/models/Edit';
import { L10N } from '../pages/L10N';
import { CategoryIcon } from '../ui/CategoryIcon';
import { FormikFieldProps, useFormikResult } from '../ui/FormikAPI';
import { FieldSetRazorpayApiCredentials } from './FieldSetRazorpayApiCredentials';

const size = 20;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectItemImg: {//bug in MUI. This is required to properly display selected value
            position: 'absolute',
            margin: 'auto',
            top: '0',
            left: size / 2,
            bottom: '0',
            width: size,
            height: size,
        },
        menuText: {
            marginLeft: size,
        },
    })
);
export const FieldSetCompanyBasics = (props: {
    formik: useFormikResult<CompanyEditModel>,
}) => {
    const { searchCategories, searchHintsInProgress } = useSearchCategoriesAutoLoaded();
    const classes = useStyles();

    return <>
        <Accordion defaultExpanded={false} >
            <AccordionDetails className="d-block">
                <TextField className="mb-3"
                    disabled
                    label={L10N({ key: "Company ID" })}
                    {...FormikFieldProps(props.formik, m => m.companyID)}
                /><br />
                <TextField className="mb-3 text-black"
                    label={L10N({ key: "Company Name" })}
                    {...FormikFieldProps(props.formik, m => m.name)}
                /><br />
                <TextField className="mb-3"
                    select
                    label={L10N({ key: "ClassNow Category" })}
                    {...FormikFieldProps(props.formik, m => m.category)}
                >
                    {searchHintsInProgress && <CircularProgress />}
                    {searchCategories?.results?.map &&
                        searchCategories.results
                            .filter((m, i, a) => m.text !== SearchCategory_Anything)
                            .map((m, i) => (
                                <MenuItem key={m.encodedText} value={m.text}>
                                    <CategoryIcon name={m.text as SearchCategoryValues} className={classes.selectItemImg} />
                                    <Typography variant="body1"
                                        color={m.hasResults ? "textPrimary" : "textSecondary"}
                                        className={classes.menuText}>
                                        {L10N({ key: "_category", cat: m.text })}
                                    </Typography>
                                </MenuItem>
                            ))}
                </TextField>
                <TextField className="mb-3 text-black"
                    label={L10N({ key: "Search Keywords" })}
                    {...FormikFieldProps(props.formik, m => m.keyWords)}
                /><br />
                <Row>
                    <Col xs={12} md={6}>
                        <TextField className="mb-3"
                            label={L10N({ key: "Contact Phone for ClassNow" })}
                            {...FormikFieldProps(props.formik, m => m.eduinPhone)}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <TextField className="mb-3"
                            label={L10N({ key: "Contact Email for ClassNow" })}
                            {...FormikFieldProps(props.formik, m => m.eduinEmail)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FieldSetRazorpayApiCredentials formik={props.formik} />
                    </Col>
                </Row>
            </AccordionDetails>
        </Accordion>
    </>;
}