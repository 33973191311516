import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { useFormik } from 'formik';
import { isMobile } from 'react-device-detect';
import { CreateNewVisit, VisitEditModelValidationSchema } from '../../api/models/EditVisit';
import { RefreshResourceAvailabilityIfPossible, UpdateVisitAndReload } from '../../store/edit/edit.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { FormVisitDetails } from '../edit/FormVisitDetails';
import { L10N } from '../pages/L10N';

export const EditVistDialog = (props: {
    open: boolean,
    onClose: () => void,
    onReloadRequired: () => Promise<void>,
}) => {
    const dispatch = useAppDispatch();
    const { inProgress } = useAppSelector((state: RootState) => state.company);
    const { user } = useAppSelector((state: RootState) => state.user);
    const { visit, inProgress: editInProgress } = useAppSelector((state: RootState) => state.edit);
    const theme = useTheme();
    const screenSizeIsSmall = !useMediaQuery(theme.breakpoints.up('sm'));

    const formik = useFormik({
        initialValues: visit ?? CreateNewVisit(),
        enableReinitialize: true,
        validationSchema: VisitEditModelValidationSchema,
        onSubmit: async (values) => {
            values.accessToken = user?.accessToken ?? "";
            await dispatch(UpdateVisitAndReload({ message: values }));
            props.onClose();
            await props.onReloadRequired();
        },
    });
    async function onCancel() {
        formik.resetForm();
        props.onClose();
    }
    function onSubmitCapture() {
        if (!formik.isValid) {
            console.log(formik.errors);
        }
        formik.submitForm();
    }
    async function refreshAvailableResources() {
        if (!user) return;

        await dispatch(RefreshResourceAvailabilityIfPossible({
            message: {
                ...visit,
                ...formik.values,
                accessToken: user.accessToken,
            }
        }));
    }
    return (
        <>
            <Dialog
                fullScreen={isMobile || screenSizeIsSmall}
                open={props.open}
                onClose={onCancel}>
                <DialogTitle>{L10N({ key: "Edit" })}</DialogTitle>
                <DialogContent>
                    {!inProgress && !editInProgress &&
                        <FormVisitDetails formik={formik} onRefreshAvailableResources={refreshAvailableResources} />
                    }
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" type="submit"
                        onClick={onSubmitCapture}
                        onSubmitCapture={onSubmitCapture}
                        disabled={!formik.dirty}>
                        {inProgress
                            ? <CircularProgress />
                            : L10N({ key: "Save" })
                        }
                    </Button>
                    <Button onClick={onCancel}
                        color="primary" autoFocus variant="outlined">
                        {L10N({ key: "Cancel" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};