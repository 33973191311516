import { createSlice } from '@reduxjs/toolkit';
import { MapCoords } from '../../api/models/Details';
import { ClientEditModel, CompanyEditModel, ResourceEditModel, ServiceEditModel, VisitReviewEditModel } from '../../api/models/Edit';
import { VisitEditModel } from '../../api/models/EditVisit';
import { addInProgressCases, addInProgressMatcher } from '../reduxHelpers';
import { CreateCompanyAndReload, CreateVisit, loadAllCompanyForEdit, loadClientsForEdit, loadCompanyForEdit, loadCompanyResourcesForEdit, loadCompanyServicesForEdit, loadCoordsFromAddress, loadVisitForEdit, RefreshResourceAvailabilityIfPossible, UpdateCompanyAndReload, UpdateCompanyClient, UpdateCompanyResourceAndReload, UpdateCompanyServiceAndReload, updatePhoto, UpdateVisitAndReload, UpdateVisitReviewAndReload } from './edit.actions';

export interface EditState {
    inProgress: boolean,
    editedClient?: ClientEditModel,
    company?: CompanyEditModel,
    resources?: ResourceEditModel[],
    services?: ServiceEditModel[],
    visitReviews?: VisitReviewEditModel[],
    visitReviews_AverageScore?: number,
    clients?: ClientEditModel[],
    mapCoords?: MapCoords,
    visit?: VisitEditModel,
}
const initialState: EditState = {
    inProgress: false,
    editedClient: undefined,
    company: undefined,
    resources: undefined,
    services: undefined,
    visitReviews: undefined,
    visitReviews_AverageScore: undefined,
    clients: undefined,
    mapCoords: undefined,
    visit: undefined,
}
const editSlice = createSlice({
    name: 'editSlice',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        addInProgressCases(builder, CreateCompanyAndReload);
        addInProgressCases(builder, CreateVisit);
        addInProgressCases(builder, RefreshResourceAvailabilityIfPossible);
        addInProgressCases(builder, UpdateCompanyAndReload);
        addInProgressCases(builder, UpdateCompanyClient);
        addInProgressCases(builder, UpdateCompanyResourceAndReload);
        addInProgressCases(builder, UpdateCompanyServiceAndReload);
        addInProgressCases(builder, UpdateVisitAndReload);
        addInProgressCases(builder, UpdateVisitReviewAndReload);
        addInProgressCases(builder, loadAllCompanyForEdit);
        addInProgressCases(builder, loadClientsForEdit);
        addInProgressCases(builder, loadCompanyForEdit);
        addInProgressCases(builder, loadCompanyResourcesForEdit);
        addInProgressCases(builder, loadCompanyServicesForEdit);
        addInProgressCases(builder, loadCoordsFromAddress);
        addInProgressCases(builder, loadVisitForEdit);
        addInProgressCases(builder, updatePhoto);

        builder.addCase(CreateCompanyAndReload.fulfilled, (state, action) => {
            state.company = action.payload?.company;
        })
        builder.addCase(CreateVisit.fulfilled, (state, action) => {
            state.visit = action.payload?.visit;
        })
        builder.addCase(RefreshResourceAvailabilityIfPossible.fulfilled, (state, action) => {
            state.visit = action.payload?.visit;
        })
        builder.addCase(UpdateCompanyAndReload.fulfilled, (state, action) => {
            state.company = action.payload?.company;
        })
        builder.addCase(UpdateCompanyClient.fulfilled, (state, action) => {
            state.editedClient = action.payload?.editedClient;
            if (action.payload.editedClient) {
                if (state.clients) {
                    state.clients = state.clients
                        ?.map(m => m.userID === action.payload.editedClient?.userID
                            ? action.payload.editedClient!
                            : m)
                        ;
                } else {
                    state.clients = [action.payload.editedClient];
                }
            }
        })
        builder.addCase(UpdateCompanyResourceAndReload.fulfilled, (state, action) => {
            if (action.payload.resource) {
                if (state.resources) {
                    state.resources = state.resources
                        ?.map(m => m.resourceID === action.payload.resource?.resourceID
                            ? action.payload.resource!
                            : m)
                        ;
                } else {
                    state.resources = [action.payload.resource];
                }
            }
        })
        builder.addCase(UpdateCompanyServiceAndReload.fulfilled, (state, action) => {
            if (action.payload.service) {
                if (state.services) {
                    state.services = state.services
                        ?.map(m => m.serviceID === action.payload.service?.serviceID
                            ? action.payload.service!
                            : m)
                        ;
                } else {
                    state.services = [action.payload.service];
                }
            }
        })
        builder.addCase(UpdateVisitAndReload.fulfilled, (state, action) => {
            state.visit = action.payload?.visit;
        })
        builder.addCase(UpdateVisitReviewAndReload.fulfilled, (state, action) => {
            if (action.payload.visitReview) {
                if (state.visitReviews) {
                    state.visitReviews = state.visitReviews
                        ?.map(m => m.visitReviewID === action.payload.visitReview?.visitReviewID
                            ? action.payload.visitReview!
                            : m)
                        ;
                } else {
                    state.visitReviews = [action.payload.visitReview];
                }
            }
        })
        builder.addCase(loadAllCompanyForEdit.fulfilled, (state, action) => {
            state.company = action.payload?.company;
            state.resources = action.payload?.resources;
            state.services = action.payload?.services;
            state.visitReviews = action.payload?.visitReviews;
            state.visitReviews_AverageScore = action.payload?.visitReviews_AverageScore;
        })
        builder.addCase(loadClientsForEdit.fulfilled, (state, action) => {
            state.clients = action.payload?.clients;
        })
        builder.addCase(loadCompanyForEdit.fulfilled, (state, action) => {
            state.company = action.payload?.company;
        })
        builder.addCase(loadCompanyResourcesForEdit.fulfilled, (state, action) => {
            state.resources = action.payload?.resources;
        })
        builder.addCase(loadCompanyServicesForEdit.fulfilled, (state, action) => {
            state.services = action.payload?.services;
        })
        builder.addCase(loadCoordsFromAddress.fulfilled, (state, action) => {
            state.mapCoords = action.payload?.mapCoords;
        })
        builder.addCase(loadVisitForEdit.fulfilled, (state, action) => {
            state.visit = action.payload?.visit;
        })
        builder.addCase(updatePhoto.fulfilled, (state, action) => {
        })
        addInProgressMatcher(builder, CreateCompanyAndReload);
        addInProgressMatcher(builder, CreateVisit);
        addInProgressMatcher(builder, RefreshResourceAvailabilityIfPossible);
        addInProgressMatcher(builder, UpdateCompanyAndReload);
        addInProgressMatcher(builder, UpdateCompanyClient);
        addInProgressMatcher(builder, UpdateCompanyResourceAndReload);
        addInProgressMatcher(builder, UpdateCompanyServiceAndReload);
        addInProgressMatcher(builder, UpdateVisitAndReload);
        addInProgressMatcher(builder, UpdateVisitReviewAndReload);
        addInProgressMatcher(builder, loadAllCompanyForEdit);
        addInProgressMatcher(builder, loadClientsForEdit);
        addInProgressMatcher(builder, loadCompanyForEdit);
        addInProgressMatcher(builder, loadCompanyResourcesForEdit);
        addInProgressMatcher(builder, loadCompanyServicesForEdit);
        addInProgressMatcher(builder, loadCoordsFromAddress);
        addInProgressMatcher(builder, loadVisitForEdit);
        addInProgressMatcher(builder, updatePhoto);
    },
});
export default editSlice.reducer

