import { Accordion, AccordionDetails, AccordionSummary, Divider, Link, Typography } from "@mui/material";
import { Col, Container, Row } from 'react-bootstrap';
import { RootState, useAppSelector } from '../../store/store';
import { L10N } from '../pages/L10N';
import { CspImageList } from "../ui/CspImageList";

export const ResourceDetailsView = (props: {
    defaultExpanded: boolean,
}) => {
    const { resource } = useAppSelector((state: RootState) => state.details);

    return (
        <Accordion defaultExpanded={props.defaultExpanded}>
            <AccordionSummary>{resource?.title}</AccordionSummary>
            <Divider />
            <AccordionDetails>
                <Container>
                    <Row>
                        <Col>
                            {resource && <>
                                <CspImageList photos={resource?.photos} moreColumns />
                                <Typography variant="subtitle1">{resource?.title}</Typography>
                                <Typography variant="subtitle2">{resource?.subtitle}</Typography>
                                {resource?.htmlDescription && <>
                                    <div dangerouslySetInnerHTML={{ __html: resource?.htmlDescription }} />
                                </>}
                                {resource?.description && <>
                                    <Typography variant="body2">{resource?.description}</Typography>
                                </>}
                                {resource?.learnMoreURL && <>
                                    <Link target="" href={resource?.learnMoreURL} >
                                        {L10N({ key: "Learn more" })}
                                    </Link >
                                </>}
                            </>}
                        </Col>
                    </Row>
                </Container>
            </AccordionDetails>
        </Accordion>
    );
};
