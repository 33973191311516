import * as React from 'react';
import { ModalDialogs } from './ModalDialogs';
import { NavMenu } from './NavMenu';

export const Layout = (props: {
    children?: React.ReactNode,
}) => {

    return (<>
        <div style={{
            height: '100%',
        }} >
            <ModalDialogs />
            {props.children}
        </div>
        <div style={{ height: '60px',
         }} />
        <NavMenu />
    </>
    );
}
