import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { PickerChangeHandlerContext, TimeValidationError } from '@mui/x-date-pickers/models';
import moment, { Moment } from 'moment';
import { TeacherWizardEditModel } from 'src/api/models/TeacherWizard';
import { ResourceEditModel } from '../../api/models/Edit';
import { useFormikResult } from '../ui/FormikAPI';

export const BeeTimePicker = (props: {
    formik: useFormikResult<ResourceEditModel>
    | useFormikResult<TeacherWizardEditModel>,
    valueHHmm: string,
    fieldName: string,
}) => {
    async function onChange(value: Moment | null, context: PickerChangeHandlerContext<TimeValidationError>) {
        await props.formik.setFieldValue(props.fieldName, value?.format("HH:mm"));
    }
    return <MobileTimePicker views={['hours', 'minutes']}
        onChange={onChange}
        openTo="hours" ampm={false}
        minutesStep={30}
        format="HH:mm"
        value={moment(`2000-01-01T${props.valueHHmm}`)}
        name={props.fieldName}
    />;
}