import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { api } from '../../api/api';
import { DimensionTypes } from '../../api/models/Global';
import { CreateInviteMessage, Invite, InviteDimension } from '../../api/models/Invite';
import { RootState } from '../store';
import { clearVisitResponse } from '../visit/visit.reducer';
import { setCurrentInviteMessage } from './invite.reducer';

export const changeInvite = createAsyncThunk(
    'thunk/changeInvite',
    async (args: {
        message: CreateInviteMessage
    }, thunkAPI) => {

        thunkAPI.dispatch(setCurrentInviteMessage(args.message));
        thunkAPI.dispatch(clearVisitResponse());
        const apiResponse = await api.Invite.createInvite(args.message);

        if (apiResponse.error) throw apiResponse.error;
        return {
            createInviteMessage: args.message,
            serverInvite: apiResponse.result,
            currentInvite: apiResponse.result ? rebuildInvite(apiResponse.result, apiResponse.result) : undefined,
        };
    },
)

function rebuildInvite(invite: Invite, serverInvite: Invite): Invite {
    const filteredOffers = serverInvite.offers.filter((v, i, a) => {
        const result =
            (!invite.durationDimensionSelectedValue || v.du === invite.durationDimensionSelectedValue)
            && (!invite.priceDimensionSelectedValue || v.p === invite.priceDimensionSelectedValue)
            && (!invite.companyServiceDimensionSelectedValue || v.s === invite.companyServiceDimensionSelectedValue)
            && (!invite.companyDimensionSelectedValue || v.c === invite.companyDimensionSelectedValue)
            && (!invite.companyResourceDimensionSelectedValue || v.r === invite.companyResourceDimensionSelectedValue)
            && (!invite.timeDimensionSelectedValue || v.t === invite.timeDimensionSelectedValue)
            && (!invite.dayPartDimensionSelectedValue || v.dp === invite.dayPartDimensionSelectedValue)
            && (!invite.dateDimensionSelectedValue || v.da === invite.dateDimensionSelectedValue)
            ;
        return result;
    });
    const result = _.cloneDeep(invite);
    result.offers = filteredOffers;

    result.durationDimension.forEach(function (m, i, a) {
        m.countOfOffers = filteredOffers.filter((v, i, a) => { return v.du === m.dimensionValue }).length;
    });
    result.priceDimension.forEach((m, i) => {
        m.countOfOffers = filteredOffers.filter((v, i, a) => { return v.p === m.dimensionValue }).length;
    });
    result.companyServiceDimension.forEach((m, i) => {
        m.countOfOffers = filteredOffers.filter((v, i, a) => { return v.s === m.dimensionValue }).length;
    });
    result.companyDimension.forEach((m, i) => {
        m.countOfOffers = filteredOffers.filter((v, i, a) => { return v.c === m.dimensionValue }).length;
    });
    result.companyResourceDimension.forEach((m, i) => {
        m.countOfOffers = filteredOffers.filter((v, i, a) => { return v.r === m.dimensionValue }).length;
    });
    result.timeDimension.forEach((m, i) => {
        m.countOfOffers = filteredOffers.filter((v, i, a) => { return v.t === m.dimensionValue }).length;
    });
    result.dayPartDimension.forEach((m, i) => {
        m.countOfOffers = filteredOffers.filter((v, i, a) => { return v.dp === m.dimensionValue }).length;
    });
    result.dateDimension.forEach((m, i) => {
        m.countOfOffers = filteredOffers.filter((v, i, a) => { return v.da === m.dimensionValue }).length;
    });

    if (!result.durationDimensionSelectedValue) {
        const filtered = result.durationDimension.filter((v, i, a) => v.countOfOffers > 0);
        result.durationDimensionSelectedValue = filtered.length === 1 ? filtered[0].dimensionValue : undefined;
    }
    if (!result.priceDimensionSelectedValue) {
        const filtered = result.priceDimension.filter((v, i, a) => v.countOfOffers > 0);
        result.priceDimensionSelectedValue = filtered.length === 1 ? filtered[0].dimensionValue : undefined;
    }
    if (!result.companyServiceDimensionSelectedValue) {
        const filtered = result.companyServiceDimension.filter((v, i, a) => v.countOfOffers > 0);
        result.companyServiceDimensionSelectedValue = filtered.length === 1 ? filtered[0].dimensionValue : undefined;
    }
    if (!result.companyDimensionSelectedValue) {
        const filtered = result.companyDimension.filter((v, i, a) => v.countOfOffers > 0);
        result.companyDimensionSelectedValue = filtered.length === 1 ? filtered[0].dimensionValue : undefined;
    }
    if (!result.companyResourceDimensionSelectedValue) {
        const filtered = result.companyResourceDimension.filter((v, i, a) => v.countOfOffers > 0);
        result.companyResourceDimensionSelectedValue = filtered.length === 1 ? filtered[0].dimensionValue : undefined;
    }
    if (!result.timeDimensionSelectedValue) {
        const filtered = result.timeDimension.filter((v, i, a) => v.countOfOffers > 0);
        result.timeDimensionSelectedValue = filtered.length === 1 ? filtered[0].dimensionValue : undefined;
    }
    if (!result.dayPartDimensionSelectedValue) {
        const filtered = result.dayPartDimension.filter((v, i, a) => v.countOfOffers > 0);
        result.dayPartDimensionSelectedValue = filtered.length === 1 ? filtered[0].dimensionValue : undefined;
    }
    if (!result.dateDimensionSelectedValue) {
        const filtered = result.dateDimension.filter((v, i, a) => v.countOfOffers > 0);
        result.dateDimensionSelectedValue = filtered.length === 1 ? filtered[0].dimensionValue : undefined;
    }

    return result;
}

export const changeSelectedDimension = createAsyncThunk(
    'thunk/changeSelectedDimension',
    async (args: {
        dimensionValue: InviteDimension,
        dimensionType: DimensionTypes,
    }, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const { dimensionValue, dimensionType } = args;

        if (state.invite.currentInvite && state?.invite?.serverInvite) {
            const currentInvite = { ...state.invite.currentInvite };
            if (dimensionValue.countOfOffers === 0) {
                currentInvite.durationDimensionSelectedValue = undefined;
                currentInvite.priceDimensionSelectedValue = undefined;
                currentInvite.companyServiceDimensionSelectedValue = undefined;
                currentInvite.companyDimensionSelectedValue = undefined;
                currentInvite.companyResourceDimensionSelectedValue = undefined;
                currentInvite.timeDimensionSelectedValue = undefined;
                currentInvite.dayPartDimensionSelectedValue = undefined;
                currentInvite.dateDimensionSelectedValue = undefined;
            }
            switch (dimensionType) {
                case "duration": currentInvite.durationDimensionSelectedValue =
                    currentInvite.durationDimensionSelectedValue === dimensionValue.dimensionValue
                        ? undefined : dimensionValue.dimensionValue; break;
                case "price": currentInvite.priceDimensionSelectedValue =
                    currentInvite.priceDimensionSelectedValue === dimensionValue.dimensionValue
                        ? undefined : dimensionValue.dimensionValue; break;
                case "service": currentInvite.companyServiceDimensionSelectedValue =
                    currentInvite.companyServiceDimensionSelectedValue === dimensionValue.dimensionValue
                        ? undefined : dimensionValue.dimensionValue; break;
                case "when": currentInvite.dateDimensionSelectedValue =
                    currentInvite.dateDimensionSelectedValue === dimensionValue.dimensionValue
                        ? undefined : dimensionValue.dimensionValue; break;
                case "what_time": currentInvite.timeDimensionSelectedValue =
                    currentInvite.timeDimensionSelectedValue === dimensionValue.dimensionValue
                        ? undefined : dimensionValue.dimensionValue; break;
                case "day_part": currentInvite.dayPartDimensionSelectedValue =
                    currentInvite.dayPartDimensionSelectedValue === dimensionValue.dimensionValue
                        ? undefined : dimensionValue.dimensionValue; break;
                case "company": currentInvite.companyDimensionSelectedValue =
                    currentInvite.companyDimensionSelectedValue === dimensionValue.dimensionValue
                        ? undefined : dimensionValue.dimensionValue; break;
                case "resource": currentInvite.companyResourceDimensionSelectedValue =
                    currentInvite.companyResourceDimensionSelectedValue === dimensionValue.dimensionValue
                        ? undefined : dimensionValue.dimensionValue; break;
            }
            const nextInvite = rebuildInvite(currentInvite, state?.invite?.serverInvite);
            return {
                currentInvite: nextInvite,
            }
        }
    },
)
export const clearSelectedDimensions = createAsyncThunk(
    'thunk/clearSelectedDimensions',
    async (args: {
    }, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        if (state.invite.currentInvite && state?.invite?.serverInvite) {
            const currentInvite = { ...state.invite.currentInvite };
            currentInvite.durationDimensionSelectedValue = undefined;
            currentInvite.priceDimensionSelectedValue = undefined;
            currentInvite.companyServiceDimensionSelectedValue = undefined;
            currentInvite.companyDimensionSelectedValue = undefined;
            currentInvite.companyResourceDimensionSelectedValue = undefined;
            currentInvite.dateDimensionSelectedValue = undefined;
            currentInvite.timeDimensionSelectedValue = undefined;
            currentInvite.dayPartDimensionSelectedValue = undefined;

            const nextInvite = rebuildInvite(currentInvite, state?.invite?.serverInvite);

            return {
                currentInvite: nextInvite,
            }
        }
    },
)

export const clearVisitIDFromCurrentInvite = createAsyncThunk(
    'thunk/clearVisitIDFromCurrentInvite',
    async (args: {
    }, thunkAPI) => {
        const state = (thunkAPI.getState() as RootState).invite;
        const currentInvite = state.currentInvite;
        if (!currentInvite) return;

        currentInvite.visitIDToBeReplaced = undefined;
        return {
            currentInvite: currentInvite
        }
    },
)