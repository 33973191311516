import { Paper, PaperProps } from "@mui/material";
import * as React from 'react';
import { useState } from 'react';

export const EmiPaper = (props: PaperProps & {
    elevationOnMouseOver?: number,
    elevationOnNoMouse?: number,
    classOnMouseOver?: string,
    classOnNoMouse?: string,
}) => {
    const [mouseOver, setMouseOver] = useState(false);
    function handleMouseEnter(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        setMouseOver(true);
    }
    function handleMouseLeave(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        setMouseOver(false);
    }
    const {
        elevationOnMouseOver,
        elevationOnNoMouse,
        classOnMouseOver,
        classOnNoMouse,
        className,
        ...paperProps } = props;
    return (
        <Paper onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            {...paperProps}
            elevation={mouseOver
                ? (elevationOnMouseOver ?? 10)
                : (elevationOnNoMouse ?? 1)}
            className={className
                + " " + (mouseOver ? classOnMouseOver : classOnNoMouse)}
        >
        </Paper>
    );
};