import { useEffect } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import { refreshHints } from "./search.actions";

export const useSearchCategoriesAutoLoaded = () => {
    const dispatch = useAppDispatch();
    const { searchCategories, searchHintsInProgress } = useAppSelector((state: RootState) => state.search);

    useEffect(() => {
        if ((searchCategories?.results?.length ?? 0) === 0) {
            dispatch(refreshHints({}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { searchCategories, searchHintsInProgress };
};
