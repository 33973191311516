import { CircularProgress, Fab, Tooltip } from "@mui/material";
import * as React from 'react';
import { useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { toggleFavorites } from '../../store/user/user.actions';
import { L10N } from '../pages/L10N';
import { CspIcon } from "./CspIcon";
import { theme } from "./Theme";

export const FavoriteIcon = (props: {
    companyID: string,
    className?: string,
}) => {
    const dispatch = useAppDispatch();
    const { user, inProgress, inProgressFavoriteCompanyID } = useAppSelector((state: RootState) => state.user);
    const [showTooltip, setShowTooltip] = useState(false);

    async function handleFavClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        event.preventDefault();
        event.stopPropagation();

        if (!user) {
            setShowTooltip(true);
            return;
        } else {
            setShowTooltip(false);
        }
        dispatch(toggleFavorites({ user: user, companyID: props.companyID }));
    }
    function isFavOfUser(): boolean {
        if (!user) return false;
        const isFav = user?.favoriteCompanyIDs?.find((v, i, a) => v === props.companyID);
        return isFav ? true : false;
    }
    const isChecked = isFavOfUser();
    return (<Tooltip title={user ? "" : L10N({ key: "Login to finalize" })}
        open={showTooltip}
        onClose={() => setShowTooltip(false)}
        leaveDelay={2000}>
        <div>
            <Fab size="small" disabled={!Boolean(user)}
                className={props.className}
                sx={{
                    bgcolor: isChecked ? theme.palette.primary.main : theme.palette.info.main,
                }}
                color={(isChecked ? "secondary" : "default")}
                onClick={handleFavClick}
            >
                {inProgress && inProgressFavoriteCompanyID === props.companyID
                    ? <CircularProgress />
                    : <CspIcon name="FavoriteBorderOutlinedIcon" />
                }
            </Fab>
        </div>
    </Tooltip >);
};
