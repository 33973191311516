import { AccessTokenBearer, BeePhoto, PriceAndCurrency } from "./Global";

export interface ConfirmedVisit {
    visitID: string,
    visitStartForClient: string,
    visitEndForClient: string,
    visitNotes: string,
    visitStatus: VisitStatusEnum,
    paymentUpfront: boolean,
    paymentCompleted: boolean,
    visitCompleted: boolean,
    visitReviewID?: string,
    remarks: string,
    priceAndCurrency: PriceAndCurrency,
    photos: BeePhoto[],
    addressLine: string,
    companyID: string,
    companyName: string,
    companyCategory: string,
    resourceID: string,
    resourceName: string,
    resourcePhotos: BeePhoto[],
    serviceID: string,
    serviceName: string,
    classType: string,
    offerID: string,
}
export interface CreateVisitMessage extends AccessTokenBearer {
    visitIDToBeReplaced?: string,
    offeredResourceID: string,
    visitStartForClient: string,
    visitNotes: string,
}
export interface UpdateVisitMessage extends AccessTokenBearer {
    visitID: string,
    shouldCancel?: boolean,
    shouldDelete?: boolean,
}
export interface GetVisitMessage extends AccessTokenBearer {
    visitID: string,
}
export type VisitStatusEnum =
    "Draft"
    | "Requested"
    | "Confirmed"
    | "Done"
    | "CanceledByClient"
    | "CanceledByCompany"
    | "RescheduledByClient"
    ;
export const VisitStatusEnumValuesArray: VisitStatusEnum[] =
    [
        "Draft"
        , "Requested"
        , "Confirmed"
        , "Done"
        , "CanceledByClient"
        , "CanceledByCompany"
        , "RescheduledByClient"
    ];