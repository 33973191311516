import { Accordion, AccordionDetails, AccordionSummary, Button } from "@mui/material";
import { useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AssignCompanyMessage } from '../../api/models/Edit';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { assignCompany } from '../../store/user/user.actions';
import { CompanyDetailsViewTabPortfolio } from '../details/CompanyDetailsViewTabPortfolio';
import { CompanyDetailsViewTabReviews } from '../details/CompanyDetailsViewTabReviews';
import { CompanyDetailsViewTabServices } from '../details/CompanyDetailsViewTabServices';
import { L10N } from '../pages/L10N';
import { LogotypeTokens } from "../ui/LogotypeSpans";
import { StyledTitleRow } from '../ui/StyledTitle';
import { CompanyDetailsCard } from "./CompanyDetailsCard";
import { CompanyQRCode } from "./CompanyQRCode";

export const CompanyDetailsView = (props: {
    defaultExpanded: boolean,
}) => {
    const dispatch = useAppDispatch();
    // const [currentTab, setCurrentTab] = useState("services");
    const { company } = useAppSelector((state: RootState) => state.details);
    const { user } = useAppSelector((state: RootState) => state.user);
    const ref = useRef<HTMLDivElement | null>(null);

    function onBookClick() {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
    function onAssignCompanyClick() {
        if (!user) return;
        if (!company) return;

        const assignCompanyMessage: AssignCompanyMessage = {
            accessToken: user.accessToken,
            companyID: company.itemID,
        };
        dispatch(assignCompany({ message: assignCompanyMessage }));
    }
    return (<>
        <div ref={ref} />
        <StyledTitleRow text={<LogotypeTokens text={company?.title} />} />
        {company && <>
            <CompanyDetailsCard company={company} />
            <Container className="my-5 p-0">
                <StyledTitleRow text={L10N({ key: "Classes" })} />
                <Row>
                    <Col>
                        <CompanyDetailsViewTabServices key={"services"} onBookClick={onBookClick} />
                    </Col>
                </Row>
                <StyledTitleRow text={L10N({ key: "Reviews" })} />
                <Row>
                    <Col>
                        <CompanyDetailsViewTabReviews key={"reviews"} />
                    </Col>
                </Row>
                {company?.portfolio?.length > 0 && <>
                    <StyledTitleRow text={L10N({ key: "Certificates" })} />
                    <Row>
                        <Col />
                        <Col className="col-auto">
                            <CompanyDetailsViewTabPortfolio key={"portfolio"} />
                        </Col>
                        <Col />
                    </Row>
                </>}
                {company && <>
                    <StyledTitleRow text={L10N({ todo: "Your unique QR Code" })} />
                    <Row>
                        <Col />
                        <Col xs={12} md={6} lg={4}>
                            <CompanyQRCode company={company} />
                        </Col>
                        <Col />
                    </Row>
                </>}
                {/* <StyledTitleRow text={L10N({ key: "Details" })}  /> */}
                {/* <CompanyDetailsViewTabDetails key={"details"} /> */}
                {user?.isSuperAdmin === true &&
                    <Row >
                        <Col>
                            <Container >
                                <Row className="mx-n5">
                                    <Col >
                                        <Accordion defaultExpanded={false}>
                                            <AccordionSummary>{L10N({ key: "Learn more" })}</AccordionSummary>
                                            <AccordionDetails className="d-block">
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={onAssignCompanyClick}>
                                                    {L10N({ key: "SuperAdmin: Assign Company" })}
                                                </Button>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </Container>

                        </Col>
                    </Row>
                }
            </Container >
        </>}
    </>);
};
