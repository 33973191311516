import { Button, TextField } from "@mui/material";
import { useEffect } from 'react';
import { MapCoords } from '../../api/models/Details';
import { CompanyEditModel } from '../../api/models/Edit';
import { loadCoordsFromAddress } from '../../store/edit/edit.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { CompanyMapView } from '../details/CompanyMapView';
import { L10N } from '../pages/L10N';
import { Animations } from '../ui/Animations';
import { EmiPaper } from '../ui/EmiPaper';
import { useFormikResult } from '../ui/FormikAPI';

export const FieldSetCompanyAddress = (props: {
    formik: useFormikResult<CompanyEditModel>,
}) => {
    const dispatch = useAppDispatch();
    const { mapCoords } = useAppSelector((state: RootState) => state.edit);
    function onMapClick(coords: MapCoords) {
        props.formik.setFieldValue("mapCoords", coords);
    }
    useEffect(() => {
        if (!mapCoords) return;
        props.formik.setFieldValue("mapCoords", mapCoords);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapCoords]);

    async function refreshCoords() {
        await dispatch(loadCoordsFromAddress({ address: props.formik.values.address ?? "" }));
    }
    return <>
        <EmiPaper className="p-3 mb-3">
            <TextField variant="outlined" className="mb-3" fullWidth
                multiline rows={5}
                id="address" name="address" label={L10N({ key: "Address" })}
                value={props.formik.values.address}
                onChange={props.formik.handleChange}
                error={props.formik.touched.address && Boolean(props.formik.errors.address)}
                helperText={props.formik.touched.address && props.formik.errors.address}
            /><br />
            {props.formik.dirty && <>
                <Button className={"mb-3 " + Animations.swing_in_top_fwd()}
                    onClick={refreshCoords}
                    variant="contained"
                    color="primary"
                >
                    {L10N({ key: "Update map" })}
                </Button><br />
            </>
            }
            <CompanyMapView onMapClick={onMapClick}
                company={{
                    address: '',
                    category: props.formik.values.category ?? "",
                    mapCoords: {
                        "lat": props.formik.values.mapCoords?.lat ?? 0,
                        "lng": props.formik.values.mapCoords?.lng ?? 0,
                    },
                    serviceGroups: [],
                    resources: [],
                    portfolio: [],
                    reviews: [],
                    itemID: '',
                    itemType: 'Company',
                    title: '',
                    subtitle: '',
                    description: '',
                    htmlDescription: '',
                    learnMoreURL: '',
                    photos: [],
                    isDemo: true,
                    isFavorite: false,
                }} />
        </EmiPaper>
    </>;
}
