import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { IconButton, Snackbar, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useEffect, useState } from 'react';
import { api } from '../../api/api';
import { AccessTokenBearer, SignalMessage } from '../../api/models/Global';
import { setSignalMessages } from '../../store/company/company.reducer';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { L10N } from '../pages/L10N';
import { CspIcon } from '../ui/CspIcon';

const useStyles = makeStyles((theme) =>
    createStyles({
        snackbar: {
            "& .MuiSnackbarContent-root": {
                backgroundColor: 'transparent',
            },
        },
    })
);
export const SignalListener = (props: {
    onClose?: () => Promise<void>,
}) => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state: RootState) => state.user);
    const { signalMessages } = useAppSelector((state: RootState) => state.company);
    const [connection, setConnection] = useState<HubConnection | null>(null);
    const classes = useStyles();

    useEffect(() => {
        if (!user?.accessToken) {
            setConnection(null);
            return;
        }
        if (connection) return;

        const newConnection = new HubConnectionBuilder()
            .withUrl(api.signalrUrl)
            .withAutomaticReconnect()
            .build();
        setConnection(newConnection);
        return () => {
            newConnection?.stop();
            setConnection(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {

        if (connection && connection.state === "Disconnected") {
            connection.start()
                .then(result => {
                    if (user?.accessToken) {
                        const bearer: AccessTokenBearer = {
                            accessToken: user.accessToken,
                        };
                        connection.send('JoinAsUser', bearer);
                    }
                    connection.on('ReceiveMessage', async message => {
                        await dispatch(setSignalMessages({ signalMessages: [...signalMessages ?? [], message] }));
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection, user, dispatch, signalMessages]);

    async function handleClose(data: SignalMessage) {
        if (!signalMessages) return;
        await dispatch(setSignalMessages({ signalMessages: signalMessages.filter(item => item !== data) }));
        if (props.onClose) {
            await props.onClose();
        }
    }
    const Message = (props: {
        data: SignalMessage,
    }) => {

        return (
            <Snackbar open={true}
                className={" " + classes.snackbar}
                onClose={() => handleClose(props.data)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                message={
                    <div >
                        <Typography variant="body2">
                            {L10N({ key: "_dateAsTimeAgo", date: props.data.timeStamp })}
                        </Typography>
                        <Typography variant="body1">
                            {L10N({ key: "_signalMessageType", signalMessageType: props.data.messageType })}
                        </Typography>
                    </div>
                }
                action={<>
                    <IconButton size="small" onClick={() => handleClose(props.data)} className="bg-gradient-bee-diagonal text-white">
                        <CspIcon name="DoneIcon" fontSize="small" />
                    </IconButton>
                </>} />
        );
    }
    return (
        <div>
            {signalMessages && signalMessages.map(m =>
                <Message key={m.timeStamp + m.itemID + m.messageType}
                    data={m} />)
            }
        </div>
    );
};