import { CircularProgress } from "@mui/material";
import { useState } from 'react';

interface ImageLoaderProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    // add additional props here if needed
}
export const ImageLoader: React.FC<ImageLoaderProps> = ({ src, alt, ...props }) => {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <div>
            {isLoading && <CircularProgress />}
            <img
                src={src}
                alt={alt}
                onLoad={() => setIsLoading(false)}
                onError={() => setIsLoading(false)}
                style={{ display: isLoading ? 'none' : 'block' }}
                {...props}
            />
        </div>
    );
};