import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { berlinMapCoords, MapCoords } from '../../api/models/Details';
import { SearchResult } from '../../api/models/Search';
import { changeInvite } from '../../store/invite/invite.actions';
import { changeSearchString, refreshHints } from '../../store/search/search.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { SearchBar } from '../search/SearchBar';
import { SearchCategorySelect } from '../search/SearchCategorySelect';
import { SearchWhenSelect } from '../search/SearchWhenSelect';
import { Animations } from '../ui/Animations';
import { CategoryIconURL } from '../ui/CategoryIcon';
import { L10N } from './L10N';
import { AppRoutes } from './Routes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'absolute',
            left: 0,
            right: 0,
        },
        rowSearch1: {
            position: 'absolute',
            zIndex: 2,
            top: theme.spacing(1),
            width: '100%',
        },
        rowSearch2: {
            position: 'absolute',
            zIndex: 2,
            top: theme.spacing(9),
            width: '100%',
        },
        mapLabel: {
            maxWidth: '140px',
            whiteSpace: 'normal',
            color: 'white !important',
            borderRadius: '8px',
            border: '2px white solid',
            padding: '8px',
        },
    })
);
export const SearchMapView = (props: {
    height?: string,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { searchString, currentLocation, searchResults, featuredResults } = useAppSelector((state: RootState) => state.search);
    const { createInviteMessage } = useAppSelector((state: RootState) => state.invite);
    const mapRef = useRef<GoogleMap>(null);
    const classes = useStyles();

    useMemo(() => dispatch(refreshHints({})), [dispatch]);

    const mapContainerStyle = {
        width: '100%',
        height: props.height ?? '90vh',
    };
    const options = {
        disableDefaultUI: true,
        mapId: "3b47b43fdae52c1f",
        zoomControlOptions: {
        }
    }
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAAB-pgbzDAcfFU2Ej9W3kWjT8dcPpvbIE",
        mapIds: ["3b47b43fdae52c1f"],
    })
    function onLoad(mapInstance: any) {
        navigator?.geolocation.getCurrentPosition(
            ({ coords: { latitude: lat, longitude: lng } }) => {
                const pos = { lat, lng };
                dispatch(changeSearchString({ searchString: searchString, currentLocation: pos }));
            }
        );
    }
    async function handleClick(item: SearchResult) {
        if (item.offeredResourceID === createInviteMessage?.offeredResourceID) {
            await navigate(AppRoutes.invitePage());
        } else {
            await dispatch(changeInvite({
                message: {
                    query: searchResults?.query ?? "",
                    offeredResourceID: item.offeredResourceID,
                }
            }));
        }
    }
    function initialCoords(): MapCoords {
        if (!searchResults?.results) return berlinMapCoords;

        const first = searchResults?.results.find(v => v.mapCoords);
        return first?.mapCoords ?? berlinMapCoords;
    }
    function iconUrl(white: boolean, item?: SearchResult): any {
        const iconName = item?.subtitle ?? "anything";
        const url = CategoryIconURL(iconName, white);
        return url;
    }
    function renderMarker(featured: boolean, m: SearchResult): JSX.Element {
        // const scale = 0.4;
        // const bgIcon = {
        //     url: iconUrl(true),
        //     scaledSize: {
        //         height: 100 * scale,
        //         width: 100 * scale,
        //     },
        //     anchor: {
        //         x: 61.5 * scale,
        //         y: 61.5 * scale,
        //     },
        // };
        // const scaleCat = 0.5;
        // const catIcon = {
        //     url: iconUrl(false, m),
        //     scaledSize: {
        //         height: 40 * scaleCat,
        //         width: 40 * scaleCat,
        //     },
        //     anchor: {
        //         x: 31.5 * scaleCat,
        //         y: 31.5 * scaleCat,
        //     },
        // };
        const keyChar = featured ? "F" : "S";
        return (<React.Fragment
            key={keyChar + "0" + m.offeredResourceID.join(keyChar + "0")}
        >
            <Marker
                key={keyChar + "1" + m.offeredResourceID.join(keyChar + "1")}
                // icon={bgIcon}
                position={m.mapCoords ?? berlinMapCoords}
                label={m.offeredResourceID === createInviteMessage?.offeredResourceID
                    ? {
                        text: m.title,
                        className: "bg-gradient-bee-diagonal " + classes.mapLabel
                    }
                    : undefined}
                onClick={() => handleClick(m)}>
                <div>
                    {m.title}
                </div>
            </Marker>
            <Marker
                key={keyChar + "2" + m.offeredResourceID.join(keyChar + "2")}
                // icon={catIcon}
                position={m.mapCoords ?? berlinMapCoords}
                onClick={() => handleClick(m)}>
                <div>
                    {m.title}
                </div>
            </Marker>
        </React.Fragment>);
    }
    const renderMap = () => {
        return <GoogleMap
            options={options}
            onLoad={onLoad}
            onClick={(e) => console.log(JSON.stringify({ lat: e.latLng?.lat(), lng: e.latLng?.lng() }))}
            center={currentLocation ?? initialCoords()}
            ref={mapRef}
            zoom={currentLocation ? 15 : 6}
            mapContainerStyle={mapContainerStyle}>
            {searchResults?.results?.map && searchResults?.results?.map((m, i) => renderMarker(false, m))}
            {featuredResults?.results?.map && featuredResults?.results?.map((m, i) => renderMarker(true, m))}
            {currentLocation && <Marker position={currentLocation}></Marker>}
        </GoogleMap >
    }

    if (loadError) {
        return <div>{L10N({ key: "Map cannot be loaded" })}</div>
    }

    return isLoaded ? renderMap() : <>{L10N({ key: "loading..." })}</>
};

export const ExplorePage = () => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(true);

    function handleClickMore() {
        setExpanded(!expanded);
    }
    return (
        <Container className={classes.container}>
            <Row className={classes.rowSearch1}>
                <SearchBar handleClickMore={handleClickMore} />
            </Row>
            {expanded && <Row className={classes.rowSearch2 + " " + Animations.slide_in_elliptic_top_fwd()}>
                <Col >
                    <SearchWhenSelect />
                </Col>
                <Col >
                    <SearchCategorySelect />
                </Col>
            </Row>
            }
            <Row>
                <SearchMapView />
            </Row>
        </Container>
    );
}
