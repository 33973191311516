import { ImageList, ImageListItem, useMediaQuery } from "@mui/material";
import { api } from "src/api/api";
import { IHavePhotos } from "src/api/models/Details";
import { BeePhoto } from "src/api/models/Global";
import { Animations } from "./Animations";
import { theme } from "./Theme";
import { useIsVisible } from "./useIsVisible";

export const CspImageList = (props: {
    photos?: BeePhoto[],
    havePhotos?: IHavePhotos,
    another?: JSX.Element,
    other?: JSX.Element[],
    moreColumns?: boolean,
    animationFromRight?: boolean,
}) => {
    const { ref, wasVisible } = useIsVisible<HTMLUListElement>();
    const screenSMLess = useMediaQuery(theme.breakpoints.down('sm'));
    const animationClassName = (index: number) => {
        const animation = props.animationFromRight
            ? Animations.slide_in_blurred_right()
            : Animations.slide_in_blurred_left()
        return animation + Animations.delay(index);
    }
    const photos = [
        ...(props.photos ?? []),
        ...(props.havePhotos?.photos ?? []),
    ];
    return (<>
        {photos &&
            <ImageList ref={ref}
                style={{ overflow: "hidden" }}
                variant="masonry"
                cols={screenSMLess ? 1
                    : props.moreColumns ? 2
                        : 1}
                gap={8}
            >
                {photos.map((m, i) => (
                    <ImageListItem key={m.objectID + "#" + m.photoIndex}
                        sx={{
                            borderRadius: theme.shape.borderRadius + 'px',
                            overflow: 'clip',
                        }}
                        className={wasVisible ? animationClassName(i) : ""}
                    >
                        <img
                            srcSet={`${api.Search.getPhotoUrl(m)}`}
                            src={`${api.Search.getPhotoUrl(m)}`}
                            alt={"photo#" + m.photoIndex}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
                {props.another &&
                    <ImageListItem
                        sx={{
                            borderRadius: theme.shape.borderRadius + 'px',
                            overflow: 'clip',
                        }}
                        className={wasVisible ? animationClassName(photos.length + 1) : ""}
                    >
                        {props.another}
                    </ImageListItem>
                }
                {props.other && props.other.map((m, i) =>
                    <ImageListItem key={"other#" + i}
                        sx={{
                            borderRadius: theme.shape.borderRadius + 'px',
                            overflow: 'clip',
                        }}
                        className={wasVisible ? animationClassName(photos.length + 1) : ""}
                    >
                        {m}
                    </ImageListItem>)}
            </ImageList>
        }
    </>
    );
};
