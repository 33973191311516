import { SearchCategory_Anything, SearchCategoryValues } from 'src/api/models/Search';
import { L10N } from '../pages/L10N';
import { Typography } from '@mui/material';
import { LogotypeSpans } from "./LogotypeSpans";

export function CategoryIconURL(categoryName: string, white?: boolean): string {
    const fileType = ".svg";
    const src = white
        ? `/img/categories/${categoryName}-1${fileType}`
        : `/img/categories/${categoryName}${fileType}`;

    return src;
}
export const CategoryIcon = (props: {
    name: SearchCategoryValues,
    white?: boolean,
    className?: string,
    size?: 'small' | 'default' | 'full',
}) => {
    const src = CategoryIconURL(props.name, props.white);
    function getSize() {
        switch (props.size) {
            case "small": return 20;
            case "full": return undefined;
            default: return 50;
        }
    }
    return <img src={src}
        style={{
            maxWidth: getSize(),
            maxHeight: getSize(),
        }}
        className={props.className}
        alt={L10N({ key: "_category", cat: props.name })} />
};

export const CategoryDescription = (props: {
    categoryName: SearchCategoryValues,
}) => {
    if (props.categoryName === SearchCategory_Anything) {
        return (<>
            <Typography color="secondary">
                Selecting a specialization in <LogotypeSpans /> is a powerful way to announce your primary area of expertise.
                <br />
                It helps to find students in the field that excites you.
                <br />
                <br />
                Whether you're passionate about:<br />
                - technology <CategoryIcon name='stem' size='small' />,
                <br />
                - arts<CategoryIcon name='arts' size='small' />,
                <br />
                - business <CategoryIcon name='biz' size='small' /> or
                <br />
                - aviation <CategoryIcon name='aviation' size='small' />,
                <br />
                choosing the right specialization allows you to make a lasting impact in students lives.
                <br />
                Take the step today to invest in a future that reflects who you are and what you aim to achieve!
                <br />
            </Typography>
        </>)
    }
    else if (props.categoryName === "arts") {
        return (<>
            <Typography color="secondary">
                Arts and creativity encompass a wide array of disciplines focused on imaginative expression,
                skill development, and the cultivation of personal style.
                <br />
                <br />
                As much as the discipline is wide and almost limitless, we at <LogotypeSpans /> focus around:
                <br />
                - Visual Arts (Painting, Drawing, and Digital art)
                <br />
                - Music Performance (singing, piano, violin and other instruments)
                <br />
                - Architecture (with special focus on exam preps),
                <br />
                - Design (graphic and product)
            </Typography>
        </>)
    }
    else if (props.categoryName === "aviation") {
        return (<>
            <Typography color="secondary">
                Aviation specialization encompasses various fields, each vital to ensuring the smooth and safe operation of air travel.
                <br />
                As the aviation industry grows, so does the need for experts across different sectors, contributing to the efficiency and safety of the entire system.
                <br />
                <br />
                In <LogotypeSpans /> we help students learn in these areas:
                <br />
                - Pilots and Crew,
                <br />
                - Aircraft Maintenance,
                <br />
                - Air Traffic Control,
                <br />
                - Aviation Travel and Customer Service.
                <br />
            </Typography>
        </>)
    }
    else if (props.categoryName === "stem") {
        return (<>
            <Typography color="secondary">
                STEM (Science, Technology, Engineering, and Mathematics) represents a broad and dynamic field
                that shapes the way we understand and interact with the world.
                <br />
                <br />
                In <LogotypeSpans /> we help students of various levels:
                <br />
                - Primary and Secondary (Class 1-10; CBSE, ICSE and state boards)
                <br />
                - Senior Secondary (Class 11-12; prepare for higher studies),
                <br />
                - Undergraduate (B.Sc., B.Tech, B.A.)
                <br />
                - Competitive Exams and Olympiads
                <br />
            </Typography>
        </>)
    }
    else if (props.categoryName === "biz") {
        return (<>
            <Typography color="secondary">
                Specialization in Business and Commerce focuses on advanced knowledge in areas like:
                <br />
                - management,
                <br />
                - finance,
                <br />
                - accounting,
                <br />
                - and marketing,
                <br />
                preparing individuals for leadership roles in various sectors.
                <br />
                <br />

                In <LogotypeSpans /> we believe these specializations are crucial for navigating
                the complex dynamics of business operations in a global economy.
                <br />
            </Typography>
        </>)
    }
    else if (props.categoryName === "personal") {
        return (<>
            <Typography color="secondary">
                Personal development focuses on:
                <br />
                - Career Development,
                <br />
                - Health & Wellness,
                <br />
                - Leadership,
                <br />
                - and Coaching,
                <br />
                to foster personal and professional growth.
                <br />
                <br />
                With <LogotypeSpans /> individuals build resilience, emotional intelligence, and effective habits
                that enable success in various areas of life.
                <br />
            </Typography>
        </>)
    }
    else if (props.categoryName === "lang") {
        return (<>
            <Typography color="secondary">
                Specialization in Business and Commerce focuses on advanced knowledge in areas like:
                <br />
                - management,
                <br />
                - finance,
                <br />
                - accounting,
                <br />
                - and marketing,
                <br />
                preparing individuals for leadership roles in various sectors.
                <br />
                <br />

                In <LogotypeSpans /> we believe these specializations are crucial for navigating
                the complex dynamics of business operations in a global economy.
                <br />
            </Typography>
        </>)
    }
    else if (props.categoryName === "human") {
        return (<>
            <Typography color="secondary">
                Human and social sciences focus on the study of human behavior, societies, cultures,
                and how individuals interact with their environments.
                <br />
                Specializations in this field aim to understand the complexities of human life,
                from the psychological and social to the economic and political aspects.
                <br />
                - Psychology,
                <br />
                - Sociology,
                <br />
                - Political Science,
                <br />
                - History,
                <br />
                - Philosophy
                <br />
                are all examples of the fields of focus at <LogotypeSpans />.
                <br />
            </Typography>
        </>)
    } else {
        return (<>
            To be defined
        </>)
    }
}