import { Col, Container, Row } from 'react-bootstrap';
import { BackArrowHeader } from './BackArrowHeader';
import { L10N } from './L10N';

export const PrivacyPolicyPage = (props: {}) => {

    return (<>
        {<Container>
            <Row className="my-3" />
            <BackArrowHeader title={L10N({ key: "Privacy Policy" })} />
            <Row className="my-5">
                <Col />
                <Col className="col-auto">
                    <img src={`/img/icons/notebook.png`} alt="heart" width="150" />
                </Col>
                <Col />
            </Row>
            <Row className="mb-3">
                <Col>
                    <p><strong><span>Contact</span></strong></p>
                    <p>
                        <span>
                            <a href="https://classnow.in">
                                <span>classnow.in</span>
                            </a>
                        </span>
                        <br />
                        <span>
                            E-Mail:</span>
                        <span>
                            <a href="mailto:info@classnow.in">
                                <span>info@classnow.in</span>
                            </a>
                        </span>
                    </p>
                </Col>
            </Row>
        </Container >}
    </>
    );
};
