import { createSlice } from '@reduxjs/toolkit';
import { CompanyDetails, ResourceDetails, ServiceDetails } from '../../api/models/Details';
import { ConfirmedVisit } from '../../api/models/Visit';
import { addInProgressCases, addInProgressMatcher } from '../reduxHelpers';
import { loadAllDetails, loadCompanyDetails, loadResourceDetails, loadServiceDetails, loadVisitAndAllDetails, updateCompanyDetails } from './details.actions';

export interface DetailsState {
    inProgress: boolean;
    visit?: ConfirmedVisit;
    company?: CompanyDetails;
    service?: ServiceDetails;
    resource?: ResourceDetails;
}
const initialState: DetailsState = {
    inProgress: false,
    visit: undefined,
    company: undefined,
    service: undefined,
    resource: undefined,
}
const detailsSlice = createSlice({
    name: 'detailsSlice',
    initialState,
    reducers: {
        clearAllDetails(state) {
            state.visit = undefined;
            state.company = undefined;
            state.service = undefined;
            state.resource = undefined;
        },
        clearVisitDetails(state) {
            state.visit = undefined;
        },
        clearCompanyDetails(state) {
            state.company = undefined;
        },
        clearServiceDetails(state) {
            state.service = undefined;
        },
        clearResourceDetails(state) {
            state.resource = undefined;
        },
    },
    extraReducers: (builder) => {
        addInProgressCases(builder, loadAllDetails);
        addInProgressCases(builder, loadVisitAndAllDetails);
        addInProgressCases(builder, loadCompanyDetails);
        addInProgressCases(builder, loadServiceDetails);
        addInProgressCases(builder, loadResourceDetails);
        addInProgressCases(builder, updateCompanyDetails);

        builder.addCase(loadAllDetails.fulfilled, (state, action) => {
            state.visit = action.payload.visit;
            state.company = action.payload.company;
            state.service = action.payload.service;
            state.resource = action.payload.resource;
        })
        builder.addCase(loadVisitAndAllDetails.fulfilled, (state, action) => {
            state.visit = action.payload.visit;
        })
        builder.addCase(loadCompanyDetails.fulfilled, (state, action) => {
            state.company = action.payload.company;
        })
        builder.addCase(loadServiceDetails.fulfilled, (state, action) => {
            state.service = action.payload.service;
        })
        builder.addCase(loadResourceDetails.fulfilled, (state, action) => {
            state.resource = action.payload.resource;
        })
        builder.addCase(updateCompanyDetails.fulfilled, (state, action) => {
            state.company = action.payload.company;
        })
        addInProgressMatcher(builder, loadAllDetails);
        addInProgressMatcher(builder, loadVisitAndAllDetails);
        addInProgressMatcher(builder, loadCompanyDetails);
        addInProgressMatcher(builder, loadServiceDetails);
        addInProgressMatcher(builder, loadResourceDetails);
        addInProgressMatcher(builder, updateCompanyDetails);
    },
});
export const {
    clearAllDetails,
    clearVisitDetails,
    clearCompanyDetails,
    clearResourceDetails,
    clearServiceDetails
} = detailsSlice.actions
export default detailsSlice.reducer