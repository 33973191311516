import { BottomNavigation, BottomNavigationAction, Box, Typography } from "@mui/material";
import { useState } from "react";
import {useNavigate} from 'react-router-dom';
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { CspDivider } from '../ui/CspDivider';
import { CspIcon } from "../ui/CspIcon";
import { L10N } from './L10N';
import { AppRoutes } from './Routes';

export const NavMenu = (props: {
}) => {
    const isKeyboardOpen = useDetectKeyboardOpen();

    const navigate = useNavigate();
    const [value, setValue] = useState(AppRoutes.home);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        navigate(newValue);
        const targetId = window.location.hash.slice(1); // Removes the '#' from '#hometop123'
        if (targetId) {
            document.getElementById(targetId)?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (<><Box sx={{
        position: isKeyboardOpen ? "static" : "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
    }}>
        <CspDivider shadowTop />
        <BottomNavigation
            value={value}
            onChange={handleChange}
            showLabels>
            <BottomNavigationAction
                label={<Typography
                    color={value === AppRoutes.homeTop ? "primary" : "secondary"}
                >
                    {L10N({ key: "Home" })}
                </Typography>}
                value={AppRoutes.homeTop}
                icon={<CspIcon name="SchoolOutlinedIcon"
                    color={value === AppRoutes.homeTop ? "primary" : "secondary"}
                />}
            />
            <BottomNavigationAction
                label={<Typography
                    color={value === AppRoutes.visitTab() ? "primary" : "secondary"}
                >
                    {L10N({ key: "Classes" })}
                </Typography>}
                value={AppRoutes.visitTab()}
                icon={<CspIcon name="DepartureBoardOutlinedIcon"
                    color={value === AppRoutes.visitTab() ? "primary" : "secondary"}
                />}
            />
            <BottomNavigationAction
                label={<Typography
                    color={value === AppRoutes.favourites ? "primary" : "secondary"}
                >
                    {L10N({ key: "Favorites" })}
                </Typography>}
                value={AppRoutes.favourites}
                icon={<CspIcon name="FavoriteBorderOutlinedIcon"
                    color={value === AppRoutes.favourites ? "primary" : "secondary"}
                />}
            />
            <BottomNavigationAction
                label={<Typography
                    color={value === AppRoutes.profilePage() ? "primary" : "secondary"}
                >
                    {L10N({ key: "Account" })}
                </Typography>}
                value={AppRoutes.profilePage()}
                icon={<CspIcon name="InsertEmoticonOutlinedIcon"
                    color={value === AppRoutes.profilePage() ? "primary" : "secondary"}
                />}
            />
        </BottomNavigation>
    </Box>
    </>
    );
}