import { StudentWizardStage } from "src/api/models/StudentWizard";

export type StudentWizardStep =
    | "About you"
    | "Appearance"
    | "Experience"
    | "Classes"
    | "Availability"
    | "Contact Details"
    | "Summary"
    ;

export function getAllStudentWizardSteps(stage: StudentWizardStage): StudentWizardStep[] {
    switch (stage) {
        case "initial":
        case "initial-submitted":
            return [
                "About you",
                "Appearance",
                "Experience",
                "Classes",
                "Summary",
            ];
        case "reviewed-accepted":
        default:
            return [
                "About you",
                "Appearance",
                "Experience",
                "Classes",
                "Availability",
                "Contact Details",
                "Summary",
            ];
    }
}