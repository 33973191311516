import { TextField } from "@mui/material";
import { Container } from 'react-bootstrap';
import { VisitReviewEditModel } from '../../api/models/Edit';
import { L10N } from '../pages/L10N';
import { EmiPaper } from '../ui/EmiPaper';
import { FormikFieldProps, useFormikResult } from '../ui/FormikAPI';
import { AppBarSubmitCancel } from './AppBarSubmitCancel';

export const FormVisitReviewDetails = (props: {
    formik: useFormikResult<VisitReviewEditModel>,
}) => {
    return <>
        <form onSubmit={props.formik.handleSubmit}>
            <Container>
                <EmiPaper className="p-3 mb-3">
                    <TextField className="mb-3"
                        disabled
                        label={L10N({ key: "Visit Review ID" })}
                        {...FormikFieldProps(props.formik, m => m.visitReviewID)}
                    /><br />
                    <TextField className="mb-3"
                        disabled
                        label={L10N({ key: "Visit ID" })}
                        {...FormikFieldProps(props.formik, m => m.visitID)}
                    /><br />
                    <TextField className="mb-3"
                        label={L10N({ key: "Title" })}
                        {...FormikFieldProps(props.formik, m => m.reviewTitle)}
                    /><br />
                    <TextField className="mb-3"
                        type="number"
                        label={L10N({ key: "Rating Score" })}
                        {...FormikFieldProps(props.formik, m => m.ratingScore)}
                    /><br />
                    <TextField className="mb-3"
                        multiline rows={5}
                        label={L10N({ key: "Details" })}
                        {...FormikFieldProps(props.formik, m => m.reviewDetails)}
                    /><br />
                </EmiPaper>
            </Container>
            {props.formik.dirty && <AppBarSubmitCancel formik={props.formik} />}
        </form>
    </>;
}