import { Breakpoint, useMediaQuery, useTheme } from "@mui/material";
import * as React from 'react';
import { useEffect } from 'react';

export const ScreenSizeListener = (props: {
    onSizeChange?: (size: Breakpoint | "xxs") => void,
    onGridListColsChange?: (cols: number) => void,
    threshold?: Breakpoint | "xxs",
    whenThresholdOrBelow?: React.ReactNode,
    whenAboveThreshold?: React.ReactNode
}) => {
    const theme = useTheme();
    const screenExtraLarge = useMediaQuery(theme.breakpoints.only('xl'));
    const screenLarge = useMediaQuery(theme.breakpoints.only('lg'));
    const screenMedium = useMediaQuery(theme.breakpoints.only('md'));
    const screenSmall = useMediaQuery(theme.breakpoints.only('sm'));
    const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
    const screenNarrow = useMediaQuery('(max-width:340px)');

    useEffect(() => {
        if (screenExtraLarge) {
            if (props.onSizeChange) props.onSizeChange("xl");
            if (props.onGridListColsChange) props.onGridListColsChange(4);
        } else if (screenLarge) {
            if (props.onSizeChange) props.onSizeChange("lg");
            if (props.onGridListColsChange) props.onGridListColsChange(3);
        } else if (screenMedium) {
            if (props.onSizeChange) props.onSizeChange("md");
            if (props.onGridListColsChange) props.onGridListColsChange(3);
        } else if (screenSmall) {
            if (props.onSizeChange) props.onSizeChange("sm");
            if (props.onGridListColsChange) props.onGridListColsChange(2);
        } else if (screenExtraSmall) {
            if (props.onSizeChange) props.onSizeChange("xs");
            if (props.onGridListColsChange) props.onGridListColsChange(1);
        } else if (screenNarrow) {
            if (props.onSizeChange) props.onSizeChange("xxs");
            if (props.onGridListColsChange) props.onGridListColsChange(1);
        } else {
        }
    }, [screenExtraLarge, screenLarge, screenMedium, screenSmall, screenExtraSmall, screenNarrow, props]);
    function WhenThresholdOrBelow(): boolean {
        if (!props.threshold) return false;
        switch (props.threshold) {
            case "xl": return screenExtraLarge
                || screenLarge
                || screenMedium
                || screenSmall
                || screenExtraSmall
                || screenNarrow;
            case "lg": return screenLarge
                || screenMedium
                || screenSmall
                || screenExtraSmall
                || screenNarrow;
            case "md": return screenMedium
                || screenSmall
                || screenExtraSmall
                || screenNarrow;
            case "sm": return screenSmall
                || screenExtraSmall
                || screenNarrow;
            case "xs": return screenExtraSmall
                || screenNarrow;
            case "xxs": return screenNarrow;
        }
    }
    function WhenAboveThreshold(): boolean {
        return !WhenThresholdOrBelow();
    }
    return (<>
        {WhenThresholdOrBelow() && props.whenThresholdOrBelow}
        {WhenAboveThreshold() && props.whenAboveThreshold}
    </>);
} 