import { nameof } from "ts-simple-nameof";

export function formKey<T extends Object>(selector: (obj: T) => any, replace_i?: number): string {
  let result = nameof<T>(selector);
  result = result.replaceAll("[", ".").replaceAll("]", "");
  if (replace_i || replace_i === 0) {
    result = result.replaceAll(".i.", "." + replace_i + ".");
  }
  return result;
}
export function formKeyPrefix<T extends Object>(prefix: string, selector: (obj: T) => any, replace_i?: number): string {
  let result = nameof<T>(selector);
  result = result.replaceAll("[", ".").replaceAll("]", "");
  if (replace_i || replace_i === 0) {
    result = result.replaceAll(".i.", "." + replace_i + ".");
  }
  return prefix + "." + result;
}
export function formKeyWithIndex<T extends Object>(selector: (obj: T) => any, replace_i?: number): string {
  let result = nameof<T>(selector);
  result = result.replaceAll("[", ".").replaceAll("]", "");
  if (replace_i || replace_i === 0) {
    result = result.replaceAll(".i.", "." + replace_i + ".");
  }
  return result;
}