import { Button, ButtonGroup, Card, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ChartScaleEnum, ReferralStatisticsEntry, VisitStatisticsEntry } from '../../api/models/User';
import { RootState, useAppSelector } from '../../store/store';
import { Animations } from '../ui/Animations';
import { TabsSwitch } from '../ui/TabsSwitch';
import { BackArrowHeader } from './BackArrowHeader';
import { L10N } from './L10N';
import { PageHeader } from './PageHeader';
import { AppRoutes } from './Routes';

export const ReferralStatisticsTab = (props: {
    data?: ReferralStatisticsEntry[]
}) => {
    const [activePoint, setActivePoint] = useState<{
        index?: number,
        data?: ReferralStatisticsEntry,
        scale: ChartScaleEnum,
    }>({
        index: props.data && props.data.length > 0
            ? props.data.length - 1
            : undefined,
        data: props.data && props.data.length > 0
            ? props.data[props.data.length - 1]
            : undefined,
        scale: "Day",
    });

    // function onClick(data: any, index: number) {
    //     if (activePoint?.index === index) {
    //         setActivePoint({
    //             scale: activePoint.scale,
    //         });
    //     } else {
    //         setActivePoint({
    //             index: index,
    //             data: data,
    //             scale: activePoint.scale,
    //         });
    //     }
    // }
    function onScaleChange(scale: ChartScaleEnum) {
        setActivePoint({
            index: activePoint.index,
            data: activePoint.data,
            scale: scale,
        });
    }
    return <>
        <Row className="my-2">
            <Col md={3} />
            <Col >
                <Typography variant="subtitle2" align="center">
                    {L10N({ key: "Demo", })}
                </Typography>
            </Col>
            <Col md={3} />
        </Row>
        {props.data && props.data.length > 0
            ?
            <>
                <Row className="mb-3">
                    <Col />
                    <Col className="col-auto">
                        <ButtonGroup size="small">
                            <Button onClick={() => onScaleChange("Day")}
                                color={activePoint.scale === "Day" ? "primary" : "inherit"}
                                variant={activePoint.scale === "Day" ? "contained" : "outlined"}
                            >
                                {L10N({ key: 'Day' })}
                            </Button>
                            <Button onClick={() => onScaleChange("Week")}
                                color={activePoint.scale === "Week" ? "primary" : "inherit"}
                                variant={activePoint.scale === "Week" ? "contained" : "outlined"}
                            >
                                {L10N({ key: 'Week' })}
                            </Button>
                            <Button onClick={() => onScaleChange("Month")}
                                color={activePoint.scale === "Month" ? "primary" : "inherit"}
                                variant={activePoint.scale === "Month" ? "contained" : "outlined"}
                            >
                                {L10N({ key: 'Month' })}
                            </Button>
                        </ButtonGroup>
                    </Col>
                    <Col />
                </Row>
                <Row className="mb-3">
                    <Col sm={0} md={3} />
                    <Col sm={12} md={6}>
                        {/* <ResponsiveContainer width={'100%'} height={300} className="mx-auto">
                            <ComposedChart
                                data={props.data.filter(m => m.scale === activePoint.scale)}
                            >
                                <CartesianGrid stroke="#f5f5f5" />
                                <XAxis dataKey="date"
                                    type="category"
                                    scale="band"
                                    tickFormatter={(m, i) => {
                                        switch (activePoint.scale) {
                                            case "Day": return L10N({ key: "_dateAsddd", date: m });
                                            case "Week": return L10N({ key: "_dateAsOn", date: m });
                                            case "Month": return L10N({ key: "_dateAsMMM", date: m });
                                        }
                                    }}
                                />
                                <Bar dataKey="clientsOnboardedCumulativeCount" barSize={20} fill="#BFBFBF" stroke="#BFBFBF"
                                    onClick={onClick}>
                                    {props.data.map((entry, index) => (
                                        <Cell key={`cell-${index}`}
                                            cursor="pointer"
                                            fill={index === activePoint?.index ? '#D2218B' : '#BFBFBF'}
                                        />
                                    ))}
                                </Bar>
                                <Line type="monotone" dataKey="clientsOnboardedCumulativeCount" stroke="#D2218B" />
                            </ComposedChart>
                        </ResponsiveContainer> */}
                    </Col>
                    <Col sm={0} md={3} />
                </Row>
            </>
            :
            <>
                <Row className="mb-3">
                    <Col />
                    <Col className="col-auto">
                        <img src={`/img/icons/notebook.png`} alt="heart" width="150" />
                    </Col>
                    <Col />
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Typography align="center">
                            {L10N({ key: "No results found" })}
                        </Typography>
                    </Col>
                </Row>
                <PageHeader showLogo logoNavigatesToHome />
            </>
        }
        {activePoint && activePoint.data && <>
            <Row className="my-5">
                <Col md={3} />
                <Col >
                    <Typography variant="subtitle2" align="center">
                        {L10N({ key: "_dateAsOn", date: activePoint.data.date })}
                    </Typography>
                </Col>
                <Col md={3} />
            </Row>
            <Row className="mb-3">
                <Col md={3} />
                <Col md={3}>
                    <Typography variant="body1" align="center" color="textPrimary">
                        {L10N({ key: "You've earned:", })}
                    </Typography>
                    <Card className={"p-3 m-3"
                        + " " + Animations.slide_in_blurred_right()
                        + " " + Animations.delay(1)
                    }>
                        <Typography variant="subtitle1" align="center">
                            {activePoint.data.amountEarnedCumulative}
                        </Typography>
                        <Typography variant="body2" align="center" color="textSecondary">
                            EUR
                        </Typography>
                    </Card>
                </Col>
                <Col md={3}>
                    <Typography variant="body1" align="center" color="textPrimary">
                        {L10N({ key: "You've almost earned additional:", })}
                    </Typography>
                    <Card className={"p-3 m-3"
                        + " " + Animations.slide_in_blurred_right()
                        + " " + Animations.delay(2)
                    }>
                        <Typography variant="subtitle1" align="center">
                            +{activePoint.data.amountPotentialCumulative}
                        </Typography>
                        <Typography variant="body2" align="center" color="textSecondary">
                            EUR
                        </Typography>
                    </Card>
                </Col>
                <Col md={3} />
            </Row>
            <Row className="mb-3">
                <Col md={1} lg={3} />
                <Col md={3} lg={2}>
                    <Typography variant="body1" align="center" color="textPrimary">
                        {L10N({ key: "Your referrals:", })}
                    </Typography>
                    <Card className={"p-3 m-3"
                        + " " + Animations.slide_in_blurred_right()
                        + " " + Animations.delay(3)
                    }>
                        <Typography variant="subtitle1" align="center">
                            {activePoint.data.clientsOnboardedCumulativeCount}
                        </Typography>
                        <Typography variant="body2" align="center" color="textSecondary">
                            {L10N({ key: "Your referrals:...Clients", })}
                        </Typography>
                    </Card>
                </Col>
                <Col md={3} lg={2}>
                    <Typography variant="body1" align="center" color="textPrimary">
                        {L10N({ key: "Monthly payments:", })}
                    </Typography>
                    <Card className={"p-3 m-3"
                        + " " + Animations.slide_in_blurred_right()
                        + " " + Animations.delay(4)
                    }>
                        <Typography variant="subtitle1" align="center">
                            {activePoint.data.clientsWhoPaidMonthCumulativeCount}
                        </Typography>
                        <Typography variant="body2" align="center" color="textSecondary">
                            {L10N({ key: "Monthly payments:...Clients", })}
                        </Typography>
                    </Card>
                </Col>
                <Col md={3} lg={2}>
                    <Typography variant="body1" align="center" color="textPrimary">
                        {L10N({ key: "Yearly payments:", })}
                    </Typography>
                    <Card className={"p-3 m-3"
                        + " " + Animations.slide_in_blurred_right()
                        + " " + Animations.delay(5)
                    }>
                        <Typography variant="subtitle1" align="center">
                            {activePoint.data.clientsWhoPaidYearCumulativeCount}
                        </Typography>
                        <Typography variant="body2" align="center" color="textSecondary">
                            {L10N({ key: "Yearly payments:...Clients", })}
                        </Typography>
                    </Card>
                </Col>
                <Col md={1} lg={3} />
            </Row>
        </>}
    </>;
}
export const VisitStatisticsTab = (props: {
    data?: VisitStatisticsEntry[]
}) => {
    const [activePoint, setActivePoint] = useState<{
        index?: number,
        data?: VisitStatisticsEntry,
        scale: ChartScaleEnum,
    }>({
        index: props.data && props.data?.length > 0
            ? 0
            : undefined,
        data: props.data && props.data?.length > 0
            ? props.data[0]
            : undefined,
        scale: "Day",
    });

    // function onClick(data: any, index: number) {
    //     if (activePoint?.index === index) {
    //         setActivePoint({
    //             scale: activePoint.scale,
    //         });
    //     } else {
    //         setActivePoint({
    //             index: index,
    //             data: data,
    //             scale: activePoint.scale,
    //         });
    //     }
    // }
    function onScaleChange(scale: ChartScaleEnum) {
        setActivePoint({
            index: activePoint.index,
            data: activePoint.data,
            scale: scale,
        });
    }
    return <>
        {props.data && props.data.length > 0
            ? <>
                <Row className="mb-3">
                    <Col />
                    <Col className="col-auto">
                        <ButtonGroup size="small">
                            <Button onClick={() => onScaleChange("Day")}
                                color={activePoint.scale === "Day" ? "primary" : "inherit"}
                                variant={activePoint.scale === "Day" ? "contained" : "outlined"}
                            >
                                {L10N({ key: 'Day' })}
                            </Button>
                            <Button onClick={() => onScaleChange("Week")}
                                color={activePoint.scale === "Week" ? "primary" : "inherit"}
                                variant={activePoint.scale === "Week" ? "contained" : "outlined"}
                            >
                                {L10N({ key: 'Week' })}
                            </Button>
                            <Button onClick={() => onScaleChange("Month")}
                                color={activePoint.scale === "Month" ? "primary" : "inherit"}
                                variant={activePoint.scale === "Month" ? "contained" : "outlined"}
                            >
                                {L10N({ key: 'Month' })}
                            </Button>
                        </ButtonGroup>
                    </Col>
                    <Col />
                </Row>
                <Row className="mb-3">
                    <Col sm={0} md={3} />
                    <Col sm={12} md={6}>
                        {/* <ResponsiveContainer width={'100%'} height={300} className="mx-auto">
                            <ComposedChart
                                data={props.data.filter(m => m.scale === activePoint.scale)}
                            >
                                <CartesianGrid stroke="#f5f5f5" />
                                <XAxis dataKey="date"
                                    type="category"
                                    scale="band"
                                    reversed
                                    tickFormatter={(m, i) => {
                                        switch (activePoint.scale) {
                                            case "Day": return L10N({ key: "_dateAsddd", date: m });
                                            case "Week": return L10N({ key: "_dateAsOn", date: m });
                                            case "Month": return L10N({ key: "_dateAsMMM", date: m });
                                        }
                                    }}
                                />
                                <Bar dataKey="totalEarnings" barSize={20} fill="#BFBFBF" stroke="#BFBFBF"
                                    onClick={onClick}>
                                    {props.data.map((entry, index) => (
                                        <Cell key={`cell-${index}`}
                                            cursor="pointer"
                                            fill={index === activePoint?.index ? '#D2218B' : '#BFBFBF'}
                                        />
                                    ))}
                                </Bar>
                                <Line type="monotone" dataKey="totalEarnings" stroke="#D2218B" />
                            </ComposedChart>
                        </ResponsiveContainer> */}
                    </Col>
                    <Col sm={0} md={3} />
                </Row>
            </>
            :
            <>
                <Row className="mb-3">
                    <Col />
                    <Col className="col-auto">
                        <img src={`/img/icons/notebook.png`} alt="heart" width="150" />
                    </Col>
                    <Col />
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Typography align="center">
                            {L10N({ key: "No results found" })}
                        </Typography>
                    </Col>
                </Row>
                <PageHeader showLogo logoNavigatesToHome />
            </>
        }
        {activePoint && activePoint.data && <>
            <Row className="my-5">
                <Col md={3} />
                <Col >
                    <Typography variant="subtitle2" align="center">
                        {L10N({ key: "_dateAsOn", date: activePoint.data.date })}
                    </Typography>
                </Col>
                <Col md={3} />
            </Row>
            <Row className="mb-3">
                <Col md={3} />
                <Col md={3}>
                    <Typography variant="body1" align="center" color="textPrimary">
                        {L10N({ key: "You've earned:", })}
                    </Typography>
                    <Card className={"p-3 m-3"
                        + " " + Animations.slide_in_blurred_left()
                        + " " + Animations.delay(1)
                    }>
                        <Typography variant="subtitle1" align="center">
                            {activePoint.data.totalEarnings}
                        </Typography>
                        <Typography variant="body2" align="center" color="textSecondary">
                            EUR
                        </Typography>
                    </Card>
                </Col>
                <Col md={3}>
                    <Typography variant="body1" align="center" color="textPrimary">
                        {L10N({ key: "From the total of:", })}
                    </Typography>
                    <Card className={"p-3 m-3"
                        + " " + Animations.slide_in_blurred_left()
                        + " " + Animations.delay(2)
                    }>
                        <Typography variant="subtitle1" align="center">
                            {activePoint.data.visitsBooked}
                        </Typography>
                        <Typography variant="body2" align="center" color="textSecondary">
                            {L10N({ key: "From the total of:...visits", })}
                        </Typography>
                    </Card>
                </Col>
                <Col md={3} />
            </Row>
            <Row className="mb-3">
                <Col md={3} />
                <Col md={3}>
                    <Typography variant="body1" align="center" color="textPrimary">
                        {L10N({ key: "Unique clients:", })}
                    </Typography>
                    <Card className={"p-3 m-3"
                        + " " + Animations.slide_in_blurred_left()
                        + " " + Animations.delay(1)
                    }>
                        <Typography variant="subtitle1" align="center">
                            {activePoint.data.uniqueClients}
                        </Typography>
                    </Card>
                </Col>
                <Col md={3}>
                    <Typography variant="body1" align="center" color="textPrimary">
                        {L10N({ key: "Completed visits:", })}
                    </Typography>
                    <Card className={"p-3 m-3"
                        + " " + Animations.slide_in_blurred_left()
                        + " " + Animations.delay(2)
                    }>
                        <Typography variant="subtitle1" align="center">
                            {activePoint.data.visitsCompleted}
                        </Typography>
                    </Card>
                </Col>
                <Col md={3} />
            </Row>
        </>}
    </>;
}

export const StatisticsPage = (props: {}) => {
    const navigate = useNavigate();
    const { user, inProgress } = useAppSelector((state: RootState) => state.user);
    const [selectedTab, setSelectedTab] = useState(0);
    const urlParams = useParams<{ tab: "visit" | "referral" }>();

    useEffect(() => {
        if (!urlParams.tab || urlParams.tab === "visit") {
            setSelectedTab(0);
        } else {
            setSelectedTab(1);
        }
    }, [urlParams]);
    async function onTabChanged(value: number) {
        if (value === 0) {
            await navigate(AppRoutes.statisticsTab("visit"));
        } else {
            await navigate(AppRoutes.statisticsTab("referral"));
        }
    }

    return (<>
        {!inProgress && !(user?.clientAccessGranted ?? false) && <Navigate replace to={AppRoutes.loginAndReturn(AppRoutes.statisticsTab())} />}
        <Container>
            <Row className="my-3" />
            <BackArrowHeader title={L10N({ key: "Statistics &amp; Reports", })} />
            <Row className="my-3">
                <Col className="p-0" />
                <Col className="col-auto">
                    <TabsSwitch selectedTab={selectedTab} onTabChanged={onTabChanged}
                        tabs={[
                            L10N({ key: "Visit statistics" }),
                            L10N({ key: "Referral statistics" })
                        ]}
                    />
                </Col>
                <Col className="p-0" />
            </Row>
            {selectedTab === 0 && <VisitStatisticsTab data={undefined} />}
            {selectedTab === 1 && <ReferralStatisticsTab data={undefined} />}
        </Container >
    </>
    );
};
