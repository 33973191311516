import { Button, CardActions, CardContent, CardHeader, CardMedia } from "@mui/material";
import { Col, Container, Row } from 'react-bootstrap';
import { CompanyViewDetailsButton } from "../details/CompanyViewDetailsButton";
import { AppRoutes } from '../pages/Routes';
import { Animations } from "../ui/Animations";
import { CspDivider } from '../ui/CspDivider';
import { CspIcon } from "../ui/CspIcon";
import { CspImage } from '../ui/CspImage';
import { CspList } from '../ui/CspList';
import { EmiCard } from '../ui/EmiCard';
import { LogotypeSpans } from "../ui/LogotypeSpans";
import { StyledTitle } from "../ui/StyledTitle";

export const BenefitsViewItem = (props: {
    index: number,
    title: string,
    subtitle: JSX.Element,
    content: JSX.Element,
    button: JSX.Element,
    className?: string,
    imgSrc: string,
    startAnimation: boolean,
}) => {

    const imgHeight = 294;
    return (<>
        <EmiCard
            className={"my-3 h-100 " +
                props.className + " "
                + Animations.slide_in_left() + " "
                + Animations.delay(16 - 3 * props.index)
            }
            elevation={12}
        >
            <CardHeader
                className="px-0 text-white-shadow"
                sx={(theme) => ({
                    background: theme.palette.primary.main,
                })}
                title={<>
                    {props.title}
                </>}
                titleTypographyProps={{
                    align: 'center',
                    sx: (theme) => ({
                        color: theme.palette.common.white,
                    })
                }}
            >
            </CardHeader>
            <CspDivider shadowTop noAnimation />
            <CardMedia
                style={{
                    overflow: 'hidden',
                    maxHeight: imgHeight,
                }}
            >
                <CspImage src={props.imgSrc}
                    title={props.title}
                    animationIndex={props.index}
                />
            </CardMedia>
            <CspDivider />
            <CardContent>
                <StyledTitle subtitle hideDivider text={props.subtitle} className="mb-3" />
                {props.content}
            </CardContent>
            <CardActions style={{
                position: 'fixed',
                bottom: 0,
                left: 8,
                right: 8,
            }}>
                {props.button}
            </CardActions>
        </EmiCard >
    </>)
}

export const BenefitsView = () => {
    let i = 0;
    let j = 0;
    return (<>
        <Container >
            <Row className="my-5">
                <Col xs={12} md={6} lg={true}
                    className="mb-3">
                    <BenefitsViewItem index={1}
                        title="For Students"
                        subtitle={<>
                            Get started with <LogotypeSpans /> and access the knowledge you need today
                        </>}

                        imgSrc="/img/benefit-student.png"
                        startAnimation
                        content={<>
                            <CspList
                                items={[
                                    {
                                        index: i++,
                                        icon: <CspIcon name="CurrencyRupeeOutlinedIcon" />,
                                        primaryTitle: "Pay less yourself and let Teachers earn more!",
                                        primaryTitleColor: "primary",
                                    },
                                    {
                                        index: i++,
                                        icon: <CspIcon name="EventAvailableOutlinedIcon" />,
                                        primaryTitle: "Highest quality education",
                                        secondaryTitle: "Only verified Teachers and Private Classes",
                                        primaryTitleColor: "primary",
                                    },
                                    {
                                        index: i++,
                                        icon: <CspIcon name="Earth" />,
                                        primaryTitle: "Education beyond the borders!",
                                        secondaryTitle: "We bring you foreign Teachers with their unique perspective!",
                                        primaryTitleColor: "primary",
                                    },
                                ]}
                            />
                        </>
                        }
                        button={<>
                        
                            <Button variant="outlined" fullWidth
                                href={AppRoutes.homeSearchBar}
                            >
                                Find the &nbsp; <LogotypeSpans />
                            </Button>
                        </>}
                    />
                </Col>
                <Col xs={12} md={6} lg={true}
                    className="mb-3">
                    <BenefitsViewItem index={2}
                        title="For Teachers"
                        subtitle={<>
                            Join <LogotypeSpans /> today and connect with eager students
                            ready to learn from you!
                        </>}

                        imgSrc="/img/benefit-teacher.png"
                        startAnimation
                        content={<>
                            <CspList animationFromRight
                                items={[
                                    {
                                        index: j++,
                                        icon: <CspIcon name="CloudDoneOutlinedIcon" />,
                                        primaryTitle: "We take care of your marketing",
                                        secondaryTitle: "We bring you more students, so that you earn x2 more",
                                        primaryTitleColor: "primary",
                                    },
                                    {
                                        index: j++,
                                        icon: <CspIcon name="HowToRegOutlinedIcon" />,
                                        primaryTitle: "Keep high quality and foused atmosphere of the classes",
                                        secondaryTitle: "Our classes are small (up to 10 students)",
                                        primaryTitleColor: "primary",
                                    },
                                    {
                                        index: j++,
                                        icon: <CspIcon name="EventAvailableOutlinedIcon" />,
                                        primaryTitle: "We coordinate for you",
                                        secondaryTitle: "We take care of shedule, notifications, payments and the legal obligations",
                                        primaryTitleColor: "primary",
                                    },
                                ]}
                            />
                        </>
                        }
                        button={<>
                            <CompanyViewDetailsButton variant="outlined" fullWidth>
                                Join &nbsp; <LogotypeSpans />
                            </CompanyViewDetailsButton>
                        </>}
                    />
                </Col>
            </Row >
            <Row />
        </Container >
    </>);
}
