import * as yup from 'yup';
import { MapCoords } from "./Details";
import { AccessTokenValidationSchema, CreateNextDateTimeRange, EditModel, MapCoordsValidationSchema, PhotoEditModel, PhotoEditModelArrayValidationSchema, PriceAndCurrencyEditModel, PriceAndCurrencyEditModelValidationSchema, WorkingHoursEditModel, WorkingHoursEditModelValidationSchema } from './Edit';
import { AccessTokenBearer, CURRENCY_INR } from "./Global";
import { SearchCategory_Anything, SearchCategory_Array } from './Search';
import { EmailValidationSchema } from './Validators';

export interface RequestTeacherWizardCreateModel extends AccessTokenBearer {
    referralCode: string,
}
export interface ClassEditModel {
    name: string,
}
export function CreateNewClass(): ClassEditModel {
    return {
        name: "",
    };
}
export interface TeacherWizardPricingEditModel {
    pricePerHalfClass: PriceAndCurrencyEditModel,
    pricePerFullClass: PriceAndCurrencyEditModel,
    pricePerDoubleClass: PriceAndCurrencyEditModel,
}
export type TeacherWizardStage = "initial"
    | "initial-submitted"
    | "reviewed-accepted"
    ;
export const TeacherWizardStageAll: TeacherWizardStage[] = [
    "initial",
    "initial-submitted",
    "reviewed-accepted",
];
export interface TeacherWizardEditModel extends EditModel {
    userID: string,
    firstName: string | null,
    lastName: string | null,
    displayName: string,
    category: string,
    languages: string[],
    eduinPhone: string | null,
    eduinEmail: string | null,
    address: string | null,
    mapCoords: MapCoords,
    description: string | null,
    keyWords: string | null,
    classes: ClassEditModel[],
    workingHours: WorkingHoursEditModel[],
    pricing: TeacherWizardPricingEditModel,
    avatarPhotosOwnerID: string,
    cardPhotosOwnerID: string,
    certificatePhotosOwnerID: string,
    avatarPhotos: PhotoEditModel[],
    cardPhotos: PhotoEditModel[],
    certificatePhotos: PhotoEditModel[],
    stage?: TeacherWizardStage | null,
}
export function CreateNewCompanyWizard(): TeacherWizardEditModel {
    return {
        accessToken: "",
        userID: "123-564",
        firstName: "",
        lastName: "",
        displayName: "",
        languages: [],
        category: SearchCategory_Anything,
        eduinPhone: "",
        eduinEmail: "",
        address: "",
        mapCoords: {
            lat: 1,
            lng: 1,
        },
        description: "",
        keyWords: "",
        classes: [],
        workingHours: [
            { dayOfWeek_asDate: "2000-01-03T12:00", dayWorkingHours: [CreateNextDateTimeRange()], dayOfWeek: 'Monday', },
            { dayOfWeek_asDate: "2000-01-04T12:00", dayWorkingHours: [CreateNextDateTimeRange()], dayOfWeek: 'Tuesday', },
            { dayOfWeek_asDate: "2000-01-05T12:00", dayWorkingHours: [CreateNextDateTimeRange()], dayOfWeek: 'Wednesday', },
            { dayOfWeek_asDate: "2000-01-06T12:00", dayWorkingHours: [CreateNextDateTimeRange()], dayOfWeek: 'Thursday', },
            { dayOfWeek_asDate: "2000-01-07T12:00", dayWorkingHours: [CreateNextDateTimeRange()], dayOfWeek: 'Friday', },
            { dayOfWeek_asDate: "2000-01-08T12:00", dayWorkingHours: [], dayOfWeek: 'Saturday', },
            { dayOfWeek_asDate: "2000-01-09T12:00", dayWorkingHours: [], dayOfWeek: 'Sunday', },
        ],
        pricing: {
            pricePerHalfClass: { price: "200", currency: CURRENCY_INR },
            pricePerFullClass: { price: "300", currency: CURRENCY_INR },
            pricePerDoubleClass: { price: "500", currency: CURRENCY_INR },
        },
        avatarPhotosOwnerID: "",
        cardPhotosOwnerID: "",
        certificatePhotosOwnerID: "",
        avatarPhotos: [],
        cardPhotos: [],
        certificatePhotos: [],
        stage: "initial",
    };
}
export const ClassEditModelValidationSchema
    = yup
        .object<ClassEditModel>()
        .shape({
            name: yup.string().defined(),
        });
export const TeacherWizardPricingEditModelValidationSchema = yup
    .object<TeacherWizardPricingEditModel>()
    .shape({
        pricePerHalfClass: PriceAndCurrencyEditModelValidationSchema,
        pricePerFullClass: PriceAndCurrencyEditModelValidationSchema,
        pricePerDoubleClass: PriceAndCurrencyEditModelValidationSchema,
    });
export const TeacherWizardEditModelValidationSchema = yup
    .object<TeacherWizardEditModel>()
    .shape({
        accessToken: AccessTokenValidationSchema,
        userID: yup.string().defined(),
        firstName: yup.string().defined().nullable(),
        lastName: yup.string().defined().nullable(),
        displayName: yup.string().defined(),
        category: yup.string().oneOf(SearchCategory_Array).defined().nullable(),
        languages: yup.array().optional().nullable(),
        eduinPhone: yup.string().defined().nullable(),
        eduinEmail: EmailValidationSchema.defined().nullable(),
        address: yup.string().defined().default("").nullable(),
        mapCoords: MapCoordsValidationSchema.nullable(),
        description: yup.string().default("").nullable(),
        keyWords: yup.string().default("").nullable(),
        classes: yup.array().of(ClassEditModelValidationSchema).optional().nullable(),
        workingHours: yup.array().of(WorkingHoursEditModelValidationSchema).optional().nullable(),
        pricing: TeacherWizardPricingEditModelValidationSchema.nullable(),
        avatarPhotosOwnerID: yup.string().defined().nullable(),
        cardPhotosOwnerID: yup.string().defined().nullable(),
        certificatePhotosOwnerID: yup.string().defined().nullable(),
        avatarPhotos: PhotoEditModelArrayValidationSchema.optional().nullable(),
        cardPhotos: PhotoEditModelArrayValidationSchema.optional().nullable(),
        certificatePhotos: PhotoEditModelArrayValidationSchema.optional().nullable(),
        stage: yup.mixed().oneOf(TeacherWizardStageAll).default("").nullable(),
    });