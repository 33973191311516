import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SearchResult } from '../../api/models/Search';
import { RootState, useAppSelector } from '../../store/store';
import { AppRoutes } from '../pages/Routes';
import { Animations } from '../ui/Animations';
import { useIsVisible } from '../ui/useIsVisible';
import { SearchCard } from './SearchCard';

export const SearchTableCol = (props: {
    result: SearchResult,
    resultIndex: number,
    inProgress?: boolean,
    onCardClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, card: SearchResult) => void,
}) => {
    const { ref, wasVisible } = useIsVisible<HTMLDivElement>();
    return (<>
        <Col xs={12} sm={6} md={6} lg={4} xl={3}
            className="mb-3 "
            ref={ref}
        >
            {wasVisible &&
                <SearchCard
                    className={Animations.slide_in_left() + Animations.delayRandom10()}
                    data={props.result}
                    showImage
                    inProgress={props.inProgress}
                    onCardClick={props.onCardClick} />
            }
        </Col >
    </>)
}
export const SearchTable = () => {
    const navigate = useNavigate();
    const { searchResults } = useAppSelector((state: RootState) => state.search);
    const { inProgress: inviteInProgress, createInviteMessage } = useAppSelector((state: RootState) => state.invite);

    async function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>, item: SearchResult) {
        await navigate(AppRoutes.detailsTab("company", item.companyID));
    }

    return (
        <><Col>
            <Container style={{ minHeight: 200 }}>
                <Row>
                    {searchResults?.results.map((m, i) =>
                        <SearchTableCol key={i + m.companyID}
                            result={m}
                            resultIndex={i}
                            onCardClick={handleClick}
                            inProgress={inviteInProgress && createInviteMessage?.offeredResourceID === m.offeredResourceID}
                        />
                    )}
                </Row>
            </Container>
        </Col >
        </>
    );
}
