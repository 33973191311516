import { Col, Container, Row } from 'react-bootstrap';
import { BackArrowHeader } from './BackArrowHeader';
import { L10N } from './L10N';

export const TermsOfUsePage = (props: {}) => {

    return (<>
        {<Container>
            <Row className="my-3" />
            <BackArrowHeader title={L10N({ key: "Terms of Use" })} />
            <Row className="my-5">
                <Col />
                <Col className="col-auto">
                    <img src={`/img/icons/notebook.png`} alt="heart" width="150" />
                </Col>
                <Col />
            </Row>
            <Row className="my-5">
                <Col>
                    <a href="terms/#terms1" className="px-1">Section 1</a><br></br>
                    <a href="terms/#terms2" className="px-1">Section 2</a><br></br>
                    <a href="terms/#terms3" className="px-1">Section 3</a><br></br>
                </Col>
            </Row>

            <Row className="mb-3" id="terms1">
                <Col>
                    <a href="terms">Top</a>
                    <p><strong><span>Section 1</span></strong></p>
                </Col>
            </Row>
            <Row className="mb-3" id="terms2">
                <Col>
                    <a href="terms">Top</a>
                    <p><strong><span>Section 2</span></strong></p>
                </Col>
            </Row>
            <Row className="mb-3" id="terms3">
                <Col>
                    <a href="terms">Top</a>
                    <p><strong><span>Section 3</span></strong></p>

                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <a href="terms">Top</a>
                </Col>
            </Row>
        </Container >}
    </>
    );
};
