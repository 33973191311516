import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { api } from '../../api/api';
import { AccessTokenBearer } from '../../api/models/Global';

export const loadCompanyCalendar = createAsyncThunk(
    'thunk/search/company-calendar',
    async (args: {
        message: AccessTokenBearer,
        companyID?: string
    }, thunkAPI) => {
        const apiResponse = await api.Calendar.getCompanyCalendar({
            accessToken: args.message.accessToken,
            companyID: args.companyID,
            dateFrom: moment().toJSON(),
            dateTo: moment().add(5, "weeks").toJSON(),
            dateBatchNumber: 0,
        });
        if(apiResponse.error)throw apiResponse.error;
        return apiResponse.result;
    },
)
export const loadResourceCalendar = createAsyncThunk(
    'thunk/search/resource-calendar',
    async (args: {
        message: AccessTokenBearer,
        resourceID?: string
    }, thunkAPI) => {
        const apiResponse = await api.Calendar.getResourceCalendar({
            accessToken: args.message.accessToken,
            resourceID: args.resourceID,
            dateFrom: moment().toJSON(),
            dateTo: moment().add(5, "weeks").toJSON(),
            dateBatchNumber: 0,
        });
        if(apiResponse.error)throw apiResponse.error;
        return apiResponse.result;
    },
)