import { Button, Container } from "@mui/material";
import { useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import { loadVisitAndAllDetails } from '../../store/details/details.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { getCurrentUser } from "../../store/user/user.actions";
import { InviteSummaryCard } from "../invite/Summary";
import { TeacherWizardVisitConfirmed } from "../invite/TeacherWizardInviteCard";
import { LogotypeSpans } from "../ui/LogotypeSpans";
import { StyledTitleRow } from "../ui/StyledTitle";
import { L10N } from "./L10N";
import { PageHeader } from "./PageHeader";
import { AppRoutes } from "./Routes";

export const VisitConfirmedPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { visit, inProgress } = useAppSelector((state: RootState) => state.details);
    const { user } = useAppSelector((state: RootState) => state.user);
    const urlParams = useParams<{ visitID: string, visitAccessToken?: string }>();

    useEffect(() => {
        if (inProgress) return;
        if (!urlParams) return;
        if (!urlParams.visitID) return;
        if (urlParams.visitID === "undefined") return;
        if (!user && !urlParams.visitAccessToken) return;

        if (!user && urlParams.visitAccessToken) {
            dispatch(getCurrentUser({ message: { accessToken: urlParams.visitAccessToken } }));
            return;
        }
        if (!user) return;

        if (urlParams.visitID !== visit?.visitID) {
            dispatch(loadVisitAndAllDetails({ message: user, visitID: urlParams.visitID }));
        }
    }, [visit, urlParams, inProgress, dispatch, user]);
    async function handleOK() {
        await navigate(AppRoutes.visitTab("upcoming"));
    }
    return (<>
        {visit?.classType === "TeacherWizard"
            ? <TeacherWizardVisitConfirmed />
            : <>
                <Container
                    style={{
                        overflowY: 'hidden',
                        minHeight: '100vh',
                    }}>
                    <PageHeader title="" showLogo />
                    <StyledTitleRow text={<>Congratulations, your <LogotypeSpans /> is confirmed</>} className='mb-3' />
                    <Row className="my-3">
                        <Col >
                            {visit &&
                                <InviteSummaryCard largePhoto={visit.photos?.[0]}
                                    class={visit?.serviceName}
                                    resource={{
                                        text: visit.resourceName,
                                        photo: visit.resourcePhotos?.[0],
                                    }}
                                    date={L10N({ key: "_dateAsDDMMMM", date: visit.visitStartForClient })}
                                    time={L10N({ key: "_dateAsHHmm", date: visit.visitStartForClient })}
                                    price={L10N({ key: "_priceAndCurrency", price: visit.priceAndCurrency })}
                                />
                            }
                        </Col>
                    </Row>
                    <Row >
                        <Col xs={false} md={true} />
                        <Col>
                            <Button className="mb-3"
                                onClick={handleOK}
                                variant="contained"
                                fullWidth >
                                {L10N({ key: "OK, got it" })}
                            </Button>
                        </Col>
                        <Col xs={false} md={true} />
                    </Row>
                </Container>
            </>
        }
    </>);
};
