import moment from "moment";
import { L10N } from "src/components/pages/L10N";
import * as yup from 'yup';
import { isAssignableToUnion } from "../extensions";

export function yupIsAssignableToUnionTest<T extends string>() {
    const test: yup.TestConfig<string | undefined, Record<string, any>> = {
        name: "isTimeTest",
        test: (value) => isAssignableToUnion<T>(value),
    }
    return test;
}
export const isNumberTest: yup.TestConfig<string | undefined, Record<string, any>> = {
    name: "isNumberTest",
    test: (value) => {
        if (!value) return true;
        const val = value.replace(/,/, '.');
        return !isNaN(+value)
            || !isNaN(+val);
    },
    message: L10N({ key: "Invalid number" }),
}
export const isTimeTest: yup.TestConfig<string | undefined, Record<string, any>> = {
    name: "isTimeTest",
    test: (value) => {
        if (!value) return true;
        const parsed = moment(value, "HH:mm");
        const result = parsed.isValid();
        return result;
    },
    message: L10N({ key: "Specify time as HH:mm" }),
}
export const EmailValidationSchema = yup
    .string()
    .trim()
    .email(L10N({ key: 'Enter a valid email' }));
export const PhoneValidationSchema = yup
    .string()
    .matches(/^[0-9]+/, "Must be only digits")
    .min(9);
export const PinValidationSchema = yup
    .string()
    .length(6);