import { AppBar, Button, ButtonGroup, CircularProgress, Toolbar } from "@mui/material";
import { TeacherWizardEditModel } from "src/api/models/TeacherWizard";
import { L10N } from "../pages/L10N";
import { Animations } from "../ui/Animations";
import { CspIcon } from "../ui/CspIcon";
import { useFormikResult } from "../ui/FormikAPI";

export type TeacherWizardAppBarState = {
    nextDisabled?: boolean,
    backDisabled?: boolean,
    previewDisabled?: boolean,
}
export const TeacherWizardAppBar = (props: {
    formik: useFormikResult<TeacherWizardEditModel>;
    state: TeacherWizardAppBarState;
    onHome: () => Promise<void>;
    onNext: () => Promise<void>;
    onBack: () => Promise<void>;
    onPreview: () => Promise<void>;
    onSave: () => Promise<void>;
}) => {

    return <>
        {props.formik.dirty &&
            <AppBar position="fixed" color="secondary"
                className={Animations.slit_in_vertical()}
                style={{
                    top: 'auto',
                    bottom: 0,
                    zIndex: 2010,
                }}>
                <Toolbar className="mx-auto">
                    <ButtonGroup color="primary" variant="contained">
                        {props.formik.values.stage
                            && props.formik.values.stage !== "initial" && <>
                                <Button onClick={props.onHome}
                                >
                                    <CspIcon name="Home" />
                                    {L10N({ key: 'Home' })}
                                </Button>
                            </>}
                        <Button disabled={props.state.backDisabled}
                            onClick={props.onBack}
                        >
                            {L10N({ key: 'Back' })}
                        </Button>
                        {!props.state.previewDisabled
                            &&
                            <Button className={Animations.puff_in_center() + " " + Animations.delay(5)}
                                onClick={props.onPreview}
                            >
                                <CspIcon name="PreviewIcon" />
                                {L10N({ todo: 'Preview' })}
                                <CspIcon name="PreviewIcon" />
                            </Button>}
                        <Button
                            disabled={props.state.nextDisabled}
                            onClick={props.onNext}
                        >
                            {L10N({ key: 'Next' })}
                        </Button>
                        {props.formik.values.stage
                            && props.formik.values.stage !== "initial" && <>
                                <Button onClick={props.onSave} 
                                >
                                    {props.formik.isSubmitting
                                        ? <CircularProgress color="secondary" />
                                        : L10N({ key: 'Save' })
                                    }
                                </Button>
                            </>}
                    </ButtonGroup>
                </Toolbar>
            </AppBar>
        }
    </>;
};
