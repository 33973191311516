import { Button, LinearProgress, Typography } from "@mui/material";
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router-dom';
import { PurchaseRequest } from 'src/api/models/Purchase';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { payForVisit } from '../../store/visit/visit.actions';
import { BackArrowHeader } from '../pages/BackArrowHeader';
import { ButtonShadow } from '../ui/ButtonShadow';
import { ImageLoader } from '../ui/ImageLoader';
import { L10N } from './L10N';
import { AppRoutes } from './Routes';

export const PaymentPage = () => {
    const dispatch = useAppDispatch();
    const { inProgress: inviteInProgress } = useAppSelector((state: RootState) => state.invite);
    const { inProgress, purchaseResponse } = useAppSelector((state: RootState) => state.visit);
    const { user } = useAppSelector((state: RootState) => state.user);
    const urlParams = useParams<{ visitID: string }>();

    useEffect(() => {
        if (inProgress) return;
        if (!urlParams.visitID) return;
        if (urlParams.visitID !== purchaseResponse?.visitID) {
            if (!user) return;
            const callback = AppRoutes.externalUrl(AppRoutes.visitConfirmedPage(urlParams.visitID, user.accessToken));

            const request: PurchaseRequest = {
                accessToken: user?.accessToken ?? "",
                visitID: urlParams.visitID,
                frontendCallback: callback,
            };
            // console.log("calling API");
            // console.log(request);

            dispatch(payForVisit({ message: request }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purchaseResponse, urlParams, user, dispatch]);

    return (
        <>
            {!(user?.clientAccessGranted ?? false) && <Navigate replace to={AppRoutes.loginAndReturn(AppRoutes.paymentPage(urlParams.visitID))} />}
            {user?.clientAccessGranted && purchaseResponse?.paymentNotRequired && <Navigate replace to={AppRoutes.visitConfirmedPage(urlParams.visitID, user.accessToken)} />}
            <Container>
                <BackArrowHeader title={L10N({ key: "PaymentPage - Title" })} />
                {(inviteInProgress || inProgress) && <>
                    <Row>
                        <Col xs={12}>
                            <LinearProgress className="w-100 mt-n1" color="secondary" />
                        </Col>
                    </Row>
                </>}
                <Row className="my-3">
                    <Col />
                    <Col className="col-auto">
                        <img src={`/img/icons/payment-secondary.svg`} alt="heart" width="150" />
                    </Col>
                    <Col />
                </Row>
                <Row className="my-3">
                    <Col md={3} />
                    <Col md={6}>
                        <Typography variant="subtitle2" align="center" >
                            {L10N({ key: "PaymentPage - Description" })}
                        </Typography >
                    </Col>
                    <Col md={3} />
                </Row>
                <Row className="mb-3">
                    <Col md={4} />
                    <Col md={4}>
                        <a href={purchaseResponse?.paymentLink}>
                            <ImageLoader src={purchaseResponse?.paymentQRCodeUrl} alt="QR" className="w-100" />
                        </a>
                    </Col>
                    <Col md={4} />
                </Row>
                <Row className="mb-3">
                    <Col md={3} />
                    <Col>
                        <Button
                            component="a"
                            href={purchaseResponse?.paymentLink}
                            variant="contained"
                            color="primary"
                            className="text-white w-100 m-0 h-100" style={{
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                            fullWidth>
                            {L10N({ key: "PaymentPage - Finalize Payment" })}
                            <ButtonShadow />
                        </Button>
                    </Col>
                    <Col md={3} />
                </Row>

            </Container>
        </>
    );
};
