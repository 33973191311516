import { useEffect, useState } from "react";

export const useTimer = (seconds: number, onFinished?: () => void) => {
    const [timeLeft, setTimeLeft] = useState<number>(seconds);
    const [isFinished, setIsFinished] = useState<boolean>(false);

    useEffect(() => {
        if (timeLeft <= 0) {
            setIsFinished(true);
            if (onFinished) {
                onFinished();
            }
            return;
        }

        const timer = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);
        return () => clearInterval(timer); // Cleanup on unmount
    }, [timeLeft]);

    return { isFinished, timeLeft };
};
