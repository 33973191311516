import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { AccessTokenBearer } from "../models/Global";
import { TeacherWizardEditModel } from "../models/TeacherWizard";

export interface RequestTeacherWizard extends AccessTokenBearer {
    userID?: string,
}
export class TeacherWizardEndpoint {

    async getForm(message: RequestTeacherWizard): Promise<ApiResponse<TeacherWizardEditModel>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/TeacherWizard/get-form`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as TeacherWizardEditModel,
        });
        return apiResponse;
    }
    async saveForm(message: TeacherWizardEditModel): Promise<ApiResponse<TeacherWizardEditModel>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/TeacherWizard/save-form`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as TeacherWizardEditModel,
        });
        return apiResponse;
    }
    async getList(message: AccessTokenBearer): Promise<ApiResponse<TeacherWizardEditModel[]>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/TeacherWizard/get-list`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as TeacherWizardEditModel[],
        });
        return apiResponse;
    }
    async deleteForm(message: RequestTeacherWizard): Promise<ApiResponse<boolean>> {
        const apiResponse = await api.createRequest({
            endpoint: `api/TeacherWizard/delete-form`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as boolean,
        });
        return apiResponse;
    }
}
