import { ServiceDetails } from "src/api/models/Details";
import { AppRoutes } from "../pages/Routes";
import { CspQrCode } from "../ui/CspQrCode";

export const ServiceQRCode = (props: {
    service: ServiceDetails;
}) => {
    const url = AppRoutes.externalUrl(AppRoutes.invitePage(props.service.offeredResourceID));
    return <>
        <CspQrCode value={url} />
    </>;
};

