import { Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import { Animations } from "./Animations";
import { theme } from "./Theme";
import { useIsVisible } from "./useIsVisible";

export type AnimationDelay = "default" | "fast" | "faster" | "fastest" | "noDelay";

export type CspListItemProps = {
    index: number,
    primaryTitle?: JSX.Element | string,
    secondaryTitle?: JSX.Element | string,
    icon?: JSX.Element,
    iconSrc?: string,
    animationFromRight?: boolean,
    startAnimation?: boolean,
    hidden?: boolean,
    delayMultiplier?: AnimationDelay,
    primaryTitleColor?: "primary" | "secondary",
    onClick?: () => Promise<void>,
    notificationBadge?: number,
};
export const CspListItem = (props: CspListItemProps) => {

    const delayMultiplier = props.delayMultiplier === "default" ? 3
        : props.delayMultiplier === "fastest" ? 1
            : props.delayMultiplier === "faster" ? 2
                : props.delayMultiplier === "fast" ? 3
                    : 10;

    const animationClassName = props.delayMultiplier === "noDelay" ? ""
        : Animations.delay(props.index * delayMultiplier)
        + " " +
        (props.animationFromRight
            ? Animations.slide_in_blurred_right()
            : Animations.slide_in_blurred_left());

    const ListItemContent = () => {
        return (<>
            <ListItemAvatar >
                <Avatar sx={{
                    bgcolor: theme.palette.primary.main,
                }}
                    src={props.iconSrc}>
                    {props.icon}
                </Avatar>
            </ListItemAvatar>
            <ListItemText className="m-0"
                primary={<Typography color={props.primaryTitleColor ?? "secondary"} >{props.primaryTitle}</Typography>}
                secondary={<Typography color={props.primaryTitleColor === "primary" ? "secondary" : "primary"} variant="body2">{props.secondaryTitle}</Typography>}
            />
        </>
        )
    }
    return (
        <ListItem className={"px-0 pt-0 pb-3 " + (props.startAnimation ? animationClassName : "")}
        >
            {props.onClick
                ?
                <ListItemButton onClick={props.onClick} >
                    <ListItemContent />
                </ListItemButton>
                :
                <ListItemContent />
            }
        </ListItem >
    )
}

export const CspList = (props: {
    items: CspListItemProps[],
    animationFromRight?: boolean,
    delayMultiplier?: AnimationDelay,
}) => {
    const { ref, wasVisible } = useIsVisible<HTMLUListElement>();
    return (
        <List ref={ref} className="py-0">
            {props.items
                .filter(m => !m.hidden)
                .map((m, i) =>
                    <CspListItem key={i + " " + m.index}
                        startAnimation={wasVisible}
                        animationFromRight={props.animationFromRight}
                        delayMultiplier={props.delayMultiplier}
                        {...m}
                    />
                )}
        </List>
    )
}
