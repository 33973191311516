import { Box, Button, CardActionArea, CardHeader, CardProps, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { api } from '../../api/api';
import { ConfirmedVisit } from '../../api/models/Visit';
import { L10N } from '../pages/L10N';
import { AppRoutes } from '../pages/Routes';
import { CustomStyle } from '../ui/CustomStyle';
import { DimensionIcon } from '../ui/DimensionIcon';
import { EmiCard } from '../ui/EmiCard';
import { LogotypeTokens } from "../ui/LogotypeSpans";
import { CheckPaymentStatusAndPayIfPossible } from '../ui/RazorpayPayments';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        day: {
            lineHeight: '18px',
        },
        bold: {
            fontWeight: 'bold',
        },
        writeReviewButton: {
            position: 'absolute',
            right: '10px',
            bottom: '10px',
            padding: '0px',
        }
    })
);

export const VisitListItemCard = (props: CardProps & {
    data: ConfirmedVisit,
    onCardClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>, card: ConfirmedVisit) => void,
    showPhotoInsteadOfDate?: boolean,
}) => {
    const navigate = useNavigate();
    const { data,
        onCardClick,
        showPhotoInsteadOfDate,
        ...cardProps } = props;
    const classes = useStyles();

    async function writeReviewClick(event: React.MouseEvent<any, MouseEvent>) {
        if (!data) return;
        event.preventDefault();
        event.stopPropagation();
        await navigate(AppRoutes.visitReviewEditPage(data?.visitID));
    }
    function cardClick(event: React.MouseEvent<any, MouseEvent>) {
        if (!props?.onCardClick) return;
        props.onCardClick(event, props.data);
    }
    return (
        <>
            <EmiCard
                {...cardProps}
                elevation={1}
                onClick={props.onCardClick
                    ? cardClick
                    : undefined}
            >
                <Container>
                    <Row >
                        <Col className="p-0 align-self-center" xs={3}>
                            <CardActionArea>
                                {props.showPhotoInsteadOfDate ?
                                    <Box borderRadius={6} border={1} borderColor={"secondary.light"} p={1} m={1}
                                        style={{
                                            backgroundRepeat: 'no-repeat',
                                            backgroundImage: `url(${api.Search.getPhotoUrl(props.data.photos[0])})`,
                                            backgroundSize: 'cover',
                                        }}>
                                        <Typography variant="subtitle1" align="center" color="textPrimary" className={classes.day}>
                                            &nbsp;
                                        </Typography>
                                        <Typography variant="body2" align="center" color="textPrimary" className={classes.bold}>
                                            &nbsp;
                                        </Typography>
                                        <Typography variant="body2" align="center" color="textPrimary">
                                            &nbsp;
                                        </Typography>
                                    </Box>
                                    :
                                    <Box borderRadius={6} border={1} borderColor={"secondary.light"} p={1} m={1}>
                                        <Typography variant="subtitle1" align="center" color="textPrimary" className={classes.day}>
                                            {L10N({ key: "_dateAsDD", date: props.data.visitStartForClient })}<br />
                                        </Typography>
                                        <Typography variant="body2" align="center" color="textPrimary" className={classes.bold}>
                                            {L10N({ key: "_dateAsMMMM", date: props.data.visitStartForClient })}<br />
                                        </Typography>
                                        <Typography variant="body2" align="center" color="textPrimary">
                                            {L10N({ key: "_dateAsHHmm", date: props.data.visitStartForClient })}<br />
                                        </Typography>
                                    </Box>
                                }
                            </CardActionArea>
                        </Col>
                        <Col className="p-0 col-auto" xs={9}>
                            <CardActionArea>
                                <CardHeader
                                    title={<>
                                        <Typography variant="body1" align="left">
                                            <LogotypeTokens text={props.data.companyName} />&nbsp;
                                            {props.showPhotoInsteadOfDate && L10N({ key: "_dateAsOn", date: props.data.visitStartForClient })}
                                        </Typography>
                                        {props.data.addressLine &&
                                            <div>
                                                <Typography variant="body2" align="left" color="textPrimary">
                                                    <DimensionIcon type='company' size="small" />
                                                    {props.data.addressLine}
                                                </Typography>
                                            </div>
                                        }
                                        <Typography variant="body2" align="left" color="textPrimary">
                                            <LogotypeTokens text={props.data.serviceName} />&nbsp;
                                        </Typography>
                                        <Typography variant="body1" align="left" className={CustomStyle.visitStatusClassName(props.data?.visitStatus)}>
                                            {L10N({ key: "_status", status: props.data?.visitStatus })}
                                        </Typography>
                                    </>}
                                />
                            </CardActionArea>
                        </Col>
                    </Row>
                    <Row className="pb-1">
                        <Col />
                        <Col className="col-auto">
                            {props.data?.visitCompleted && !props.data?.visitReviewID &&
                                <Button onClick={writeReviewClick}>
                                    <img alt="star" src={'/img/icons/icon_star_full.svg'}
                                        height={16} width={16}
                                        className="mr-1" />
                                    <Typography>
                                        {L10N({ key: "Write review" })}
                                    </Typography>
                                </Button>}
                            {!props.data.visitCompleted && props.data?.paymentUpfront &&
                                <CheckPaymentStatusAndPayIfPossible visitID={props.data.visitID}
                                    paymentCompleted={props.data.paymentCompleted}
                                    priceAndcurrency={props.data.priceAndCurrency}
                                    autoLoad={false}
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </EmiCard>
        </>
    );
};
