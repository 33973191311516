import { Accordion, AccordionDetails } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { CreateNextDateTimeRange } from 'src/api/models/Edit';
import { WorkingHoursListCard } from '../edit/WorkingHoursListCard';
import { FieldSetProps } from './FormTeacherWizard';

export const FieldSetAvailability = (props: FieldSetProps) => {
    
    return <>
        <Accordion defaultExpanded={false} >
            <AccordionDetails className="d-block pt-3 my-3">
                <Row>
                    {props.formik.values.workingHours?.map && props.formik.values.workingHours.map((m, i) =>
                        <Col key={m.dayOfWeek} sm={12} md={6} xl={6} className="m-0 p-0 p-md-2">
                            <WorkingHoursListCard formik={props.formik}
                                cardIndex={i} model={m}
                                fieldNamePrefix={(index, suffix) => `workingHours[${i}].dayWorkingHours[${index}]${suffix}`}
                                onAdd={() => props.formik.setFieldValue(
                                    `workingHours[${i}].dayWorkingHours[${m.dayWorkingHours?.length ?? 0}]`,
                                    CreateNextDateTimeRange(m)
                                )}
                                onDelete={(model, index) => props.formik.setFieldValue(
                                    `workingHours[${i}].dayWorkingHours`,
                                    m.dayWorkingHours.filter((mFilter, iFilter) => iFilter !== index)
                                )}
                            />
                        </Col>
                    )}
                </Row>
            </AccordionDetails>
        </Accordion>
    </>;
}