import { DimensionTypes } from '../../api/models/Global';
import { CspIcon } from './CspIcon';

export const DimensionIcon = (props: {
    type: DimensionTypes,
    size?: 'inherit' | 'medium' | 'small' | 'large',
    variant?: 'light' | 'dark',
}) => {
    function getAvatar(): JSX.Element {
        const suffix = !props.variant || props.variant === "light"
            ? "" : "_dark";
        switch (props.type.toLowerCase()) {
            case "duration": return <><CspIcon name="HourglassEmptyIcon" fontSize={props.size} /></>;
            case "price": return <><CspIcon name="CurrencyRupeeOutlinedIcon" fontSize={props.size} /></>;
            case "service": return <><CspIcon name="HandshakeOutlinedIcon" fontSize={props.size} /></>;
            case "when": return <><img alt="calendar" src={`/img/icons/icon_calendar${suffix}.svg`} className="mx-1" /></>;
            case "what_time": return <><img alt="clock" src={`/img/icons/icon_clock${suffix}.svg`} className="mx-1" width="16" /></>;
            case "day_part": return <><CspIcon name="TimelapseIcon" fontSize={props.size} /></>;
            case "company": return <><img alt="company" src="/img/icons/icon_place.svg" className="mx-1" /></>;
            case "resource": return <><CspIcon name="AssignmentIndIcon" fontSize={props.size} /></>;
            default: return <>?{props.type}?</>;
        }
    }
    return (getAvatar());
};
