import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { CancelVisitDialogVisible } from "src/store/ui/ui.reducer";
import { clearVisitIDFromCurrentInvite } from '../../store/invite/invite.actions';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { updateVisit } from '../../store/visit/visit.actions';
import { L10N } from '../pages/L10N';

export const CancelVisitDialog = () => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state: RootState) => state.user);
    const { cancelVisitDialogVisible } = useAppSelector((state: RootState) => state.ui);
    const { inProgress: inviteInProgress, currentInvite } = useAppSelector((state: RootState) => state.invite);

    async function handleClose() {
        await dispatch(CancelVisitDialogVisible(false));
    }
    async function cancelConfirmClick() {
        if (!currentInvite) return;
        if (!currentInvite.visitIDToBeReplaced) return;
        if (!user) return;

        await dispatch(updateVisit({
            message: {
                accessToken: user?.accessToken,
                visitID: currentInvite.visitIDToBeReplaced,
                shouldCancel: true,
            }
        }));
        await dispatch(clearVisitIDFromCurrentInvite({}));
        await handleClose();
    }
    return (
        <>
            <Dialog
                open={cancelVisitDialogVisible ?? false}
                onClose={handleClose}>
                <DialogTitle>
                    {L10N({ key: "Cancel visit?" })}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {L10N({ key: "Are you sure that you want to cancel your visit?" })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                        color="primary" variant="outlined">
                        {L10N({ key: "No, I decided to come" })}
                    </Button>
                    <Button onClick={cancelConfirmClick}
                        color="primary" autoFocus variant="contained">
                        {inviteInProgress && <CircularProgress color="secondary" />}
                        {!inviteInProgress && L10N({ key: "Yes, please cancel" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
