import { Badge, Typography } from "@mui/material";
import React, { CSSProperties, useRef } from "react";
import { CalendarSlot } from "../../api/models/Company";
import { SignalMessage } from "../../api/models/Global";
import { CalendarVisitListItemCard } from '../company/CalendarVisitListItemCard';
import { L10N } from "../pages/L10N";
import { Animations } from "../ui/Animations";
import { EmiPaper } from "../ui/EmiPaper";

export const CalendarSlotWithVisit = (props: {
    slot: CalendarSlot,
    scale: number,
    onCardDeleteClick?: () => Promise<void>,
    minimalStartInMinutes: number,
    highlightFromSignal?: SignalMessage,
    onCardCollapseClick: (slot: CalendarSlot) => void,
    onReloadRequired: () => Promise<void>,
    expanded?: boolean,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const top = props.scale * (props.slot.startInMinutes - props.minimalStartInMinutes);
    const height = props.scale * (props.slot.endInMinutes - props.slot.startInMinutes);
    const styleBox: CSSProperties = {
        top: top,
        height: height,
        minHeight: height,
        width: '100%',
        position: 'absolute',
        zIndex: props.expanded ? 1 : 'unset',
    };
    const canCollapse = (ref?.current?.clientHeight ?? 99999) > height + 3;

    function onCardCollapseClick(e: React.MouseEvent<HTMLDivElement>) {
        if (e.defaultPrevented) return;

        e.preventDefault();
        e.stopPropagation();

        props.onCardCollapseClick(props.slot);
    }
    return (<>
        <div key={props.slot.visitID}>
            <div key={"collapsed"}>
                <EmiPaper style={{
                    ...styleBox,
                    zIndex: props.expanded || !canCollapse ? -1 : 1,
                    cursor: "pointer",
                }}
                    onClick={onCardCollapseClick}>

                    <div className="w-100 position-absolute ml-1">
                        {props.slot.startForClient !== props.slot.start &&
                            <Typography variant="body2" color="textPrimary">
                                {L10N({ key: "_dateAsHHmm", date: props.slot.startForClient })}<br />
                            </Typography>
                        }
                        <Typography variant="body2" color="textSecondary">
                            {L10N({ key: "_dateAsHHmm", date: props.slot.start })}<br />
                        </Typography>
                        {props.highlightFromSignal &&
                            <Typography variant="body2" color="primary" className={"mt-n3 " + Animations.heartbeat()} align="center">
                                {L10N({ key: "_signalMessageType", signalMessageType: props.highlightFromSignal.messageType })}
                            </Typography>
                        }
                    </div>
                    <Typography variant="body2" color="textPrimary"
                        className="w-100 position-absolute"
                        align="center">
                        {props.slot.serviceName}
                    </Typography>

                    <div onClick={onCardCollapseClick}
                        style={{
                            position: 'absolute',
                            width: 'auto',
                            right: '3px',
                            lineHeight: '0px',
                        }}>
                        <Badge badgeContent={props.slot.visitStatus === 'Requested' ? 1 : undefined} color="primary"
                            max={9}
                            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
                            <img src="/img/icons/icon_down_dark.svg" alt="down" />
                        </Badge>
                    </div>
                </EmiPaper>
            </div>
            <div key={"expanded"}
                style={{
                    ...styleBox,
                    zIndex: props.expanded || !canCollapse ? 2 : -1,
                }}>
                <CalendarVisitListItemCard data={props.slot}
                    ref={ref}
                    minHeight={height}
                    canCollapse={canCollapse}
                    onCardDeleteClick={props.onCardDeleteClick}
                    onReloadRequired={props.onReloadRequired}
                    onCardClick={canCollapse ? onCardCollapseClick : undefined} />
                {props.highlightFromSignal &&
                    <Typography variant="body2" color="primary" className={"mt-n3 " + Animations.heartbeat()} align="center">
                        {L10N({ key: "_signalMessageType", signalMessageType: props.highlightFromSignal.messageType })}
                    </Typography>
                }
            </div>
        </div>
    </>);
}