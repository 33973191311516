import { Accordion, AccordionDetails, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Col, Container, Row } from 'react-bootstrap';
import { SearchCategoryValues } from 'src/api/models/Search';
import { ClassEditModel, CreateNewClass } from 'src/api/models/TeacherWizard';
import { L10N } from '../pages/L10N';
import { CategoryIcon } from '../ui/CategoryIcon';
import { CspIcon } from '../ui/CspIcon';
import { LogotypeSpans } from "../ui/LogotypeSpans";
import { StyledTitleRow } from '../ui/StyledTitle';
import { FieldSetProps } from './FormTeacherWizard';

export const FieldSetClasses = (props: FieldSetProps) => {

    const onAdd = (index?: number) => {
        const i = index ?? props.formik.values.classes?.length ?? 0;
        const newArray = [...props.formik.values.classes];
        newArray.splice(i, 0, CreateNewClass());

        props.formik.setFieldValue(`classes`, newArray);
    }
    const onDelete = (model: ClassEditModel, index: number) => props.formik.setFieldValue(
        `classes`,
        props.formik.values.classes.filter((mFilter, iFilter) => iFilter !== index)
    )
    const onChange = (model: ClassEditModel, index: number, name: string) => props.formik.setFieldValue(
        `classes[${index}].name`,
        name
    )
    return <>
        <Accordion defaultExpanded={false} >
            <AccordionDetails className="d-block pt-3 my-3">
                <Row>
                    <Col xs={12} md={6} >
                        <StyledTitleRow text={"Which subjects you feel good teaching"} hideDivider subtitle />
                        <Container className="m-0 p-0">
                            <Row>
                                <Col className="mx-0 ">
                                    <Container className="m-0 p-0">
                                        {props.formik.values.classes?.length > 0
                                            ? props.formik.values.classes?.map((m, i) =>
                                                <div key={i}>
                                                    <Row >
                                                        <Col className="mx-0 pr-0">
                                                            <TextField className="mb-3 px-0"
                                                                size='small'
                                                                label={L10N({ todo: "Class Name" })}
                                                                value={m.name}
                                                                onChange={(event) => onChange(m, i, event.target.value)}
                                                                slotProps={{
                                                                    input: {
                                                                        className: "pr-0",
                                                                        startAdornment: (<InputAdornment position='start'>
                                                                            {i + 1}.
                                                                        </InputAdornment>
                                                                        ),
                                                                        endAdornment: (<InputAdornment position='end'>
                                                                            <IconButton
                                                                                onClick={() => onDelete(m, i)}>
                                                                                <CspIcon name="HighlightOffIcon" fontSize="small" />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                        ),
                                                                    }
                                                                }}
                                                            /><br />
                                                        </Col>
                                                        <Col className="col-auto mx-0 px-0">
                                                            <IconButton
                                                                onClick={() => onAdd(i + 1)}>
                                                                <CspIcon name="AddCircleIcon" fontSize="small" color="primary" />
                                                            </IconButton>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                            :
                                            <Row >
                                                <Col className="my-1">
                                                    <Typography variant="body2" color="textPrimary">
                                                        {L10N({ todo: "Tell us about your specialization" })}
                                                    </Typography>
                                                </Col>
                                            </Row>
                                        }
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col xs={12} md={6} >
                        <StyledTitleRow text={"Why is it important?"} hideDivider subtitle />
                        <Typography color="secondary">
                            At <LogotypeSpans /> we believe it's best to lead by example.
                            <br />
                            We've generated the list of standard classess in your category
                            automatically.
                            <br />
                            <CategoryIcon name={props.formik.values.category as SearchCategoryValues} />
                            <br />
                            <br />
                            Please have a look and adjust as per your needs!
                        </Typography>
                    </Col>
                </Row>
            </AccordionDetails>
        </Accordion>
    </>;
}