import { Divider, Typography } from "@mui/material";
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LanguageSelectFlag } from '../ui/LanguageSelect';
import { AppRoutes } from './Routes';

export const PageHeader = (props: {
    title?: string,
    showDivider?: boolean,
    showLogo?: boolean,
    showLang?: boolean,
    logoNavigatesToHome?: boolean,
    noMarginTop?: boolean,
}) => {
    function marginClass(): string {
        const marginTop = props.noMarginTop ? "" : "mt-3";
        const marginDivider = props.showDivider ? "" : "mb-3";
        return marginTop + " " + marginDivider;
    }
    const route = props.logoNavigatesToHome
        ? AppRoutes.home
        : undefined;
    const s = 0.35;
    const w = 800 * s;
    const h = 496 * s;
    return (
        <>
            <Row>
                <Col>
                    {props.showLogo &&
                        <div className={"justify-content-center d-flex " + marginClass()}>
                            <div style={{ flex: 1 }} />
                            {route
                                ? <Link to={route} >
                                    <img src="/img/logo.png" width={w} height={h} alt="classnow" />
                                </Link>
                                :
                                <img src="/img/logo.png" width={w} height={h} alt="classnow" />
                            }
                            <div style={{ flex: 1 }} />
                            <div className="mr-3"
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                }}>
                                {props.showLang &&
                                    <LanguageSelectFlag hideLabel />
                                }
                            </div>
                        </div>
                    }
                    <Typography variant="subtitle1" color="primary"
                        className={marginClass()}>
                        {props.title}
                    </Typography >
                    {props.showDivider &&
                        <Divider className="mb-3" />
                    }
                </Col>
            </Row>
        </>
    )
}
export const PageTitle = (props: {
    title: string,
}) => {
    return (
        <>
            <Row>
                <Col>
                    <Typography variant="subtitle1"
                        align="center"
                        className="my-3">
                        {props.title}
                    </Typography >
                </Col>
            </Row>
        </>
    )
}
