import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/pl";
import { PriceAndCurrencyEditModel } from "../../api/models/Edit";
import { PriceAndCurrency, SignalMessageType } from "../../api/models/Global";
import { VisitStatusEnum } from "../../api/models/Visit";
import { EditStep } from "./EditPage";

export type L10N_resx_keys = {
    "resx_locale": "pl" | "en",
    "_defaultCurrency": string,
    "_Go to marketing website": string,
    "About the Teacher": string,
    "About us": string,
    "Account": string,
    "Account details": string,
    "Account Details": string,
    "Add": string,
    "Add some resources to configure your offer": string,
    "Add some services to configure your offer": string,
    "Add to calendar": string,
    "Add to Calendar": string,
    "Additional info:": string,
    "Address": string,
    "All clients": string,
    "All services": string,
    "Apple or Microsoft": string,
    "Appointment confirmed": string,
    "Appointment requested": string,
    "appointments": string,
    "Are you sure?": string,
    "Are you sure that you want to cancel your visit?": string,
    "at server": string,
    "Austria": string,
    "Average:": string,
    "Back": string,
    "Badge Level": string,
    "Banned clients": string,
    "Belgium": string,
    "Block - the student will not be allowed to join a class": string,
    "Join": string,
    "join and pay": string,
    "Join Now": string,
    "Join again": string,
    "Join a class": string,
    "Your ClassNow:": string,
    "ClassNow Category": string,
    "Cancel": string,
    "Cancel Visit": string,
    "Cancel visit?": string,
    "cannot be empty": string,
    "Cannot login?": string,
    "Certificates": string,
    "Change": string,
    "Classes": string,
    "Click OK to un-block this client.": string,
    "Click OK to block this client from your company.": string,
    "Client Name": string,
    "Client Phone": string,
    "Clients": string,
    "Closed": string,
    "Company Calendar": string,
    "Company Email": string,
    "Company ID": string,
    "Company description:": string,
    "Company Name": string,
    "Company Website": string,
    "Completed": string,
    "Completed visits:": string,
    "Confirm the visit to client": string,
    "Contact Details for Clients": string,
    "Contact Details": string,
    "Contact Email for ClassNow": string,
    "Contact Phone for ClassNow": string,
    "Contact details:": string,
    "Contact Us": string,
    "contact us and help us resolving the issue.": string,
    "Create Company": string,
    "Create Company - save": string,
    "Czech Republic": string,
    "India": string,
    "Day": string,
    "Duration [minutes]": string,
    "Default Price": string,
    "Delete": string,
    "Demo": string,
    "Description": string,
    "Description:": string,
    "Details": string,
    "Display Name": string,
    "Deutschland": string,
    "Do you have any special notes? Leave note here.": string,
    "Download": string,
    "Edit": string,
    "Edit Company": string,
    "Edit Profile": string,
    "Edit review": string,
    "emailsubject:Invite to join ClassNow": string,
    "emailbody1:Check out this new ClassNow: ": string,
    "emailbody2:Remember to use my referral code": string,
    "English": string,
    "Enter a valid email": string,
    "Enter a valid number": string,
    "Error occurred:": string,
    "explore": string,
    "Explore offers around you": string,
    "Favorites": string,
    "favourites": string,
    "Featured": string,
    "Finish a visit to review it": string,
    "First Name": string,
    "From the total of:": string,
    "From the total of:...visits": string,
    "German": string,
    "Google": string,
    "Here is your special code.": string,
    "Home": string,
    "https://classnow.in": string,
    "I agree to ": string,
    "I don't have referral code": string,
    "If you convince more than 10 people, you can expect a special prize. Go there! Help us!!!": string,
    "Are you satisfied with ClassNow and want to find even more offers?": string,
    "Then recommend us to your friends, colleagues or other service provider and receive a bonus for every subscription you take out.": string,
    "Get started now, support us and secure your reward!": string,
    "Invalid data provided.": string,
    "Invalid number": string,
    "Invite to join ClassNow": string,
    "Klarna API url": string,
    "Klarna Connection Not Working": string,
    "Klarna Connection Successful": string,
    "Klarna Connection Test": string,
    "Klarna Intro Text": string,
    "Klarna Payments": string,
    "Klarna username": string,
    "Klarna password": string,
    "Razorpay Connection Not Working": string,
    "Razorpay Connection Successful": string,
    "Razorpay Connection Test": string,
    "Razorpay Intro Text": string,
    "Razorpay Payments": string,
    "Razorpay Api Key": string,
    "Razorpay secret": string,
    "Language": string,
    "Last Name": string,
    "Latitude": string,
    "Learn more": string,
    "loading...": string,
    "Loading...": string,
    "Log in": string,
    "Log into your account": string,
    "Login": string,
    "Login to finalize": string,
    "Logout": string,
    "Longitude": string,
    "mailto:contact@classnow.in?subject=Login to ClassNow&body=I need assistance with login": string,
    "Map cannot be loaded": string,
    "Mark for deletion": string,
    "Mark for publishing": string,
    "Month": string,
    "Monthly payments:": string,
    "Monthly payments:...Clients": string,
    "Must be later": string,
    "My Appointments": string,
    "My Calendar": string,
    "Next": string,
    "No results found": string,
    "No visit selected": string,
    "No, I decided to come": string,
    "Not getting SMS?": string,
    "Notes:": string,
    "OK": string,
    "OK, got it": string,
    "Online payment impossible": string,
    "OR": string,
    "Paid": string,
    "Pay": string,
    "Payment ID": string,
    "PaymentPage - Title": string,
    "PaymentPage - Description": string,
    "PaymentPage - Finalize Payment": string,
    "Phone number": string,
    "Photo": string,
    "PIN": string,
    "Please enter partner code below or check that you don't have it": string,
    "Please contact us to change your phone number": string,
    "Please define your ClassNow": string,
    "Poland": string,
    "Polish": string,
    "Portfolio": string,
    "Prepare": string,
    "Privacy Policy": string,
    "profile": string,
    "Publish": string,
    "Rate and review your experience": string,
    "Rating Score": string,
    "Ready to publish": string,
    "Recommend": string,
    "Recommend ClassNow and gain instant benefits": string,
    "Referral code": string,
    "Referral statistics": string,
    "refresh reminders:": string,
    "Replace": string,
    "Require manual confirmation of the booking": string,
    "Resource ID": string,
    "Resource Name": string,
    "Resource edit: Phone number": string,
    "Resources": string,
    "Return": string,
    "Review": string,
    "Reviews": string,
    "Save": string,
    "Save account": string,
    "Search": string,
    "Search bar placeholder": string,
    "Search Keywords": string,
    "Send": string,
    "Send to contacts": string,
    "Service Category": string,
    "Service ID": string,
    "Service Name": string,
    "Services": string,
    "Share": string,
    "Share your unique code with friends and business owners. When they subscribe to our service, we'll contact you with benefits.": string,
    "Show directions": string,
    "Specify time as HH:mm": string,
    "Staff members:": string,
    "Statistics &amp; Reports": string,
    "Subtitle": string,
    "SuperAdmin: Assign Company": string,
    "Switzerland": string,
    "ClassNow Tagline": string,
    "terms and conditions": string,
    "Terms and Conditions": string,
    "Terms of Use": string,
    "THANK YOU": string,
    "There are no offers that satisfy your criteria. We need to remove your filtering.": string,
    "This person has no portoflio": string,
    "This person has no reviews yet": string,
    "Time after [minutes]": string,
    "Time before [minutes]": string,
    "Title": string,
    "Under construction": string,
    "United Arab Emirates": string,
    "Unique clients:": string,
    "Upcoming": string,
    "Update map": string,
    "Upfront payment": string,
    "Upfront payment - price": string,
    "Upfront payment - you need to pay upfront for this visit": string,
    "Upfront payment - the client has to pay upfront online while booking a visit": string,
    "View as client": string,
    "Visit ID": string,
    "Visit Review ID": string,
    "Visit Start": string,
    "Visit Start for Client": string,
    "Visit statistics": string,
    "Visit Status": string,
    "VisitSummaryCard:When your request is accepted(1/2)": string,
    "VisitSummaryCard:When your request is accepted(2/2)": string,
    "VisitSummaryCard:Your request will be answered shortly.": string,
    "Waiting for your rating and review:": string,
    "We need the following information before publishing:": string,
    "Week": string,
    "What time?": string,
    "Working Hours:": string,
    "Working hours": string,
    "Write here...": string,
    "Write review": string,
    "Yearly payments:": string,
    "Yearly payments:...Clients": string,
    "Yes, please cancel": string,
    "You can select your favourite staff for the service and select your preferred date and time": string,
    "You have no completed visits": string,
    "You have no favorites yet": string,
    "You have no upcoming visits": string,
    "You're done!": string,
    "We'll send you a reminder": string,
    "You've almost earned additional:": string,
    "You've earned:": string,
    "before your appointment": string,
    "Your company calendar...": string,
    "Your consent is required": string,
    "Your referrals:": string,
    "Your referrals:...Clients": string,
    "Your reviews:": string,
    "Your session has expired.": string,
    "Zoom": string,
    "Besucher": string,
    "Dienstleister": string,
    "Für Besucher": string,
    "Für Dienstleister": string,
    "Email": string,
    "Nachricht": string,
    "Name": string,
    '100% kostenlos für Besucher': string,
    '100% kostenlos': string,
    '100% online': string,
    '12 Monate zum Preis von 10': string,
    '24 / 7 / 365 Termine': string,
    '24h Buchungsassistent': string,
    '30 Tage kostenfrei testen': string,
    '49€': string,
    '499€': string,
    'ABONNIEREN': string,
    'Als Beispiel: Eine Arzthelferin verbringt um die 50% der Arbeitszeit am Telefon, um aufwendig die Terminanfragen anzunehmen, in den Kalender zu schauen und auch rumzublättern, bis der passende Termin für beide gefunden ist. Dadurch entstehen längere Wartezeiten in der Praxis, und wichtigere Aufgaben bleiben dabei auf der Strecke.': string,
    'Am Sandfeld 18, 76149 Karlsruhe': string,
    'Angebote für Dienstleister': string,
    'Buchen': string,
    'Dann schreib uns einfach via Email, oder Kontaktformular an und wir melden uns dann schnellstmöglich bei dir.': string,
    'Datenschutz &amp; AGB': string,
    'Direkt und zeitsparend': string,
    'Empfehlung': string,
    'Erinnerungs-SMS mit Hinweise an Kunden': string,
    'FEATURES': string,
    'Features': string,
    'Genau jetzt kommen wir mit ClassNow ins Spiel. Wir haben uns eine Lösung ausgedacht, die Dir als Kunden, aber auch als Geschäftsbetreiber eine passende Antwort liefert.': string,
    'Hier sind wir': string,
    'Impressum': string,
    'Jahres-Abo': string,
    'KATEGORIEN': string,
    'KONTAKT': string,
    'Kategorien': string,
    'Kennst du das, du rufst beim Arzt an, es einige Minuten hin und her geht, nur um einen freien Termin zu bekommen? Oder wenn Du am Wochenende gerne einen Termin für Montag beim Frisör vereinbaren möchtest, aber niemand geht ans Telefon?': string,
    'Kontaktiere uns': string,
    'Mit ClassNow kannst du jederzeit nach einem passenden Termin online schauen, buchen und ggfs. auch ändern oder sogar stornieren. Falls Du ein Geschäft betreibst und du es satt hast, ständig am Telefon zu sitzen um die Termine zu koordinieren, ist ClassNow genau das Richtige für dich. Mit ClassNow, als deinen persönlichen‚ rund um die Uhr Termin-Assistenten, hast du mehr Zeit für dich und für die wichtigeren Dinge im privaten oder beruflichen Alltag.': string,
    'Monats-Abo': string,
    'Möchtest du gerne als Dienstleister unseren Service nutzen?': string,
    'Nachricht erfolgreich gesendet': string,
    'Nutze unser Kontaktformular': string,
    'PROBIER ES AUS': string,
    'Probier es aus': string,
    'SCREENSHOTS': string,
    'SMS-Erinnerung': string,
    'Screenshots': string,
    'Senden': string,
    'Sie werden zu unserem Partner weitergeleitet, um die Zahlung abzuschließen': string,
    'TEAM': string,
    'Unabhängige Flexibilität': string,
    'Vorzugspreis': string,
    'WARUM?': string,
    'Warum ClassNow?': string,
    'Was ist ClassNow?': string,
    'bitte füllen Sie das Formular aus': string,
    'inkl. Werbe-Material': string,
    'jederzeit buchbar': string,
    'komplette Übersicht': string,
    'monatlich kündbar': string,
    'weniger Zeit am Telefon': string,
    'ÜBER UNS': string,
    'MIT ClassNow KANNST DU KOSTENLOS &amp; BEQUEM RUND UM DIE UHR DEINEN TERMIN BUCHEN': string,
}

export type L10N_resx_date = {
    "_dateAsOn": (value?: string) => string,
    "_dateAsTimeAgo": (value?: string) => string,
    "_dateAsDD": (value?: string) => string,
    "_dateAsddd": (value?: string) => string,
    "_dateAsdddd": (value?: string) => string,
    "_dateAsMM": (value?: string) => string,
    "_dateAsMMM": (value?: string) => string,
    "_dateAsMMMM": (value?: string) => string,
    "_dateAsMMMMYYYY": (value?: string) => string,
    "_dateAsHHmm": (value?: string) => string,
    "_dateAsDDMMMM": (value?: string) => string,
    "_dateAsDDMMMHHmm": (value?: string) => string,
    "_dateAsDayOrTomorrow": (value?: string) => string,
}
export type L10N_resx_duration = {
    "_duration": (value?: number) => string,
}
export type L10N_resx_category = {
    "_category": (value?: string) => string,
    "_categoryDescriptionHtml": (value?: string) => string,
}
export type L10N_resx_hint = {
    "_hint": (value?: string) => string,
}
export type L10N_resx_editStep = {
    "_editStep": (value?: EditStep) => string,
}
export type L10N_resx_status = {
    "_status": (value?: VisitStatusEnum) => string,
}
export type L10N_resx_price = {
    "_priceAndCurrency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => string,
    "_currency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => string,
}
export type L10N_resx_signal = {
    "_signalMessageType": (value?: SignalMessageType) => string,
}
export type L10N_resx = L10N_resx_keys
    & L10N_resx_date
    & L10N_resx_duration
    & L10N_resx_category
    & L10N_resx_hint
    & L10N_resx_editStep
    & L10N_resx_status
    & L10N_resx_price
    & L10N_resx_signal;

export const L10N_date_en: L10N_resx_date = {
    "_dateAsOn": (value?: string) => moment(value).locale("en-IN").format("on MMM, DD"),
    "_dateAsTimeAgo": (value?: string) => moment(value).locale("en-IN").fromNow(),
    "_dateAsDD": (value?: string) => moment(value).locale("en-IN").format("DD"),
    "_dateAsddd": (value?: string) => moment(value).locale("en-IN").format("ddd"),
    "_dateAsdddd": (value?: string) => moment(value).locale("en-IN").format("dddd"),
    "_dateAsMM": (value?: string) => moment(value).locale("en-IN").format("MM"),
    "_dateAsMMM": (value?: string) => moment(value).locale("en-IN").format("MMM"),
    "_dateAsMMMM": (value?: string) => moment(value).locale("en-IN").format("MMMM"),
    "_dateAsMMMMYYYY": (value?: string) => moment(value).locale("en-IN").format("MMMM, YYYY"),
    "_dateAsHHmm": (value?: string) => moment(value).locale("en-IN").format("HH:mm"),
    "_dateAsDDMMMM": (value?: string) => moment(value).locale("en-IN").format("DD MMMM"),
    "_dateAsDDMMMHHmm": (value?: string) => moment(value).locale("en-IN").format("DD MMM HH:mm"),
    "_dateAsDayOrTomorrow": (value?: string) => moment(value).locale("en-IN").calendar({
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'ddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] ddd',
        sameElse: 'ddd'
    }),
}
export const L10N_duration_en: L10N_resx_duration = {
    "_duration": (value?: number) => {
        if (!value) {
            return "-";
        } else if (value < 60) {
            return `${value}min`;
        } else if (value % 60 === 0) {
            return `${value / 60}h`;
        } else {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return `${hours}h ${minutes}m`;
        }
    },
}
export const L10N_category_en: L10N_resx_category = {
    "_category": (value?: string) => {
        switch (value) {
            case undefined: return "";
            case "anything": return "anything";
            case "stem": return "STEM (Science, Tech, Eng & Maths)";
            case "lang": return "Language & Comms";
            case "biz": return "Business & Commerce";
            case "human": return "Human & Social Science";
            case "exam": return "Exam Preps";
            case "arts": return "Arts & Creative";
            case "aviation": return "Aviation & Travel";
            case "personal": return "Personal Development";
            default: return value;
        }
    },
    "_categoryDescriptionHtml": (value?: string) => {
        switch (value) {
            case undefined: return "";
            case "anything": return "anything";
            case "stem": return "STEM (Science, Tech, Eng & Maths) newline <br/> <br/> \r\n\r\n\r\n\r\n next line blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ";
            case "lang": return "Language & Comms blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ";
            case "biz": return "Business & Commerce blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ";
            case "human": return "Human & Social Science blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ";
            case "exam": return "Exam Preps blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ";
            case "arts": return "Arts & Creative blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ";
            case "aviation": return "Aviation & Travel blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ";
            case "personal": return "Personal Development blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ";
            default: return value;
        }
    },
}
export const L10N_hint_en: L10N_resx_hint = {
    "_hint": (value?: string) => {
        switch (value) {
            case undefined: return "";
            case "anytime": return "anytime";
            case "today": return "today";
            case "tomorrow": return "tomorrow";
            default: return value;
        }
    },
}
export const L10N_editStep_en: L10N_resx_editStep = {
    "_editStep": (value?: EditStep) => {
        switch (value) {
            case "about": return "About you";
            case "address": return "Your address";
            case "for clients": return "For clients";
            case "services": return "Services";
            case "resources": return "Resources";
            case "publish": return "Publish";
            case "reviews": return "Reviews";
            default: return value ?? "";
        }
    },
}
export const L10N_status_en: L10N_resx_status = {
    "_status": (value?: VisitStatusEnum) => {
        switch (value) {
            case undefined: return "";
            case "Draft": return "Draft";
            case "Requested": return "Requested";
            case "Confirmed": return "Confirmed";
            case "Done": return "Done";
            case "CanceledByClient": return "Canceled by Client";
            case "CanceledByCompany": return "Canceled by Company";
            case "RescheduledByClient": return "Rescheduled by Client";
            default: return value;
        }
    },
}
export const L10N_price_en: L10N_resx_price = {
    "_priceAndCurrency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => {
        if ((value?.price ?? 0) === 0) return "free";
        // const currency = (!value?.currency) ? "₹"
        //     : value?.currency?.toUpperCase() === "INR" ? "₹"
        //         : value?.currency?.toUpperCase() === "USD" ? "$"
        //             : value?.currency?.toUpperCase() === "EUR" ? "€"
        //                 : value?.currency;
        return `${value?.price}`;
    },
    "_currency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => {
        const currency = (!value?.currency) ? "₹"
            : value?.currency?.toUpperCase() === "INR" ? "₹"
                : value?.currency?.toUpperCase() === "USD" ? "$"
                    : value?.currency?.toUpperCase() === "EUR" ? "€"
                        : value?.currency;
        return `${currency}`;
    },
}
export const L10N_signal_en: L10N_resx_signal = {
    "_signalMessageType": (value?: SignalMessageType) => {
        switch (value) {
            case undefined: return "";
            case "Custom": return "Someone is interested in your company";
            case "CompanyViewed": return "Someone has just viewed your company";
            case "InviteCreated": return "Someone is looking for a visit";
            case "VisitCancelled": return "Someone has cancelled a visit";
            case "VisitConfirmed": return "Someone has confirmed a visit";
            case "VisitRescheduled": return "Someone has rescheduled a visit";
            default: return value;
        }
    },
}
export const L10N_keys_en: L10N_resx_keys = {
    "resx_locale": "en",
    "_defaultCurrency": "₹",
    "_Go to marketing website": "Info",
    "About the Teacher": "About the Teacher",
    "About us": "About us",
    "Account": "Account",
    "Account details": "Account details",
    "Account Details": "Account Details",
    "Add": "Add",
    "Add some resources to configure your offer": "Add some resources to configure your offer",
    "Add some services to configure your offer": "Add some services to configure your offer",
    "Add to calendar": "Add to calendar",
    "Add to Calendar": "Add to Calendar",
    "Additional info:": "Additional info:",
    "Address": "Address",
    "All clients": "All clients",
    "All services": "All services",
    "Apple or Microsoft": "Apple or Microsoft",
    "Appointment confirmed": "Appointment confirmed",
    "Appointment requested": "Appointment requested",
    "appointments": "appointments",
    "Are you sure?": "Are you sure?",
    "Are you sure that you want to cancel your visit?": "Are you sure that you want to cancel your visit?",
    "at server": "at server",
    "Austria": "Austria",
    "Average:": "Average:",
    "Back": "Back",
    "Badge Level": "Badge Level",
    "Banned clients": "Banned clients",
    "Belgium": "Belgium",
    "Block - the student will not be allowed to join a class": "Block - the student will not be allowed to join a class",
    "Classes": "Classes",
    "Join": "Join",
    "join and pay": "join and pay",
    "Join Now": "Join Now",
    "Join again": "Join again",
    "Join a class": "Join a class",
    "Your ClassNow:": "Your ClassNow:",
    "ClassNow Category": "ClassNow Category",
    "Cancel": "Cancel",
    "Cancel Visit": "Cancel Visit",
    "Cancel visit?": "Cancel visit?",
    "cannot be empty": "cannot be empty",
    "Cannot login?": "Cannot login?",
    "Certificates": "Certificates",
    "Change": "Change",
    "Click OK to un-block this client.": "Click OK to un-block this client.",
    "Click OK to block this client from your company.": "Click OK to block this client from your company.",
    "Client Name": "Client Name",
    "Client Phone": "Client Phone",
    "Clients": "Clients",
    "Closed": "Closed",
    "Company Calendar": "Company Calendar",
    "Company Email": "Company Email",
    "Company ID": "Company ID",
    "Company description:": "Company description",
    "Company Name": "Company Name",
    "Company Website": "Company Website",
    "Completed": "Completed",
    "Completed visits:": "Completed visits:",
    "Confirm the visit to client": "Confirm the visit to client",
    "Contact Details for Clients": "Contact Details for Clients",
    "Contact Details": "Contact Details",
    "Contact details:": "Contact details:",
    "Contact Email for ClassNow": "Contact Email for ClassNow",
    "Contact Phone for ClassNow": "Contact Phone for ClassNow",
    "Contact Us": "Contact Us",
    "contact us and help us resolving the issue.": "contact us and help us resolving the issue.",
    "Create Company": "Add your offer",
    "Create Company - save": "Create",
    "Czech Republic": "Czech Republic",
    "India": "India",
    "Day": "Day",
    "Delete": "Delete",
    "Duration [minutes]": "Default Duration [minutes]",
    "Default Price": "Default Price",
    "Demo": "Demo",
    "Description": "Description",
    "Description:": "Description:",
    "Details": "Details",
    "Display Name": "Display Name",
    "Deutschland": "Deutschland",
    "Do you have any special notes? Leave note here.": "Do you have any special notes? Leave note here.",
    "Download": "Download",
    "Edit": "Edit",
    "Edit Company": "Edit Company",
    "Edit Profile": "Edit Profile",
    "Edit review": "Edit review",
    "emailsubject:Invite to join ClassNow": "Invite to join ClassNow",
    "emailbody1:Check out this new ClassNow: ": "You can follow this link to register to ClassNow: ",
    "emailbody2:Remember to use my referral code": " (this is from my partnership program)",
    "English": "English",
    "Enter a valid email": "Enter a valid email",
    "Enter a valid number": "Enter a valid number",
    "Error occurred:": "Error occurred:",
    "explore": "explore",
    "Explore offers around you": "Explore offers around you",
    "Favorites": "Favorites",
    "favourites": "favourites",
    "Featured": "Featured",
    "Finish a visit to review it": "Finish a visit to review it",
    "First Name": "First Name",
    "From the total of:": "From the total of:",
    "From the total of:...visits": "visits",
    "German": "German",
    "Google": "Google",
    "Here is your special code.": "Here is your referral link.",
    "Home": "Home",
    "https://classnow.in": "https://classnow.in",
    "I agree to ": "I agree to ",
    "I don't have referral code": "I don't have referral code",
    "If you convince more than 10 people, you can expect a special prize. Go there! Help us!!!": "If you convince more than 10 people, you can expect a special prize. Go there! Help us!!!",
    "Are you satisfied with ClassNow and want to find even more offers?": "Are you satisfied with ClassNow and want to find even more offers?",
    "Then recommend us to your friends, colleagues or other service provider and receive a bonus for every subscription you take out.": "Then recommend us to your friends, colleagues or other service provider and receive a bonus for every subscription you take out.",
    "Get started now, support us and secure your reward!": "Get started now, support us and secure your reward!",
    "Invalid number": "Invalid number",
    "Invalid data provided.": "Invalid data provided.",
    "Invite to join ClassNow": "Invite to join ClassNow",
    "Klarna API url": "Klarna API URL",
    "Klarna Connection Not Working": "Klarna Connection Not Working",
    "Klarna Connection Successful": "Klarna Connection Successful",
    "Klarna Connection Test": "Test Klarna Connection",
    "Klarna Intro Text": "If you already use Klarna in the shop, you can connect your Klarna account here",
    "Klarna Payments": "Upfront payments system - soon available",
    "Klarna username": "Klarna login",
    "Klarna password": "Klarna password",
    "Razorpay Connection Not Working": "Razorpay Connection Not Working",
    "Razorpay Connection Successful": "Razorpay Connection Successful",
    "Razorpay Connection Test": "Test Razorpay Connection",
    "Razorpay Intro Text": "If you already use Razorpay in the shop, you can connect your Razorpay account here",
    "Razorpay Payments": "Payments system",
    "Razorpay Api Key": "Razorpay Api Key",
    "Razorpay secret": "Razorpay secret",
    "Language": "Language",
    "Last Name": "Last Name",
    "Latitude": "Latitude",
    "Learn more": "Learn more",
    "loading...": "loading...",
    "Loading...": "Loading...",
    "Log in": "Log in",
    "Log into your account": "Log into your account",
    "Login": "Login",
    "Login to finalize": "Login to finalize",
    "Logout": "Logout",
    "Longitude": "Longitude",
    "mailto:contact@classnow.in?subject=Login to ClassNow&body=I need assistance with login": "mailto:contact@classnow.in?subject=Login to ClassNow&body=I need assistance with login",
    "Map cannot be loaded": "Map cannot be loaded",
    "Mark for deletion": "Mark for deletion",
    "Mark for publishing": "Show the offer to clients",
    "Month": "Month",
    "Monthly payments:": "Monthly payments:",
    "Monthly payments:...Clients": "Clients",
    "Must be later": "Must be later",
    "My Appointments": "My Appointments",
    "My Calendar": "My Calendar",
    "Next": "Next",
    "No results found": "There is no teacher offering this course yet. Give us some time to figure it out.",
    "No visit selected": "No visit selected",
    "No, I decided to come": "No, I decided to come",
    "Not getting SMS?": "Not getting SMS?",
    "Notes:": "Notes:",
    "OK": "OK",
    "OK, got it": "OK, got it",
    "Online payment impossible": "Online payment impossible",
    "OR": "OR",
    "Paid": "Paid",
    "Pay": "Pay",
    "Payment ID": "Payment ID",
    "PaymentPage - Title": "Pay for the visit",
    "PaymentPage - Description": "Our payments are supported with external provider. You can finalize the payment using the button below, or scanning the QR code.",
    "PaymentPage - Finalize Payment": "Finalize Payment",
    "Phone number": "Phone number",
    "Photo": "Photo",
    "PIN": "PIN",
    "Please enter partner code below or check that you don't have it": "Please enter partner code below or check that you don't have it",
    "Please contact us to change your phone number": "Please contact us to change your phone number",
    "Please define your ClassNow": "Please select date and time of your visit",
    "Poland": "Poland",
    "Polish": "Polish",
    "Portfolio": "Portfolio",
    "Prepare": "Prepare",
    "Privacy Policy": "Privacy Policy",
    "profile": "profile",
    "Publish": "Publish",
    "Rate and review your experience": "Rate and review your experience",
    "Rating Score": "Rating Score",
    "Ready to publish": "Ready to publish",
    "Recommend": "Recommend",
    "Recommend ClassNow and gain instant benefits": "Recommend ClassNow and gain instant benefits",
    "Referral code": "Referral code",
    "Referral statistics": "Referral statistics",
    "refresh reminders:": "refresh reminders:",
    "Replace": "Replace",
    "Require manual confirmation of the booking": "Require manual confirmation of the booking",
    "Resource ID": "Resource ID",
    "Resource Name": "Resource Name",
    "Resource edit: Phone number": "Phone number",
    "Resources": "Resources",
    "Return": "Return",
    "Review": "Review",
    "Reviews": "Reviews",
    "Save": "Save",
    "Save account": "Save account",
    "Search": "Search",
    "Search bar placeholder": "or search subject, teacher, class...",
    "Search Keywords": "Search Keywords",
    "Send": "Send",
    "Send to contacts": "Send to contacts",
    "Service Category": "Service Category",
    "Service ID": "Service ID",
    "Service Name": "Service Name",
    "Services": "Services",
    "Share": "Share",
    "Share your unique code with friends and business owners. When they subscribe to our service, we'll contact you with benefits.": "Share your unique code with friends and business owners. When they subscribe to our service, we'll contact you with benefits.",
    "Show directions": "Show directions",
    "Specify time as HH:mm": "Specify time as HH:mm",
    "Staff members:": "Staff members:",
    "Statistics &amp; Reports": "Statistics & Reports",
    "Subtitle": "Subtitle",
    "SuperAdmin: Assign Company": "Join the company (admin)",
    "Switzerland": "Switzerland",
    "ClassNow Tagline": "Join the Class... Now",
    "terms and conditions": "terms and conditions",
    "Terms and Conditions": "Terms and Conditions",
    "Terms of Use": "Terms of Use",
    "THANK YOU": "THANK YOU",
    "There are no offers that satisfy your criteria. We need to remove your filtering.": "There are no offers that satisfy your criteria. We need to remove your filtering.",
    "This person has no portoflio": "This person has no portoflio",
    "This person has no reviews yet": "This person has no reviews yet",
    "Time after [minutes]": "Time after [minutes]",
    "Time before [minutes]": "Time before [minutes]",
    "Title": "Title",
    "Under construction": "Under construction",
    "United Arab Emirates": "United Arab Emirates",
    "Unique clients:": "Unique clients:",
    "Upcoming": "Upcoming",
    "Update map": "Update map",
    "Upfront payment": "Upfront payment",
    "Upfront payment - price": "Upfront payment (quote)",
    "Upfront payment - you need to pay upfront for this visit": "Upfront payment - you need to pay upfront for this visit",
    "Upfront payment - the client has to pay upfront online while booking a visit": "Upfront payment - the client has to pay upfront online while booking a visit",
    "View as client": "Preview",
    "Visit ID": "Visit ID",
    "Visit Review ID": "Visit Review ID",
    "Visit Start": "Visit",
    "Visit Start for Client": "Visit (for client)",
    "Visit statistics": "Visit statistics",
    "Visit Status": "Visit Status",
    "VisitSummaryCard:When your request is accepted(1/2)": "When your request is accepted",
    "VisitSummaryCard:When your request is accepted(2/2)": "we will send you an SMS.",
    "VisitSummaryCard:Your request will be answered shortly.": "Your request will be answered shortly.",
    "Waiting for your rating and review:": "Waiting for your rating and review:",
    "We need the following information before publishing:": "We need the following information before publishing:",
    "Week": "Week",
    "What time?": "What time?",
    "Working Hours:": "Working Hours:",
    "Working hours": "Working hours",
    "Write here...": "Write here...",
    "Write review": "Write review",
    "Yearly payments:": "Yearly payments:",
    "Yearly payments:...Clients": "Clients",
    "Yes, please cancel": "Yes, please cancel",
    "You can select your favourite staff for the service and select your preferred date and time": "You can select your favourite staff for the service and select your preferred date and time",
    "You have no completed visits": "You have no completed visits",
    "You have no favorites yet": "You have no favorites yet",
    "You have no upcoming visits": "You have no upcoming visits",
    "You're done!": "You're done!",
    "We'll send you a reminder": "We'll send you a reminder",
    "You've earned:": "You've earned:",
    "You've almost earned additional:": "You've almost earned additional:",
    "before your appointment": "before your appointment",
    "Your company calendar...": "Your company calendar...",
    "Your consent is required": "Your consent is required",
    "Your referrals:": "Your referrals:",
    "Your referrals:...Clients": "Clients",
    "Your reviews:": "Your reviews:",
    "Your session has expired.": "Your session has expired.",
    "Zoom": "Zoom",
    'Besucher': 'Visitors',
    'Dienstleister': 'Service provider',
    'Für Besucher': 'For visitor',
    'Für Dienstleister': 'For service provider',
    'Email': 'Email',
    'Nachricht': 'Message',
    'Name': 'Name',
    '100% kostenlos für Besucher': '100% free for visitors',
    '100% kostenlos': '100% free of charge',
    '100% online': '100% online',
    '12 Monate zum Preis von 10': '12 months for the price of 10',
    '24 / 7 / 365 Termine': '24 / 7 / 365 dates',
    '24h Buchungsassistent': '24h booking assistant',
    '30 Tage kostenfrei testen': '30 days free trial',
    '49€': 'INR 599',
    '499€': 'INR 4990',
    'ABONNIEREN': 'SUBSCRIBE',
    'Als Beispiel: Eine Arzthelferin verbringt um die 50% der Arbeitszeit am Telefon, um aufwendig die Terminanfragen anzunehmen, in den Kalender zu schauen und auch rumzublättern, bis der passende Termin für beide gefunden ist. Dadurch entstehen längere Wartezeiten in der Praxis, und wichtigere Aufgaben bleiben dabei auf der Strecke.': 'As an example: A receptionist spends about 50% of her working time on the phone, taking appointment requests, looking in the calendar and also browsing around until the right appointment is found for both of them. This results in longer waiting times, and more important tasks fall by the wayside.',
    'Am Sandfeld 18, 76149 Karlsruhe': 'Am Sandfeld 18, 76149 Karlsruhe, Germany',
    'Angebote für Dienstleister': 'Offers for service providers',
    'Buchen': 'Book',
    'Dann schreib uns einfach via Email, oder Kontaktformular an und wir melden uns dann schnellstmöglich bei dir.': 'Then just write to us via email, or contact form and we will get back to you as soon as possible.',
    'Datenschutz &amp; AGB': 'Privacy & GTC (General terms and conditions)',
    'Direkt und zeitsparend': 'Direct and time saving',
    'Empfehlung': 'Recommendation',
    'Erinnerungs-SMS mit Hinweise an Kunden': 'SMS reminder with notices to customers',
    'FEATURES': 'FEATURES',
    'Features': 'Features',
    'Genau jetzt kommen wir mit ClassNow ins Spiel. Wir haben uns eine Lösung ausgedacht, die Dir als Kunden, aber auch als Geschäftsbetreiber eine passende Antwort liefert.': 'This is exactly where we come in with "ClassNow". We have come up with a solution that provides you as a customer, but also as a business owner, with a suitable answer.',
    'Hier sind wir': 'Here we are',
    'Impressum': 'Imprint',
    'Jahres-Abo': 'Annual subscription',
    'KATEGORIEN': 'CATEGORIES',
    'KONTAKT': 'Contact',
    'Kategorien': 'Categories',
    'Kennst du das, du rufst beim Arzt an, es einige Minuten hin und her geht, nur um einen freien Termin zu bekommen? Oder wenn Du am Wochenende gerne einen Termin für Montag beim Frisör vereinbaren möchtest, aber niemand geht ans Telefon?': 'Do you know this, you call the hairdresser, it goes back and forth for a few minutes, just to get a free appointment? Or when you would like to make an appointment for Monday at the weekend, but nobody answers the phone?',
    'Kontaktiere uns': 'Contact us',
    'Mit ClassNow kannst du jederzeit nach einem passenden Termin online schauen, buchen und ggfs. auch ändern oder sogar stornieren. Falls Du ein Geschäft betreibst und du es satt hast, ständig am Telefon zu sitzen um die Termine zu koordinieren, ist ClassNow genau das Richtige für dich. Mit ClassNow, als deinen persönlichen‚ rund um die Uhr Termin-Assistenten, hast du mehr Zeit für dich und für die wichtigeren Dinge im privaten oder beruflichen Alltag.': 'With ClassNow you can always look for a suitable appointment online, book it and if necessary change or even cancel it. If you run a business and you`re tired of being on the phone to coordinate appointments, ClassNow is for you.With ClassNow as your personal round- the - clock appointment assistant, you have more time for yourself and for the more important things in your private or professional life.',
    'Monats-Abo': 'Monthly subscription',
    'Möchtest du gerne als Dienstleister unseren Service nutzen?': 'Would you like to use our service as a service provider?',
    'Nachricht erfolgreich gesendet': 'Message sent successfully',
    'Nutze unser Kontaktformular': 'Use our contact form',
    'PROBIER ES AUS': 'TRY IT OUT',
    'Probier es aus': 'Try it out',
    'SCREENSHOTS': 'SCREENSHOTS',
    'SMS-Erinnerung': 'SMS reminder',
    'Screenshots': 'Screenshots',
    'Senden': 'Send',
    'Sie werden zu unserem Partner weitergeleitet, um die Zahlung abzuschließen': 'You will be redirected to our partner to complete the payment',
    'TEAM': 'TEAM',
    'Unabhängige Flexibilität': 'connect with social media',
    'Vorzugspreis': 'Preferential price',
    'WARUM?': 'WHY?',
    'Warum ClassNow?': 'Why ClassNow?',
    'Was ist ClassNow?': 'What is ClassNow?',
    'bitte füllen Sie das Formular aus': 'please fill out the form',
    'inkl. Werbe-Material': 'incl. advertising material',
    'jederzeit buchbar': 'bookable at any time',
    'komplette Übersicht': 'complete overview',
    'monatlich kündbar': 'can be cancelled monthly',
    'weniger Zeit am Telefon': 'less time on the phone',
    'ÜBER UNS': 'About us',
    'MIT ClassNow KANNST DU KOSTENLOS &amp; BEQUEM RUND UM DIE UHR DEINEN TERMIN BUCHEN': 'DITCH THE WAIT AND BOOK YOUR SERVICE LIKE A PRO WITH ClassNow',
};
export const L10N_en: L10N_resx = {
    ...L10N_keys_en,
    ...L10N_date_en,
    ...L10N_duration_en,
    ...L10N_category_en,
    ...L10N_hint_en,
    ...L10N_editStep_en,
    ...L10N_status_en,
    ...L10N_price_en,
    ...L10N_signal_en,
};
export const L10N_pl: L10N_resx = {
    "resx_locale": "pl",
    "_defaultCurrency": "₹",
    "_dateAsOn": (value?: string) => moment(value).locale("pl").format("DD. MMM"),
    "_dateAsTimeAgo": (value?: string) => moment(value).locale("pl").fromNow(),
    "_dateAsDD": (value?: string) => moment(value).locale("pl").format("DD"),
    "_dateAsddd": (value?: string) => moment(value).locale("pl").format("ddd"),
    "_dateAsdddd": (value?: string) => moment(value).locale("pl").format("dddd"),
    "_dateAsMM": (value?: string) => moment(value).locale("pl").format("MM"),
    "_dateAsMMM": (value?: string) => moment(value).locale("pl").format("MMM"),
    "_dateAsMMMM": (value?: string) => moment(value).locale("pl").format("MMMM"),
    "_dateAsMMMMYYYY": (value?: string) => moment(value).locale("pl").format("MMMM, YYYY"),
    "_dateAsHHmm": (value?: string) => moment(value).locale("pl").format("HH:mm"),
    "_dateAsDDMMMM": (value?: string) => moment(value).locale("pl").format("DD MMMM"),
    "_dateAsDDMMMHHmm": (value?: string) => moment(value).locale("pl").format("DD MMM HH:mm"),
    "_dateAsDayOrTomorrow": (value?: string) => moment(value).locale("pl").calendar({
        sameDay: '[dzisiaj]',
        nextDay: '[jutro]',
        nextWeek: 'ddd',
        lastDay: '[wczoraj]',
        lastWeek: '[poprzedni] ddd',
        sameElse: 'ddd'
    }),
    "_duration": (value?: number) => {
        if (!value) {
            return "-";
        } else if (value < 60) {
            return `${value}min`;
        } else if (value % 60 === 0) {
            return `${value / 60}h`;
        } else {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return `${hours}h ${minutes}m`;
        }
    },
    "_category": (value?: string) => {
        switch (value) {
            case undefined: return "";
            case "anything": return "anything";
            case "stem": return "STEM (Science, Technology, Engineering, Mathematics)";
            case "lang": return "Language & Communication";
            case "biz": return "Business & Commerce";
            case "human": return "Humanities & Social Sciences";
            case "exam": return "Competitive Exam Preparation";
            case "arts": return "Arts & Creative Skills";
            case "aviation": return "Aviation & Travel";
            case "personal": return "Personal Development & Life Skills";
            default: return value;
        }
    },
    "_categoryDescriptionHtml": (value?: string) => {
        switch (value) {
            case undefined: return "";
            case "anything": return "anything";
            case "stem": return "STEM (Science, Tech, Eng & Maths) blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ";
            case "lang": return "Language & Comms blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ";
            case "biz": return "Business & Commerce blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ";
            case "human": return "Human & Social Science blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ";
            case "exam": return "Exam Preps blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ";
            case "arts": return "Arts & Creative blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ";
            case "aviation": return "Aviation & Travel blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ";
            case "personal": return "Personal Development blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah ";
            default: return value;
        }
    },
    "_hint": (value?: string) => {
        switch (value) {
            case undefined: return "";
            case "anytime": return "dowolny czas";
            case "today": return "dzisiaj";
            case "tomorrow": return "jutro";
            default: return value;
        }
    },
    "_editStep": (value?: EditStep) => {
        switch (value) {
            case "about": return "Dane podstawowe";
            case "address": return "Adres";
            case "for clients": return "O Twojej firmie";
            case "services": return "Usługi";
            case "resources": return "Pracownicy";
            case "publish": return "Publikuj";
            case "reviews": return "Oceny";
            default: return value ?? "";
        }
    },
    "_status": (value?: VisitStatusEnum) => {
        switch (value) {
            case undefined: return "";
            case "Draft": return "W trakcie";
            case "Requested": return "Zamówiona";
            case "Confirmed": return "Potwierdzona";
            case "Done": return "Zakończona";
            case "CanceledByClient": return "Anulowana przez klienta";
            case "CanceledByCompany": return "Anulowana przez firmę";
            case "RescheduledByClient": return "Przesunięta przez klienta";
            default: return value;
        }
    },
    "_priceAndCurrency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => {
        if ((value?.price ?? 0) === 0) return "free";
        const currency = (!value?.currency) ? "₹"
            : value?.currency?.toUpperCase() === "INR" ? "₹"
                : value?.currency?.toUpperCase() === "USD" ? "$"
                    : value?.currency?.toUpperCase() === "EUR" ? "€"
                        : value?.currency;
        return `${currency} ${value?.price}`;
    },
    "_currency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => {
        const currency = (!value?.currency) ? "₹"
            : value?.currency?.toUpperCase() === "INR" ? "₹"
                : value?.currency?.toUpperCase() === "USD" ? "$"
                    : value?.currency?.toUpperCase() === "EUR" ? "€"
                        : value?.currency;
        return `${currency}`;
    },
    "_signalMessageType": (value?: SignalMessageType) => {
        switch (value) {
            case undefined: return "";
            case "Custom": return "Ktoś zainteresował się Twoją ofertą";
            case "CompanyViewed": return "Ktoś ogląda Twoją ofertę";
            case "InviteCreated": return "Ktoś szuka terminu wizyty";
            case "VisitCancelled": return "Ktoś anulował wizytę";
            case "VisitConfirmed": return "Ktoś potwierdził wizytę";
            case "VisitRescheduled": return "Ktoś zmienił termin wizyty";
            default: return value;
        }
    },
    "_Go to marketing website": "Info",
    "About the Teacher": "O Nauczycielu",
    "About us": "O nas",
    "Account": "Konto",
    "Account details": "Konto",
    "Account Details": "Konto",
    "Add": "Dodaj",
    "Add some resources to configure your offer": "Dodaj pracowników, żeby dokończyć ofertę",
    "Add some services to configure your offer": "Dodaj usługi, żeby dokończyć ofertę",
    "Add to calendar": "Zapisz w kalendarzu",
    "Add to Calendar": "Zapisz w kalendarzu",
    "Additional info:": "Informacje:",
    "Address": "Adres",
    "All clients": "Wszyscy Klienci",
    "All services": "Wszystkie usługi",
    "Apple or Microsoft": "Apple lub Microsoft",
    "Appointment confirmed": "Potwierdzono spotkanie",
    "Appointment requested": "Spotkanie zgłoszone",
    "appointments": "Spotkania",
    "Are you sure?": "Czy na pewno?",
    "Are you sure that you want to cancel your visit?": "Czy jesteś pewien, żeby anulować wizytę?",
    "at server": "na serwerze",
    "Austria": "Austria",
    "Average:": "Średnia:",
    "Back": "Wróć",
    "Badge Level": "Odznaka jakości",
    "Banned clients": "Zablokowani",
    "Belgium": "Belgia",
    "Block - the student will not be allowed to join a class": "Block - the student will not be allowed to join a class",
    "Classes": "Zajęcia",
    "Join": "Wybierz",
    "join and pay": "dołącz i zapłać",
    "Join Now": "dołącz",
    "Join again": "dołącz ponownie",
    "Join a class": "Dołącz do zajęć",
    "Your ClassNow:": "Twoje zajęcia:",
    "ClassNow Category": "Kategoria ClassNow",
    "Cancel": "Anuluj",
    "Cancel Visit": "Anuluj spotkanie",
    "Cancel visit?": "Czy anulować spotkanie?",
    "cannot be empty": "wartość wymagana",
    "Cannot login?": "Nie możesz się zalogować?",
    "Certificates": "Certyfikaty",
    "Change": "Zmień",
    "Click OK to un-block this client.": "Naciśnij OK, żeby ODBLOKOWAĆ klienta.",
    "Click OK to block this client from your company.": "Naciśnij OK, żeby ZABLOKOWAĆ klienta.",
    "Client Name": "Klient",
    "Client Phone": "Telefon Klienta",
    "Clients": "Klienci",
    "Closed": "Zamknięte",
    "Company Calendar": "Kalendarz firmowy",
    "Company Email": "Email firmowy",
    "Company ID": "ID firmy",
    "Company description:": "Opis firmy",
    "Company Name": "Nazwa firmy",
    "Company Website": "Strona firmowa www",
    "Completed": "Zakończone",
    "Completed visits:": "Wizyty zakończone:",
    "Confirm the visit to client": "Potwierdzam wizytę klienta",
    "Contact Details for Clients": "Dane kontaktowe dla klientów",
    "Contact Details": "Dane kontaktowe",
    "Contact details:": "Dane kontaktowe:",
    "Contact Email for ClassNow": "Email kontaktowy dla ClassNow",
    "Contact Phone for ClassNow": "Telefon kontaktowy dla ClassNow",
    "Contact Us": "Kontakt",
    "contact us and help us resolving the issue.": "Skontaktuj się z nami i pomóż rowiązać ten problem.",
    "Create Company": "Stwórz ofertę",
    "Create Company - save": "Zapisz",
    "Czech Republic": "Czechy",
    "India": "Indie",
    "Day": "Dzień",
    "Delete": "Usuń",
    "Duration [minutes]": "Czas trwania [minuty]",
    "Default Price": "Cena",
    "Demo": "Demo",
    "Description": "Opis",
    "Description:": "Opis:",
    "Details": "Szczegóły",
    "Display Name": "Nazwa",
    "Deutschland": "Niemcy",
    "Do you have any special notes? Leave note here.": "Napisz jeżeli masz dodatkowe uwagi.",
    "Download": "Pobierz",
    "Edit": "Edytuj",
    "Edit Company": "Edytuj firmę",
    "Edit Profile": "Edytuj profil",
    "Edit review": "Edytuj komentarz",
    "emailsubject:Invite to join ClassNow": "Zaproszenie do ClassNow",
    "emailbody1:Check out this new ClassNow: ": "Użyj tego linku, żeby się zarejestrować do ClassNow: ",
    "emailbody2:Remember to use my referral code": " (to część mojego programu parnerskiego)",
    "English": "angielski",
    "Enter a valid email": "Wpisz poprawny email",
    "Enter a valid number": "Wpisz poprawną liczbę",
    "Error occurred:": "Wystąpił błąd:",
    "explore": "odkryj",
    "Explore offers around you": "Odkryj oferty w Twojej okolicy",
    "Favorites": "Ulubione",
    "favourites": "Ulubione",
    "Featured": "Premium",
    "Finish a visit to review it": "Zakończ wizytę, żeby móc ją ocenić",
    "First Name": "Imię",
    "From the total of:": "z następujących:",
    "From the total of:...visits": "wizyt",
    "German": "niemiecki",
    "Google": "Google",
    "Here is your special code.": "Twój specjalny kod.",
    "Home": "Home",
    "https://classnow.in": "https://classnow.in",
    "I agree to ": "Zgadzam się na ",
    "I don't have referral code": "Nie mam kodu partnerskiego",
    "If you convince more than 10 people, you can expect a special prize. Go there! Help us!!!": "Przekonaj conajmniej 10 osób i zdobądź nagrodę specjalną. Podziel się z przyjaciółmi! Pomoż nam!!!",
    "Are you satisfied with ClassNow and want to find even more offers?": "Jesteś zadowolony z ClassNow i chciałbyś mieć nawet więcej ofert?",
    "Then recommend us to your friends, colleagues or other service provider and receive a bonus for every subscription you take out.": "Poleć nas swoim przyjaciołom, znajomym, kolegom i innym, aby otrzymać bonus za każdą subskrypcję.",
    "Get started now, support us and secure your reward!": "Zacznij już teraz, pomóż nam i zapewnij sobie nagrodę!",
    "Invalid data provided.": "Nieprawidłowa wartość.",
    "Invalid number": "Nieprawidłowa wartość",
    "Invite to join ClassNow": "Zaproś do ClassNow",
    "Klarna API url": "Klarna API URL",
    "Klarna Connection Not Working": "Połączenie z Klarna nie działa",
    "Klarna Connection Successful": "Połączenie z Klarna działa",
    "Klarna Connection Test": "Sprawdź połączenie z Klarna",
    "Klarna Intro Text": "Jeśli korzystasz już z Klarna w swojej firmie, tutaj możesz połączyć swoje konto",
    "Klarna Payments": "System płatności z góry - wkrótce dostępny",
    "Klarna username": "Klarna login",
    "Klarna password": "Klarna hasło",
    "Razorpay Connection Not Working": "Połączenie z Razorpay nie działa",
    "Razorpay Connection Successful": "Połączenie z Razorpay działa",
    "Razorpay Connection Test": "Sprawdź połączenie z Razorpay",
    "Razorpay Intro Text": "Jeśli korzystasz już z Razorpay w swojej firmie, tutaj możesz połączyć swoje konto",
    "Razorpay Payments": "System płatności z góry - wkrótce dostępny",
    "Razorpay Api Key": "Razorpay Api Key",
    "Razorpay secret": "Razorpay secret",
    "Language": "Język",
    "Last Name": "Nazwisko",
    "Latitude": "Szerokość geograficzna",
    "Learn more": "Czytaj więcej",
    "loading...": "wczytywanie...",
    "Loading...": "Wczytywanie...",
    "Log in": "Zaloguj",
    "Log into your account": "Zaloguj się",
    "Login": "Zaloguj",
    "Login to finalize": "Zaloguj aby kontynuować",
    "Logout": "Wyloguj",
    "Longitude": "Długość geograficzna",
    "mailto:contact@classnow.in?subject=Login to ClassNow&body=I need assistance with login": "mailto:contact@classnow.in?subject=Rejestracja w ClassNow&body=Potrzebuję pomocy z logowaniem",
    "Map cannot be loaded": "Nie można wczytać mapy",
    "Mark for deletion": "Zaznacz do usunięcia",
    "Mark for publishing": "Pokaż ofertę w serwisie",
    "Month": "Miesiąc",
    "Monthly payments:": "Płatności miesięczne:",
    "Monthly payments:...Clients": "klientów",
    "Must be later": "Musi być później",
    "My Appointments": "Moje spotkania",
    "My Calendar": "Mój kalendarz",
    "Next": "Dalej",
    "No results found": "Brak wyników",
    "No visit selected": "Wybierz wizytę",
    "No, I decided to come": "Nie, jednak przyjdę.",
    "Not getting SMS?": "Nie dostałeś SMS?",
    "Notes:": "Notatki:",
    "OK": "OK",
    "OK, got it": "OK, rozumiem",
    "Online payment impossible": "Płatność online niemożliwa",
    "OR": "LUB",
    "Paid": "Zapłacono",
    "Pay": "Zapłać",
    "Payment ID": "ID płatności",
    "PaymentPage - Title": "Opłać wizytę",
    "PaymentPage - Description": "Nasze płatności są obsługiwane przez zewnętrznego partnera. Możesz sfinalizować transakcję za pomocą przycisku, lub kodu QR.",
    "PaymentPage - Finalize Payment": "Zapłać",
    "Photo": "Zdjęcie",
    "Phone number": "Numer telefonu",
    "PIN": "PIN",
    "Please contact us to change your phone number": "Skontaktuj się z nami, żeby zmienić numer telefonu",
    "Please enter partner code below or check that you don't have it": "Podaj kod partnera lub zaznacz jego brak.",
    "Please define your ClassNow": "Proszę, wybierz datę i czas wizyty",
    "Poland": "Polska",
    "Polish": "polski",
    "Portfolio": "Portfolio",
    "Prepare": "Przygotuj",
    "Privacy Policy": "Polityka prywatności",
    "profile": "profil",
    "Publish": "Publikuj",
    "Rate and review your experience": "Oceń i opisz swoją wizytę",
    "Rating Score": "Ocena",
    "Ready to publish": "Gotowe do publikacji",
    "Recommend": "Rekomenduj",
    "Recommend ClassNow and gain instant benefits": "Wypromuj ClassNow i zyskaj natychmiastowe korzyści",
    "Referral code": "Kod partnera",
    "Referral statistics": "Program partnerski",
    "refresh reminders:": "odśwież przypomnienia:",
    "Replace": "Zamień",
    "Require manual confirmation of the booking": "Wymagaj ręcznego potwierdzenia wizyty",
    "Resource ID": "ID pracownika",
    "Resource Name": "Pracownik",
    "Resource edit: Phone number": "Numer telefonu",
    "Resources": "Pracownicy",
    "Return": "Powrót",
    "Review": "Komentarz",
    "Reviews": "Komentarze",
    "Save": "Zapisz",
    "Save account": "Zapisz konto",
    "Search": "Szukaj",
    "Search bar placeholder": "Szukaj ",
    "Search Keywords": "Słowa konkretnej lekcji",
    "Send": "Wyślij",
    "Send to contacts": "Wyślij do znajomych",
    "Service Category": "Kategoria usługi",
    "Service ID": "ID usługi",
    "Service Name": "Nazwa usługi",
    "Services": "Usługi",
    "Share": "Podziel się",
    "Share your unique code with friends and business owners. When they subscribe to our service, we'll contact you with benefits.": "Przekaż Twój unikalny kod przyjaciołom i dostawcom usług. Gdy zalogują się do ClassNow, skontaktujemy się z Tobą i wręczymy nagrodę",
    "Show directions": "Wskazówki dojazdu",
    "Specify time as HH:mm": "Wprowadź czas jako HH:mm",
    "Staff members:": "Pracownicy:",
    "Statistics &amp; Reports": "Statystyki i Raporty",
    "Subtitle": "Podtytuł",
    "SuperAdmin: Assign Company": "Dołącz do firmy (admin)",
    "Switzerland": "Szwajcaria",
    "ClassNow Tagline": "Startuj Teraz",
    "terms and conditions": "warunki umowy",
    "Terms and Conditions": "Warunki umowy",
    "Terms of Use": "Warunki używania",
    "THANK YOU": "DZIĘKUJEMY",
    "There are no offers that satisfy your criteria. We need to remove your filtering.": "Nie ma ofert, które spełniają Twoje kryteria. Sprobujemy usunąć dodatkowe filtry.",
    "This person has no portoflio": "Ta osoba nie ma portoflio",
    "This person has no reviews yet": "Ta osoba nie ma jeszcze komentarzy",
    "Time after [minutes]": "Czas po [minuty]",
    "Time before [minutes]": "Czas przed [minuty]",
    "Title": "Tytuł",
    "Under construction": "W przygotowaniu",
    "United Arab Emirates": "Zjednoczone Emiraty Arabskie",
    "Unique clients:": "Unikalnych klientow:",
    "Upcoming": "Nadchodzące",
    "Update map": "Aktualizuj mapę",
    "Upfront payment": "Płatność online",
    "Upfront payment - price": "Płatność online (kwota)",
    "Upfront payment - you need to pay upfront for this visit": "Płatność online - możesz zapłacić online za tę usługę",
    "Upfront payment - the client has to pay upfront online while booking a visit": "Dostępne wkrótce - Płatność online - Klient może dokonać płatności online",
    "View as client": "Podgląd",
    "Visit ID": "ID wizyty",
    "Visit Review ID": "ID Recenzji",
    "Visit Start": "Wizyta",
    "Visit Start for Client": "Wizyta (dla klienta)",
    "Visit statistics": "Statystki wizyt",
    "Visit Status": "Status",
    "VisitSummaryCard:When your request is accepted(1/2)": "Gdy Twoje zgłoszenie zostanie zaakceptowane",
    "VisitSummaryCard:When your request is accepted(2/2)": "wyślemy Ci SMS.",
    "VisitSummaryCard:Your request will be answered shortly.": "Wkrótce odpowiedmy na Twoje zgłoszenie.",
    "Waiting for your rating and review:": "Czekamy na Twój komentarz i ocenę:",
    "We need the following information before publishing:": "Potrzebujemy poniższych informacji, żeby opublikować Twoją ofertę:",
    "Week": "Tydzień",
    "What time?": "O której?",
    "Working Hours:": "Godziny pracy:",
    "Working hours": "Godziny pracy",
    "Write here...": "Napisz tutaj...",
    "Write review": "Napisz ocenę",
    "Yearly payments:": "Płatność roczna:",
    "Yearly payments:...Clients": "klientów",
    "Yes, please cancel": "Tak, anuluj",
    "You can select your favourite staff for the service and select your preferred date and time": "Możesz wybrać pracownika oraz datę i czas wizyty",
    "You have no completed visits": "Nie mamy informacji o Twojej zakończonej wizycie",
    "You have no favorites yet": "Nie mamy informacji o Twoich ulubionych firmach",
    "You have no upcoming visits": "Nie mamy informacji o Twoich nadchodzących wizytach",
    "You've earned:": "Zarobiłeś dotychczas:",
    "You've almost earned additional:": "Wkrótce możesz zarobić:",
    "You're done!": "Wizyta umówiona!",
    "We'll send you a reminder": "Wyślemi Ci przypomnienie",
    "Your referrals:": "Twoje polecenia:",
    "Your referrals:...Clients": "klientów",
    "before your appointment": "przed terminem spotkania",
    "Your company calendar...": "Kalendarz Twojej firmy...",
    "Your consent is required": "Twoja zgoda jest wymagana",
    "Your reviews:": "Twoje komentarze:",
    "Your session has expired.": "Twoja sesja wygasła.",
    "Zoom": "Zoom",
    'Besucher': 'Besucher',
    'Dienstleister': 'Dienstleister',
    'Für Besucher': 'Für Besucher',
    'Für Dienstleister': 'Für Dienstleister',
    'Email': 'Email',
    'Nachricht': 'Nachricht',
    'Name': 'Name',
    '100% kostenlos für Besucher': '100% kostenlos für Besucher',
    '100% kostenlos': '100% kostenlos',
    '100% online': '100% online',
    '12 Monate zum Preis von 10': '12 Monate zum Preis von 10',
    '24 / 7 / 365 Termine': '24 / 7 / 365 Termine',
    '24h Buchungsassistent': '24h Buchungsassistent',
    '30 Tage kostenfrei testen': '30 Tage kostenfrei testen',
    '49€': '49€',
    '499€': '499€',
    'ABONNIEREN': 'ABONNIEREN',
    'Als Beispiel: Eine Arzthelferin verbringt um die 50% der Arbeitszeit am Telefon, um aufwendig die Terminanfragen anzunehmen, in den Kalender zu schauen und auch rumzublättern, bis der passende Termin für beide gefunden ist. Dadurch entstehen längere Wartezeiten in der Praxis, und wichtigere Aufgaben bleiben dabei auf der Strecke.': 'Als Beispiel: Eine Arzthelferin verbringt um die 50% der Arbeitszeit am Telefon, um aufwendig die Terminanfragen anzunehmen, in den Kalender zu schauen und auch rumzublättern, bis der passende Termin für beide gefunden ist. Dadurch entstehen längere Wartezeiten in der Praxis, und wichtigere Aufgaben bleiben dabei auf der Strecke.',
    'Am Sandfeld 18, 76149 Karlsruhe': 'Am Sandfeld 18, 76149 Karlsruhe',
    'Angebote für Dienstleister': 'Angebote für Dienstleister',
    'Buchen': 'Buchen',
    'Dann schreib uns einfach via Email, oder Kontaktformular an und wir melden uns dann schnellstmöglich bei dir.': 'Dann schreib uns einfach via Email, oder Kontaktformular an und wir melden uns dann schnellstmöglich bei dir.',
    'Datenschutz &amp; AGB': 'Datenschutz &amp; AGB',
    'Direkt und zeitsparend': 'Direkt und zeitsparend',
    'Empfehlung': 'Empfehlung',
    'Erinnerungs-SMS mit Hinweise an Kunden': 'Erinnerungs-SMS mit Hinweise an Kunden',
    'FEATURES': 'FEATURES',
    'Features': 'Features',
    'Genau jetzt kommen wir mit ClassNow ins Spiel. Wir haben uns eine Lösung ausgedacht, die Dir als Kunden, aber auch als Geschäftsbetreiber eine passende Antwort liefert.': 'Genau jetzt kommen wir mit ClassNow ins Spiel. Wir haben uns eine Lösung ausgedacht, die Dir als Kunden, aber auch als Geschäftsbetreiber eine passende Antwort liefert.',
    'Hier sind wir': 'Hier sind wir',
    'Impressum': 'Impressum',
    'Jahres-Abo': 'Jahres-Abo',
    'KATEGORIEN': 'KATEGORIEN',
    'KONTAKT': 'KONTAKT',
    'Kategorien': 'Kategorien',
    'Kennst du das, du rufst beim Arzt an, es einige Minuten hin und her geht, nur um einen freien Termin zu bekommen? Oder wenn Du am Wochenende gerne einen Termin für Montag beim Frisör vereinbaren möchtest, aber niemand geht ans Telefon?': 'Kennst du das, du rufst beim Arzt an, es einige Minuten hin und her geht, nur um einen freien Termin zu bekommen? Oder wenn Du am Wochenende gerne einen Termin für Montag beim Frisör vereinbaren möchtest, aber niemand geht ans Telefon?',
    'Kontaktiere uns': 'Kontaktiere uns',
    'Mit ClassNow kannst du jederzeit nach einem passenden Termin online schauen, buchen und ggfs. auch ändern oder sogar stornieren. Falls Du ein Geschäft betreibst und du es satt hast, ständig am Telefon zu sitzen um die Termine zu koordinieren, ist ClassNow genau das Richtige für dich. Mit ClassNow, als deinen persönlichen‚ rund um die Uhr Termin-Assistenten, hast du mehr Zeit für dich und für die wichtigeren Dinge im privaten oder beruflichen Alltag.': 'Mit ClassNow kannst du jederzeit nach einem passenden Termin online schauen, buchen und ggfs. auch ändern oder sogar stornieren. Falls Du ein Geschäft betreibst und du es satt hast, ständig am Telefon zu sitzen um die Termine zu koordinieren, ist ClassNow genau das Richtige für dich. Mit ClassNow, als deinen persönlichen‚ rund um die Uhr Termin-Assistenten, hast du mehr Zeit für dich und für die wichtigeren Dinge im privaten oder beruflichen Alltag.',
    'Monats-Abo': 'Monats-Abo',
    'Möchtest du gerne als Dienstleister unseren Service nutzen?': 'Möchtest du gerne als Dienstleister unseren Service nutzen?',
    'Nachricht erfolgreich gesendet': 'Nachricht erfolgreich gesendet',
    'Nutze unser Kontaktformular': 'Nutze unser Kontaktformular',
    'PROBIER ES AUS': 'WYPRÓBUJ',
    'Probier es aus': 'Wypróbuj',
    'SCREENSHOTS': 'SCREENSHOTS',
    'SMS-Erinnerung': 'SMS-Erinnerung',
    'Screenshots': 'Screenshots',
    'Senden': 'Senden',
    'Sie werden zu unserem Partner weitergeleitet, um die Zahlung abzuschließen': 'Sie werden zu unserem Partner weitergeleitet, um die Zahlung abzuschließen',
    'TEAM': 'TEAM',
    'Unabhängige Flexibilität': 'Unabhängige Flexibilität',
    'Vorzugspreis': 'Vorzugspreis',
    'WARUM?': 'WARUM?',
    'Warum ClassNow?': 'Warum ClassNow?',
    'Was ist ClassNow?': 'PL_Was ist ClassNow?',
    'bitte füllen Sie das Formular aus': 'bitte füllen Sie das Formular aus',
    'inkl. Werbe-Material': 'inkl. Werbe-Material',
    'jederzeit buchbar': 'jederzeit buchbar',
    'komplette Übersicht': 'komplette Übersicht',
    'monatlich kündbar': 'monatlich kündbar',
    'weniger Zeit am Telefon': 'weniger Zeit am Telefon',
    'ÜBER UNS': 'ÜBER UNS',
    'MIT ClassNow KANNST DU KOSTENLOS &amp; BEQUEM RUND UM DIE UHR DEINEN TERMIN BUCHEN': 'ERSPARE DIR DIE WARTEZEIT UND BUCHE DEINEN SERVICE WIE EIN PROFI MIT ClassNow',
};

export class L10N_current {
    public static resx: L10N_resx = L10N_en;
    public static browserLanguage = navigator.language;

    public static _initialize() {
        // console.log("Initializing to browser language:" + this.browserLanguage);
        this.changeLocale(this.browserLanguage);
    }
    public static changeLocale(lang?: string) {
        // console.log("Changing language to:" + lang);
        const effectiveLang = lang ?? this.browserLanguage;
        switch (effectiveLang?.toLowerCase()) {
            case "pl": this.resx = L10N_pl; break;
            case "en": this.resx = L10N_en; break;
            default: this.resx = L10N_en; break;
        }
    }
    public static getLocale() {
        return this.resx.resx_locale;
    }
}
L10N_current._initialize();

export const L10N = (props: {
    key?: keyof (L10N_resx),
    todo?: keyof (L10N_resx) | string,
    date?: string,
    price?: PriceAndCurrency | PriceAndCurrencyEditModel,
    duration?: number,

    cat?: string,
    hint?: string,
    editStep?: EditStep,
    status?: VisitStatusEnum,
    signalMessageType?: SignalMessageType,
}) => {
    function translate(): string {
        if (props.todo) return props.todo;
        if (!props.key) return "?0?";
        if (props.key in L10N_date_en) {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_date)];
            return mapped(props.date);
        } else if (props.key in L10N_duration_en) {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_duration)];
            return mapped(props.duration);
        } else if (props.key in L10N_category_en) {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_category)];
            return mapped(props.cat);
        } else if (props.key in L10N_hint_en) {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_hint)];
            return mapped(props.hint);
        } else if (props.key in L10N_editStep_en) {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_editStep)];
            return mapped(props.editStep);
        } else if (props.key in L10N_status_en) {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_status)];
            return mapped(props.status);
        } else if (props.key in L10N_price_en) {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_price)];
            return mapped(props.price);
        } else if (props.key in L10N_signal_en) {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_signal)];
            return mapped(props.signalMessageType);
        } else {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_keys)];
            return mapped;
        }
    }
    const showDebug = false;
    function wrap(): string {
        if (showDebug) return "*" + translate();
        else return translate();
    }
    return wrap();
}
