import { Accordion, AccordionDetails, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { api } from 'src/api/api';
import { PhotoEditModel } from 'src/api/models/Edit';
import { L10N } from '../pages/L10N';
import { AvatarUpload } from '../ui/AvatarUpload';
import { CspIcon } from '../ui/CspIcon';
import { FormikFieldProps } from '../ui/FormikAPI';
import { LogotypeSpans } from "../ui/LogotypeSpans";
import { StyledTitleRow } from '../ui/StyledTitle';
import { FieldSetProps } from './FormTeacherWizard';

export const FieldSetExperience = (props: FieldSetProps) => {

    return <>
        <Accordion defaultExpanded={false} >
            <AccordionDetails className="d-block pt-3 my-3">
                <Row>
                    <Col xs={12} md={6} >
                        <StyledTitleRow text={"Yourself as a professional"} hideDivider subtitle />
                        <TextField className="mb-3"
                            label={L10N({ todo: "Short description of your experience" })}
                            {...FormikFieldProps(props.formik, m => m.description)}
                        /><br />
                        <StyledTitleRow text={"Your educational and experience certificates or resume"} hideDivider subtitle />
                        <Row>
                            {props.formik.values.certificatePhotos?.length > 0
                                ? props.formik.values.certificatePhotos.map((m, i) =>
                                    <Col key={m.photoIndex} className="col-auto">
                                        <AvatarUpload
                                            variant="rounded"
                                            src={props.formik.values.certificatePhotos?.[i]?.base64 ?? api.Search.getPhotoUrl(m) ?? ""}
                                            editMode={true}
                                            onChange={async (base64) => { await props.formik.setFieldValue(`certificatePhotos[${i}].base64`, base64) }}
                                            fileId={"certificatePhotos" + i}
                                            alt={props.formik.values.displayName} />
                                    </Col>
                                )
                                :
                                <Col className="col-auto">
                                    <AvatarUpload
                                        variant="rounded"
                                        src=""
                                        addMode
                                        onChange={async (base64) => {
                                            await props.formik.setFieldValue(`certificatePhotos[0]`, {
                                                base64: base64,
                                                accessToken: "",
                                                objectID: props.formik.values.certificatePhotosOwnerID,
                                                photoIndex: 0,
                                                modificationDate: moment().toISOString(),
                                                isDeleted: false,
                                                position: 'Default',
                                                url: '',
                                            } as PhotoEditModel)
                                        }}
                                        fileId={"certificatePhotosnew"}
                                        alt={props.formik.values.displayName} />
                                </Col>
                            }
                        </Row>
                    </Col>
                    <Col xs={12} md={6} >
                        <StyledTitleRow text={"Why is it important?"} hideDivider subtitle />
                        <Typography color="secondary">
                            Short description and a proof of your experience help us in:
                            <br />
                            - Verification and Credibility
                            <br />
                            - Professional Growth and Opportunity
                            <br />
                            - Efficiency in Application Processes
                            <br />
                            <br />
                            At <LogotypeSpans /> we review all candidates personally to maintain highest quality <CspIcon name="WorkspacePremiumIcon" />.
                        </Typography>
                    </Col>
                </Row>
            </AccordionDetails>
        </Accordion>
    </>;
}