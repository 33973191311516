import { Avatar, AvatarGroup, Button } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { api } from "src/api/api";
import { TeacherWizardEditModel } from "src/api/models/TeacherWizard";

export const TeacherWizardList = (props: {
    teacherWizardList: TeacherWizardEditModel[],
    editTeacherWizard: (userID?: string) => Promise<void>,
    deleteTeacherWizard: (userID?: string) => Promise<void>,
}) => {
    return (
        <>
            <Row className="m\y-3">
                <Col >
                    <table className="table table-striped table-bordered">
                        <caption>List of Teachers</caption>
                        <thead>
                            <tr>
                                <th scope="col">No.</th>
                                <th scope="col">Photo</th>
                                <th scope="col">Name</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Email</th>
                                <th scope="col">Category</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.teacherWizardList.map((m, i) => <tr key={i}>
                                <td>{i + 1}.</td>
                                <td>
                                    <AvatarGroup>
                                        <Avatar
                                            src={api.Search.getFirstPhotoUrl(m?.cardPhotos)}
                                            alt={m?.firstName ?? "name"} />
                                        <Avatar
                                            src={api.Search.getFirstPhotoUrl(m?.avatarPhotos)}
                                            alt={m?.firstName ?? "name"} />
                                        <Avatar
                                            src={api.Search.getFirstPhotoUrl(m?.certificatePhotos)}
                                            alt={m?.firstName ?? "name"} />
                                    </AvatarGroup>
                                </td>
                                <td>{m.firstName} {m.lastName} [{m.displayName}]</td>
                                <td>{m.eduinPhone}</td>
                                <td>{m.eduinEmail}</td>
                                <td>{m.category}</td>
                                <td>
                                    <Button onClick={async () => props.editTeacherWizard(m.userID)}
                                        className="m-1"
                                        variant="outlined" color="primary" size="small">
                                        Edit
                                    </Button>
                                    <Button onClick={async () => props.deleteTeacherWizard(m.userID)}
                                        className="m-1"
                                        variant="outlined" color="primary" size="small">
                                        Delete
                                    </Button>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </>
    );
};
